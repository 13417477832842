import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Nimai/nimai_place_top_banner_6_1dec22.jpg";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";
import NimaiData from "../../Data/ListData/Nimai";

function NimaiListing() {
  TabTitle("Jeet Estate | Nimai Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Nimai is a brand that is known for delivering world-class
              commercial projects in NCR. Nimai Developers Pvt Ltd is a real
              estate company that is in the process of transforming an entire
              industry. This company has a diversified range of complexes that
              attract shop buyers. In addition, Nimai Developers was founded in
              1992 to build residential and commercial projects. But, this brand
              has immense expertise in commercial plazas. Thus, it is developing
              structures with comfort, quality, & convenience. This brand is
              widely recognized in Gurgaon, it has several landmark projects in
              this city, but the most attractive and worthy one is Nimai Place
              in Sector 114.
            </p>
            <div className="row">
              {NimaiData &&
                NimaiData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default NimaiListing;
