import React from "react";
import Layout from "../../Layout/Layout";
import dataMgroadRes from "../../Data/Residential/Mgroad";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function SohnaRoads() {
  TabTitle('Jeet Estate | Sohna Road')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              Residential Projects on
              <span style={{ color: "rgb(190, 144, 64)" }}>
                {" "}
                Sohna Road, Gurgaon
              </span>
            </h2>
            <p>
              Sohna Road is one of the 4 subdivisions of the Gurgaon District.
              It is a magnificent 21.65 km long locality that connects Gurgaon
              with Sohna. In addition, Sohna Road is a part of NH 248-A, making
              it an investment opportunity for Millenials and Gen Z of Gurgaon.
              Today, Sohna Road has all the facilities that anyone requires for
              an exemplary lifestyle. This corridor has best-in-class shopping
              malls, recreational centers, business parks, & residential
              buildings like; Serenity At Godrej Nature Plus, Central Park 2 The
              Room, Ashiana Anmol, Central Park Flower Valley, Orchid Petals, &
              Central Park 2 Bellevue. These premises are available at an
              exclusive price at Gupta Promoters and offer you a diversified
              range of affordable homes.
            </p>
            <div className="row">
            {dataMgroadRes &&
                dataMgroadRes.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default SohnaRoads;
