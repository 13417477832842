import React from "react";
import Layout from "../../Layout/Layout";
import golfcourseextensionroadData from "../../Data/golfcourseextensionroad";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaLocationArrow } from "react-icons/fa";
import { TabTitle } from "../../../functionTitle";

function GolfCourseExtensionRoad() {
  TabTitle('Jeet Estate | Golf Extension Road')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              Golf Course
              <span style={{ color: "rgb(190, 144, 64)" }}>
                {" "}
                Extension Road
              </span>
            </h2>
            <p>
              Golf Course Extension Road is one of the perfect business
              destinations in Gurgaon. It is investors' first choice for the
              last few years. This locality is an incredible space for MNCs,
              Shopping Malls, Business Centres, & Commercial Plazas. Many real
              estate firms are actively interested in the infrastructure of this
              region. Tata, M3M, AIPL, Mahindra, Adani, & many other giants have
              already introduced their landmarks on Golf Course Extension Road.
              In the list of most thriving commercial developments of Golf
              Course, Extension Road is M3M ROute 65, M3M Prive 73, M3M
              Broadway, Elan Paradise, M3M Corner Walk, Vatika Professional
              Point, Spaze Edge, Spaze IT Park, etc.
            </p>
            <div className="row">
              {golfcourseextensionroadData.map((items) => {
                return (
                  <div className="col-lg-4 mb-3">
                    <Link to="#" className="box--project">
                      <div className="card prop-main p-4">
                      <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                        <div className="img--box">
                          <img src={items.img} alt={items.name} />
                          
                        </div>
                        <h3>{items.name}</h3>
                        <p>{items.desc}</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default GolfCourseExtensionRoad;
