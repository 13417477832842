import React from "react";
import Layout from "../Layout/Layout";
import pdfdownload from "../../assets/Logo/sohna-master-plan-2031-map.pdf";
import { FaDownload } from "react-icons/fa";
import { TabTitle } from "../../functionTitle";

function SohnaMasterPlan() { 
  TabTitle('Jeet Estate | Sohna Master Plan')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
            Sohna 
              <span style={{ color: "rgb(190, 144, 64)" }}>
                {" "}
                Master Plan
              </span>
            </h2>
            <p>
            Sohna Master Plan 2031 has been prepared to provide vision & regulations for development and building activity in the city of Sohna in Haryana. It envisages a population of 6.4 lakhs by 2031 in Sohna. Accordingly 5600 hectares of land is earmarked as urbanisable area under the proposed land use by 2031 in Sohna master plan.
            </p>
            <p>
            The town is very well served by frequent bus services from all sides. The Kundli-Manesar-Palwal (KMP) Expressway which is under construction is passing by the southern side of the town. This is a major artery in western side of Delhi in the NCR portion of Haryana connecting Kundli to Palwal via Manesar. Two clover- leaf junctions are being developed on the KMP Expressway that will enhance accessibility of this region substantially.
            </p>
            <p>
            A 50 m wide strip of land has been reserved for Orbital Rail Corridor along KMP Expressway towards Delhi side. The Dedicated Freight Corridor (DFC) linking Delhi to Mumbai, is also passing by the south- eastern side of the town. The land for DFC has already been acquired. Thus, the Sohna Town has high potential for development.
            </p>
            <p>
            Sohna is located 24 km from the city on the highway from Gurgaon to Alwar, near a vertical rock and is an important junction at Palwal-Sohna-Rewari (N.H.-71) road. Other important cities/towns in the region around Sohna are Delhi (56 km.), Gurgaon (24 km.), Faridabad (35 km), Palwal (30 km), Nuh (20 km) and Rewari (45 km).
            </p>
            
            <a
              href={pdfdownload}
              style={{ width: "fit-content" }}
              class="btn btn-outline-primary"
              target="_blank"
              rel="noreferrer"
            >
              Gurgaon Master Plan 2031 PDF Download <FaDownload />{" "}
            </a>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default SohnaMasterPlan;

