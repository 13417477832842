import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Smartworld/smartworld-the edition-sector-66-banner-77354.jpg";
import SmartworldData from "../../Data/ListData/Smartworld";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function SmartworldListing() {
  TabTitle("Jeet Estate | Smart World Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Smart World The Edition Sector 66 Gurugram has been meticulously
              planned by the company. The living room�s walls and ceilings are
              painted with acrylic emulsion on bring-in marble parquet. The
              master bedroom has laminate flooring, and the walls are decorated
              with acrylic emulsion. The balconies have lusterless ceramic
              flooring and walls and ceilings that are watertight. The balcony�s
              parapet is made of stone coping with a tough glass barrier. The
              development is deliberately located in Gurgaon. Prices for Smart
              World Sector 66 Gurgaon are thus expected to increase in the
              coming months as well. The age we live in demands much more than
              our capability. It asks for time, passion, and unlimited energy.
              Time is sumptuous and must be saved to savor the experiences that
              make us who we are. This is why it is ready to give the
              contemporary lifestyle a makeover, with Smart World The Edition In
              Gurgaon, the most upbeat version of an urban experience. We
              present accessibility and access so that everything you need and
              love is just a minute�s walk from your home. Live impulsively and
              cherish moments of togetherness with friends and family, without
              ever having to plan for it. We carry to you the 1 minute World,
              where requirements will never have to wait again.
            </p>
            <div className="row">
              {SmartworldData &&
                SmartworldData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default SmartworldListing;
