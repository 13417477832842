import React from "react";
import Layout from "../../Layout/Layout";
import { TabTitle } from "../../../functionTitle";

function SohnaRoad() {
  TabTitle('Jeet Estate | Sohna Road ')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              Gurgaon
              <span style={{ color: "rgb(190, 144, 64)" }}> Sohna Road</span>
            </h2>
            <p>
              Sohna Road is one of the best places to invest in Gurgaon. It is
              already a hotspot of business with some Tech & Finance giants.
              Whether you buy a retail outlet, shop, plaza, or office space, it
              will provide you with the best returns in the Future. The property
              market on Sohna Road is in high demand, it is a type of tradition
              in the suburb of Gurgaon. Silverglades The Melia, AIPL Autograph,
              Spaza Boulevard, JMD Galleria, Iris Tech Park, Spaze Platinum, JMP
              IT Megapolis, & Vipul Trade Center are the most aspiring
              commercial developments on Sohna Road. Explore Shops & Office
              Space on these premises and book them exclusively at Jeet Estate Consultant.
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default SohnaRoad;
