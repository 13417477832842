
import Emaarlogo from "../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project8/ryxffghf.jpg"
import Emaar1 from "../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project8/1.jpg"
import Emaar2 from "../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project8/2.jpg"
import Emaarf1 from "../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project8/f1.png"
import img8 from "../../assets/Project/EmaarHome/1.jpg"


const EmaarDigiHomesDate = [
    {
        id: "1",
        name: "Emaar Digi Homes",
        img: img8,
        location: " Sector 62, Gurgaon",
        link: "Emaar-Digi-Homes",
        zip: "122002",
        logo: Emaarlogo,
        price: "Starting Price ₹ 7.18 Cr*",
        steps: [
          {
            id: "1",
            img: Emaar1,
          },
          {
            id: "2",
            img: Emaar2,
          },
        ],
        fec: [
          {
            id: "1",
            fcname: "24*7 Power Backup",
          },
          {
            id: "2",
            fcname: "Chair Accessible",
          },
          {
            id: "3",
            fcname: "Electricity",
          },
          {
            id: "4",
            fcname: "Parking",
          },
          {
            id: "5",
            fcname: "SecurityWiFi",
          },
          {
            id: "6",
            fcname: "WiFi",
          },
        ],
        desc: [
          {
            id: "1",
            pra: "Emaar Digi Homes is a luxury Residential project in Sector 62 Gurgaon by Emaar India Group. As the Name specifies Digital Homes, these homes are designed to work on your Touch and Voice Commands. Here, they are offering 2BHK and 3BHK Apartments ranging from 1508 sq.ft to 2589 sqft with Abundant of space by using the optimum area without disrupting one Luxury Feeling.",
          },
          {
            id: "2",
            pra: "Emaar Digi Homes are in the Heart of the Prime Golf Course Extension Road. Here, They Promise for Best towards Quality and Luxury Living. This Project consists of Three High Towers and In Each Tower, there are four Apartments. All the Facilities are provided here to be you more relaxed, de-stress and comfortable.",
          },
          {
            id: "3",
            pra: "In Emaar Digi Homes Sector 62, All the Apartments are created to put a smile on every one Face. Every apartment here is well designed and open Indoor so that Every Angle of the House looks Elegant. This Project takes care of your Necessary requirements and makes a Beautiful place for every Inhabitant in the Community. These Homes have plenty of Greenery which makes an Enjoyable Ambience. Here, You will get all the Modern Luxuries like Swimming Pool, Gym, Clubhouse, Kids Play Area and so on. These Luxury Homes with Greenery Surrounding is the Best Option for you and your Family Members.",
          },
        ],
        hig: [
          {
            id: "1",
            name: "Onsite Salon, Restaurant, & ATM. Emaar Digi Homes have Yoga Zone & Meditation Area. Emaar Digi Homes Gurgaon has automation technology. Three levels for Clubhouse with indoor & party games.",
          },
          {
            id: "2",
            name: "Emaar Digi Homes has 32 floors in each tower. Emaar Digi Homes are crafted with moderate ventilation level. Emaar Digi Homes are fitted with modern ergonomic furnishings. Emaar Digi Homes floor plan offers three sides open apartments. Emaar Digi Homes are designed with wooden flooring & vitrified tiles.",
          },
          {
            id: "3",
            name: "This property is situated in Sector 62, near Golf Course Road. It is in the vicinity of Sohna Road, Southern Peripheral Road, & Go;f Course Road.",
          },
          {
            id: "4",
            name: "Emaar Digi Homes are only 4 km from Sector 55-56 Rapid Metro Station. IGI Airport is only 30 minutes drive from Emaar Digi Homes. This property is surrounded by top business centres & schools.",
          },
          {
            id: "5",
            name: "Emaar Digi Homes has 3 side open apartments. There is an exquisitely designed clubhouse in 30,000+ sq. ft.",
          },
          {
            id: "6",
            name: "This property is seamlessly connected with Golf Course Road & Sohna Road. Apartments are equipped with digital features like Voice & Touch command controller.",
          },
        ],
        table: [
          {
            id: "1",
            type: "2 BHK",
            Saleable_Area: "1508  sq. ft",
            price: "On Request",
            fimg: Emaarf1,
          },
          {
            id: "2",
            type: "3BHK + Utility (T-1)",
            Saleable_Area: "2567  sq. ft",
            price: "On Request",
            
          },
          {
            id: "3",
            type: "3BHK + Utility (T-2)",
            Saleable_Area: "2589  sq. ft",
            price: "On Request",
            
          },
        ],
        descD: "Emaar Digi Homes is a luxury Residential project in Sector 62 Gurgaon by Emaar India Group. As the Name specifies Digital Homes, these homes are designed to work on your Touch and Voice Commands. Here, they are offering 2BHK and 3BHK Apartments ranging from 1508 sq.ft to 2589 sqft with Abundant of space by using the optimum area without disrupting one Luxury Feeling.",
        mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.536343705625!2d77.08275167545551!3d28.403068894463946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d221b3ab9c24f%3A0xd8f49965c365fd6!2sEmaar%20Digi%20Homes!5e0!3m2!1sen!2sin!4v1702299924582!5m2!1sen!2sin",
      },
]

export default EmaarDigiHomesDate; 