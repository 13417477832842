import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import BackToTopButton from '../Pages/BackToTopButton'


function Layout({children}) {
  return (
    <>
     <Header/>
      <div>{children}</div>
      <BackToTopButton />
      <Footer />
    </>
  )
}

export default Layout