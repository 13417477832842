import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Birla/Birla_Navya_Elevation-q28l7sw025yyx0bmdlqszgtknvyj3oyrxj1fx958e0.jpg";
import BirlaData from "../../Data/ListData/Birla";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function BirlaListing() {
  TabTitle("Jeet Estate | Birla Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Birla Estates is a real estate firm owned by B.K. Birla Group Of
              Companies. This is an iconic brand that transforms the real estate
              market of India. With an extraordinary perception and experience,
              Birla Estate is not only designing homes but values in Millenium
              City. This brand is known for its transparency & on-time
              construction deliveries. It follows the standards of health and
              security and provides world-class homes for you and your loved
              ones. Birla Navya is the recent launch of the brand, it is a
              customer-centric approach that is built along with landscape
              greenery, seamless connectivity, & between impeccable retail and
              social infrastructure.
            </p>
            <div className="row">
              {BirlaData &&
                BirlaData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default BirlaListing;
