import img1 from "../../../assets/Project/Silverglades/1.jpg"



const SilvergladesData = [
    {
        id: "1",
        name: "Silverglades – The Melia",
        location: "Sector 114, Gurgaon",
        img: img1,
        desc: "Silverglades The Melia is a flagship residential property in Sector 35 Gurgaon. This is a masterpiece that attracts creative people. It is a residential complex offering apartments in 2, 3, & 4 BHK sizes. Here you have amazing units in between 1425 to 2435 sq. ft. area.        "
    },
    
    
]


export default SilvergladesData;