import React from "react";
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";
import PropertiesData from "../Data/PropertiesData.js";
import "../../assets/css/style.css";

function Properties() {
  return (
    <>
      <Layout>
        <div className="box p-5">
          <div className="container">
            <div className="row">
              {PropertiesData &&
                PropertiesData.map((product) => {
                  return (
                    <>
                      <div key={product.id} className="col-lg-3 mb-4">
                        <Link to={`/Properties/${product.id}/${product.link}`}>
                          <div className="card prop-main">
                            <img
                              alt=""
                              className="swiper-lazy swiper-lazy-loaded"
                              src={product.img}
                            />
                            <div className="prop-box">
                              <div className="prop-title-name">
                                {product.name}
                              </div>
                              <div className="prop-loc">{product.location}</div>
                              <div className="prop-type">
                                {product.UnitType}
                              </div>
                              <div className="prop-size">{product.Sizes}</div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </>
                  );
                })}
            </div>
            
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Properties;
