import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Center/ev-centralpark-header-size_1230x400.jpeg";
import CentralParkData from "../../Data/ListData/CentralPark";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function CentralParkListing() {
  TabTitle("Jeet Estate | Central Park Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Central Park is a modern brand that introduces world-class
              renowned homes in Gurgaon & NCR. It is delivering an elevated
              experience and luxury residences that attract homebuyers.
              Residential complexes of Central Park are equipped with 5-star
              hospitality service and situated in sought-after locations. These
              are incredible homes available at an unbelievable price with
              maximum features. It is a real estate firm that can provide you
              with an opulent lifestyle in a way that you never imagined. In
              addition, Central Park real estate firm is also known for its
              values & commitments. It delivers projects on time with
              transparent paperwork. Central Park 2 Bellevue, Central Park 2 The
              Room, & Central Park Flower Valley are the three recent
              residential projects of Central Park.
            </p>
            <div className="row">
              {CentralParkData &&
                CentralParkData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default CentralParkListing;
