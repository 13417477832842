import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Ireo/ireo1-q28l2j0lpmr1rrzf6bo5zsqgo1ydwu0zvf9czcyraw.jpg";
import IreoData from "../../Data/ListData/Ireo";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function IreoListing() {
  TabTitle("Jeet Estate | Ireo Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Ireo is one of the top developers in Pan India. This brand started
              construction in India in 2003 and invested approx 1.3 billion USD
              till 2022. Ireo has 3,000+ acres of land in its portfolio and is
              still expanding. It is an ideal brand to offer well-planned gated
              communities in the prime spots of the city. Iros recently
              introduced 8 stylish complexes, including residential and
              commercial plazas. These are the top class solutions, integrated
              with exceptional features & facilities. Additionally, Ireo is
              constructing luxury budget accommodations so that even a lower
              middle class can afford a perfect home in Gurgaon.
            </p>
            <div className="row">
              {IreoData &&
                IreoData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default IreoListing;
