import img1 from "../../../assets/Project/Smartworld/1.jpg"
import img2 from "../../../assets/Project/Smartworld/2.jpg"
import img3 from "../../../assets/Project/Smartworld/3.jpg"



const SmartworldData = [
    {
        id: "1",
        name: "Smart World One DXP",
        location: "Sector 113, Gurgaon",
        img: img1,
        desc: "Smartworld One DXP is a prestigious residential property located alongside Dwarka Expressway, Sector 113, Gurgaon. This project is well-designed by the International Developer, i.e. SmartWorld Developers. It is an ultra-luxurious township that offers an amazing living experience to its residents.        "
    },
    {
        id: "2",
        name: "Smart World Gems Sector 89",
        location: "Sector 89, Gurgaon",
        img: img2,
        desc: "Smart World Developers – City of Dreams, a new name in the Real Estate Sector has come up with a new residential development – Smart World Gems 89 Gurgaon. The township is innovative, unique and fully equipped with the latest technology.        "
    },
    {
        id: "3",
        name: "Smart World Orchad",
        location: "Sector 61, Gurgaon",
        img: img3,
        desc: "Recently stepped into the world of offering architectural marvels, Smart World has launched a residential project Smart World Orchard Sector 61 Gurgaon. This project is a perfect opportunity to live in an ambience that mixes both convenience and comfort at the same time.        "
    },
    
]


export default SmartworldData;