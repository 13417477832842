import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/ABW/ak_632_1250281961-1515740982_700x700.png";
import AbwCommercialData from "../../Data/ListData/AbwCommercial";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function AbwCommercialListing() {
  TabTitle("Jeet Estate | Abw Commercial Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Established in the year 1988, ABW Group is a leading real estate
              builder based in Delhi, NCR. Mr. Atul Bansal is the founder of the
              company. ABW Group builder is an ISO certified company with its
              strong presence in South Delhi and Gurgaon. The primary business
              of this construction firm includes development of residential and
              commercial projects. So far, ABW Group Construction has developed
              a total built up area of 1 million sq. ft. in prominent localities
              of South Delhi like Jor Bagh, Malcha Marg, Golf Links, Shanti
              Niketan and West End.
            </p>
            <div className="row">
              {AbwCommercialData &&
                AbwCommercialData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AbwCommercialListing;
