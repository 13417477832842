import React from "react";
import Layout from "../Layout/Layout";
import contactBanner from "../../assets/contactBanner.png";
import { TabTitle } from "../../functionTitle";
import Iframe from "react-iframe";

function Contact() {
  TabTitle('Jeet Estate | Contact')
  return (
    <>
      <Layout>
        <div className="main-box">
          <div className="banner-box">
            <img src={contactBanner} alt="" />
            <div className="banner-text">
              <h3>Contact Us</h3>
            </div>
          </div>
          <div className="container">
            <div className="main-box-padd">
              <p className="heading-text">Contact Us</p>
              <div className="row">
                <div className="col-lg-8">
                  <div className="card prop-main p-5">
                    <form>
                      <div class="form-row row">
                        <div class="col-lg-6 col-sm-12 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="First name"
                          />
                        </div>
                        <div class="col-lg-6 col-sm-12 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Last name"
                          />
                        </div>
                        <div class="col-lg-12 col-sm-12 mb-3">
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Email Address"
                          />
                        </div>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlTextarea1"
                            class="form-label"
                          >
                            Message
                          </label>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </div>
                        <button class="btn btn-danger" type="submit">Button</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card prop-main p-4">
                    <p class="padding-top">
                      <strong>For inquiries</strong>
                      <strong>&nbsp;contact:</strong>
                    </p>
                    <strong>Amy Miller</strong>
                    <p>
                      Public Relations Manager 774 NE 84th St Miami, FL 33879
                      amy.miller@houzez.com
                    </p>
                    <strong>Kyle Parker</strong>
                    <p>
                      Public Relations Associated 774 NE 84th St Miami, FL 33879
                      kyle.parker@houzez.com
                    </p>
                  </div>
                </div>
              </div>
              <div className="card card prop-main mt-4">
                <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224567.72023372515!2d76.82493817188703!3d28.42316029174212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19d582e38859%3A0x2cf5fe8e5c64b1e!2sGurugram%2C%20Haryana!5e0!3m2!1sen!2sin!4v1696682405743!5m2!1sen!2sin" width="100%" height="650"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Contact;
