import img1 from "../../../assets/Project/Puri/1.jpg"



const PuriData = [
    {
        id: "1",
        name: "Puri The Aravallis",
        location: "Sector 61, Gurgaon",
        img: img1,
        desc: "Puri The Aravallis is a residential project by Puri Builders on 10.5 Acre of land with Four glittering towers, each comprising Magnificent 42 floors. It is positioned in Sector 61, Gurgaon & its strategic location is on Golf Course Extension Road. Puri The Aravallis is in the proximity of Sector 54 Chowk Rapid Metro Station & only a few miles from Huda City Centre. In addition, it is an iconic luxury residence project, introduced in July 2022 & its expected possession is August 2026.        "
    },
    
]


export default PuriData;