import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/AMB/amb-22-header-fahnen-1050x320.jpg";
import AMBData from "../../Data/ListData/AMB";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function AMBListing() {
  TabTitle("Jeet Estate | AMB Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              AMB is one of the best real estate developers in Gurgaon. This
              brand transforms the way of work and life. It is a progressive
              real estate firm dedicated to professionalism and infrastructure
              development. AMB is known for designing and building world-class
              renowned commercial projects. The core values of this firm
              include; Transparency, Accuracy, & Master Planning. In addition,
              AMB is not building commercial complexes, but building assets with
              an extraordinary experience. Recently this brand introduced two
              commercial complexes; AMB Selfie Street & AMB Selfie Square. These
              are magnificent projects that are attractive and already set an
              example of success.
            </p>
            <div className="row">
              {AMBData &&
                AMBData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AMBListing;
