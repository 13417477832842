import React from "react";
import "./index.css";
import {  Route, Switch } from "react-router-dom";
import Home from "./components/Pages/Home";
import Properties from "./components/Pages/Properties";
import PropertiesDetails from "./components/Pages/PropertiesDetails";
import About from "./components/Pages/About";
import Contact from "./components/Pages/Contact";
import GolfCourseRoad from "./components/Pages/CommercialProjects/GolfCourseRoad";

import Pagenotfound from "./components/Pages/Pagenotfound"
import Map from "./components/Pages/map";
import GurgaonMasterPlan from "./components/Pages/GurgaonMasterPlan";
import DwarkaExpresswayMap from "./components/Pages/DwarkaExpresswayMap";
import SohnaMasterPlan from "./components/Pages/SohnaMasterPlan";
import MGRoad from "./components/Pages/CommercialProjects/MGRoad";
import GolfCourseExtensionRoad from "./components/Pages/CommercialProjects/GolfCourseExtensionRoad";
import DwarkaExpressway from "./components/Pages/CommercialProjects/DwarkaExpressway";
import SohnaRoad from "./components/Pages/CommercialProjects/SohnaRoad";
import SohnaRoads from "./components/Pages/ResidentialProjects/SohnaRoad";
import GolfExtensionRoad from "./components/Pages/ResidentialProjects/GolfExtensionRoad";
import GolfCourseRoad2 from "./components/Pages/ResidentialProjects/GolfCourseRoad";
import DwarkaExpressway2 from "./components/Pages/ResidentialProjects/DwarkaExpressway";
import DLFListing from "./components/Pages/BuildersProjects/DLFListing";
import AshianaListing from "./components/Pages/BuildersProjects/AshianaListing";
import M3MListing from "./components/Pages/BuildersProjects/M3MListing";
import ResidentialProject from "./components/Pages/ResidentialProjects/ResidentialProject";
import DwarkaDetails from "./components/Pages/ResidentialProjects/DwarkaDetails";
import GolfexDetails from "./components/Pages/ResidentialProjects/GolfexDetails";
import AbwCommercialListing from "./components/Pages/BuildersProjects/AbwCommercialListing";
import AIPLListings from "./components/Pages/BuildersProjects/AIPLListings";
import AMBListing from "./components/Pages/BuildersProjects/AMBListing";
import AnsalListing from "./components/Pages/BuildersProjects/AnsalListing";
import BirlaListing from "./components/Pages/BuildersProjects/BirlaListing";
import CentralParkListing from "./components/Pages/BuildersProjects/CentralParkListing";
import ElanListing from "./components/Pages/BuildersProjects/ElanListing";
import GodrejListing from "./components/Pages/BuildersProjects/GodrejListing";
import IreoListing from "./components/Pages/BuildersProjects/IreoListing";
import NimaiListing from "./components/Pages/BuildersProjects/NimaiListing";
import PuriListing from "./components/Pages/BuildersProjects/PuriListing";
import SignatureGlobalListing from "./components/Pages/BuildersProjects/SignatureGlobalListing";
import SilvergladesListing from "./components/Pages/BuildersProjects/SilvergladesListing";
import SmartworldListing from "./components/Pages/BuildersProjects/SmartworldListing";
import SobhaListing from "./components/Pages/BuildersProjects/SobhaListing";
import TataListing from "./components/Pages/BuildersProjects/TataListing";
import TulipListing from "./components/Pages/BuildersProjects/TulipListing";
import VatikaListing from "./components/Pages/BuildersProjects/VatikaListing";
import EmaarListing from "./components/Pages/BuildersProjects/EmaarListing";
import EmaarDigiHomes from "./components/Pages/emaarDigiHomes";
import BestechListing from "./components/Pages/BuildersProjects/BestechListing";
import Dlfpriivana from "./components/Pages/Dlfpriivana";
import EmaarBusinessDistrict from "./components/Pages/ProjectHome/EmaarBusinessDistrict";
import EBD99 from "./components/Pages/ProjectHome/EBD99";
import EBD89 from "./components/Pages/ProjectHome/EBD89";
import Lisiting from "./components/Pages/Lisiting";
import MGRoadDeatils from "./components/Pages/CommercialProjects/MGRoadDeatils";
import Gurgaon from "./components/Pages/Gurgaon";
import GurgaonCommercial from "./components/Pages/CommercialProjects/Gurgaon";


function App() {
  return (
    <>
      <Switch  basename="">
      <Route exact path='/'>
         <Home/>
      </Route>
      <Route exact path="/Properties">
        <Properties/>
      </Route>
      <Route path="/Properties/:productId">
        <PropertiesDetails/>
      </Route>
      <Route path="/ResidentialProjects/GolfCourseRoad/:productNumId">
        <ResidentialProject />
      </Route>
      <Route path="/ResidentialProjects/DwarkaExpressway/:projectDetailsId" >
        <DwarkaDetails />
      </Route>
      <Route path="/ResidentialProjects/GolfExtensionRoad/:exNumId">
        <GolfexDetails/>
      </Route>
      <Route path="/About">
        <About />
      </Route>
      <Route path="/Contact"> 
        <Contact />
      </Route>
      <Route path="/GolfCourseRoad">
        <GolfCourseRoad />
      </Route>
      
      <Route path="/Map">
        <Map />
      </Route>
      <Route path="/GurgaonMasterPlan">
        <GurgaonMasterPlan />
      </Route>
      <Route path="/DwarkaExpresswayMap">
        <DwarkaExpresswayMap />
      </Route>
      <Route path="/SohnaMasterPlan">
        <SohnaMasterPlan />
      </Route>
      <Route path="/CommercialProjects/MGRoad">
        <MGRoad />
      </Route>
      <Route path="/CommercialProjects/MGRoadDeatils/:MGDetailsId">
        <MGRoadDeatils/>
      </Route>
      <Route path="/CommercialProjects/GolfCourseExtensionRoad">
        <GolfCourseExtensionRoad />
      </Route>
      <Route path="/CommercialProjects/DwarkaExpressway">
        <DwarkaExpressway />
      </Route>
      <Route path="/CommercialProjects/SohnaRoad">
        <SohnaRoad />
      </Route>
      <Route path="/ResidentialProjects/SohnaRoad">
        <SohnaRoads />
      </Route>
      <Route path="/ResidentialProjects/GolfExtensionRoad">
        <GolfExtensionRoad />
      </Route>
      <Route path="/ResidentialProjects/GolfCourseRoad">
        <GolfCourseRoad2 />
      </Route>
      <Route path="/ResidentialProjects/DwarkaExpressway">
        <DwarkaExpressway2/>
      </Route>
      <Route path="/DLF">
        <DLFListing />
      </Route>
      <Route path="/Ashiana">
        <AshianaListing />
      </Route>
      <Route path="/M3M">
        <M3MListing />
      </Route>
      <Route path="/AbwCommercial">
        <AbwCommercialListing />
      </Route>
      <Route path="/AIPLListings">
        <AIPLListings/>
      </Route>
      <Route path="/AMBListing">
        <AMBListing />
      </Route>
      <Route path="/AnsalListing">
        <AnsalListing/>
      </Route>
      <Route path="/BirlaListing">
        <BirlaListing/>
      </Route>
      <Route path="/CentralParkListing">
        <CentralParkListing/>
      </Route>
      <Route path="/ElanListing">
        <ElanListing />
      </Route>
      <Route path="/EmaarListing">
        <EmaarListing />
      </Route>
      <Route path="/GodrejListing">
        <GodrejListing />
      </Route>
      <Route path="/IreoListing">
        <IreoListing />
      </Route>
      <Route path="/NimaiListing">
        <NimaiListing />
      </Route>
      <Route path="/PuriListing">
        <PuriListing />
      </Route>
      <Route path="/SignatureGlobalListing">
        <SignatureGlobalListing />
      </Route>
      <Route path="/SilvergladesListing">
        <SilvergladesListing />
      </Route>
      <Route path="/SmartworldListing">
        <SmartworldListing />
      </Route>
      <Route path="/SobhaListing">
        <SobhaListing />
      </Route>
      <Route path="/TataListing">
        <TataListing />
      </Route>
      <Route path="/TulipListing">
        <TulipListing />
      </Route>
      <Route path="/VatikaListing">
        <VatikaListing />
      </Route>
      <Route path="/emaarDigiHomes/:productNumIds">
        <EmaarDigiHomes />
      </Route>
      <Route path="/OURBrandsListing/:productIdNew">
        <BestechListing />
      </Route>
      <Route path="/Dlfpriivana">
        <Dlfpriivana />
      </Route>
      <Route path="/EmaarBusinessDistrict">
        <EmaarBusinessDistrict />
      </Route>
      <Route path="/EmaarBusinessDistrict99">
        <EBD99 />
      </Route>
      <Route path="/EmaarBusinessDistrict89">
        <EBD89 />
      </Route>
      <Route  path="/Lisiting">
        <Lisiting />
      </Route>
      <Route path="/Gurgaon" >
        <Gurgaon />
      </Route>
      <Route path="/GurgaonCommercial" >
        <GurgaonCommercial />
      </Route>
      <Route path="*">
        <Pagenotfound/>
      </Route>
     
     </Switch>
    </>
  );
}

export default App;
