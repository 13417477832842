import img1 from "../../../assets/Project/Godrej/1.jpg"
import img2 from "../../../assets/Project/Godrej/2.jpg"
import img3 from "../../../assets/Project/Godrej/3.jpg"
import img4 from "../../../assets/Project/Godrej/4.jpg"
import img5 from "../../../assets/Project/Godrej/5.jpg"
import img6 from "../../../assets/Project/Godrej/6.jpg"
import img7 from "../../../assets/Project/Godrej/7.jpg"
import img8 from "../../../assets/Project/Godrej/8.jpg"



const GodrejData = [
    {
        id: "1",
        name: "Godrej Nature Plus Gurgaon",
        location: "Sector 33, Sohna Gurgaon",
        img: img1,
        desc: "Godrej Nature Plus: A development with modern craftsmanship and the beauty of nature for the life you always wanted! Gurgaon is a fabulous city and part of the National Capital Region that is constantly evolving every day. There is so much in this city beyond what meets the eye! Today, Gurgaon is one of the most famous cities in the country. "
    },
    {
        id: "2",
        name: "Godrej Aria",
        location: "Sector 79, Gurgaon",
        img: img2,
        desc: "The magnificent Godrej Aria Gurgaon is a project of the Godrej Properties sprawled near the splendid Aravali Hills. It is spread over an area of 7.1 acres and Godrej Aria is Located in Sector 79, Gurgaon area just off National Highway 8 (NH8). Godrej Aria Sector 79 Gurgaon gives you options of 2 and 3 BHK apartments for residents as per their specific budget and requirements."
    },
    {
        id: "3",
        name: "Godrej 101",
        location: "Sector 79, Gurgaon",
        img: img3,
        desc: "Godrej 101 is one of the luxury developments in Gurgaon by Godrej Properties. Located in Sector-79 Gurgaon, it offers lavish 2 and 3 BHK Apartments with the floor plan size from 1383 to 2366 sq. ft. As the name specifies 101, it offers you not one or two but 101 reasons to live luxury and inspired life.        "
    },
    {
        id: "4",
        name: "Godrej Habitat",
        location: "Sector 3, Gurgaon",
        img: img4,
        desc: "Godrej Habitat is a new launch residential project by Godrej Properties in Sector-3 Gurgaon. It is designed to give you a lavish and exceptional lifestyle. It is located in Sector-3 Gurgaon, offers the choice of 2, 3 and 4 BHK residences with the floor plan size from 1056 to 1917 sq.ft."
    },
    {
        id: "5",
        name: "Godrej Meridien",
        location: "Sector 106, Gurgaon",
        img: img5,
        desc: "Godrej Meridien is the new Flagship Residential project by Godrej Properties on Sector-106 Gurgaon. It is another luxurious property by Godrej Properties which has surpassed all the competition in the real estate industry. This beautifully designed residential property – Godrej Meridien Gurgaon has made a mark among its nearby properties.        "
    },
    {
        id: "6",
        name: "Godrej Oasis",
        location: "Sector 88-A, Gurgaon",
        img: img6,
        desc: "Come home to the peaceful retreat called Godrej Oasis Sector-88A Gurgaon. It is a 5-tower luxurious home set amidst peaceful, endless green views, surrounded by life’s conveniences and well connected to all that matters. Spread over 6.8 Acres, Godrej Oasis Gurgaon offers 2, 3 BHK Apartments with the floor plan size from 1307 to 2066 sq.ft.        "
    },
    {
        id: "7",
        name: "Godrej Icon",
        location: "Sector 88-A, Gurgaon",
        img: img7,
        desc: "Godrej Icon is a premier ready-to-move-in residential development. It is an iconic offering by the Godrej Properties on 14+ Acres of land with more than 750 astonishing apartments. This is a place for your comfy and unparalleled way of living in the prime region of Millenium City in Sector 88A Gurgaon. There are 11 soaring shiny skyscrapers with 32 levels in each, making it the tallest complex in the surroundings."
    },
    {
        id: "8",
        name: "Godrej Air",
        location: "Sector 85, Gurgaon",
        img: img8,
        desc: "Godrej Air is nothing but a dream house development in the iconic metropolitan city, Gurgaon. Godrej Air Gurgaon is attractive & luxurious, it is integrated with an extensive green lush, flower garden, & a variety of curing herbs. This is an urban estate, making a buyer’s dream come true in real life."
    },
]


export default GodrejData;