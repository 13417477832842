import React from "react";
import { useParams } from "react-router-dom";
import { TabTitle } from "../../../functionTitle";
import galleryList from "../../Data/galleryList";
import Layout from "../../Layout/Layout";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function BestechListing() {
  const { productIdNew } = useParams();
  const thisProduct =
    galleryList && galleryList.find((prod) => prod.id === productIdNew);

  TabTitle(`Jeet Estate | ${thisProduct.name}`);
  return (
    <>
      <Layout>
        <div className="main-box">
          <div className="container">
            <div className="row">
              {thisProduct.ProjectsBestech && 
                thisProduct.ProjectsBestech.map((itens) => (
                  <>
                    <div key={itens.id} className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {itens.location}
                          </span>
                          <div className="img--box">
                            <img src={itens.img} alt={itens.name} />
                          </div>
                          <h3>{itens.name}</h3>
                          <h6>{itens.price}</h6>
                          <p>{itens.doc}</p>
                        </div>
                      </Link>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default BestechListing;
