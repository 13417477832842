import React, {useState} from "react";
import Layout from "../../Layout/Layout";
import DwarkaExpresswayDataRes from "../../Data/Residential/DwarkaExpresswayData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaLocationArrow } from "react-icons/fa";
import { TabTitle } from "../../../functionTitle";
import { ArrowLeft, ArrowRight } from "lucide-react";

const itemPage = 9;

const numberOfPage = Math.ceil(DwarkaExpresswayDataRes.length / itemPage);
const pageIndex = Array.from({ length: numberOfPage }, (_, idx) => idx + 1);

function DwarkaExpressway() {
  const [currentPage, setCurrentPage] = useState(0);
  const rows = DwarkaExpresswayDataRes.slice(currentPage * itemPage, (currentPage + 1) * itemPage);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  }


  TabTitle(`Jeet Estate | Dwarka Expressway`)
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              Residential Projects on
              <span style={{ color: "rgb(190, 144, 64)" }}>
                {" "}
                Dwarka Expressway, Gurgaon
              </span>
            </h2>
            <p>
              Dwarka Expressway is nothing less than a boon for the community of
              Gurgaon & Delhi. It is a suburb with the best-in-class
              infrastructure & retail development. Dwarka Expressway is a 27+ km
              long stretch that connects Delhi Dwarka to Sector 86 Gurgaon. It
              is an 8-lane highway, a residential hub, and an investor
              attraction. Today, Dwarka Expressway boasts hundreds of iconic
              housing solutions. From High Rise to Low Rise to Mid Rise, all
              types of buildings are either constructed or under construction in
              this region. Some premier developments with BHK Flats, Independent
              Floors, & Penthouse in Dwarka Expressway are Krisumi WaterFall
              Residencies, Signature Global Park 4&5, SS Linden, Sobha City,
              Experion Windchants, Hero Homes, etc.
            </p>
            <div className="row">
              {
                rows && rows.map((items)=> {
                  return(
                    <div className="col-lg-4 mb-3">
                      <Link to={`/ResidentialProjects/DwarkaExpressway/${items.id}/${items.link}`} className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.descD}</p>
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
            </div>
            <div className="pagination-button">
              <button className="btn btn-primery" disabled={currentPage < 1} onClick={() => handlePageChange(currentPage - 1)}><ArrowLeft /></button>
              {
                pageIndex.slice(
                  Math.max(0, currentPage - 2),
                  Math.min(numberOfPage, currentPage + 3)
                )
                  .map((page) => (
                    <button key={page} onClick={() => handlePageChange(page - 1)} className={page === currentPage + 1 ? "active" : ""} >
                      {page}
                    </button>
                  ))
              }
              <button className="btn btn-primery" disabled={currentPage >= numberOfPage - 1} onClick={() => handlePageChange(currentPage + 1)}><ArrowRight /></button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default DwarkaExpressway;
