import React , {useRef} from "react";
import Layout from "../../Layout/Layout";
import { useParams } from "react-router-dom";
import golfcourseDataRes from "../../Data/Residential/golfcourseroad";
import Iframe from "react-iframe";
import emailjs from '@emailjs/browser';
import { TabTitle } from "../../../functionTitle";

function ResidentialProject() {
  
  const { productNumId } = useParams();
  const thisProduct =
    golfcourseDataRes &&
    golfcourseDataRes.find((prod) => prod.id === productNumId);

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      
  
      emailjs.sendForm('service_fcgfkv9', 'template_ymhabkt', form.current, 'h2yzF8GAYjfD61rwt')
        .then((result) => {
            console.log(result.text);
            console.log("Message Sent");
        }, (error) => {
            console.log(error.text);
        });
    };
  
    TabTitle(`Jeet Estate | ${thisProduct.name}`)

  return (
    <>
      <Layout>
        <div className="main-box">
          <div className="container">
            <div className="card prop-main p-4">
              <div className="gallery--main-box">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="PhotonCard--gallery left--gallery zoomin">
                      <img
                        className="PhotonCard--img1 zoomout"
                        src={thisProduct.img}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-4  mb-4">
                    {thisProduct.steps &&
                      thisProduct.steps.map((imgData) => (
                        <div className="zoomin right--gallery">
                          <img
                            className="PhotonCard--img2 zoomout mb-3"
                            src={imgData.img}
                            alt=""
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="main--boxprop mt-3">
                <img src={thisProduct.logo} alt="" />
                <div className="leftpropbox">
                  <h4>{thisProduct.name}</h4>
                  <span>{thisProduct.location}</span>
                </div>
              </div>
            </div>
            <div className="text-center mt-4">
              <h2 class="titile--main">{thisProduct.name}</h2>
              <div class="section-divider divider-traingle"></div>
            </div>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Description</h4>
                {thisProduct.desc &&
                  thisProduct.desc.map((decData) => (
                    <div className="text-descFont">
                      <p>{decData.pra}</p>
                    </div>
                  ))}
                <div className="row">
                  <div className="col-lg-6">
                    <img
                      className="prop-banner-img"
                      src={thisProduct.img}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6">
                    <h4 className="panel-title">Highlights</h4>
                    <ul>
                      {thisProduct.hig &&
                        thisProduct.hig.map((decDatas) => (
                          <div className="text-descFont">
                            <li
                              style={{
                                listStyleType: "square",
                                marginBottom: "10px",
                              }}
                            >
                              {decDatas.name}
                            </li>
                          </div>
                        ))}
                    </ul>
                    <button
                      type="button"
                      class="btn btn-primary "
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      style={{ width: "250px" }}
                    >
                      Download Brochure
                    </button>
                  </div>
                </div>

                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div class="modal-dialog  modal-dialog-centered modal-lg">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div class="left_col">
                          <div class="content">
                            <img
                              src={thisProduct.logo}
                              alt="project logo"
                              class="img-fluid"
                            />
                            <h4 className="text-center">{thisProduct.name}</h4>
                            <h4 class="typo">{thisProduct.UnitType}</h4>
                            <h4 class="price">Starting Price: ₹ 1.99 Cr*</h4>
                          </div>
                        </div>
                        <div class="right_col">
                          <form ref={form} onSubmit={sendEmail} class="animated_form">
                            <div class="form-head">
                              <h4 class="form_heading">Send us a message</h4>
                            </div>
                            <div class="form-group">
                              <input
                                class="form-input"
                                name="to_name"
                                type="text"
                                placeholder="Your Name"
                                required=""
                                
                              />
                            </div>
                            <div class="form-group">
                              <input
                                class="form-input email-address"
                                type="email"
                                name="from_name"
                                placeholder="Your Email"
                                required=""
                                
                              />
                            </div>
                            <div class="form-group">
                              <input
                                id="qMobileNomodal"
                                class="form-input numberText number-only"
                                placeholder="Mobile Number"
                                type="number"
                                name="to_number"
                                required=""
                              />
                            </div>

                            <div class="form-group">
                              <input
                                id="qMessagemodal"
                                class="form-input"
                                type="text"
                                name="message"
                                placeholder="Comment"
                                required=""
                              />
                            </div>
                            <input
                              type="submit"
                              value="Request A Call"
                              class="btn form-control"
                              id="SubmitQuerymodal"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Price</h4>

                <table class="table text-center">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Type</th>
                      <th scope="col">Saleable Area</th>
                      <th scope="col">Price </th>
                      <th scope="col"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {thisProduct.table &&
                      thisProduct.table.map((priLis) => (
                        <tr>
                          <th scope="row">{priLis.id}</th>
                          <td>{priLis.type}</td>
                          <td>{priLis.Saleable_Area}</td>
                          <td>{priLis.price} </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-success ml-3 "
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              style={{
                                width: "140px",
                                fontSize: ".8rem",
                                padding: "7px",
                                marginLeft: "10px",
                                background: "green",
                              }}
                            >
                              Price Breakup
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </section>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Address</h4>
                <div className="row">
                  <div class="listing_detail col-md-4">
                    <strong>Address:</strong> {thisProduct.location}
                  </div>
                  <div class="listing_detail col-md-4">
                    <strong>City:</strong> Gurgaon
                  </div>
                  <div class="listing_detail col-md-4">
                    <strong>State/County:</strong> Haryana
                  </div>
                  <div class="listing_detail col-md-4">
                    <strong>Zip:</strong> {thisProduct.zip}
                  </div>
                  <div class="listing_detail col-md-4">
                    <strong>Country:</strong> India
                  </div>
                </div>
                
              </div>
            </section>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Details</h4>
                <div className="row">
                  <div class="listing_detail col-md-4">
                    <strong>Price:</strong> {thisProduct.price}
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Features & Amenities</h4>
                <div className="row">
                  <div class="feature_chapter_name col-md-12">
                    Other Features
                  </div>
                  {thisProduct.fec &&
                    thisProduct.fec.map((popl) => (
                      <div class="listing_detail col-md-4">
                        <i class="fas fa-check checkon"></i> {popl.fcname}
                      </div>
                    ))}
                </div>
              </div>
            </section>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Map</h4>
                <div className="row-map">
                  <Iframe
                    url={thisProduct.mapLocation}
                    width="100%"
                    height="520px"
                    id=""
                    className=""
                    display="block"
                    frameBorder="8px"
                    position="relative"
                  />
                </div>
              </div>
            </section>
            
          </div>
        </div>
      </Layout>
    </>
  );
}

export default ResidentialProject;
