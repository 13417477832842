import img1 from "../../assets/Logo/logo01.jpg"
import img2 from "../../assets/Logo/image-1.png"
import img3 from "../../assets/Logo/logo11.jpg"
import img4 from "../../assets/Logo/logo04.jpg"
import img5 from "../../assets/Logo/logo.jpg"
import img6 from "../../assets/Logo/logoAdani.png"

// Bestech Altura 
import Bestechimg1 from "../../assets/Project/ProjectHome/1.jpg"
import Bestechimg2 from "../../assets/Project/ProjectHome/2.jpg"
import Bestechimg3 from "../../assets/Project/ProjectHome/3.jpg"

// DLF Projects
import DLFimg1 from "../../assets/Project/DLF/1.jpg";
import DLFimg2 from "../../assets/Project/DLF/2.jpg";
import DLFimg3 from "../../assets/Project/DLF/3.jpg";
import DLFimg4 from "../../assets/Project/DLF/4.jpg";
import DLFimg5 from "../../assets/Project/DLF/5.jpg";
import DLFimg6 from "../../assets/Project/DLF/6.jpg";
import DLFimg7 from "../../assets/Project/DLF/7.jpg";
import DLFimg8 from "../../assets/Project/DLF/8.jpg";
import DLFimg9 from "../../assets/Project/DLF/9.jpg";
import DLFimg10 from "../../assets/Project/DLF/10.jpg";
import DLFimg11 from "../../assets/Project/DLF/11.jpg";
import DLFimg12 from "../../assets/Project/DLF/12.jpg";

// Emaar Project 
import Emaarimg1 from "../../assets/Project/Emaar/1.jpg";
import Emaarimg2 from "../../assets/Project/Emaar/2.jpg";
import Emaarimg3 from "../../assets/Project/Emaar/3.jpg";
import Emaarimg4 from "../../assets/Project/Emaar/4.jpg";
import Emaarimg5 from "../../assets/Project/Emaar/5.jpg";
import Emaarimg6 from "../../assets/Project/Emaar/6.jpg";

// Godrej Project


import Godrejimg1 from "../../assets/Project/Godrej/1.jpg"
import Godrejimg2 from "../../assets/Project/Godrej/2.jpg"
import Godrejimg3 from "../../assets/Project/Godrej/3.jpg"
import Godrejimg4 from "../../assets/Project/Godrej/4.jpg"
import Godrejimg5 from "../../assets/Project/Godrej/5.jpg"
import Godrejimg6 from "../../assets/Project/Godrej/6.jpg"
import Godrejimg7 from "../../assets/Project/Godrej/7.jpg"
import Godrejimg8 from "../../assets/Project/Godrej/8.jpg"

const galleryList = [
    {
        id: "1",
        img: img1,
        name: "DLF Platinum Phase IV",
        location: "Sector 77, Gurugram",
        ProjectsBestech: [
            {
                id: "1",
                name: "DLF The Arbour",
                location: "Sector 63, Gurgaon",
                img: DLFimg1,
            
                doc: "DLF The Arbour Sector 63 Gurgaon is a newly launched residential project on 25 acres of land. This is a top-notch property in the upcoming suburb of the city, near Golf Course Road & Sector 55-56 rapid metro station.",
              },
              {
                id: "2",
                name: "DLF Ultima",
                location: "Sector 81, Gurgaon",
                img: DLFimg2,
            
                doc: "DLF has come up with many “Never Seen Before” Features in this part of Gurugram within a budget range. DLF The Ultima- Luxury Home from DLF Sector 81 Gurugram (ready to Move). The Craftsmanship silently touches “The King” in you and gives access to a stunning lush green Oasis 60% of staggering out of 23 acres land parcel has been developed into a multi-activity green area.",
              },
              {
                id: "3",
                name: "DLF Primus",
                location: "Sector 82A, Gurgaon",
                img: DLFimg3,
            
                doc: "DLF The Primus is ready to move residential project situated in Sector 82A, Gurugram. Dlf Primus floor plan consists of 3BHK & 4BHK apartments. DLF Primus is spread over an area of 12.5 acres and has around 626 beautiful well-planned apartment. The apartment size ranges from 1800 sq.ft. to 2600 sq.ft.",
              },
              {
                id: "4",
                name: "DLF New Town Heights",
                location: "Sector 90, Gurgaon",
                img: DLFimg4,
            
                doc: "DLF now brings quality living and a contemporary lifestyle at NEW TOWN HEIGHTS, a residential project in Sector-86, 90 & 91 Gurgaon. A truly integrated township in the new Gurgaon that is well-connected from NH-8 and Manesar.",
              },
              {
                id: "5",
                name: "DLF Regal Garden",
                location: "Sector 90, Gurgaon",
                img: DLFimg5,
            
                doc: "At DLF Regal Garden the focus has been to create a retreat-like space that stands the test of time. As more complexes get built, Regal Gardens will always remain a landmark at DLF Gardencity with its verdant gardens, pool, and club complex which is partly sunk to give more openness.",
              },
              {
                id: "6",
                name: "DLF The Skycourt",
                location: "Sector 86, Gurgaon",
                img: DLFimg6,
            
                doc: "DLF Skycourt offers 3BHK Air Conditioned apartments. Each apartment covers an area of around 1,900 sq. ft. These classy and premium residences are going to set another landmark as most of DLF’s projects are known to do, particularly in Gurgaon.",
              },
              {
                id: "7",
                name: "DLF Aralias",
                location: "Sector 42, Gurgaon",
                img: DLFimg7,
            
                doc: "DLF The Aralias is the Ready to Move Residential Projects in Sector-42 Gurgaon with the Luxurious Offering of 4BHK Apartments with the size of 5575 Sq. Ft. to 6,000 Sq. Ft.",
              },
              {
                id: "8",
                name: "DLF Belaire",
                location: "Sector 54, Gurgaon",
                img: DLFimg8,
            
                doc: "DLF Belaire is a High-End Residential Project in Sector-54 Golf Course Road Gurgaon. Here. DLF presents the Luxurious 3,4 BHK Apartments plus Servant Room with the size range from 2667 Sq. Ft. to 4098 Sq. Ft.",
              },
              {
                id: "9",
                name: "DLF Magnolias",
                location: "Sector 42, Gurgaon",
                img: DLFimg9,
            
                doc: "DLF Magnolias brings an Amazing View of DLF Golf Course Road and Aravalias by offering the 4 BHK Apartments and 5BHK Duplex and Penthouses with Servant Room covering 5825 Sq. Ft. to 9800 Sq. Ft. This Project is located in Sector-42 Gurgaon with the Top-Notch Amenities and Specifications for a Lavish Lifestyle.",
              },
              {
                id: "10",
                name: "DLF Park Place",
                location: "Sector 54, Gurgaon",
                img: DLFimg10,
            
                doc: "DLF Park Place is one of the Best Residential Projects by DLF Group. Here, they are offering 3,4 BHK Lavish Apartments with one Servant Room covering 1677 Sq. Ft. to 2627 Sq. Ft.",
              },
              {
                id: "11",
                name: "DLF PINNACLE",
                location: "Sector 43, Gurgaon",
                img: DLFimg11,
            
                doc: "DLF Pinnacle is a High-End Residential Project in Sector-43 Gurgaon offering the Lavish 4BHK Apartments with two Servant Rooms per Apartment with an area of 3868 sq. ft. These Luxury Apartments are available for both Rent and Sale.",
              },
              {
                id: "12",
                name: "DLF Crest",
                location: "Sector 54, Gurgaon",
                img: DLFimg12,
            
                doc: "DLF Crest is nothing but the most luxurious residential complex in Gurgaon. This skyscraper development hosts apartments & penthouses as your ideal homes in Sector 54. DLF The Crest is extended into an 8.82-acre area with 6 dazzling highly elevated towers. These towers are designed by Hafeez Contractor, which individually has 36 floors and a total of 500+ homes in the most privileged location of the City.",
              },
        ]
       },
       {
        id: "2",
        img: img2,
        name: "Bestech Group",
        location: "Gurugram",
        ProjectsBestech: [
            {
                id: "1",
                name: "Bestech Park View Altura",
                img: Bestechimg1,
                location: "Sector 79, Gurgaon",
                size: "2015 - 2675 Sq. Ft.",
                doc: "Bestech Altura is the best luxurious residential property which offers 3 BHK and 4 BHK spacious flats in Sector 79, Gurugram. Altura offers you to be a part of an active community where you nestle amidst Aravalli Hills and listen to the eloquence of  serenity where you begin your journey to living anew. Live in an apartment enveloped by nature and be the best version of yourself. Your active lifestyle is waiting for you, just outside your door. Altura offers you a home that is surrounded by nature and has all kinds of activities you can indulge in. What are you waiting for? Just grab your running shoes or your favorite yoga mat, and GET.SET.GO! Bestech Altura, active homes for active living."
            },
            {
                id: "2",
                name: "Bestech Park View Sanskruti",
                img: Bestechimg2,
                location: "Sector 92, Gurgaon",
                size: "1,920 - 2,325 sq.ft.",
                doc: "Bestech Park View Sanskruti in Sector 92, Gurgaon is a ready-to-move housing society. It offers apartments in varied budget range. These units are a perfect combination of comfort and style, specifically designed to suit your requirements and conveniences. There are 3BHK and 4BHK apartments available in this project. This housing society is now ready to be called home as families have started moving in. Check out some of the features of Bestech Park View Sanskruti housing society: *Bestech Park View Sanskruti Sector 92 has 8 towers, with 19 floors each and 608 units on offer."
            },
            {
                id: "3",
                name: "Bestech Park View Spa Next",
                img: Bestechimg3,
                location: "Sector 67, Gurgaon",
                size: "1,790 - 2,635  sq.ft.",
                doc: "Bestech Park View Spa Next in Sector 67, Gurgaon is a ready-to-move housing society. It offers apartments in varied budget range. These units are a perfect combination of comfort and style, specifically designed to suit your requirements and conveniences. There are 2BHK, 3BHK and 4BHK Apartments available in this project. This housing society is now ready to be called home as families have started moving in. Check out some of the features of Bestech Park View Spa Next housing society:"
            },
        ]
       },
       {
        id: "3",
        img: img3,
        name: "Emaar Properties",
        location: "Gurugram",
        ProjectsBestech: [
            {
                id: "1",
                name: "Emaar Digi Homes",
                location: "Sector 62, Gurgaon",
                img: Emaarimg1,
                doc: "Emaar Digi Homes is a luxury Residential project in Sector 62 Gurgaon by Emaar India Group. As the Name specifies Digital Homes, these homes are designed to work on your Touch and Voice Commands. Here, they are offering 2BHK and 3BHK Apartments ranging from 1508 sq.ft to 2589 sqft with Abundant of space by using the optimum area without disrupting one Luxury Feeling.",
              },
              {
                id: "2",
                name: "Emaar Palm Hills",
                location: "Sector 77, Gurgaon",
                img: Emaarimg2,
                doc: "Palm Hills – Presenting a refreshing new look at modern living. Palm Hills, Gurgaon, with welcoming green spaces, invigorating fresh air and sunlight, and modern design concepts that complement your sensibilities.    ",
              },
              {
                id: "3",
                name: "Emaar Palm Heights",
                location: "Sector 77, Gurgaon",
                img: Emaarimg3,
                doc: "EMAAR PALM HEIGHTS – Bringing families together with redefined spaces With three modern landmark towers rising above the horizon, Emaar Palm Heights welcomes families who seek the luxury of well-designed spaces to live a well-deserved life.    ",
              },
              {
                id: "4",
                name: "Emaar Palm Gardens",
                location: "Sector 83, Gurgaon",
                img: Emaarimg4,
                doc: "Palm Gardens – Emaar presents a new residential project Emaar Palm Gardens located at Sector 83 Gurgaon. Emaar Palm Gardens Gurgaon offers 3 BHK apartments with modernized features like AC & Modular Kitchen, Total privacy, Garden Facing, Colourful & continuous landscaping, efficient use of space, and much more.    ",
              },
              {
                id: "5",
                name: "Emaar Imperial Garden",
                location: "Sector 102, Gurgaon",
                img: Emaarimg5,
                doc: "Imperial Gardens – Emaar presents a new residential project Emaar Imperial Gardens located at Sector 102 Gurgaon. Emaar Imperial Gardens Gurgaon offers 3 BHK apartments with modernized features like AC & Modular Kitchen, Total privacy, Garden Facing, Colorful & continuous landscaping, efficient use of space and much more.    ",
              },
              {
                id: "6",
                name: "Emaar Gurgaon Greens",
                location: "Sector 102, Gurgaon",
                img: Emaarimg6,
                doc: "Emaar Gurgaon Greens- Emaar Gurgaon Greens New Residential Projects, a place where you feel, nestled in a posh residential area of Gurgaon, just across Dwarka Expressway. The whole complex is designed keeping your perfect taste in mind.    ",
              },
        ]
       },
       {
        id: "4",
        img: img4,
        name: "GODREJ Housing",
        location: "Sector 49, Gurugram",
        ProjectsBestech: [
            {
                id: "1",
                name: "Godrej Nature Plus Gurgaon",
                location: "Sector 33, Sohna Gurgaon",
                img: Godrejimg1,
                doc: "Godrej Nature Plus: A development with modern craftsmanship and the beauty of nature for the life you always wanted! Gurgaon is a fabulous city and part of the National Capital Region that is constantly evolving every day. There is so much in this city beyond what meets the eye! Today, Gurgaon is one of the most famous cities in the country. "
            },
            {
                id: "2",
                name: "Godrej Aria",
                location: "Sector 79, Gurgaon",
                img: Godrejimg2,
                doc: "The magnificent Godrej Aria Gurgaon is a project of the Godrej Properties sprawled near the splendid Aravali Hills. It is spread over an area of 7.1 acres and Godrej Aria is Located in Sector 79, Gurgaon area just off National Highway 8 (NH8). Godrej Aria Sector 79 Gurgaon gives you options of 2 and 3 BHK apartments for residents as per their specific budget and requirements."
            },
            {
                id: "3",
                name: "Godrej 101",
                location: "Sector 79, Gurgaon",
                img: Godrejimg3,
                doc: "Godrej 101 is one of the luxury developments in Gurgaon by Godrej Properties. Located in Sector-79 Gurgaon, it offers lavish 2 and 3 BHK Apartments with the floor plan size from 1383 to 2366 sq. ft. As the name specifies 101, it offers you not one or two but 101 reasons to live luxury and inspired life.        "
            },
            {
                id: "4",
                name: "Godrej Habitat",
                location: "Sector 3, Gurgaon",
                img: Godrejimg4,
                doc: "Godrej Habitat is a new launch residential project by Godrej Properties in Sector-3 Gurgaon. It is designed to give you a lavish and exceptional lifestyle. It is located in Sector-3 Gurgaon, offers the choice of 2, 3 and 4 BHK residences with the floor plan size from 1056 to 1917 sq.ft."
            },
            {
                id: "5",
                name: "Godrej Meridien",
                location: "Sector 106, Gurgaon",
                img: Godrejimg5,
                doc: "Godrej Meridien is the new Flagship Residential project by Godrej Properties on Sector-106 Gurgaon. It is another luxurious property by Godrej Properties which has surpassed all the competition in the real estate industry. This beautifully designed residential property – Godrej Meridien Gurgaon has made a mark among its nearby properties.        "
            },
            {
                id: "6",
                name: "Godrej Oasis",
                location: "Sector 88-A, Gurgaon",
                img: Godrejimg6,
                doc: "Come home to the peaceful retreat called Godrej Oasis Sector-88A Gurgaon. It is a 5-tower luxurious home set amidst peaceful, endless green views, surrounded by life’s conveniences and well connected to all that matters. Spread over 6.8 Acres, Godrej Oasis Gurgaon offers 2, 3 BHK Apartments with the floor plan size from 1307 to 2066 sq.ft.        "
            },
            {
                id: "7",
                name: "Godrej Icon",
                location: "Sector 88-A, Gurgaon",
                img: Godrejimg7,
                doc: "Godrej Icon is a premier ready-to-move-in residential development. It is an iconic offering by the Godrej Properties on 14+ Acres of land with more than 750 astonishing apartments. This is a place for your comfy and unparalleled way of living in the prime region of Millenium City in Sector 88A Gurgaon. There are 11 soaring shiny skyscrapers with 32 levels in each, making it the tallest complex in the surroundings."
            },
            {
                id: "8",
                name: "Godrej Air",
                location: "Sector 85, Gurgaon",
                img: Godrejimg8,
                doc: "Godrej Air is nothing but a dream house development in the iconic metropolitan city, Gurgaon. Godrej Air Gurgaon is attractive & luxurious, it is integrated with an extensive green lush, flower garden, & a variety of curing herbs. This is an urban estate, making a buyer’s dream come true in real life."
            },
        ]
       },
       {
        id: "5",
        img: img5,
        name: "Elevate",
        location: "Sector 59, Gurugram",
        link: "https://elevate59.jeetestate.com/",
       },
       {
        id: "6",
        img: img6,
        name: "Adani project",
        location: "Gurugram",
        // link: "https://elevate59.jeetestate.com/",
       },
]
export default galleryList; 