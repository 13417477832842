import React from "react";
import dlfBanne from "../../../assets/Project/M3M/banner.jpg";
import M3MData from "../../Data/ListData/M3M ";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Layout from "../../Layout/Layout";
import { TabTitle } from "../../../functionTitle";

function M3MListing() {
  TabTitle('Jeet Estate | M3M Projects')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              M3M is the No.1 real estate developer in North India & No.1 in
              India. This is a reputed brand that has a wide range of commercial
              & residential luxury developments. In less than a decade, M3M
              emerged as the fastest growing real estate development firm that
              has something for everyone. In the last financial year, the sales
              of M3M was INR 5,500 Cr which is the combined sale of major other
              firms. In addition, M3M developers are committed to providing
              on-time deliveries, world-class quality, contemporary design, best
              customer support, & value creations, making them different from
              local real estate firms in the market.
            </p>
            <div className="row">
              {M3MData &&
                M3MData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default M3MListing;
