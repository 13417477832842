import img1 from "../../../assets/Project/Emaar/1.jpg";
import img2 from "../../../assets/Project/Emaar/2.jpg";
import img3 from "../../../assets/Project/Emaar/3.jpg";
import img4 from "../../../assets/Project/Emaar/4.jpg";
import img5 from "../../../assets/Project/Emaar/5.jpg";
import img6 from "../../../assets/Project/Emaar/6.jpg";

const EmaarData = [
  {
    id: "1",
    name: "Emaar Digi Homes",
    loc: "Sector 62, Gurgaon",
    img: img1,
    desc: "Emaar Digi Homes is a luxury Residential project in Sector 62 Gurgaon by Emaar India Group. As the Name specifies Digital Homes, these homes are designed to work on your Touch and Voice Commands. Here, they are offering 2BHK and 3BHK Apartments ranging from 1508 sq.ft to 2589 sqft with Abundant of space by using the optimum area without disrupting one Luxury Feeling.",
  },
  {
    id: "2",
    name: "Emaar Palm Hills",
    loc: "Sector 77, Gurgaon",
    img: img2,
    desc: "Palm Hills – Presenting a refreshing new look at modern living. Palm Hills, Gurgaon, with welcoming green spaces, invigorating fresh air and sunlight, and modern design concepts that complement your sensibilities.    ",
  },
  {
    id: "3",
    name: "Emaar Palm Heights",
    loc: "Sector 77, Gurgaon",
    img: img3,
    desc: "EMAAR PALM HEIGHTS – Bringing families together with redefined spaces With three modern landmark towers rising above the horizon, Emaar Palm Heights welcomes families who seek the luxury of well-designed spaces to live a well-deserved life.    ",
  },
  {
    id: "4",
    name: "Emaar Palm Gardens",
    loc: "Sector 83, Gurgaon",
    img: img4,
    desc: "Palm Gardens – Emaar presents a new residential project Emaar Palm Gardens located at Sector 83 Gurgaon. Emaar Palm Gardens Gurgaon offers 3 BHK apartments with modernized features like AC & Modular Kitchen, Total privacy, Garden Facing, Colourful & continuous landscaping, efficient use of space, and much more.    ",
  },
  {
    id: "5",
    name: "Emaar Imperial Garden",
    loc: "Sector 102, Gurgaon",
    img: img5,
    desc: "Imperial Gardens – Emaar presents a new residential project Emaar Imperial Gardens located at Sector 102 Gurgaon. Emaar Imperial Gardens Gurgaon offers 3 BHK apartments with modernized features like AC & Modular Kitchen, Total privacy, Garden Facing, Colorful & continuous landscaping, efficient use of space and much more.    ",
  },
  {
    id: "6",
    name: "Emaar Gurgaon Greens",
    loc: "Sector 102, Gurgaon",
    img: img6,
    desc: "Emaar Gurgaon Greens- Emaar Gurgaon Greens New Residential Projects, a place where you feel, nestled in a posh residential area of Gurgaon, just across Dwarka Expressway. The whole complex is designed keeping your perfect taste in mind.    ",
  },
];

export default EmaarData;
