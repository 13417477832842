import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Ashiana/banner.jpg";
import AshianaData from "../../Data/ListData/Ashiana";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function AshianaListing() {
  TabTitle('Jeet Estate | Ashiana Projects')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Ashiana is a brand that is delivering joy and happiness in
              customers' life. It is a brand to offer the keys to perfect homes
              at unparalleled prices. Ashiana is not just a real estate company,
              but a service provider that is building a happy community in
              Gurgaon & India. It is known for its exceptional and world-class
              deliveries. This is the brand that introduces kid-centric and
              senior citizen homes in India. Today Ashiana Anmol, Kids Centric
              Home in Gurgaon is one of the top choices in the like-minded
              community of the city. Peoples of Gurgaon admit that a perfect
              home means an apartment in Ashiana.
            </p>
            <div className="row">
              {AshianaData &&
                AshianaData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.loc}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AshianaListing;
