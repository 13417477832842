import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Tulip/tulip-white-q28l55yavwd0ce5iryphhms0nyygi9hvwh5egb1zug.jpg";
import TulipData from "../../Data/ListData/Tulip";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function TulipListing() {
  TabTitle("Jeet Estate | Tulip Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Tulip Group is an iconic real estate development firm in Gurgaon.
              This group has immense expertise in building sustainable
              structures. It is a firm that develops strategically located and
              highly admirable residential complexes. The CEO & Managing
              Director of Tulip Group is Shri Praveen Jain who has an experience
              of 17 years as an indigenous entrepreneur. With uncountable
              efforts & hard work, Tulip Group invested INR 5,500 crore in the
              last eight years. This brand introduced 15 world-class residential
              developments in Gurgaon and some of the complexes which are
              providing homes right now are; Tulip Violet, Tulip Ivory, Tulip
              Purple, & Tulip Orange.
            </p>
            <div className="row">
              {TulipData &&
                TulipData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default TulipListing;
