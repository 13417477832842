import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import golfcourseDataRes from "../../Data/Residential/golfcourseroad";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaLocationArrow } from "react-icons/fa";
import { TabTitle } from "../../../functionTitle";
import { ArrowLeft, ArrowRight } from "lucide-react";

const itemPage = 9;

const numberOfPage = Math.ceil(golfcourseDataRes.length / itemPage);
const pageIndex = Array.from({ length: numberOfPage }, (_, idx) => idx + 1);

function GolfCourseRoad() {

  const [currentPage, setCurrentPage] = useState(0);
  const rows = golfcourseDataRes.slice(currentPage * itemPage, (currentPage + 1) * itemPage);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  }


  TabTitle('Jeet Estate | GolfCourseRoad')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              Residential Projects on
              <span style={{ color: "rgb(190, 144, 64)" }}>
                {" "}
                Golf Course Road, Gurgaon
              </span>
            </h2>
            <p>
              Golf Course Road is a premier 9.61 km long corridor. This locality
              is nothing less than a boon for Gurgaon. It is not only a prime
              motorway in the city but also the link line between Sector 42,
              Sector 54, Sector 53, DLF Phase 1, DLF Phase 5, Sector 43, &
              Sikanderpur areas. Golf Course Road is a residential and
              commercial district that has retail outlets of top luxury brands.
              This road has stores of PEPE Jeans, Starbucks, Pizza Hut, BMW,
              Mercedes, & lot more. In addition, Golf Course Road is also the
              homeplace of thriving high-rise residential complexes. DLF Crest,
              DLF Pinnacle, DLF Magnolias, DLF Belaire, DLF Aralias, Salcon
              Verandas, ABW La Lagune, & Vipul Belmonte are the most thriving
              housing solutions on Golf Course Road.
            </p>
            <div className="row">
              {rows &&
                rows.map((items) => {
                  return (
                    <div key={items.id} className="col-lg-4 mb-3">
                      <Link to={`/ResidentialProjects/GolfCourseRoad/${items.id}/${items.link}`} className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <h6>{items.price}</h6>
                          <p>{items.descD}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
            <div className="pagination-button">
              <button className="btn btn-primery" disabled={currentPage < 1} onClick={() => handlePageChange(currentPage - 1)}><ArrowLeft /></button>
              {
                pageIndex.slice(
                  Math.max(0, currentPage - 2),
                  Math.min(numberOfPage, currentPage + 3)
                )
                  .map((page) => (
                    <button key={page} onClick={() => handlePageChange(page - 1)} className={page === currentPage + 1 ? "active" : ""} >
                      {page}
                    </button>
                  ))
              }
              <button className="btn btn-primery" disabled={currentPage >= numberOfPage - 1} onClick={() => handlePageChange(currentPage + 1)}><ArrowRight /></button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default GolfCourseRoad;
