import React, { useRef } from "react";
import Layout from "../../Layout/Layout";
import Iframe from "react-iframe";
import emailjs from "@emailjs/browser";
import { TabTitle } from "../../../functionTitle";
import emmar1 from "../../../assets/Project/ResidentialProjects/RandomFolder/banner189.webp";
import emmar2 from "../../../assets/Project/ResidentialProjects/RandomFolder/gallery-289.webp";
import emmar3 from "../../../assets/Project/ResidentialProjects/RandomFolder/gallery-389.webp";
import emmar4 from "../../../assets/Project/ResidentialProjects/RandomFolder/gallery-589.webp";
import logo from "../../../assets/Project/ResidentialProjects/RandomFolder/logo89.webp";
import bannerc from "../../../assets/Project/ResidentialProjects/RandomFolder/location-map89.webp";
import floor from "../../../assets/Project/ResidentialProjects/RandomFolder/floor-plan189.webp";
import floor1 from "../../../assets/Project/ResidentialProjects/RandomFolder/floor-plan289.webp";
import floor2 from "../../../assets/Project/ResidentialProjects/RandomFolder/floor-plan389.webp";
import masterplan from "../../../assets/Project/ResidentialProjects/RandomFolder/floor-plan489.webp";

function EBD89() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_fcgfkv9",
        "template_ymhabkt",
        form.current,
        "h2yzF8GAYjfD61rwt"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message Sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  TabTitle(`Jeet Estate | Emaar Business District 89`);

  const name = "Emaar Business District 89";
  const loc = "Sector 89, Gurgaon";
  const price = "On Request";
  return (
    <>
      <Layout>
        <div className="main-box">
          <div className="container">
            <div className="card prop-main p-4">
              <div className="gallery--main-box">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="PhotonCard--gallery left--gallery zoomin">
                      <img
                        className="PhotonCard--img1 zoomout"
                        src={emmar1}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-4  mb-4">
                    <div className="zoomin right--gallery">
                      <img
                        className="PhotonCard--img2 zoomout mb-3"
                        src={emmar3}
                        alt=""
                      />
                    </div>
                    <div className="zoomin right--gallery">
                      <img
                        className="PhotonCard--img2 zoomout mb-3"
                        src={emmar4}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="main--boxprop mt-3">
                <img src={logo} alt="" />
                <div className="leftpropbox">
                  <h4>{name}</h4>
                  <span>{loc}</span>
                </div>
              </div>
            </div>
            <div className="text-center mt-4">
              <h2 class="titile--main">{name}</h2>
              <div class="section-divider divider-traingle"></div>
            </div>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Description</h4>

                <div className="text-descFont">
                  <p>
                    EBD 89 is a manifestation of impeccable business investment
                    – an offering nestled within a micro-market already gifted
                    with rich social infrastructure, a bustling ecosystem and a
                    strong base of consumers. A powerful and unique
                    Shop-Cum-Office (SCO) concept that suits both business and
                    investment objectives.
                  </p>
                  <p>
                    Emaar Business District 99 (EBD 99) brings to you a unique
                    opportunity to own a piece of the future. Visualise, drive,
                    grow and transform your business in the coming years.
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <img className="prop-banner-img" src={emmar2} alt="" />
                  </div>
                  <div className="col-lg-6">
                    <h4 className="panel-title">Highlights</h4>
                    <ul>
                      <div className="text-descFont">
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          Shop-Cum-Office plots with areas ranging between (100
                          sq. yds. - 306 sq. yds)
                        </li>
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          125-meter of wide frontage* on the 75-meter sector
                          road
                        </li>
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          Direct access from 90-metre wide Southern Peripheral
                          road and 60-metre wide sector road
                        </li>
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          Ample parking space with easy access
                        </li>
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          Open air boulevards and promenades
                        </li>
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          Low maintenance charges
                        </li>
                        
                      </div>
                    </ul>
                    <button
                      type="button"
                      class="btn btn-primary "
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      style={{ width: "250px" }}
                    >
                      Download Brochure
                    </button>
                  </div>
                </div>

                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div class="modal-dialog  modal-dialog-centered modal-lg">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div class="left_col">
                          <div class="content">
                            <img
                              src={logo}
                              alt="project logo"
                              class="img-fluid"
                            />
                            <h4 className="text-center">{name}</h4>
                            <h4 class="typo">type</h4>
                            <h4 class="price">Starting Price: ₹ 1.99 Cr*</h4>
                          </div>
                        </div>
                        <div class="right_col">
                          <form
                            ref={form}
                            onSubmit={sendEmail}
                            class="animated_form"
                          >
                            <div class="form-head">
                              <h4 class="form_heading">Send us a message</h4>
                            </div>
                            <div class="form-group">
                              <input
                                class="form-input"
                                name="to_name"
                                type="text"
                                placeholder="Your Name"
                                required=""
                              />
                            </div>
                            <div class="form-group">
                              <input
                                class="form-input email-address"
                                type="email"
                                name="from_name"
                                placeholder="Your Email"
                                required=""
                              />
                            </div>
                            <div class="form-group">
                              <input
                                id="qMobileNomodal"
                                class="form-input numberText number-only"
                                placeholder="Mobile Number"
                                type="number"
                                name="to_number"
                                required=""
                              />
                            </div>

                            <div class="form-group">
                              <input
                                id="qMessagemodal"
                                class="form-input"
                                type="text"
                                name="message"
                                placeholder="Comment"
                                required=""
                              />
                            </div>
                            <input
                              type="submit"
                              value="Request A Call"
                              class="btn form-control"
                              id="SubmitQuerymodal"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">{price}</h4>

                <table class="table text-center">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Type</th>
                      <th scope="col">Saleable Area</th>
                      <th scope="col">Price </th>
                      <th scope="col"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>SCO Plot</td>
                      <td>103 - 179 Sq. Yds*</td>
                      <td>On Request </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-success ml-3 "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          style={{
                            width: "140px",
                            fontSize: ".8rem",
                            padding: "7px",
                            marginLeft: "10px",
                            background: "green",
                          }}
                        >
                          Price Breakup
                        </button>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </section>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Address</h4>
                <div className="row">
                  <div class="listing_detail col-md-4">
                    <strong>Address:</strong> {loc}
                  </div>
                  <div class="listing_detail col-md-4">
                    <strong>City:</strong> Gurgaon
                  </div>
                  <div class="listing_detail col-md-4">
                    <strong>State/County:</strong> Haryana
                  </div>
                  <div class="listing_detail col-md-4">
                    <strong>Zip:</strong> zip
                  </div>
                  <div class="listing_detail col-md-4">
                    <strong>Country:</strong> India
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Details</h4>
                <div className="row">
                  <div class="listing_detail col-md-4">
                    <strong>Price:</strong> {price}
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Features & Amenities</h4>
                <div className="row">
                  <div class="feature_chapter_name col-md-12">
                    Other Features
                  </div>

                  <div class="listing_detail col-md-4">
                    <i class="fas fa-check checkon"></i> Fanam
                  </div>
                </div>
              </div>
            </section>
            <div className="text-center mt-4">
              <h2 class="titile--main">Floor & Master Plan</h2>
              <div class="section-divider divider-traingle"></div>
            </div>
            <section>
              <div className="row mt-5">
                <img
                  src={floor}
                  alt={name}
                  style={{
                    height: "300px",
                    width: "400px",
                    display: "block",
                    margin: "5px auto",
                  }}
                />
                <img
                  src={floor1}
                  alt={name}
                  style={{
                    height: "300px",
                    width: "400px",
                    display: "block",
                    margin: "5px auto",
                  }}
                />
                <img
                  src={floor2}
                  alt={name}
                  style={{
                    height: "300px",
                    width: "400px",
                    display: "block",
                    margin: "5px auto",
                  }}
                />
              </div>
            </section>
            <div className="text-center mt-4">
              <h2 class="titile--main">Location Advantages</h2>
              <div class="section-divider divider-traingle"></div>
            </div>
            <section>
              <div className="card prop-main p-4 mt-4">
                <div className="row">
                  <div class="col-md-6">
                    <img src={bannerc} alt={name} />
                  </div>
                  <div class="col-md-6">
                    <ul>
                      <div className="text-descFont">
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                         Hero Honda Chowk - 10 min
                        </li>
                        <hr />
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          Sector 30 - 26 min
                        </li>
                        <hr />
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          Udyog Vihar - 27 min
                        </li>
                        <hr />
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          Proposed ISBT - 32 min
                        </li>
                        <hr />
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          Cybercity - 35 min
                        </li>
                        <hr />
                        <li
                          style={{
                            listStyleType: "square",
                            marginBottom: "10px",
                          }}
                        >
                          IGI Airport - 35 min
                        </li>
                        <hr />
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="card prop-main p-4 mt-4">
                <h4 className="panel-title">Map</h4>
                <div className="row-map">
                  <Iframe
                    url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d112220.62111570765!2d77.1077781!3d28.4827264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ed3dc744075%3A0x4f108f3be1091e5c!2sEmaar%20Business%20District%2089!5e0!3m2!1sen!2sin!4v1703927347834!5m2!1sen!2sin"
                    width="100%"
                    height="520px"
                    id=""
                    className=""
                    display="block"
                    frameBorder="8px"
                    position="relative"
                  />
                </div>
              </div>
            </section>
            <div className="text-center mt-4">
              <h2 class="titile--main">EBD 99 Gurgaon Master Plan</h2>
              <div class="section-divider divider-traingle"></div>
            </div>
            <section>
              <div className="card prop-main p-4 mt-4">
                <img src={masterplan} alt={name} />
              </div>
            </section>

            <section className="">
              <div className="text-center mt-5 mb-3">
                <h2 class="titile--main">Contact Us Form</h2>
                <div class="section-divider divider-traingle"></div>
              </div>
              <div class="container contact-form mt-5">
                <div class="content ">
                  <div class="left-side">
                    <div class="address details">
                      <i class="fas fa-map-marker-alt"></i>
                      <div class="topic">Address</div>
                      <div class="text-one">Surkhet, NP12</div>
                      <div class="text-two">Birendranagar 06</div>
                    </div>
                    <div class="phone details">
                      <i class="fas fa-phone-alt"></i>
                      <div class="topic">Phone</div>
                      <div class="text-one">+0098 9893 5647</div>
                      <div class="text-two">+0096 3434 5678</div>
                    </div>
                    <div class="email details">
                      <i class="fas fa-envelope"></i>
                      <div class="topic">Email</div>
                      <div class="text-one">codinglab@gmail.com</div>
                      <div class="text-two">info.codinglab@gmail.com</div>
                    </div>
                  </div>
                  <div class="right-side">
                    <div class="topic-text">Send us a message</div>
                    <p>
                      If you have any work from me or any types of quries
                      related to my tutorial, you can send me message from here.
                      It's my pleasure to help you.
                    </p>
                    <form action="#" ref={form} onSubmit={sendEmail}>
                      <div class="input-box">
                        <input
                          type="text"
                          placeholder="Enter your name"
                          name="to_name"
                        />
                      </div>
                      <div class="input-box">
                        <input
                          type="text"
                          placeholder="Enter your email"
                          name="from_name"
                        />
                      </div>
                      <div class="input-box">
                        <input
                          type="number"
                          maxLength={10}
                          placeholder="Enter your phone"
                          name="to_number"
                        />
                      </div>
                      <div class="input-box message-box">
                        <textarea
                          className="from-control"
                          placeholder="Enter your message-box"
                          name="message"
                        ></textarea>
                      </div>
                      <div class="button">
                        <input type="submit" className="btn" value="Send Now" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EBD89;
