import React from "react";
import Layout from "../Layout/Layout";
import pdfdownload from "../../assets/Logo/dwarka-expressway-map.jpg";
import { FaDownload } from "react-icons/fa";
import { TabTitle } from "../../functionTitle";

function DwarkaExpresswayMap() {
  TabTitle('Jeet Estate | Expressway Map')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              Dwarka{" "}
              <span style={{ color: "rgb(190, 144, 64)" }}>
                {" "}
                Expressway Map
              </span>
            </h2>
            <p>
              A place we find the happiness and pleasure by is none other than
              the destination to the home. At present, to get the home, this
              comes easy anywhere but does not suffice to all the expectations
              everyone stands for. The better conveniences with peaceful
              environment have been a dreamlike. The growth in the population
              all over the globe is witnessed to never come with green and
              tranquil living.
            </p>
            <p>
              In addition to the availability of good infrastructure and basic
              facilities in the vicinity, connectivity today is a major deciding
              factor while buying a property. Road connectivity has a massive
              impact on real estate development and prices. It comes as a little
              surprise that Dwarka Expressway is poised to become the New
              Shangrila and centre of Gurgaon. It has become a significant real
              estate investment destination owing to its vast potential for
              robust growth and expansion in the coming years. It provides an
              immaculate blend of strategic location, varied residential
              options, world-class amenities and easy accessibility via
              infrastructure development in the area.
            </p>
            <p>
              The Dwarka Expressway Gurgaon Project is being considered as one
              of the most promising and progressive residential and commercial
              projects. This project is being developed by the Haryana Urban
              Development Authority. The masterplan of this project comprises of
              residential options available in multiple budget and house
              requirement ratios.
            </p>
            <p>
              The Dwarka Expressway Map can be found on our website of Dwarka
              Expressway. This map gives clear and in detail idea about what to
              expect from the project. It also gives an idea to the investor
              about where and how many projects are scattered all across the
              Dwarka Expressway. It also highlights the closest and the most
              popular spots all around the Dwarka Expressway including the
              International and Domestic Airports. This way the investors can
              gain an in-depth knowledge about the various transportation routes
              that the Dwarka Expressway is closely surrounded with just by
              looking at the Dwarka Expressway Location Map.
            </p>
            <p>
              The Dwarka Expressway is now considered as one of the biggest real
              estate projects ever to be announced not just in Delhi but all
              across India. Several real estate builders and investors are
              highly anticipating the onset of it and have already invested in
              some of the housing projects in the Dwarka Expressway.
            </p>
            <a
              href={pdfdownload}
              style={{ width: "fit-content" }}
              class="btn btn-outline-primary"
              target="_blank"
              rel="noreferrer"
            >
              Gurgaon Master Plan 2031 PDF Download <FaDownload />{" "}
            </a>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default DwarkaExpresswayMap;
