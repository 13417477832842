import React from 'react'
import Layout from '../../Layout/Layout'
import golfcourseextensionroadDataRes from "../../Data/Residential/golfcourseextensionroad";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaLocationArrow } from "react-icons/fa";
import { TabTitle } from '../../../functionTitle';


function GolfExtensionRoad() {
  TabTitle('Jeet Estate | Golf Extension Road')
  return (
    <>
    <Layout>
    <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              Residential Projects on
              <span style={{ color: "rgb(190, 144, 64)" }}>
                {" "}
                Golf Course Road, Gurgaon
              </span>
            </h2>
            <p>
              Golf Course Road is a premier 9.61 km long corridor. This locality
              is nothing less than a boon for Gurgaon. It is not only a prime
              motorway in the city but also the link line between Sector 42,
              Sector 54, Sector 53, DLF Phase 1, DLF Phase 5, Sector 43, &
              Sikanderpur areas. Golf Course Road is a residential and
              commercial district that has retail outlets of top luxury brands.
              This road has stores of PEPE Jeans, Starbucks, Pizza Hut, BMW,
              Mercedes, & lot more. In addition, Golf Course Road is also the
              homeplace of thriving high-rise residential complexes. DLF Crest,
              DLF Pinnacle, DLF Magnolias, DLF Belaire, DLF Aralias, Salcon
              Verandas, ABW La Lagune, & Vipul Belmonte are the most thriving
              housing solutions on Golf Course Road.
            </p>
            <div className="row">
              {golfcourseextensionroadDataRes &&
                golfcourseextensionroadDataRes.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to={`/ResidentialProjects/GolfExtensionRoad/${items.id}/${items.link}`} className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.descD}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
    </Layout>
    </>
  )
}

export default GolfExtensionRoad