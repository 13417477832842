import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Ansal/banner0.jpg";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";
import AnsalData from "../../Data/ListData/Ansal";

function AnsalListing() {
  TabTitle("Jeet Estate | Ansal Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Ansal Highland Park promises you to offer a brilliant living
              experience balanced with the invigorating vibrancy of everything
              under the sun. The project draws its inspiration from the perfect
              harmony between nature and people as is evident from its
              apartments existing seamlessly to create a balanced life. With
              open green spaces, walking trails and much more, one will find a
              serene sanctuary in every possible corner. The project offers a
              chance for people to relax, enjoy themselves and experience a
              wonderful quality of life with family and friends. Ansal Highland
              Park truly provides a layer of open and covered spaces into the
              private domain of ones own apartment.
            </p>
            <div className="row">
              {AnsalData &&
                AnsalData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AnsalListing;
