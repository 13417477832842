import img1 from "../../../assets/Project/Tulip/1.jpg"
import img2 from "../../../assets/Project/Tulip/2.jpg"
import img3 from "../../../assets/Project/Tulip/3.jpg"
import img4 from "../../../assets/Project/Tulip/4.jpg"
import img5 from "../../../assets/Project/Tulip/5.jpg"



const TulipData = [
    {
        id: "1",
        name: "Tulip Yellow",
        location: "Sector 69, Gurgaon",
        img: img1,
        desc: "Tulip Yellow is a Luxury Residential Project in Sector-69 Gurgaon by Tulip Group. It is offering Lavish 3 BHK Apartments with a size of 1704 sq. ft. Tulip Yellow Sector 69 Gurgaon is spread over an area of 8.5 Acres.        "
    },
    {
        id: "2",
        name: "Tulip Violet",
        location: "Sector 69, Gurgaon",
        img: img2,
        desc: " Tulip Violet is a Ready to Move Residential Project by Tulip Group in Sector-69 Gurgaon. Tulip Violet offering Luxury 3,4 BHK Apartments with the Floor Plan size of 1578 Sq. Ft. to 3156 Sq. Ft. This High Scale Development Project is spread over the Built-up area of 25.44 Acres.        "
    },
    {
        id: "3",
        name: "Tulip Ivory",
        location: "Sector 70, Gurgaon",
        img: img3,
        desc: "Tulip Ivory Apartments are inspired by Ivory tulips which are a spring beauty. The homes boast of having a firm foundation and strong structure just like the deeply rooted tulips in soil. Splashes of orange in tulips propagate beauty, passion, freshness, warmth, and radiance. It has been perfectly set in Sector 69-70 of Gurgaon, where several factors make life comfortable.        "
    },
    {
        id: "4",
        name: "Tulip Purple",
        location: "Sector 69, Gurgaon",
        img: img4,
        desc: "Tulip Purple in Sector-69, Gurgaon is a ready-to-move housing society. It offers apartments and villas in varied budget range. These units are a perfect combination of comfort and style, specifically designed to suit your requirements and conveniences. There are 2BHK, 3BHK and 4BHK Apartments and 4BHK villas available in this project. This housing society is now ready to be called home as families have started moving.        "
    },
    {
        id: "5",
        name: "Tulip Orange",
        location: "Sector 70, Gurgaon",
        img: img5,
        desc: "Tulip Group presenting the Exclusive Project- Tulip Orange urged from the orange tulips. Tulip Orange is a Ready to Move Residential Project in Sector-70 Gurgaon offering 3 BHK Apartments with a floor plan size of 1137 and 1437 Sq. Ft. It has a built-up area of 5.5 Acres. Tulip Orange Gurgaon comprises 11 Towers which include 440 Apartments.        "
    },
]


export default TulipData;