import img1 from "../../../assets/Project/Ashiana/1.jpg";
import img2 from "../../../assets/Project/Ashiana/2.jpg";


const AshianaData = [
  {
    id: "1",
    name: "Ashiana Anmol",
    loc: "Sector 33, Gurgaon",
    img: img1,
    desc: "Ashiana Anmol is a high-class residential project that offers, designed and luxurious living homes, apartments, and flats to each family with numerous ranges and sizes starting from 780 to 1262 sq ft. This premium residency is another luxury project which features high-rise apartments & flats that offers you 2 and 3 BHK apartments with an open wide area with lush greens gardens and plants.",
  },
  {
    id: "2",
    name: "Ashiana Amarah",
    loc: "Sector 93, Gurgaon",
    img: img2,
    desc: "Ashiana Amarah is a Kid Centric Home, sprawling in 22 Acre land. This is a residential complex to raise a compatible child in Gurgaon. Ashiana Amarah is a seed of parenting for the creative upbringing of little champs. There is a world-class environment & utmost features to grow your children with Love, Care, & Patience.",
  }
];

export default AshianaData;
