import React from "react";
import { TabTitle } from "../../functionTitle";
import Layout from "../Layout/Layout";

function Lisiting() {
  TabTitle("Jeet Estate | Listing");
  const styles = {
    margin: "0 auto",
    display: "grid",
    gap: "1rem",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
  };
  const styles1 = {
    marginBottom: "20px",
    backgroundColor: "DodgerBlue",
    padding: "20px",
    textAlign: "center",
    color: "#fff"
  };
  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="grid-row" style={styles}>
            <div className="grid-items" style={styles1}>
              1
            </div>
            <div className="grid-items" style={styles1}>
             2
            </div>
            <div className="grid-items" style={styles1}>
              3
            </div>
            <div className="grid-items" style={styles1}>
             4
            </div>
            <div className="grid-items" style={styles1}>
              5
            </div>
            <div className="grid-items" style={styles1}>
              6
            </div>
            <div className="grid-items" style={styles1}>
              7
            </div>
            <div className="grid-items" style={styles1}>
              7
            </div>
            <div className="grid-items" style={styles1}>
              7
            </div>
            <div className="grid-items" style={styles1}>
              7
            </div>
            <div className="grid-items" style={styles1}>
              7
            </div>
            <div className="grid-items" style={styles1}>
              7
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Lisiting;
