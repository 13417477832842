import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Emaar/emaar-dev-banner.jpg";
import EmaarData from "../../Data/ListData/Emaar";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function EmaarListing() {
  TabTitle("Jeet Estate | Emaar Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              EMAAR is the biggest real estate development firm in India. It is
              involved in the construction, development, & promotion of
              residential & commercial projects. EMAAR transformed the real
              estate industry and the growth of prime cities like; Delhi,
              Gurgaon, Lucknow, Mohali, Indore, etc. In addition, this firm won
              various national and international awards, 6 of which were at DNA
              Real Estate & Infrastructure Awards & 9th Realty Plus Conclave and
              Excellence Awards in 2017. EMAAR is a premium brand to book ultra
              luxury modern homes. Recent residential complexes by EMAAR in
              Gurgaon are; EMAAR Palm Hills, EMAAR Palm Heights, EMAAR Palm
              Gardens, EMAAR Imperial Garden, & EMAAR Gurgaon Greens.
            </p>
            <div className="row">
              {EmaarData &&
                EmaarData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.loc}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EmaarListing;
