import React from "react";
import Layout from "../Layout/Layout";
import AboutBanner from "../../assets/bannerAbout.jpg";
import { TabTitle } from "../../functionTitle";
import aboutBnner from "../../assets/aboutvv.png";

function About() {
  TabTitle("Jeet Estate | About");
  return (
    <>
      <Layout>
        <div className="main-box">
          <div className="banner-box">
            <img src={AboutBanner} alt="" />
            <div className="banner-text">
              <h3>About Us</h3>
            </div>
          </div>
          <div className="container">
            <div className="main-box-padd">
              <p className="heading-text">About Us</p>
              <div className="card prop-main p-4">
                <div className="row">
                  <div className="col-lg-6">
                    <p>
                      Jeet Estate Consultant is a distinguished real estate
                      firm, established independently in 2010 after years of
                      dedicated effort. Situated in Gurugram, Haryana, our
                      accomplished team specializes in the sale, purchase,
                      lease, and management of numerous properties. Our profound
                      knowledge of the region, coupled with extensive local
                      market experience, enables us to provide the utmost level
                      of service to our esteemed clientele, comprising Property
                      Owners, Buyers, and Investors.
                    </p>
                    <p>
                      We understand that selecting a new home or the right team
                      to manage your investments is a significant decision.
                      Thus, our team stands ready to offer unwavering support
                      and expert advice, ensuring that you and your family make
                      informed choices. Our ultimate satisfaction lies in
                      witnessing delighted Property Sellers, Buyers, and Tenants
                      achieving top-market returns on their investments. Whether
                      you're an individual property owner seeking to sell your
                      home or a landlord with an extensive portfolio, we offer a
                      tailored range of packages to meet your specific needs.
                    </p>
                    <p>
                      Choose Jeet Estate Consultant for a refined and
                      sophisticated approach to real estate that promises
                      unwavering commitment to your success.
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <img
                      src={aboutBnner}
                      alt=""
                      style={{ height: "450px", borderRadius: "8px",objectFit: "cover" }}
                    />
                  </div>
                </div>

                <p>
                  At Jeet Estate Consultant, we collaborate with private buyers
                  to help them find their dream homes and secure the finest
                  investments. Our clients value our discretion, transparent and
                  candid guidance, as well as our unwavering commitment to going
                  the extra mile in every Sale and Purchase. Our dedicated staff
                  is renowned for their down-to-earth approach, ensuring that
                  clients never feel pressured or without guidance when making
                  significant decisions. Our primary role is to identify the
                  perfect property match for our clients and negotiate
                  vigorously on their behalf to secure the best possible price
                  and terms. Additionally, we oversee the entire transaction,
                  ensuring a seamless process and providing support and guidance
                  until completion.
                </p>
                <p>
                  Our ethos centers around growth, adaptability, and the
                  acceleration of success. We pride ourselves on providing
                  expert advice to property occupiers, owners, and investors,
                  leading the industry into the future. With a long-standing
                  commitment to operating with unwavering integrity and a
                  collaborative mindset, we have achieved enduring success. Our
                  ability to deliver exceptional value to clients and investors
                  is a testament to our dedication. It's important to note that
                  we exclusively operate within India, serving clients across
                  the nation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default About;
