import img1 from "../../assets/Project/GolfCourseExtensionRoad/1.jpg"
import img2 from "../../assets/Project/GolfCourseExtensionRoad/2.jpg"
import img3 from "../../assets/Project/GolfCourseExtensionRoad/3.jpg"
import img4 from "../../assets/Project/GolfCourseExtensionRoad/4.jpg"
import img5 from "../../assets/Project/GolfCourseExtensionRoad/5.jpg"
import img6 from "../../assets/Project/GolfCourseExtensionRoad/6.jpg"
import img7 from "../../assets/Project/GolfCourseExtensionRoad/7.jpg"
import img8 from "../../assets/Project/GolfCourseExtensionRoad/8.jpg"
import img9 from "../../assets/Project/GolfCourseExtensionRoad/9.jpg"
import img10 from "../../assets/Project/GolfCourseExtensionRoad/10.jpg"
import img11 from "../../assets/Project/GolfCourseExtensionRoad/11.jpg"
import img12 from "../../assets/Project/GolfCourseExtensionRoad/12.jpg"
import img13 from "../../assets/Project/GolfCourseExtensionRoad/13.jpg"



const golfcourseextensionroadData = [
    {
        id: "1",
        name: "M3M Route 65",
        img: img1,
        location: " Sector 65, Gurgaon",
        desc: "M3M Route 65 is an exclusive commercial complex in Gurgaon Sector 65. It is in a prime location that is an flawless base for your extraordinary business. M3M Route 65 offers an excellent address to showcase your business in the heart of the city. In addition, when you book your retail space in this complex, you will be close to a residential hub as well as schools, hospitals, hotels, and the prime motorway of Gurgaon, Golf Course Extension Road."
    },
    {
        id: "2",
        name: "M3M Prive 73",
        img: img2,
        location: " Sector 73, Gurgaon",
        desc: "M3M Prive is a perfect commercial plaza with retail and office space. It’s a unique combination of innovation and creativity that makes it an advanced trade & commerce premise. This is one of the modern complexes in Southern Peripheral Road, Sector 73, ready to host thriving restaurants, entertainment zones, studios, leisure facilities, & business corners. From its impressive entrance to ample car parking, M3M Prive has all the features that are ideal to attract customers."
    },
    {
        id: "3",
        name: "M3M Broadway",
        img: img3,
        location: " Sector 71, Gurgaon",
        desc: "M3M Broadway is an excellent investment opportunity. It is not just a commercial complex, but a guaranteed option with the highest return. It is one of the most anticipated retail spaces on Southern Peripheral Road. This premise offers you attractive deals for your business and investment portfolio. It is situated in Sector 71 Gurgaon, one of the most desirable markets in the city, and houses the best luxury offices for various companies."
    },
    {
        id: "4",
        name: "M3M Corner Walk",
        img: img4,
        location: " Sector 74, Gurgaon",
        desc: "M3M Corner Walk is a unique and creative space for trade & commerce. This is a world-class commercial space with high-end retail shops and office space in Gurgaon. It is situated on Golf Course Extension Road in Sector 75 and has excellent transit connectivity to Manesar, Dwarka, & NCR. This space is also in the vicinity of Golf Course Road, NH8, & Sohna Road, making it an ideal locality for futuristic business."
    },
    {
        id: "5",
        name: "Elan Paradise",
        img: img5,
        location: " Sector 50, Gurgaon",
        desc: "Elan Paradise is a premier investment opportunity, it is not only a bet but an assured option with maximum returns. It is one of the most awaited commercial spaces in Nirvana Country, offering exciting shops for your businesses & investment portfolio. Elan Paradise Sector 50 Gurgaon is among the desirable plazas of Gurgaon, hosting best-in-class & high-end shops for various businesses."
    },
    {
        id: "6",
        name: "Vatika Professional Point",
        img: img6,
        location: " Sector 66, Gurgaon",
        desc: "Vatika Professional Point is one of the High-End Commercial Projects by Vatika Group in Sector-66 Gurgaon. Here, Vatika presents Stunning Commercial Office Spaces fully enriched with all the Top-Notch Amenities to take your Business to the Next Level. This Amazing Commercial Project is constructed on the Built-up Area of 2.11 Acres."
    },
    {
        id: "7",
        name: "Spaze Edge",
        img: img7,
        location: " Sector 47, Gurgaon",
        desc: "Spaze Edge is a commercial office space building delivered by a Spaze developer situated in Sector 47, Sohna Road, Gurgaon. Commercial office space is available for rent on Sohna road in the Spaze Edge building at an attractive price. Spazedge offers modern commercial office spaces that have been elegantly designed and feature a world-class atrium on the ground floor of the building. Built across an area of 3,50,000 sq.ft. , Spaze Edge Sohna road features efficiently designed floor plates spread over two blocks."
    },
    {
        id: "8",
        name: "Spaze IT Park",
        img: img8,
        location: " Sector 49, Gurgaon",
        desc: "Spaze i-Tech Park situated at the prime location of Sector-49, Sohna Road, Gurgaon is a groundbreaking concept in construction & design. Spaze i-Tech Park Gurgaon is spread over an area of aroudn10 acres. Spaze i-Tech Park offers extensive office space on rent, wide range of commercial spaces and restaurant spaces."
    },
    {
        id: "9",
        name: "M3M Tee Point",
        img: img9,
        location: " Sector 65, Gurgaon",
        desc: "M3M Tee Point is a High-End Commercial Project by M3M Group on Sector-65 Golf Course Extension Road Gurgaon. It is a Well-Mix of Retail Shops and Premium Office Spaces. This Project is located near M3M Golf Estate Gurgaon. This Commercial Project is on the built-up of 2 Acres."
    },
    {
        id: "10",
        name: "M3M Cosmopolitan",
        img: img10,
        location: " Sector 66, Gurgaon",
        desc: "M3M Cosmopolitan is an Amazing Mix of Premium Retail Spaces, Food Courts, and Office Spaces in Sector-66 Gurgaon. It is made on a Built-up Area of 3 Acres of Land on Golf Course Extension Road. M3M Cosmopolitan Mall is Designed by RSP Singapore which is a High-End Designing Consultancy. The Landscaping around the M3M Cosmopolitan Mall Gurgaon is done by the Element Design Studio."
    },
    {
        id: "11",
        name: "Emaar Digital Greens",
        img: img11,
        location: " Sector 61, Gurgaon",
        desc: "Emaar Digital Greens is a Ready to Move Commercial Project with the LEED Certified Integrated IT Park in Sector-61 Gurgaon Offering Fully Furnished Office Spaces with a size range starting from 1500 Sq. Ft. onwards. It is on a Built-up Area of 6.8 Acres."
    },
    {
        id: "12",
        name: "AIPL Business Club",
        img: img12,
        location: " Sector 62, Gurgaon",
        desc: "AIPL (Advance India Projects Ltd.) presenting the Best Ever Commercial Project- AIPL Business Club in Golf Course Extension Road where you can Uplift your Business with its Fast-Growing Corporate Community. This Amazing Commercial Project is on the Sector-62 Gurgaon."
    },
    {
        id: "13",
        name: "Pioneer Urban Square",
        img: img13,
        location: " Sector 62, Gurgaon",
        desc: "Pioneer Urban Square is a High-End Commercial Project by Pioneer Urban Land and Infrastructure Ltd. Pioneer Urban Square is a part of the Big Project Plan Pioneer Park. Pioneer Urban Land and Infrastructure Ltd. is known for its Benchmark Real Estate Projects over the Two Decades. SO Many Big MNC’s like Pepsi, Sony, American Express, IBM have set up their Office in Pioneer Urban Square Gurgaon."
    }
]

export default golfcourseextensionroadData;