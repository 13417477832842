import img1 from "../../../assets/Project/Ireo/1.jpg"
import img2 from "../../../assets/Project/Ireo/2.jpg"
import img3 from "../../../assets/Project/Ireo/3.jpg"



const IreoData = [
    {
        id: "1",
        name: "Ireo Grand Arch",
        location: "Sector 58, Sohna Gurgaon",
        img: img1,
        desc: "Ireo Grand Arch is one of the High-End Residential Projects located near the Aravalli Hills Gurgaon. It is a part of the Grand 700-acre Ireo City Township. This Lavish Project is on the Sector-58 Golf Course Extension Road Gurgaon. Here, Ireo The Grand Arch is offering 2,3, and 4 BHK Apartments including duplexes with a size range from 1375 to 2864 Sq. Ft.        "
    },
    {
        id: "2",
        name: "Ireo Skyon",
        location: "Sector 60, Gurgaon",
        img: img2,
        desc: "Ireo Skyon is one of the Remarkable Residential Projects by Ireo Group in Sector-60 Gurgaon. This Lavish Project is on the most Prominent Location of Golf Course Extension Road. Its Premium Amenities, Recreational Facilities, and Lush Greens make this Project very unique from others.        "
    },
    {
        id: "3",
        name: "Ireo Victory Valley",
        location: "Sector 67, Gurgaon",
        img: img3,
        desc: "Ireo Victory Valley is the High-End Residential Project offering 2,3,4 and 5 BHK Apartments with the size range from 1435 Sq. Ft. to 5970 Sq. Ft. This Lavish Project is on the Sector-67 Golf Course Extension Road Gurgaon. Ireo Victory Valley is spread on a Built-up Area of 25 Acres with more than 750 Apartments in both Simplex and Duplex form.        "
    }
]


export default IreoData;