import img1 from "../../../assets/Project/Nimai/1.jpg"



const NimaiData = [
    {
        id: "1",
        name: "Nimai place",
        location: "Sector 114, Gurgaon",
        img: img1,
        desc: "Nimai Place is an impressive commercial development in Dwarka Expressway, Gurgaon. It spans 3.5 Acres of land and has landscape space for trade and commerce. There is a wide range of Office Spaces, Studio Apartments, Commercial Shops, & Retail Outlets to showcase your business & lifestyle.        "
    },
    
    
]


export default NimaiData;