import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Tata/tata-q28sdk6q6mtmnhwx90rsqf80zpi1vo8y1batkuyq48.jpg";
import TataData from "../../Data/ListData/Tata";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function TataListing() {
  TabTitle("Jeet Estate | Tata Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Tata Housing or Tata Housing Development Company is a real estate
              subsidiary firm of Tata Sons. This company was formed in 1984 by
              the late JRD Tata and revived in late 2006 by Brotin Banerjee.
              Since Tata Housing is revived, it is delivering holistic
              world-class real estate developments in Gurgaon & India. This firm
              is dedicated to residential property development and is currently
              involved in 33 projects in India. Three of them; are Tata Gurgaon
              Gateway, Tata Primanti, & Tata La Vida in Gurgaon. These are the
              high-end accommodations in the prime spot of the city. Tata
              Housing's vision is to build better homes for Gen Z of India.
            </p>
            <div className="row">
              {TataData &&
                TataData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default TataListing;
