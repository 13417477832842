import img1 from "../../../assets/Project/ABW/1.jpg";
import img2 from "../../../assets/Project/ABW/2.jpg";


const AbwCommercialData = [
  {
    id: "1",
    name: "ABW Tower",
    location: "Sector 25, Gurgaon",
    img: img1,
    desc: "ABW Tower is a complete commercial project located in Sector-25, Gurgaon offering international class corporate and commercial spaces. Spread over an area of 1 Lakh square feet, the project offers state-of-the-art facilities for all its occupants.    ",
  },
  {
    id: "2",
    name: "Abw La Lagune",
    location: "Sector 54, Gurgaon",
    img: img2,
    desc: "“La-Lagune”, in the heart Gurgaon, spread across 11.3 acres of land. Located on the Golf Course road, La-Lagune, the most sought after premium residential address in Gurgaon, has been conceptualized to offer a luxurious living experience at par with international design and comfort.",
  }
];

export default AbwCommercialData;
