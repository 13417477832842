import img1 from "../../../assets/Project/DLF/1.jpg";
import img2 from "../../../assets/Project/DLF/2.jpg";
import img3 from "../../../assets/Project/DLF/3.jpg";
import img4 from "../../../assets/Project/DLF/4.jpg";
import img5 from "../../../assets/Project/DLF/5.jpg";
import img6 from "../../../assets/Project/DLF/6.jpg";
import img7 from "../../../assets/Project/DLF/7.jpg";
import img8 from "../../../assets/Project/DLF/8.jpg";
import img9 from "../../../assets/Project/DLF/9.jpg";
import img10 from "../../../assets/Project/DLF/10.jpg";
import img11 from "../../../assets/Project/DLF/11.jpg";
import img12 from "../../../assets/Project/DLF/12.jpg";

const DlfData = [
  {
    id: "1",
    name: "DLF The Arbour",
    loc: "Sector 63, Gurgaon",
    img: img1,

    desc: "DLF The Arbour Sector 63 Gurgaon is a newly launched residential project on 25 acres of land. This is a top-notch property in the upcoming suburb of the city, near Golf Course Road & Sector 55-56 rapid metro station.",
  },
  {
    id: "2",
    name: "DLF Ultima",
    loc: "Sector 81, Gurgaon",
    img: img2,

    desc: "DLF has come up with many “Never Seen Before” Features in this part of Gurugram within a budget range. DLF The Ultima- Luxury Home from DLF Sector 81 Gurugram (ready to Move). The Craftsmanship silently touches “The King” in you and gives access to a stunning lush green Oasis 60% of staggering out of 23 acres land parcel has been developed into a multi-activity green area.",
  },
  {
    id: "3",
    name: "DLF Primus",
    loc: "Sector 82A, Gurgaon",
    img: img3,

    desc: "DLF The Primus is ready to move residential project situated in Sector 82A, Gurugram. Dlf Primus floor plan consists of 3BHK & 4BHK apartments. DLF Primus is spread over an area of 12.5 acres and has around 626 beautiful well-planned apartment. The apartment size ranges from 1800 sq.ft. to 2600 sq.ft.",
  },
  {
    id: "4",
    name: "DLF New Town Heights",
    loc: "Sector 90, Gurgaon",
    img: img4,

    desc: "DLF now brings quality living and a contemporary lifestyle at NEW TOWN HEIGHTS, a residential project in Sector-86, 90 & 91 Gurgaon. A truly integrated township in the new Gurgaon that is well-connected from NH-8 and Manesar.",
  },
  {
    id: "5",
    name: "DLF Regal Garden",
    loc: "Sector 90, Gurgaon",
    img: img5,

    desc: "At DLF Regal Garden the focus has been to create a retreat-like space that stands the test of time. As more complexes get built, Regal Gardens will always remain a landmark at DLF Gardencity with its verdant gardens, pool, and club complex which is partly sunk to give more openness.",
  },
  {
    id: "6",
    name: "DLF The Skycourt",
    loc: "Sector 86, Gurgaon",
    img: img6,

    desc: "DLF Skycourt offers 3BHK Air Conditioned apartments. Each apartment covers an area of around 1,900 sq. ft. These classy and premium residences are going to set another landmark as most of DLF’s projects are known to do, particularly in Gurgaon.",
  },
  {
    id: "7",
    name: "DLF Aralias",
    loc: "Sector 42, Gurgaon",
    img: img7,

    desc: "DLF The Aralias is the Ready to Move Residential Projects in Sector-42 Gurgaon with the Luxurious Offering of 4BHK Apartments with the size of 5575 Sq. Ft. to 6,000 Sq. Ft.",
  },
  {
    id: "8",
    name: "DLF Belaire",
    loc: "Sector 54, Gurgaon",
    img: img8,

    desc: "DLF Belaire is a High-End Residential Project in Sector-54 Golf Course Road Gurgaon. Here. DLF presents the Luxurious 3,4 BHK Apartments plus Servant Room with the size range from 2667 Sq. Ft. to 4098 Sq. Ft.",
  },
  {
    id: "9",
    name: "DLF Magnolias",
    loc: "Sector 42, Gurgaon",
    img: img9,

    desc: "DLF Magnolias brings an Amazing View of DLF Golf Course Road and Aravalias by offering the 4 BHK Apartments and 5BHK Duplex and Penthouses with Servant Room covering 5825 Sq. Ft. to 9800 Sq. Ft. This Project is located in Sector-42 Gurgaon with the Top-Notch Amenities and Specifications for a Lavish Lifestyle.",
  },
  {
    id: "10",
    name: "DLF Park Place",
    loc: "Sector 54, Gurgaon",
    img: img10,

    desc: "DLF Park Place is one of the Best Residential Projects by DLF Group. Here, they are offering 3,4 BHK Lavish Apartments with one Servant Room covering 1677 Sq. Ft. to 2627 Sq. Ft.",
  },
  {
    id: "11",
    name: "DLF PINNACLE",
    loc: "Sector 43, Gurgaon",
    img: img11,

    desc: "DLF Pinnacle is a High-End Residential Project in Sector-43 Gurgaon offering the Lavish 4BHK Apartments with two Servant Rooms per Apartment with an area of 3868 sq. ft. These Luxury Apartments are available for both Rent and Sale.",
  },
  {
    id: "12",
    name: "DLF Crest",
    loc: "Sector 54, Gurgaon",
    img: img12,

    desc: "DLF Crest is nothing but the most luxurious residential complex in Gurgaon. This skyscraper development hosts apartments & penthouses as your ideal homes in Sector 54. DLF The Crest is extended into an 8.82-acre area with 6 dazzling highly elevated towers. These towers are designed by Hafeez Contractor, which individually has 36 floors and a total of 500+ homes in the most privileged location of the City.",
  },
];

export default DlfData;
