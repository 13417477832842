import img1 from "../../../assets/Project/ResidentialProjects/Dwarka/1.jpg";
import img2 from "../../../assets/Project/ResidentialProjects/Dwarka/2.jpg";
import img3 from "../../../assets/Project/ResidentialProjects/Dwarka/3.jpg";
import img4 from "../../../assets/Project/ResidentialProjects/Dwarka/4.jpg";
import img5 from "../../../assets/Project/ResidentialProjects/Dwarka/5.jpg";
import img6 from "../../../assets/Project/ResidentialProjects/Dwarka/6.jpg";
import img7 from "../../../assets/Project/ResidentialProjects/Dwarka/7.jpg";
import img8 from "../../../assets/Project/ResidentialProjects/Dwarka/8.jpg";
import img9 from "../../../assets/Project/ResidentialProjects/Dwarka/9.jpg";
import img10 from "../../../assets/Project/ResidentialProjects/Dwarka/10.jpg";
import img11 from "../../../assets/Project/ResidentialProjects/Dwarka/11.jpg";
import img12 from "../../../assets/Project/ResidentialProjects/Dwarka/12.jpg";
import img13 from "../../../assets/Project/ResidentialProjects/Dwarka/13.jpg";
import img14 from "../../../assets/Project/ResidentialProjects/Dwarka/14.jpg";
import img15 from "../../../assets/Project/ResidentialProjects/Dwarka/15.jpg";
import img16 from "../../../assets/Project/ResidentialProjects/Dwarka/16.jpg";
import img17 from "../../../assets/Project/ResidentialProjects/Dwarka/17.jpg";
import img18 from "../../../assets/Project/ResidentialProjects/Dwarka/18.jpg";
import img19 from "../../../assets/Project/ResidentialProjects/Dwarka/19.jpg";
import img20 from "../../../assets/Project/ResidentialProjects/Dwarka/20.jpg";
import img21 from "../../../assets/Project/ResidentialProjects/Dwarka/21.jpg";
import img22 from "../../../assets/Project/ResidentialProjects/Dwarka/22.jpg";
import img23 from "../../../assets/Project/ResidentialProjects/Dwarka/23.jpg";
import img24 from "../../../assets/Project/ResidentialProjects/Dwarka/24.jpg";
import img25 from "../../../assets/Project/ResidentialProjects/Dwarka/25.jpg";
import img26 from "../../../assets/Project/ResidentialProjects/Dwarka/26.png";
import img27 from "../../../assets/Project/ResidentialProjects/Dwarka/27.jpg";
import img28 from "../../../assets/Project/ResidentialProjects/Dwarka/28.jpg";
import img29 from "../../../assets/Project/ResidentialProjects/Dwarka/29.jpg";
import img30 from "../../../assets/Project/ResidentialProjects/Dwarka/30.jpg";
import img31 from "../../../assets/Project/ResidentialProjects/Dwarka/31.jpg";
import img32 from "../../../assets/Project/ResidentialProjects/Dwarka/32.jpg";
import img33 from "../../../assets/Project/ResidentialProjects/Dwarka/33.jpg";
import img34 from "../../../assets/Project/ResidentialProjects/Dwarka/34.jpg";
import img35 from "../../../assets/Project/ResidentialProjects/Dwarka/35.jpg";
import img36 from "../../../assets/Project/ResidentialProjects/Dwarka/36.jpg";
import img37 from "../../../assets/Project/ResidentialProjects/Dwarka/37.jpg";
import img38 from "../../../assets/Project/ResidentialProjects/Dwarka/38.jpg";
import img39 from "../../../assets/Project/ResidentialProjects/Dwarka/39.jpg";
import img40 from "../../../assets/Project/ResidentialProjects/Dwarka/40.jpg";
import img41 from "../../../assets/Project/ResidentialProjects/Dwarka/41.jpg";
import img42 from "../../../assets/Project/ResidentialProjects/Dwarka/42.jpg";
import img43 from "../../../assets/Project/ResidentialProjects/Dwarka/43.jpg";
import img44 from "../../../assets/Project/ResidentialProjects/Dwarka/44.jpg";
import img45 from "../../../assets/Project/ResidentialProjects/Dwarka/45.jpg";
import img46 from "../../../assets/Project/ResidentialProjects/Dwarka/46.jpg";
import img47 from "../../../assets/Project/ResidentialProjects/Dwarka/47.jpg";
import img48 from "../../../assets/Project/ResidentialProjects/Dwarka/48.jpg";
import img49 from "../../../assets/Project/ResidentialProjects/Dwarka/49.jpg";
import img50 from "../../../assets/Project/ResidentialProjects/Dwarka/50.jpg";
import img51 from "../../../assets/Project/ResidentialProjects/Dwarka/51.jpg";
import img52 from "../../../assets/Project/ResidentialProjects/Dwarka/52.jpg";
import img53 from "../../../assets/Project/ResidentialProjects/Dwarka/53.jpg";
import img54 from "../../../assets/Project/ResidentialProjects/Dwarka/54.jpg";

// Adani Tatva Estates
import logo from "../../../assets/Project/ResidentialProjects/Dwarka/1.jpg"
import tulip1 from "../../../assets/Project/ResidentialProjects/Dwarka/1.jpg"
import tulip2 from "../../../assets/Project/ResidentialProjects/Dwarka/1.jpg"

// Adani Tatva Estates
import Adanilogo from "../../../assets/Project/ResidentialProjects/Dwarka/project1/New_Tatva_logo-04.png"
import Adani1 from "../../../assets/Project/ResidentialProjects/Dwarka/project1/1.jpg"
import Adani2 from "../../../assets/Project/ResidentialProjects/Dwarka/project1/2.jpg"


const DwarkaExpresswayDataRes = [
  {
    id: "1",
    name: "Adani Tatva Estates",
    img: img1,
    location: " Sector 99A, Gurgaon",
    link: "Adani-Tatva-Estates",
    zip: "122002",
    logo: Adanilogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Adani1,
      },
      {
        id: "2",
        img: Adani2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Adani Realty, one of the leading real estate developers in India launched a thriving residential project in Gurgaon, named Adani Tatva Estates Sector 99A Gurgaon. This project offers a diversified range of residential plots in 131 sq. yd. to 179 sq. yd. area. The property caters to a wide range of homebuyers and provides them with an exceptional living experience. It offers top-notch amenities, such as; a badminton court, cricket pitch, kids’ play area, open-air theatre, yoga garden, multipurpose garden, gaming courts, skating rink, outdoor fitness zone, etc. With these amenities, residents will be able to enjoy a luxurious and comfortable lifestyle.",
      },
      {
        id: "2",
        pra: "This is a perfect property that will suit the dreams of any family who wants to build their dream home. In addition, the biggest advantage of this project is its strategic location. It is situated in Sector 99A, Gurgaon, just off Dwarka Expressway. This property is also in proximity of Pataudi Road, Southern Peripheral, & Gurgaon Road. It offers excellent connectivity for major business districts, like; Golf Course Extension Road, Sohna Road, Dwarka Sector 21, Udyog Vihar, & IMT Manesar. It is also seamlessly linked with IGI Airport, Old Gurgaon Railway Station, & Dwarka Sector 21 Metro Station (Blue & Airport Express Line). Overall, Adani Tatva Estates is an excellent residential project and investment opportunity to grab now. Explore it!",
      },
      {
        id: "3",
        pra: "This residential project is situated just off Dwarka Highway and only a quick drive from Pataudi Road & Southern Peripheral.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "Adani Tatva Estates Dwarka Expressway is a secured gated community with a wide range of residential plots at affordable price.",
      },
      {
        id: "2",
        name: "It is equipped with 24/7 CCTV surveillance, RFD-enabled boom barriers, and more to ensure the safety of residents.",
      },
      {
        id: "3",
        name: "This property is only a 15-minute drive from Old Gurgaon Railway Station and hardly a 30-minute drive from IGI Airport.",
      },
      {
        id: "4",
        name: "This residential project is easily accessible from the suburbs of Gurgaon & Delhi.",
      },
      {
        id: "5",
        name: "It offers various attractive payment plans with inclusive gift benefits.",
      },
      {
        id: "6",
        name: "This project has top-notch lifestyle amenities, such as; a cricket net, open-air theatre, yoga garden, skating rink, & outdoor fitness zone.",
      },
      
    ],
    table: [
      {
        id: "1",
        type: "3 BHK",
        Saleable_Area: "2874-2892 sq. ft",
        price: "7.90 Cr* Onwards",
      },
      {
        id: "2",
        type: "4 BHK",
        Saleable_Area: "3684 sq. ft",
        price: "On Request",
      },
      {
        id: "3",
        type: "5 BHK",
        Saleable_Area: "4503 sq. ft",
        price: "On Request",
      },
    ],
    descD:
      "Adani Realty, one of the leading real estate developers in India launched a thriving residential project in Gurgaon, named Adani Tatva Estates Sector 99A Gurgaon. This project offers a diversified range of residential plots in 131 sq. yd. to 179 sq. yd.",
  },
  {
    id: "2",
    name: "M3M Golf Hills",
    img: img2,
    location: " Sector 79, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "M3M Golf Hills Sector 79 Gurgaon is one of the ultra-luxurious residential projects in the city. It is an ideal property by renowned developers M3M to offer a lavish lifestyle with top-notch amenities.",
  },
  {
    id: "3",
    name: "M3M Crown",
    img: img3,
    location: " Sector 111, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "M3M Crown Sector 111, Gurgaon is an ultra-luxurious residential property located near Dwarka Expressway. This property is exclusively designed by M3M India, and it is known for setting a high-scale benchmark in the real estate sector.",
  },
  {
    id: "4",
    name: "Whiteland The Aspen",
    img: img4,
    location: " Sector 76, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Whiteland The Aspen Sector 76 Gurgaon is one of the newly launched residential development. This is a well-planned property on 21 acres of land with lavish 3, 4, & 5 BHK apartments in 2290 to 7582 sq. ft. super built-up area. ",
  },
  {
    id: "5",
    name: "Elan The Presidential",
    img: img5,
    location: " Sector 106, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Elan The Presidential is a magnificent residential project located alongside Dwarka Expressway, Sector 106, Gurgaon. This newly launch 7-star project aims to offer best-in-class futuristic homes which are designed keeping in mind the requirements of its residents.",
  },
  {
    id: "6",
    name: "M3M Antalya Hills",
    img: img6,
    location: "  Sector 79, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "M3M Antalya Hills is a luxury residential project in the prime region of the city, Sector 79 Gurgaon. This is an under-development property that covers almost 10 acres of land in phase 1. It is located at Sector 79, IMT Manesar,",
  },
  {
    id: "7",
    name: "Bestech Altura",
    img: img7,
    location: " Sector 79, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Bestech Altura Gurgaon is nothing less than a masterpiece that offers lavish accommodations and a fantastic community to live together. It is located in the finest region and offers a panoramic Aravalli Hill view. ",
  },
  {
    id: "8",
    name: "Smart World One DXP",
    img: img8,
    location: " Sector 113, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Smartworld One DXP is a prestigious residential property located alongside Dwarka Expressway, Sector 113, Gurgaon. This project is well-designed by the International Developer, i.e. SmartWorld Developers. It is an ultra-luxurious township that offers an amazing living experience to its residents.",
  },
  {
    id: "9",
    name: "Godrej Aria",
    img: img9,
    location: " Sector 79, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "The magnificent Godrej Aria Gurgaon is a project of the Godrej Properties sprawled near the splendid Aravali Hills. It is spread over an area of 7.1 acres and Godrej Aria is Located in Sector 79, Gurgaon area just off National Highway 8 (NH8). Godrej Aria Sector 79 Gurgaon gives you options of 2 and 3 BHK apartments for residents as per their specific budget and requirements.",
  },
  {
    id: "10",
    name: "Krisumi Waterfall Residences",
    img: img10,
    location: " Sector 36A, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Krisumi Waterfall Residences- The world of residential projects that offers fully furnished and world-class living homes, apartments, and flats to satisfy and fulfill the requirements of the family, Krisumi Waterfall residences Gurgaon is another well-executed project which is planned and designed in the Japanese style of art and construction to offer enormous benefits to the occupants.",
  },
  {
    id: "11",
    name: "Godrej 101",
    img: img11,
    location: " Sector 79, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Godrej 101 is one of the luxury developments in Gurgaon by Godrej Properties. Located in Sector-79 Gurgaon, it offers lavish 2 and 3 BHK Apartments with the floor plan size from 1383 to 2366 sq. ft. As the name specifies 101, it offers you not one or two but 101 reasons to live luxury and inspired life.",
  },
  {
    id: "12",
    name: "SS Linden",
    img: img12,
    location: " Sector 84, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "SS Linden has 4-story buildings that present luxury 4 BHK apartments in a 1487 sq. ft. area with independent floors. Homes in these premises are built with added privacy & security to offer a privileged lifestyle to you. Along with a lift, internally connected staircase, servant room, & twin balcony, & many other amenities, SS Linden Gurgaon homes are best in class with all features.",
  },
  {
    id: "13",
    name: "Godrej Meridien",
    img: img13,
    location: " Sector 106, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Godrej Meridien is the new Flagship Residential project by Godrej Properties on Sector-106 Gurgaon. It is another luxurious property by Godrej Properties which has surpassed all the competition in the real estate industry. This beautifully designed residential property – Godrej Meridien Gurgaon has made a mark among its nearby properties.",
  },
  {
    id: "14",
    name: "Sobha City Gurgaon",
    img: img14,
    location: " Sobha City Sector 108, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Sobha City is nothing less than the most luxurious residential complex in Gurgaon. This multi-story community features lavish 2 & 3 BHK apartments with utility space and servant quarters. The size of a 2 BHK home starts from 1381 sq. ft. whereas a 3 BHK premium apartment spans 1711 sq. ft. These are the ideal homes with maximum space, here you can live a premier lifestyle while keeping your head parallel to the sky.",
  },
  {
    id: "15",
    name: "Signature Global City 92",
    img: img15,
    location: " Sector 92, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global City 92 is a premium residential society in South Gurgaon, a few miles away from Dwarka Expressway & just off of Pataudi Road. This modern construction offers uninterrupted access to Gurgaon, Delhi, & undivided NCR. Signature Global City 92 offers 2 & 3 BHK apartments in a 950+ sq. ft. area.",
  },
  {
    id: "16",
    name: "M3M Capital",
    img: img16,
    location: "Sector 113, Bajghera, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "M3M Capital M3M Capital, another residential high-rise development, offers your dream home or apartment with modern facilities and luxury amenities. M3M Capital Gurgaon offers lavish, well-designed, 2.5, 3.5, and 4.5 BHK apartments.",
  },
  {
    id: "17",
    name: "Landmark Avana",
    img: img17,
    location: " Sector 43, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global City 79B Gurgaon is a premium residential project in the Aravalli range. It is surrounded by lush greenery and offers a modern lifestyle in the upcoming residential hub of the city. This property is designed with state-of-the-art equipment and modern engineering to build a legacy for homebuyers like you. It has lavish independent floors in 2 & 3 BHK apartments and their sizes are 1211 to 1556 sq. ft. & 1831 sq. ft.",
  },
  {
    id: "18",
    name: "Emaar EBD 114 Nxt",
    img: img18,
    location: " Sector 114, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global City 79B Gurgaon is a premium residential project in the Aravalli range. It is surrounded by lush greenery and offers a modern lifestyle in the upcoming residential hub of the city. This property is designed with state-of-the-art equipment and modern engineering to build a legacy for homebuyers like you. It has lavish independent floors in 2 & 3 BHK apartments and their sizes are 1211 to 1556 sq. ft. & 1831 sq. ft.",
  },
  {
    id: "19",
    name: "New Launch By Godrej 79",
    img: img19,
    location: " Sector 79, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "New Launch By Godrej 79 is one of the luxury developments in Gurgaon by Godrej Properties. Located in Sector-79 Gurgaon, it offers lavish 2 and 3 BHK Apartments with the floor plan size from 1383 to 2366 sq. ft. As the name specifies 101, it offers you not one or two but 101 reasons to live luxury and inspired life.",
  },
  {
    id: "20",
    name: "Smart World Code 66",
    img: img20,
    location: " Sector 66, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Smart World Developers is coming up with an unquestionable newly launched residential property, i.e. Smart World Code 66 located in Sector 66, Gurgaon. This housing development is exclusively designed for those who are in search of a chic & contemporary style of living for their families. All the homes at this property are designed to meet the Vastu Shastra standards and are available in two prominent sizes, i.e. 2 & 3 BHK.",
  },
  {
    id: "21",
    name: "Microtek Grandfront",
    img: img21,
    location: " Sector 81, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Microtek Grandfront Sector 81 Gurgaon is a turnkey business center in the city. It is one of the best bases for your unique and creative enterprises & organizations in Millenium City, Gurgaon.",
  },
  {
    id: "22",
    name: "JMS Ninety 5",
    img: img22,
    location: " Sector 95, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "JMS Ninety 5 Sector 95 Gurgaon is a premium & well-designed residential project. It is one of the iconic development by renowned real estate developers, JMS Group. This project is a gated community on 16+ acres of land with vast open spaces, ",
  },
  {
    id: "23",
    name: "Signature Global City 93",
    img: img23,
    location: " Sector 93, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global City 93 Gurgaon is an iconic residential project in the top location of the city. This is a premier project on 10 acres of land with a wide variety of luxury apartments. It has 14 high-rise towers with 14 floors each, 50 % open area, and approx 1500 flats to cater to the needs & lifestyles of different people.",
  },
  {
    id: "24",
    name: "Signature Global City 79B",
    img: img24,
    location: " Sector 79B, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global City 79B Gurgaon is a premium residential project in the Aravalli range. It is surrounded by lush greenery and offers a modern lifestyle in the upcoming residential hub of the city.",
  },
  {
    id: "25",
    name: "Pareena Express Heights",
    img: img25,
    location: " Sector 99, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Pareena Express Heights is an elegant residential project in Sector 99, Gurgaon. It is a unique architecture that is creatively designed to offer an urban lifestyle on the outskirts of the city. This residential project is spread over a massive land of 13 acres with 10 shiny towers. It has a 75% open area, ",
  },
  {
    id: "26",
    name: "ROF Insignia Park II",
    img: img26,
    location: " Sector 95, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "ROF Insignia Park 2 offers a lavish lifestyle to its residents, and it is designed by a well-known developer, i.e. ROF Group. This residential development is situated in Sector 95 Gurgaon, far away from the hustle & bustle of the city. It is spread out over 10 acres of land and features 319 well-ventilated residential plots in total. ",
  },
  {
    id: "27",
    name: "ROF Insignia Park I",
    img: img27,
    location: " Sector 93, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "ROF Insignia Park I is the most luxurious residential Plots available in Gurgaon, Sector 93. This property offers luxury lands a varied range of plots with utility and amenities services. These plots are ideal homes with maximum space, here you can live an exclusive lifestyle at an unparalleled price range.",
  },
  {
    id: "28",
    name: "Mahindra Aura",
    img: img28,
    location: " Sector 110A, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Spread over 17 acres of beautiful landscape, Aura brings to you premium homes located in the prime location of Dwarka Expressway. Get fascinated by well-planned residential designs, manicured lawns, state-of-the-art clubhouse and world-class amenities. Coupled with the Mahindra assurance of quality, Aura is sure to transform your life into a style statement.",
  },
  {
    id: "29",
    name: "Signature Global SCO Plots ",
    img: img29,
    location: " Sector 37D, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global SCO 37D is a perfect commercial development. This is an innovative premise for an ultra-modern trade & commerce experience. It is the Border of Millenium City, in sector 37D. This Signature Global SCO 37D Gurgaon is just off Dwarka Expressway and only a few km from NH 48 & Pataudi Road. ",
  },
  {
    id: "30",
    name: "Paras Dews",
    img: img30,
    location: " Sector 106, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global is a popular name in the Real Estate sector. It has abundant residential and commercial properties nestled in northern India. One of the developed commercial properties in the millennium city of Gurgaon is Signum 37D. This property is focused on giving a contemporary living experience to its residents.",
  },
  {
    id: "31",
    name: "Experion Heartsong",
    img: img31,
    location: " Sector 108, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Living Precinct developed by “Experion Developer” on the high parameters to deliver the Luxury Project- Experion Windchants to live in with the choice of 2, 3 and 4 BHK apartments along with the spacious Whistling Palms and Willows Villas on Sector-112 Dwarka Expressway Gurgaon.",
  },
  {
    id: "32",
    name: "Experion Windchants",
    img: img32,
    location: " Sector 112, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Experion Windchants is a royal luxurious residential project by Experion. It is spanned in an extensive 23 Acres of land with 2,50,000+ sq. ft. for activity space. This premise is located in Sector 112, Dwarka Expressway, Gurgaon. ",
  },
  {
    id: "33",
    name: "Bestech Park View Ananda",
    img: img33,
    location: " Sector 81, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Bestech Group presents a prestigious project Bestech Park View Ananda, located in Sector- 81, Gurgaon at a distance of 1.5 km from NH8. Park View Ananda offers 2 and 3 BHK premium apartments having a total saleable area approx. 11.5 lac sq.ft. It is crafted to match your contemporary lifestyle!",
  },
  {
    id: "34",
    name: "DLF Ultima",
    img: img34,
    location: " Sector 81, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "DLF has come up with many “Never Seen Before” Features in this part of Gurugram within a budget range. DLF The Ultima- Luxury Home from DLF Sector 81 Gurugram (ready to Move). The Craftsmanship silently touches “The King” in you and gives access to a stunning lush green Oasis 60% of staggering out of 23 acres land parcel has been developed into a multi-activity green area.",
  },
  {
    id: "35",
    name: "DLF Primus",
    img: img35,
    location: " Sector 82A, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "DLF The Primus is ready to move residential project situated in Sector 82A, Gurugram. Dlf Primus floor plan consists of 3BHK & 4BHK apartments. DLF Primus is spread over an area of 12.5 acres and has around 626 beautiful well-planned apartment. The apartment size ranges from 1800 sq.ft. to 2600 sq.ft.",
  },
  {
    id: "36",
    name: "Park View Grandspa",
    img: img36,
    location: " Sector 81, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "It’s the dawn of a new phenomenon, an enthralling experience & an ultimate lifestyle! Bestech Park View Grand Spa is a collection of artistically planned high-class exclusive apartments. The elegantly designed and creatively planned Park View Grand Spa is located in Sector 81 and it conveniently connects to the rest of the city of Gurgaon.",
  },
  {
    id: "37",
    name: "SS Group The Leaf",
    img: img37,
    location: " Sector 85, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "SS Group The Leaf – Presenting The Leaf by SS Group – a premium residential property inspired by nature, it is designed to give you a luxurious lifestyle in a clean green surrounding. Come; discover a new life amidst nature, aptly complemented by luxurious amenities.",
  },
  {
    id: "38",
    name: "DLF New Town Heights",
    img: img38,
    location: " Sector 90, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "DLF now brings quality living and a contemporary lifestyle at NEW TOWN HEIGHTS, a residential project in Sector-86, 90 & 91 Gurgaon. A truly integrated township in the new Gurgaon that is well-connected from NH-8 and Manesar.",
  },
  {
    id: "39",
    name: "Emaar Palm Hills",
    img: img39,
    location: " Sector 77, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Palm Hills – Presenting a refreshing new look at modern living. Palm Hills, Gurgaon, with welcoming green spaces, invigorating fresh air and sunlight, and modern design concepts that complement your sensibilities.",
  },
  {
    id: "40",
    name: "Emaar Palm Heights",
    img: img40,
    location: " Sector 77, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "EMAAR PALM HEIGHTS – Bringing families together with redefined spaces With three modern landmark towers rising above the horizon, Emaar Palm Heights welcomes families who seek the luxury of well-designed spaces to live a well-deserved life.",
  },
  {
    id: "41",
    name: "Emaar Palm Gardens",
    img: img41,
    location: " Sector 83, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Palm Gardens – Emaar presents a new residential project Emaar Palm Gardens located at Sector 83 Gurgaon. Emaar Palm Gardens Gurgaon offers 3 BHK apartments with modernized features like AC & Modular Kitchen, Total privacy, Garden Facing, Colourful & continuous landscaping, efficient use of space, and much more.",
  },
  {
    id: "42",
    name: "DLF Regal Garden",
    img: img42,
    location: " Sector 90, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "At DLF Regal Garden the focus has been to create a retreat-like space that stands the test of time. As more complexes get built, Regal Gardens will always remain a landmark at DLF Gardencity with its verdant gardens, pool, and club complex which is partly sunk to give more openness.",
  },
  {
    id: "43",
    name: "DLF The Skycourt",
    img: img43,
    location: " Sector 86, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "DLF Skycourt offers 3BHK Air Conditioned apartments. Each apartment covers an area of around 1,900 sq. ft. These classy and premium residences are going to set another landmark as most of DLF’s projects are known to do, particularly in Gurgaon.",
  },
  {
    id: "44",
    name: "Heritage Max",
    img: img44,
    location: " Sector 102, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Heritage Max is a Ready to Move in Sports Lifestyle oriented Luxury residential project on Dwarka Expressway, Sector 102, Gurugram being delivered by Conscient Infrastructure. It offers 3BHK+SERVANT, 3BHK+SERVANT+SYUDY & 4BHK+SERVANT apartments with state of art amenities and facilities.",
  },
  {
    id: "45",
    name: "Emaar Imperial Garden",
    img: img45,
    location: " Sector 102, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Imperial Gardens – Emaar presents a new residential project Emaar Imperial Gardens located at Sector 102 Gurgaon. Emaar Imperial Gardens Gurgaon offers 3 BHK apartments with modernized features like AC & Modular Kitchen, Total privacy, Garden Facing, Colorful & continuous landscaping, efficient use of space and much more.",
  },
  {
    id: "46",
    name: "Emaar Gurgaon Greens",
    img: img46,
    location: " Sector 102, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Emaar Gurgaon Greens- Emaar Gurgaon Greens New Residential Projects, a place where you feel, nestled in a posh residential area of Gurgaon, just across Dwarka Expressway. The whole complex is designed keeping your perfect taste in mind.",
  },
  {
    id: "47",
    name: "Adani Oyster Grande",
    img: img47,
    location: " Sector 102, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "AADANI OYSTER GRANDE is one of the maiden projects of Adani Realty in Sector-102 Dwarka Expressway Gurgaon. The Lavish Residential project has Air-conditioned apartments of 3BHK, 4BHK, 5BHK & Penthouses on a built-up area of 18 acres with extensive landscaped greenery.",
  },
  {
    id: "48",
    name: "Signature Global Park 2&3",
    img: img48,
    location: " Sector 36, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global, a renowned name in the Real Estate industry presents a brand new residential project- Global Park 2 & 3 in Sector 36, Gurgaon. The project has been developed under the Deen Dayal Jan Awas Yojana scheme. It has been designed by the famous architect, Hafeez Contractor.",
  },
  {
    id: "49",
    name: "Signature Global City 81",
    img: img49,
    location: " Sector 81, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global has recently brought a sustainable residential project- Signature Global City 81 to Gurgaon. The living destination is known to offer a heaven-like experience to families altogether.",
  },
  {
    id: "50",
    name: "Tata Gurgaon Gateway",
    img: img50,
    location: " Sector 112, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Tata Gurgaon Gateway is a luxury Residential Project by Tata Housing Group in Sector-113 Gurgaon. Here, Tata Housing Gurgaon Gateway presents 2,3 BHK Luxury Apartments with the size range starting from 1580 to 2925 Sq. Ft.",
  },
  {
    id: "51",
    name: "Signature Global City 37D 2",
    img: img51,
    location: " Sector 37D, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global City 37D 2 is an elegant residential development in Gurgaon. It is a unique architecture built to provide an urban lifestyle on the outskirts of the city. A premier complex of 5.62 Acre, Signature Global City 37D II has magnificent low-rise buildings with privacy-added 2/3 BHK premium apartments.",
  },
  {
    id: "52",
    name: "Shapoorji Pallonji Joyville",
    img: img52,
    location: " Sector 102, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Shapoorji Pallonji Joyville is a highly sought-after project, due to its prime location that is in Sector 102, Gurgaon. The project is situated on Dwarka Expressway and is close to other major residential projects, as well as being near NH8. ",
  },
  {
    id: "53",
    name: "Hero Homes Gurgaon",
    img: img53,
    location: " Sector 104, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Hero Homes Gurgaon Sector 104 is an Elegant offering by Hero Realty Private Limited on Dwarka Expressway. It is one of the most Branded Business associations in India. The company has been working to bring state-of-the-art living with cutting-edge innovations and a customer-centric approach.",
  },
  {
    id: "54",
    name: "Signature Global City 37D",
    img: img54,
    location: " Sector 37D, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD:
      "Signature Global Group is among iconic developers, offering affordable & top-class residential complexes in Gurgaon & other prime cities of India. Signature Global City 37D is one of the supreme offerings of this brand.",
  },
];

export default DwarkaExpresswayDataRes;
