import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/AIPL/ak_1299_1675388068-1512984176_700x700.png";
import AIPLData from "../../Data/ListData/RoadAIPL";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function AIPLListings() {
  TabTitle("Jeet Estate | AIPL  Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              AIPL has been involved in real estate developments for more than
              two decades. This brand is ideal to design and develop world-class
              commercial plazas. It has an expansive team of experts that has a
              deep understanding of the location and the latest technologies.
              With an immense facility, amenities, & features, AIPL offers AIPL
              Autograph & APIL Business Club in Gurgaon. These are the perfect
              commercial developments in the modern suburb with easy transit
              facilities. In addition, AIPL offers you sustainable and worthy
              developments that are equipped with futuristic technologies. AIPL
              units are exclusively available at Gupta Promoters, explore them
              and book now!
            </p>
            <div className="row">
              {AIPLData &&
                AIPLData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AIPLListings;
