import Adanisamsaram3mimg1 from "../../assets/Adanisamsara/img111.jpg";
import Adanisamsaram3mimg2 from "../../assets/Adanisamsara/img04.jpg";
import Adanisamsaram3mimg3 from "../../assets/Adanisamsara/img03.jpg";
// import Adanisamsaramap from "../../assets/Adanisamsara/m3m-heights-location-map.jpg";
import Adanisamsaraaboutbanner from "../../assets/Adanisamsara/img06.jpg";
import AdanisamsaraLogo from "../../assets/Adanisamsara/developer-logo.png";
import Adanisamsaralogoprop from "../../assets/Adanisamsara/3or9whtu.jpg";
import Adanisamsaramprice1 from "../../assets/Adanisamsara/img091.jpg";
import Adanisamsaramprice2 from "../../assets/Adanisamsara/img09.jpg";
import Adanisamsaramprice3 from "../../assets/Adanisamsara/img10.jpg";
import AdanisamsaraLayout from "../../assets/Adanisamsara/Master Layout.jpg";
import AdanisamsaramAmenities1 from "../../assets/Adanisamsara/club.png";
import AdanisamsaramAmenities2 from "../../assets/Adanisamsara/elevator.png";
import AdanisamsaramAmenities3 from "../../assets/Adanisamsara/gym.png";
import AdanisamsaramAmenities4 from "../../assets/Adanisamsara/highway.png";
import AdanisamsaramAmenities5 from "../../assets/Adanisamsara/hospital.png";
import AdanisamsaramAmenities6 from "../../assets/Adanisamsara/hotel.png";
import AdanisamsaramAmenities7 from "../../assets/Adanisamsara/parking.png";
import AdanisamsaramAmenities8 from "../../assets/Adanisamsara/plane.png";
import AdanisamsaramAmenities9 from "../../assets/Adanisamsara/schools.png";
import AdanisamsaramAmenities10 from "../../assets/Adanisamsara/squash.png";
import AdanisamsaramAmenities11 from "../../assets/Adanisamsara/swimming-pool.png";
import AdanisamsaramAmenities12 from "../../assets/Adanisamsara/train.png";
import Adanisamsaralocmap from "../../assets/Adanisamsara/LocationMap.jpg";
// Bestech Altura

import BestechAltura1 from "../../assets/BestechAltura/img02.jpg";
import BestechAltura2 from "../../assets/BestechAltura/img09.jpg";
import BestechAltura3 from "../../assets/BestechAltura/img11.jpg";
import BestechAltura4 from "../../assets/BestechAltura/3or9whtu.png";
import BestechAltura5 from "../../assets/BestechAltura/img03.jpg";
import BestechAltura6 from "../../assets/BestechAltura/img03.jpg";
import BestechAltura7 from "../../assets/BestechAltura/img18.jpeg";
import BestechAltura8 from "../../assets/BestechAltura/bestech-altura-location-map.webp";
import BestechAltura9 from "../../assets/BestechAltura/img13.jpeg";
import BestechAltura10 from "../../assets/BestechAltura/img14.jpeg";
import BestechAltura11 from "../../assets/BestechAltura/img15.jpeg";
import BestechAltura13 from "../../assets/BestechAltura/img01.png";
import BestechAltura14 from "../../assets/BestechAltura/img02.png";
import BestechAltura15 from "../../assets/BestechAltura/img03.png";
import BestechAltura16 from "../../assets/BestechAltura/img04.png";
import BestechAltura17 from "../../assets/BestechAltura/img05.png";
import BestechAltura18 from "../../assets/BestechAltura/img06.png";
import BestechAltura19 from "../../assets/BestechAltura/img07.png";
import BestechAltura20 from "../../assets/BestechAltura/img08.png";
import BestechAltura21 from "../../assets/BestechAltura/img09.png";
import BestechAltura22 from "../../assets/BestechAltura/img10.png";
import BestechAltura23 from "../../assets/BestechAltura/img11.png";
import BestechAltura24 from "../../assets/BestechAltura/img12.png";

// CentralPark

import CentralPark1 from "../../assets/CentralPark/img01.jpg";
import CentralPark2 from "../../assets/CentralPark/img09.jpg";
import CentralPark3 from "../../assets/CentralPark/img11.jpg";
import CentralPark4 from "../../assets/CentralPark/logo2.svg";
import CentralPark5 from "../../assets/CentralPark/imgs02.jpg";
import CentralPark6 from "../../assets/CentralPark/img03.jpg";
import CentralPark7 from "../../assets/CentralPark/Central-Park.webp";
import CentralPark8 from "../../assets/CentralPark/Central-Park-Map-Guide-Manhattanite-Blog.webp";
import CentralPark9 from "../../assets/CentralPark/floor1.jpg";
import CentralPark10 from "../../assets/CentralPark/floor2.jpg";
import CentralPark11 from "../../assets/Adanisamsara/club.png";
import CentralPark12 from "../../assets/Adanisamsara/elevator.png";
import CentralPark13 from "../../assets/Adanisamsara/gym.png";
import CentralPark14 from "../../assets/Adanisamsara/highway.png";
import CentralPark15 from "../../assets/Adanisamsara/hospital.png";
import CentralPark16 from "../../assets/Adanisamsara/hotel.png";
import CentralPark17 from "../../assets/Adanisamsara/parking.png";
import CentralPark18 from "../../assets/Adanisamsara/plane.png";
import CentralPark19 from "../../assets/Adanisamsara/schools.png";
import CentralPark20 from "../../assets/Adanisamsara/squash.png";
import CentralPark21 from "../../assets/Adanisamsara/swimming-pool.png";
import CentralPark22 from "../../assets/Adanisamsara/train.png";


// CentralPark 2
import CentralParks1 from "../../assets/CentralPark2/img01.webp";
import CentralParks2 from "../../assets/CentralPark2/img06.jpg";
import CentralParks3 from "../../assets/CentralPark2/img09.jpg";
import CentralParks4 from "../../assets/CentralPark2/logo.png";
import CentralParks5 from "../../assets/CentralPark2/img04.jpg";
import CentralParks6 from "../../assets/CentralPark2/img03.jpg";
import CentralParks7 from "../../assets/CentralPark2/new-map.jpg";
import CentralParks8 from "../../assets/CentralPark2/Master-Plan-31-Central-Park-2-Gurgaon-5017405_600_800_310_462.jpg";
import CentralParks9 from "../../assets/CentralPark2/Tower_ABEF_Unit_C_3BHK+SQ_2719.jpg";
import CentralParks10 from "../../assets/CentralPark2/Tower_CDGHI_Unit_A_4BR+SQ3931Sqft.jpg";

const data = [
  {
    id: "1",
    name: "Adani Samsara",
    location: "Sector 63, Gurugram",
    UnitType: "3 & 4 BHK Apartment",
    Sizes: "1627 - 2239 Sq. Ft",
    link: "AdaniSamsara",
    img: Adanisamsaram3mimg1,
    logoprop: Adanisamsaralogoprop,
    aboutbanner: Adanisamsaraaboutbanner,
    Logo: AdanisamsaraLogo,
    Layout: AdanisamsaraLayout,
    locmap: Adanisamsaralocmap,
    steps: [
      {
        id: "1",
        img: Adanisamsaram3mimg2,
      },
      {
        id: "2",
        img: Adanisamsaram3mimg3,
      },
    ],
    pricing: [
      {
        id: "1",
        price: "Price on request",
        unity: "3 BHK | RERA Carpet Area: 113.81 Sq. Mtrs. | 1,225 Sq. Ft.",
        Sizes: " 3 BHK",
        img: Adanisamsaramprice1,
      },
      {
        id: "2",
        price: "Price on request",
        unity: "3 BHK | RERA Carpet Area: 117.15 Sq. Mtrs. | 1,261 Sq. Ft.",
        Sizes: " 3 BHK",
        img: Adanisamsaramprice2,
      },
      {
        id: "3",
        price: "Price on request",
        unity: "4 BHK | RERA Carpet Area: 167.13 Sq. Mtrs. | 1,799 Sq. Ft. ",
        Sizes: " 4 BHK",
        img: Adanisamsaramprice3,
      },
    ],
    Amenities: [
      {
        id: "1",
        name: "Club",
        img: AdanisamsaramAmenities1,
      },
      {
        id: "2",
        name: "Elevator",
        img: AdanisamsaramAmenities2,
      },
      {
        id: "3",
        name: "Gym",
        img: AdanisamsaramAmenities3,
      },
      {
        id: "4",
        name: "Highway",
        img: AdanisamsaramAmenities4,
      },
      {
        id: "5",
        name: "Hospital",
        img: AdanisamsaramAmenities5,
      },
      {
        id: "6",
        name: "Hotel",
        img: AdanisamsaramAmenities6,
      },
      {
        id: "7",
        name: "Parking",
        img: AdanisamsaramAmenities7,
      },
      {
        id: "8",
        name: "Plane",
        img: AdanisamsaramAmenities8,
      },
      {
        id: "9",
        name: "School",
        img: AdanisamsaramAmenities9,
      },
      {
        id: "10",
        name: "Squash",
        img: AdanisamsaramAmenities10,
      },
      {
        id: "11",
        name: "Swimming Pool",
        img: AdanisamsaramAmenities11,
      },
      {
        id: "12",
        name: "Train",
        img: AdanisamsaramAmenities12,
      },
    ],
    desc: "A Joint Venture between Adani Realty and Brahma Group, SAMSARA AVASA is an address that is a true expression of yourself. Rejoice in the convenience and comfort of living in an integrated, close-knit 4BHK community, thoughtfully planned to pamper you with all modern amenities. A world that delights your senses and celebrates your fine taste. Here’s an address as exclusive as it can get- Samsara Avasa, the low-rise 4 BHK residences with the choice of private and common terrace spread across 4 storeys and stilt parking. This gated community comes with 24/7 security, CCTV surveillance for a well-guarded and secure living. Add to that the goodness of a close-knit community and the perks of low-density occupancy.",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3509.805401012414!2d77.084193!3d28.394945!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d232087955273%3A0x33608e53196da48d!2sAdani%20Samsara%20Vilasa%20Floors!5e0!3m2!1sen!2sin!4v1697890801430!5m2!1sen!2sin",
  },
  {
    id: "2",
    name: "Bestech Altura",
    location: "Sector 79, Gurgaon",
    UnitType: "3 & 4 BHK Apartment / Residences",
    Sizes: "2015 - 2675 Sq. Ft.",
    link: "BestechAltura",
    img: BestechAltura1,
    logoprop: BestechAltura4,
    aboutbanner: BestechAltura5,
    Logo: BestechAltura6,
    Layout: BestechAltura7,
    locmap: BestechAltura8,
    steps: [
      {
        id: "1",
        img: BestechAltura2,
      },
      {
        id: "2",
        img: BestechAltura3,
      },
    ],
    pricing: [
      {
        id: "1",
        price: "Price on request",
        unity: "3 BHK | RERA Carpet Area: 187.2 Sq. Mtrs. | 2015 Sq. Ft.",
        Sizes: " 3 BHK",
        img: BestechAltura9,
      },
      {
        id: "2",
        price: "Price on request",
        unity: "3 BHK | RERA Carpet Area: 199.7 Sq. Mtrs. | 2150 Sq. Ft.",
        Sizes: " 3 BHK",
        img: BestechAltura10,
      },
      {
        id: "3",
        price: "Price on request",
        unity: "4 BHK | RERA Carpet Area: 248.5 Sq. Mtrs. | 2675 Sq. Ft. ",
        Sizes: " 4 BHK",
        img: BestechAltura11,
      },
    ],
    Amenities: [
      {
        id: "1",
        name: "Amphitheatre",
        img: BestechAltura13,
      },
      {
        id: "2",
        name: "Gymnasium",
        img: BestechAltura14,
      },
      {
        id: "3",
        name: "Kids Play area",
        img: BestechAltura15,
      },
      {
        id: "4",
        name: "Tennis Court",
        img: BestechAltura16,
      },
      {
        id: "5",
        name: "Squash Court",
        img: BestechAltura17,
      },
      {
        id: "6",
        name: "AV Room",
        img: BestechAltura18,
      },
      {
        id: "7",
        name: "Swimming pool",
        img: BestechAltura19,
      },
      {
        id: "8",
        name: "Cards room",
        img: BestechAltura20,
      },
      {
        id: "9",
        name: "Spa",
        img: BestechAltura21,
      },
      {
        id: "10",
        name: "Half basketball court",
        img: BestechAltura22,
      },
      {
        id: "11",
        name: "Urban river landscape",
        img: BestechAltura23,
      },
      {
        id: "12",
        name: "Banquet facilities",
        img: BestechAltura24,
      },
    ],
    desc: "Bestech Altura is the best luxurious residential property which offers 3 BHK and 4 BHK spacious flats in Sector 79, Gurugram. Altura offers you to be a part of an active community where you nestle amidst Aravalli Hills and listen to the eloquence of serenity where you begin your journey to living anew. Live in an apartment enveloped by nature and be the best version of yourself. Your active lifestyle is waiting for you, just outside your door. Altura offers you a home that is surrounded by nature and has all kinds of activities you can indulge in. What are you waiting for? Just grab your running shoes or your favorite yoga mat, and GET.SET.GO! Bestech Altura, active homes for active living",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3511.0779219676147!2d76.97074287545406!3d28.35649369656236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3d5eab47decb%3A0x48430f5291fb594b!2sBestech%20Altura%20Gurgaon!5e0!3m2!1sen!2sin!4v1697891441511!5m2!1sen!2sin",
  },
  {
    id: "3",
    name: "Central Park",
    location: "Sector 54, Gurgaon",
    UnitType: "3 & 4 BHK Apartment / Residences",
    Sizes: "1541 to 5100 sq. ft.",
    link: "CentralPark",
    img: CentralPark1,
    logoprop: CentralPark4,
    aboutbanner: CentralPark5,
    Logo: CentralPark6,
    Layout: CentralPark7,
    locmap: CentralPark8,
    steps: [
      {
        id: "1",
        img: CentralPark2,
      },
      {
        id: "2",
        img: CentralPark3,
      },
    ],
    pricing: [
      {
        id: "1",
        price: "Price on request",
        unity: "3 BHK | RERA Carpet Area: 330 Sq. Mtrs. | 2134 Sq. Ft.",
        Sizes: " 3 BHK",
        img: CentralPark9,
      },
      {
        id: "2",
        price: "Price on request",
        unity: "4 BHK | RERA Carpet Area: 398.5 Sq. Mtrs. | 2570 Sq. Ft. ",
        Sizes: " 4 BHK",
        img: CentralPark10,
      },
    ],
    Amenities: [
      {
        id: "1",
        name: "Club",
        img: CentralPark11,
      },
      {
        id: "2",
        name: "Elevator",
        img: CentralPark12,
      },
      {
        id: "3",
        name: "Gym",
        img: CentralPark13,
      },
      {
        id: "4",
        name: "Highway",
        img: CentralPark14,
      },
      {
        id: "5",
        name: "Hospital",
        img: CentralPark15,
      },
      {
        id: "6",
        name: "Hotel",
        img: CentralPark16,
      },
      {
        id: "7",
        name: "Parking",
        img: CentralPark17,
      },
      {
        id: "8",
        name: "Plane",
        img: CentralPark18,
      },
      {
        id: "9",
        name: "School",
        img: CentralPark19,
      },
      {
        id: "10",
        name: "Squash",
        img: CentralPark20,
      },
      {
        id: "11",
        name: "Swimming Pool",
        img: CentralPark21,
      },
      {
        id: "12",
        name: "Train",
        img: CentralPark22,
      },
    ],
    desc: "Central Park Resorts in Sector 48, Gurgaon is a ready-to-move housing society. It offers apartments and independent floors in varied budget range. These units are a perfect combination of comfort and style, specifically designed to suit your requirements and conveniences. There are 1BHK, 2BHK, 3BHK, 4BHK, 5BHK and 6BHK Apartments and 4BHK independent floors available in this project. This housing society is now ready to be called home as families have started moving in. Check out some of the features of Central Park Resorts housing society: *Central Park Resorts Sector 48 has 16 towers, with 17 floors each and 1445 units on offer. *Spread over an area of 47.5 acres, Central Park Resorts is one of the spacious housing societies in the Gurgaon region. With all the basic amenities available, Central Park Resorts fits into your budget and your lifestyle. *Sector 48 has good connectivity to some of the important areas in the proximity such as Park Hospital, Omaxe Celebration Mall and GD Goenka Public School and so on.",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14035.258141773145!2d77.0347468!3d28.4248526!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1878dee37f57%3A0xa9ecdd7d72782cf6!2sCentral%20Park%C2%AE!5e0!3m2!1sen!2sin!4v1698306349272!5m2!1sen!2sin",
  },
  {
    id: "4",
    name: "Central Park 2",
    location: "Sector 48, Gurgaon",
    UnitType: "3 & 4 BHK Apartment / Residences",
    Sizes: "1,320 | 1,418 sq. ft",
    link: "CentralPark2",
    img: CentralParks1,
    
    logoprop: CentralParks4,
    aboutbanner: CentralParks5,
    Logo: CentralParks6,
    Layout: CentralParks7,
    locmap: CentralParks8,
    steps: [
      {
        id: "1",
        img: CentralParks2,
      },
      {
        id: "2",
        img: CentralParks3,
      },
    ],
    pricing: [
      {
        id: "1",
        price: "Price on request",
        unity: "3 BHK | RERA Carpet Area: 2464 Sq Ft Unit.",
        Sizes: " 3 BHK",
        img: CentralParks9,
      },
      {
        id: "2",
        price: "Price on request",
        unity: "4 BHK | RERA Carpet Area: 2838 Sq Ft Unit. ",
        Sizes: " 4 BHK",
        img: CentralParks10,
      },
    ],
    Amenities: [
      {
        id: "1",
        name: "Club",
        img: CentralPark11,
      },
      {
        id: "2",
        name: "Elevator",
        img: CentralPark12,
      },
      {
        id: "3",
        name: "Gym",
        img: CentralPark13,
      },
      {
        id: "4",
        name: "Highway",
        img: CentralPark14,
      },
      {
        id: "5",
        name: "Hospital",
        img: CentralPark15,
      },
      {
        id: "6",
        name: "Hotel",
        img: CentralPark16,
      },
      {
        id: "7",
        name: "Parking",
        img: CentralPark17,
      },
      {
        id: "8",
        name: "Plane",
        img: CentralPark18,
      },
      {
        id: "9",
        name: "School",
        img: CentralPark19,
      },
      {
        id: "10",
        name: "Squash",
        img: CentralPark20,
      },
      {
        id: "11",
        name: "Swimming Pool",
        img: CentralPark21,
      },
      {
        id: "12",
        name: "Train",
        img: CentralPark22,
      },
    ],
    desc: "This beautiful 3 bhk flat in sector 48 gurgaon is situated in central park 2 bellevue, one of the popular residential society in gurgaon. This residential flat is north-West-Facing direction. The flat occupies a super built up area of 2350 sq.Ft. That consists of 3 bedrooms, 3 bathrooms and more than 3 balconies. The flat has a total of 16 floors and this property is situated on 7th floor. An added advantage of this 5-10 years old flat is that it is available for immediate possession as the project is already ready to move. The flat will offer a modern lifestyle as it is presented with many of the amenities such as swimming pool, park, lift(s), maintenance staff, visitor parking, water softening plant, fitness centre / gym and shopping centre. Borewell/tank and municipal corporation provides a regular supply of water to this residential project. Additional details : Piped gas facility is available in the property. The apartment has borings water supply. Daily needs shopping could be done within the society premises to make the stay convinent. Full power backup is available. The society has dedicated security guards for every tower.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7017.694969143084!2d77.032992!3d28.42385855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d180ac945e753%3A0x256f70801af28ccd!2sCentral%20Park%20II%2C%20Sector%2048%2C%20Gurugram%2C%20Haryana%20122001!5e0!3m2!1sen!2sin!4v1698307202262!5m2!1sen!2sin",
  },
  {
    id: "5",
    name: "DLF Park Place",
    location: "Sector 48, Gurgaon",
    UnitType: "3 & 4 BHK Apartment / Residences",
    Sizes: "1,320 | 1,418 sq. ft",
    link: "DLFParkPlace",
    img: CentralParks1,
    
    logoprop: CentralParks4,
    aboutbanner: CentralParks5,
    Logo: CentralParks6,
    Layout: CentralParks7,
    locmap: CentralParks8,
    steps: [
      {
        id: "1",
        img: CentralParks2,
      },
      {
        id: "2",
        img: CentralParks3,
      },
    ],
    pricing: [
      {
        id: "1",
        price: "Price on request",
        unity: "3 BHK | RERA Carpet Area: 2464 Sq Ft Unit.",
        Sizes: " 3 BHK",
        img: CentralParks9,
      },
      {
        id: "2",
        price: "Price on request",
        unity: "4 BHK | RERA Carpet Area: 2838 Sq Ft Unit. ",
        Sizes: " 4 BHK",
        img: CentralParks10,
      },
    ],
    Amenities: [
      {
        id: "1",
        name: "Club",
        img: CentralPark11,
      },
      {
        id: "2",
        name: "Elevator",
        img: CentralPark12,
      },
      {
        id: "3",
        name: "Gym",
        img: CentralPark13,
      },
      {
        id: "4",
        name: "Highway",
        img: CentralPark14,
      },
      {
        id: "5",
        name: "Hospital",
        img: CentralPark15,
      },
      {
        id: "6",
        name: "Hotel",
        img: CentralPark16,
      },
      {
        id: "7",
        name: "Parking",
        img: CentralPark17,
      },
      {
        id: "8",
        name: "Plane",
        img: CentralPark18,
      },
      {
        id: "9",
        name: "School",
        img: CentralPark19,
      },
      {
        id: "10",
        name: "Squash",
        img: CentralPark20,
      },
      {
        id: "11",
        name: "Swimming Pool",
        img: CentralPark21,
      },
      {
        id: "12",
        name: "Train",
        img: CentralPark22,
      },
    ],
    desc: "This beautiful 3 bhk flat in sector 48 gurgaon is situated in central park 2 bellevue, one of the popular residential society in gurgaon. This residential flat is north-West-Facing direction. The flat occupies a super built up area of 2350 sq.Ft. That consists of 3 bedrooms, 3 bathrooms and more than 3 balconies. The flat has a total of 16 floors and this property is situated on 7th floor. An added advantage of this 5-10 years old flat is that it is available for immediate possession as the project is already ready to move. The flat will offer a modern lifestyle as it is presented with many of the amenities such as swimming pool, park, lift(s), maintenance staff, visitor parking, water softening plant, fitness centre / gym and shopping centre. Borewell/tank and municipal corporation provides a regular supply of water to this residential project. Additional details : Piped gas facility is available in the property. The apartment has borings water supply. Daily needs shopping could be done within the society premises to make the stay convinent. Full power backup is available. The society has dedicated security guards for every tower.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7017.694969143084!2d77.032992!3d28.42385855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d180ac945e753%3A0x256f70801af28ccd!2sCentral%20Park%20II%2C%20Sector%2048%2C%20Gurugram%2C%20Haryana%20122001!5e0!3m2!1sen!2sin!4v1698307202262!5m2!1sen!2sin",
  },
];
export default data;
