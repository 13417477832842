import React from 'react'
import dataMgroad from "../../Data/Mgroad";
import Layout from '../../Layout/Layout';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function MGRoadDeatils() {
    const { MGDetailsId } = useParams();
    const thisProduct =
    dataMgroad &&
    dataMgroad.find((prod) => prod.id === MGDetailsId);
    return (
        <>
            <Layout>
                <div className="main-box">
                    <div className="container">
                        <div className="card prop-main p-4">
                            <div className="gallery--main-box">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="PhotonCard--gallery left--gallery zoomin">
                                            <img
                                                className="PhotonCard--img1 zoomout"
                                                src={thisProduct.img}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4  mb-4">
                                        {thisProduct.steps &&
                                            thisProduct.steps.map((imgData) => (
                                                <div className="zoomin right--gallery">
                                                    <img
                                                        className="PhotonCard--img2 zoomout mb-3"
                                                        src={imgData.img || "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png"}
                                                        alt=""
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div className="main--boxprop mt-3">
                                <img src={thisProduct.logo} alt="" />
                                <div className="leftpropbox">
                                    <h4>{thisProduct.name}</h4>
                                    <span>{thisProduct.location}</span>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <h2 class="titile--main">{thisProduct.name}</h2>
                            <div class="section-divider divider-traingle"></div>
                        </div>
                    </div>
                </div>
            </Layout>


        </>
    )
}

export default MGRoadDeatils