import img1 from "../../../assets/Project/Ansal/1.jpg";
import img2 from "../../../assets/Project/Ansal/2.jpg";



const AnsalData = [
  {
    id: "1",
    name: "Ansal Hub 83",
    location: "Sector 83, Gurgaon",
    img: img1,
    desc: "At Ansals Hub83, RETAIL takes an all-new dimension by emerging beyond just being a commercial activity, into a family’s day out destination. With a variety of top-notch brands under one roof, huge footfall is virtually assured. Immaculately planned OFFICES also available at Ansal Hub83 for meeting the needs of large corporate houses as well as professionals    ",
  },
  {
    id: "2",
    name: "Ansal Townwalk",
    location: "Sector 104, Gurgaon",
    img: img2,
    desc: "Ansal Town Walk is a 2.01 Acres Exclusive Commercial Project in Sector-104 Dwarka Expressway Gurgaon. Here, they are offering High-End Retail Shops and Premium Office Spaces. It is near to so many Big Residential Projects like Puri Emerald Bay, ATS Triumph and more.    ",
  }
];

export default AnsalData;
