import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Godrej/banner.jpg";
import GodrejData from "../../Data/ListData/Godrej";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaLocationArrow } from "react-icons/fa";
import { TabTitle } from "../../../functionTitle";

function GodrejListing() {
  TabTitle('Jeet Estate | Godrej Listing')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Godrej Properties is one of the top leading real estate developers
              in India. This firm was corporated in 1990 with the mission of
              providing world-class structures along with the best fixtures.
              Godrej Properties introduced 100+ residential and commercial
              projects and won various awards including; Real Estate Company Of
              The Year in 2015, Most Reliable Builder for 2014, & Innovation
              Leader in Real Estate in 2013. This company is known for its
              on-time deliveries, quality building, customer satisfaction, &
              innovative designs. It is ideal to offer you high-end homes in
              Gurgaon at a minimal price.
            </p>
            <div className="row">
              {GodrejData &&
                GodrejData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default GodrejListing;
