import img1 from "../../assets/Project/mgroad/Vipul-Agora-835x467.jpg"
import img2 from "../../assets/Project/mgroad/mgf-metropolis-835x467.jpg"
import img3 from "../../assets/Project/mgroad/abw-tower-835x467.jpg"



const dataMgroad = [
    {
        id: "1",
        name: "Vipul Agora",
        img: img1,
        location: " Sector 28, Gurgaon",
        link: "Vipul-Agora",
        desc: "Vipul Agora is a commercial project located in Sector 28, right on M.G Road, Gurgaon. This project is spread across a total area of 2,15,000 sq. ft and offers great retail units. Vipul Agora is ready to move into and is one of the landmarks in Gurgaon."
    
    },
    {
        id: "2",
        name: "MGF Metropolis",
        img: img2,
        location: "  Sector 17, Gurgaon",
        desc: "MGF Metropolis Mall has been located at MG Road, Gurgaon and is extremely close to high affluent residential complexes. This commercial project has been given an extremely futuristic look with tons of external design elements decorating the exterior."
    },
    {
        id: "3",
        name: "ABW Tower",
        img: img3,
        location: " Sector 25, Gurgaon",
        desc: "ABW Tower is a complete commercial project located in Sector-25, Gurgaon offering international class corporate and commercial spaces. Spread over an area of 1 Lakh square feet, the project offers state-of-the-art facilities for all its occupants."
    },
]

export default dataMgroad;