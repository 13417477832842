import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Silverglades/730672963banner.jpg";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";
import SilvergladesData from "../../Data/ListData/Silverglades";

function SilvergladesListing() {
  TabTitle("Jeet Estate | Silverglades Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Silverglades Hightown Residences spreads across 3 lofty towers
              nestled amidst a mixed-use development. The ultimate living
              experience unfolds in the tallest towers of the area, with the
              finest offerings and an ease of life, unlike ever before. Claiming
              the throne at the centre of the city, Silverglades Hightown spans
              5 acres and sits opposite The Laburnum* in Sushant Lok 1,
              Gurugram. Thoughtfully curated to cater to every imaginable want
              and desire, Hightown Residences offers a limited collection of 260
              luxury homes in 3 & 4 BHK configurations.
            </p>
            <div className="row">
              {SilvergladesData &&
                SilvergladesData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default SilvergladesListing;
