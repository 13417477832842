import img1 from "../../../assets/Project/AIPL/1.jpg";
import img2 from "../../../assets/Project/AIPL/2.jpg";


const AIPLData = [
  {
    id: "1",
    name: "AIPL Autograph",
    location: "Sector 66, Gurgaon",
    img: img1,
    desc: "AIPL Autograph is a Business Centre & leading coworking space in the heart of Gurgaon. It is a high-end skyscraper that creates countless opportunities for professionals and businesses. The project is a prime offering of the AIPL group, creatively designed by world-class architecture firm Benoy, United Kingdom. In addition, this premise offers amenities that are just incredible and nothing less than a boon for businesses of Millenium City.    ",
  },
  {
    id: "2",
    name: "AIPL Business Club",
    location: "Sector 62, Gurgaon",
    img: img2,
    desc: "AIPL (Advance India Projects Ltd.) presenting the Best Ever Commercial Project- AIPL Business Club in Golf Course Extension Road where you can Uplift your Business with its Fast-Growing Corporate Community. This Amazing Commercial Project is on the Sector-62 Gurgaon.    ",
  }
];

export default AIPLData;
