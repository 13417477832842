import img1 from "../../assets/Logo/logo01.jpg"
import img2 from "../../assets/Logo/logo02.jpg"
import img3 from "../../assets/Logo/logo03.jpg"
import img4 from "../../assets/Logo/logo04.jpg"
import img5 from "../../assets/Logo/logo05.jpg"
import img6 from "../../assets/Logo/logo06.jpg"
import img7 from "../../assets/Logo/logo07.jpg"
import img8 from "../../assets/Logo/logo08.jpg"
import img9 from "../../assets/Logo/logo09.jpg"
import img10 from "../../assets/Logo/logo10.jpg"
import img11 from "../../assets/Logo/logo11.jpg"
import img12 from "../../assets/Logo/logo12.jpg"
import img13 from "../../assets/Logo/logo13.jpg"
import img14 from "../../assets/Logo/logo14.jpg"
import img15 from "../../assets/Logo/logo15.jpg"
import img16 from "../../assets/Logo/logo16.jpg"
import img17 from "../../assets/Logo/logo17.jpg"
import img18 from "../../assets/Logo/logo18.jpg"
import img19 from "../../assets/Logo/logo19.jpg"
import img20 from "../../assets/Logo/logo20.jpg"
import img21 from "../../assets/Logo/logo21.jpg"
import img22 from "../../assets/Logo/logo22.jpg"

const Gallery = [
    {
     img: img1,
     link: "DLF"
    },
    {
     img: img2,
     link: "Ashiana"
    },
    {
     img: img3,
     link: "M3M"
    },
    {
     img: img4,
     link: "GodrejListing"
    },
    {
     img: img5,
     link: "IreoListing"
    },
    {
     img: img6,
     link: "TataListing"
    },
    {
     img: img7,
     link: "TulipListing"
    },
    {
     img: img8,
     link: "BirlaListing"
    },
    {
     img: img9,
     link: "PuriListing"
    },
    {
     img: img10,
     link: "SmartworldListing"
    },
    {
     img: img11,
     link: "EmaarListing"
    },
    {
     img: img12,
     link: "SobhaListing"
    },
    {
     img: img13,
     link: "SignatureGlobalListing"
    },
    {
     img: img14,
     link: "AbwCommercial"
    },
    {
     img: img15,
     link: "AIPLListings"
    },
    {
     img: img16,
     link: "AMBListing"
    },
    {
     img: img17,
     link: "CentralParkListing"
    },
    {
     img: img18,
     link: "AnsalListing"
    },
    {
     img: img19,
     link: "ElanListing"
    },
    {
     img: img20,
     link: "NimaiListing"
    },
    {
     img: img21,
     link: "SilvergladesListing"
    },
    {
     img: img22,
     link: "VatikaListing"
    },
]

export default Gallery; 
