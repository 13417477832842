import img1 from "../../assets/Gurgaon/golfestatescda.png"
import logo1 from "../../assets/Gurgaon/m3m.svg"
import img2 from "../../assets/Gurgaon/Gangarealtynandaka.jpg"
import logo2 from "../../assets/Gurgaon/ganga.svg"
import img3 from "../../assets/Gurgaon/urbanresort.jpg"
import logo3 from "../../assets/Gurgaon/whiteland.svg"
import img4 from "../../assets/Gurgaon/Navraj37d.jpg"
import logo4 from "../../assets/Gurgaon/navraj.svg"
import img5 from "../../assets/Gurgaon/deluxedxp.jpg"
import logo5 from "../../assets/Gurgaon/signature.svg"
import img6 from "../../assets/Gurgaon/crown.png"
import logo6 from "../../assets/Gurgaon/m3m.svg"
import img7 from "../../assets/Gurgaon/godrej89.jpg"
import logo7 from "../../assets/Gurgaon/godrej.svg"
import img8 from "../../assets/Gurgaon/Capital.png"
import logo8 from "../../assets/Gurgaon/m3m.svg"
import img9 from "../../assets/Gurgaon/smartonedxp.jpg"
import logo9 from "../../assets/Gurgaon/smart.svg"
import img10 from "../../assets/Gurgaon/dlfprivana.jpg"
import logo10 from "../../assets/Gurgaon/dlf.svg"
import img11 from "../../assets/Gurgaon/aspeniconic.jpg"
import logo11 from "../../assets/Gurgaon/whiteland.svg"
import img12 from "../../assets/Gurgaon/antalyahills.png"
import logo12 from "../../assets/Gurgaon/m3m.svg"
import img13 from "../../assets/Gurgaon/whitelandaspen.jpg"
import logo13 from "../../assets/Gurgaon/whiteland.svg"
import img14 from "../../assets/Gurgaon/sobhacity.jpg"
import logo14 from "../../assets/Gurgaon/sobha.svg"
import img15 from "../../assets/Gurgaon/golfestates65.png"
import logo15 from "../../assets/Gurgaon/m3m.svg"
import img16 from "../../assets/Gurgaon/trinity.jpg"
import logo16 from "../../assets/Gurgaon/trinity.svg"
import img17 from "../../assets/Gurgaon/m3mgolfhills.png"
import logo17 from "../../assets/Gurgaon/m3m.svg"
import img18 from "../../assets/Gurgaon/elanpresidential.jpg"
import logo18 from "../../assets/Gurgaon/elan.svg"
import img19 from "../../assets/Gurgaon/emaardigihome.jpg"
import logo19 from "../../assets/Gurgaon/emaar.svg"
import img20 from "../../assets/Gurgaon/blissville.jpg"
import logo20 from "../../assets/Gurgaon/whiteland.svg"
import img21 from "../../assets/Gurgaon/adani-new.jpg"
import logo21 from "../../assets/Gurgaon/adani.svg"
import img22 from "../../assets/Gurgaon/vatika-seven.jpeg"
import logo22 from "../../assets/Gurgaon/vatika-seven.jpeg"
import img23 from "../../assets/Gurgaon/unitech.jpeg"
import logo23 from "../../assets/Gurgaon/unitech.svg"
import img24 from "../../assets/Gurgaon/mahindra.jpeg"
import logo24 from "../../assets/Gurgaon/mahindra.svg"
import img25 from "../../assets/Gurgaon/BPTP-Luxe-Villas.jpeg"
import logo25 from "../../assets/Gurgaon/bptp.svg"
import img26 from "../../assets/Gurgaon/anmol-elevation.jpeg"
import logo26 from "../../assets/Gurgaon/ashiana-amarah.svg"
import img27 from "../../assets/Gurgaon/primanti.jpeg"
import logo27 from "../../assets/Gurgaon/tata.svg"
import img28 from "../../assets/Gurgaon/bestech.jpeg"
import logo28 from "../../assets/Gurgaon/bestech.svg"
import img29 from "../../assets/Gurgaon/centralpark.png"
import logo29 from "../../assets/Gurgaon/centralpark.svg"
import img30 from "../../assets/Gurgaon/tulip-ivory.jpeg"
import logo30 from "../../assets/Gurgaon/tulip.svg"
import img31 from "../../assets/Gurgaon/suncity.jpeg"
import logo31 from "../../assets/Gurgaon/suncity.svg"
import img32 from "../../assets/Gurgaon/joyville-phase-1.jpeg"
import logo32 from "../../assets/Gurgaon/shapoorji-pallonji.svg"
import img33 from "../../assets/Gurgaon/ss.jpeg"
import logo33 from "../../assets/Gurgaon/ss.svg"
import img34 from "../../assets/Gurgaon/alban-elevation.jpeg"
import logo34 from "../../assets/Gurgaon/pyramid.svg"
import img35 from "../../assets/Gurgaon/belmonte.jpeg"
import logo35 from "../../assets/Gurgaon/vipul.svg"
import img36 from "../../assets/Gurgaon/ansal.jpeg"
import logo36 from "../../assets/Gurgaon/ansal.svg"




const GurgaonData = [
    {
        id: "1",
        name: "M3M Mansion SCDA",
        location: "Sec-113, Dwarka Expressway Gurgaon",
        size: "3.5 & 4.5 BHK Apartments ",
        price: "3.99 CR*",
        img: img1,
        logo: logo1,
    },
    {
        id: "2",
        name: "Ganga Realty Nandaka 84",
        location: "Sector 84, Dwarka Expressway, Gurgaon",
        size: "3.5 & 4.5 BHK Luxury Apartment",
        price: "3.81 Cr*",
        img: img2,
        logo: logo2,
    },
    {
        id: "3",
        name: " Whiteland Urban Resort ",
        location: "Sector 103, Dwarka Expressway, Gurgaon",
        size: "3.5 & 4.5 BHK Ultra Luxury Apartment",
        price: "3.50 Cr*",
        img: img3,
        logo: logo3,
    },
    {
        id: "4",
        name: "Navraj New Launch ",
        location: "Sector 37D, Dwarka Expressway, Gurgaon",
        size: "3 & 4 BHK Ultra Luxury Apartment  ",
        price: "3.10 Cr*",
        img: img4,
        logo: logo4,
    },
    {
        id: "5",
        name: "Signature Global Deluxe DXP",
        location: "Sector 37D, Dwarka Expressway, Gurgaon",
        size: "3, 3.5 & 4.5 BHK Ultra Luxury Apartment",
        price: "3.10 Cr*",
        img: img5,
        logo: logo5,
    },
    {
        id: "6",
        name: "M3M Crown",
        location: "Sec-111, Dwarka Expressway, Gurgaon",
        size: "2, 3, & 4 BHK Apartments",
        price: "2.50 Cr*",
        img: img6,
        logo: logo6,
    },
    {
        id: "7",
        name: "Godrej New Launch ",
        location: "Sector 89, New Gurgaon",
        size: "2, 3, & 4 BHK Apartment ",
        price: "2.60 Cr*",
        img: img7,
        logo: logo7,
    },
    {
        id: "8",
        name: "M3M Capital",
        location: "Sec-113, Dwarka Expressway, Gurgaon",
        size: "2, 3, & 4 BHK Apartments",
        price: "2.30 Cr*",
        img: img8,
        logo: logo8,
    },
    {
        id: "9",
        name: " Smart One Dxp ",
        location: " Sector 113, Dwarka Expressway, Gurgaon ",
        size: " 3.5 & 4.5 BHK Ultra Luxury Apartment  ",
        price: "3.40 Cr*",
        img: img9,
        logo: logo9,
    },
    {
        id: "10",
        name: "  DLF Privana  ",
        location: "  Sector 77, National Highway 48, Gurgaon  ",
        size: "  4 & 4.5 BHK Ultra Luxury Apartment     ",
        price: "6 Cr*",
        img: img10,
        logo: logo10,
    },
    {
        id: "11",
        name: " Whiteland Aspen Iconic ",
        location: " Sector 76, SPR Road, Gurgaon ",
        size: " 4.5 & 5 BHK Ultra Luxury Apartment",
        price: "6 Cr*",
        img: img11,
        logo: logo11,
    },
    {
        id: "12",
        name: "M3M Antalya Hills",
        location: "Sec-79, Golf Course Ext Rd, Gurgaon",
        size: "2, 3 & 4 BHK Apartments",
        price: "1.25 Cr*",
        img: img12,
        logo: logo12,
    },
    {
        id: "13",
        name: " Whiteland The Aspen ",
        location: " Sector 76, SPR Road, Gurgaon ",
        size: " 2, 3 & 4 BHK Ultra Luxury Apartment  ",
        price: "3.50 Cr*",
        img: img13,
        logo: logo13,
    },
    {
        id: "14",
        name: " Sobha City Gurgaon ",
        location: " Sector 108, Near Dwarka Expressway, Gurgaon ",
        size: " 2.5, 3.5 & 4.5 BHK Ultra Luxury Apartment   ",
        price: "3 Cr*",
        img: img14,
        logo: logo14,
    },
    {
        id: "15",
        name: "M3M Golf Estate",
        location: "Sec-65, Golf Course Ext Rd, Gurgaon",
        size: "3, 4, & 5 BHK Apartments",
        price: "3.50 Cr*",
        img: img15,
        logo: logo15,
    },
    {
        id: "16",
        name: " Trinity Infratech ",
        location: " Sector 88B, Dwarka Expressway, Gurgaon ",
        size: " 3.5 & 4.5 BHK Ultra Luxury Apartment  ",
        price: "3.80 Cr*",
        img: img16,
        logo: logo16,
    },
    {
        id: "17",
        name: "M3M Golfhills",
        location: "Sec-79, Golf Course Ext Rd, Gurgaoon",
        size: "2.5, 3.5 & 4.5 BHK Apartments",
        price: "2.10 Cr*",
        img: img17,
        logo: logo17,
    },
    {
        id: "18",
        name: " Elan The Presidential ",
        location: " Sector 106, Dwarka Expressway, Gurgaon ",
        size: " 3 & 4 BHK Ultra Luxury Apartment   ",
        price: "4 Cr*",
        img: img18,
        logo: logo18,
    },
    {
        id: "19",
        name: " Emaar Urban Oasis ",
        location: "  Sector 62, Golf Course Ext Rd, Gurgaon  ",
        size: " 3 & 4 BHK Ultra Luxury Apartment   ",
        price: "4.14 Cr*",
        img: img19,
        logo: logo19,
    },
    {
        id: "20",
        name: "  Whiteland Blissville  ",
        location: "  Sector 76, SPR Road, Gurgaon ",
        size: "  2.5 & 3.5 BHK Ultra Luxury Apartment    ",
        price: "1.25 Cr*",
        img: img20,
        logo: logo20,
    },
    {
        id: "21",
        name: " Adani Samsara ",
        location: " Sector 63, Golf Course Ext. Road, Gurgaon ",
        size: "  3 & 4 BHK Luxury Apartment    ",
        price: "3.77 Cr*",
        img: img21,
        logo: logo21,
    },
    {
        id: "22",
        name: " Vatika Seven Elements ",
        location: "  Sector 89A, Gurgaon  ",
        size: "  2, 3 & 4 BHK Apartment    ",
        price: "1.55 Cr*",
        img: img22,
        logo: logo22,
    },
    {
        id: "23",
        name: " Unitech Gardens ",
        location: "   Sector 47, Gurgaon  ",
        size: "   3 & 4 BHK Apartment      ",
        price: "2.50 Cr*",
        img: img23,
        logo: logo23,
    },
    {
        id: "24",
        name: " Mahindra Luminare  ",
        location: "   Sector 59, Gurgaon  ",
        size: "   3  BHK Apartment      ",
        price: "5.10 Cr*",
        img: img24,
        logo: logo24,
    },
    {
        id: "25",
        name: " BPTP Luxe Villas ",
        location: " Sector 70A, Gurgaon ",
        size: "  3, 4 BHK ",
        price: "5.6 Cr*",
        img: img25,
        logo: logo25,
    },
    {
        id: "26",
        name: " Ashiana Anmo ",
        location: "  Sector 33 Sohna, Gurgaon ",
        size: " 2, 3 BHK  ",
        price: "1.0 Cr*",
        img: img26,
        logo: logo26,
    },
    {
        id: "27",
        name: "  TATA Primanti UberLuxe ",
        location: " Sector 72, Gurgaon ",
        size: " 3, 4, 5 BHK ",
        price: "1.75 Cr*",
        img: img27,
        logo: logo27,
    },
    {
        id: "28",
        name: " Bestech The Sky Suites ",
        location: "  Sector 81, Gurgaon ",
        size: " 4 BHK ",
        price: "3.85 Cr*",
        img: img28,
        logo: logo28,
    },
    {
        id: "29",
        name: " Central Park BellaVista ",
        location: " Sector 81, Gurgaon ",
        size: " 1 BHK & Service Apartments  ",
        price: "1.74 Cr*",
        img: img29,
        logo: logo29,
    },
    {
        id: "30",
        name: " Tulip Ivory ",
        location: " Sector 70, Gurgaon ",
        size: " 3, 4, 5 BHK ",
        price: "2.40 Cr*",
        img: img30,
        logo: logo30,
    },
    {
        id: "31",
        name: " Suncity Platinum Towers  ",
        location: " Sector 28, Gurgaon  ",
        size: " 2, 3, 4 BHK ",
        price: "5.40 Cr*",
        img: img31,
        logo: logo31,
    },
    {
        id: "32",
        name: "  Shapoorji Pallonji Joyville    ",
        location: "  Phase 1, Gurgaon   ",
        size: " 1, 2, 3, 4 BHK ",
        price: "1.37 Cr*",
        img: img32,
        logo: logo32,
    },
    {
        id: "33",
        name: "SS CENDANA",
        location: " Sector 83, New Gurugram ",
        size: " 3, 4 BHK ",
        price: "1.47 Cr*",
        img: img33,
        logo: logo33,
    },
    {
        id: "34",
        name: " Pyramid Alban ",
        location: " Sector 71, Gurgaon ",
        size: " 3, 4 BHK ",
        price: "3.63 Cr*",
        img: img34,
        logo: logo34,
    },
    {
        id: "35",
        name: " Vipul Belmonte ",
        location: " Sector 53, Gurgaon ",
        size: " 3 BHK & 4 BHK Apartment ",
        price: "1 Cr*",
        img: img35,
        logo: logo35,
    },
    {
        id: "36",
        name: " Ansal Lemon Grove ",
        location: " Sector 23, Gurgaon  ",
        size: "  3 BHK Apartment ",
        price: "1 Cr*",
        img: img36,
        logo: logo36,
    },
    
]

export default GurgaonData;