import img1 from "../../../assets/Project/Birla/1.jpg"



const BirlaData = [
    {
        id: "1",
        name: "Birla Navya",
        location: "Sector 63-A, Gurgaon",
        img: img1,
        desc: "Birla Estate has recently unveiled its newest residential development, Birla Navya, in the city of Gurgaon. This premium and contemporary housing complex is situated in a prime location that is Sector 63A Gurgaon and offers its residents easy access to the city’s best amenities and attractions. Birla Navya Gurgaon offers a wide range of spacious and well-designed homes, including 2 BHK, 3 BHK, 3 BHK+U, and 4 BHK+U units with sizes ranging from 1261 sq. ft. to 2549 sq. ft"
    },
    
]


export default BirlaData;