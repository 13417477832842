import img1 from "../../../assets/Project/Elan/1.jpg"
import img2 from "../../../assets/Project/Elan/2.jpg"
import img3 from "../../../assets/Project/Elan/3.jpg"



const ElanData = [
    {
        id: "1",
        name: "Elan The Presidential",
        location: "Sector 106, Gurgaon",
        img: img1,
        desc: "Elan The Presidential is a magnificent residential project located alongside Dwarka Expressway, Sector 106, Gurgaon. This newly launch 7-star project aims to offer best-in-class futuristic homes which are designed keeping in mind the requirements of its residents.        "
    },
    {
        id: "2",
        name: "Elan Mercado",
        location: "Sector 80, Gurgaon",
        img: img2,
        desc: "Elan Mercado Sector 80, Gurgaon is an exclusive commercial & residential complex by the Elan Group. It is in a prime location that offers you an impeccable business & office address along with a like-minded community in the city. Elan Mercado Gurgaon is situated in Sector 80 and offers excellent surroundings and easy access to major NCR highways. It is a modern all-in-one complex with retail and office space.        "
    },
    {
        id: "3",
        name: "Elan Paradise",
        location: "Sector 50, Gurgaon",
        img: img3,
        desc: "Elan Paradise is a premier investment opportunity, it is not only a bet but an assured option with maximum returns. It is one of the most awaited commercial spaces in Nirvana Country, offering exciting shops for your businesses & investment portfolio. Elan Paradise Sector 50 Gurgaon is among the desirable plazas of Gurgaon, hosting best-in-class & high-end shops for various businesses.        "
    },
    
]


export default ElanData;