import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Sobha/sobha4.jpg";
import SobhaData from "../../Data/ListData/Sobha";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function SobhaListing() {
  TabTitle("Jeet Estate | Sobha Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Sobha is one of the most reputed real estate developers in India.
              This company was incorporated in 1995 to transform the housing
              sector. Today, Sobha Limited is operating in 14 states and
              delivering high-end homes in 27 cities of India. This real estate
              firm also builds corporate houses for HCL, Wipro, Biocon, Dell,
              Wipro, & Infosys. The residential development of Sobha Limited in
              Gurgaon is Sobha City. It is a modern housing high-rise complex
              that is designed to provide ultra-luxury homes at an attractive
              price. There is also a wide range of residential complexes by
              Sobha Group that will be available in the market very soon.
            </p>
            <div className="row">
              {SobhaData &&
                SobhaData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.loc}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default SobhaListing;
