import React from "react";
import VideoBg from "../../assets/Gurgaon-Golf.mp4";
import Layout from "../Layout/Layout";
import "../../index.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import buyProp from "../../assets/property-buy.svg";
import selprop from "../../assets/property-sell.svg";
import rentprop from "../../assets/property-rent.svg";
import Lifestyles1 from "../../assets/Lifestyles1.jpg";
import Lifestyles2 from "../../assets/Lifestyles2.jpg";
import Lifestyles3 from "../../assets/Lifestyles3.jpg";
import Lifestyles4 from "../../assets/Lifestyles4.jpg";
import Lifestyles5 from "../../assets/Lifestyles5.jpg";
import Lifestyles6 from "../../assets/Lifestyles6.jpg";
import Amenities1 from "../../assets/amenities-1.jpg";
import Amenities2 from "../../assets/amenities-2.jpg";
import Amenities3 from "../../assets/amenities-3.jpg";
import Amenities4 from "../../assets/amenities-4.jpg";
import Amenities5 from "../../assets/amenities-5.jpg";
import Amenities6 from "../../assets/amenities-6.jpg";
import Amenities7 from "../../assets/amenities-7.jpg";
import Amenities8 from "../../assets/amenities-8.jpg";
import PropertiesData from "../Data/PropertiesData";
import galleryList from "../Data/galleryList";
import { TabTitle } from "../../functionTitle";
import { FaEnvelope, FaHome, FaPhone } from "react-icons/fa";
import Iframe from "react-iframe";
import emaarbnner1 from "../../assets/Project/EmaarHome/1.jpg";
import emaarbnner2 from "../../assets/Project/EmaarHome/2.jpg";
import emaarbnner3 from "../../assets/Project/EmaarHome/3.jpg";
import emaarbnner4 from "../../assets/Project/EmaarHome/4.jpg";
import emaarbnner5 from "../../assets/Project/EmaarHome/5.jpg";
import emaarbnnerel1 from "../../assets/Project/ProjectHome/main-banner.webp";
import emaarbnnerel from "../../assets/Project/ProjectHome/elevate_rendering-ariel-view-2_hres.gif";
import ParkView from "../../assets/Project/BestechParkViewSpa/banner-1.jpg";
import ParkView1 from "../../assets/Project/BestechParkViewGrandSpa/grand_spa_slide1.jpg";
import ParkView2 from "../../assets/Project/BestechParkViewSanskruti/sanskruti_slide1.jpg";
import ParkView3 from "../../assets/Project/BestechParkViewAltura/featlg.jpg";
import ParkView4 from "../../assets/Project/BestechParkViewAnanda/ananda-feature.jpg";
import Dlfp1 from "../../assets/ÐLFTheArbour/img23.jpg";
import Dlfp2 from "../../assets/DLFPriivana/Banner.jpg";
import Dlfp3 from "../../assets/DLFTheMagnolias/img01.webp";
import Dlfp4 from "../../assets/DLFThePrimus/img01.jpg";
import Dlfp5 from "../../assets/DLFTheUltima/img28.jpg";
import banner from "../../assets/banners.png";
import { AlarmSmokeIcon, Boxes, Coins, MapPin, Newspaper } from "lucide-react";
// import EmaarDigiHomesDate from "../Data/EmaarDigiHomes";
// import M3MPropertiesData from "../Data/M3MProperties";
import gurgaonBnner from "../../assets/Gurgaon/GOLF COURSE ROAD.webp"
import gurgaonBnner1 from "../../assets/Gurgaon/Golf Course Extension Road.jpg"
import gurgaonBnner2 from "../../assets/Gurgaon/Southern Peripheral Road.jpg"
import gurgaonBnner3 from "../../assets/Gurgaon/1693979402351.jpg"
import gurgaonBnner4 from "../../assets/Gurgaon/gurgaon.jpg"
import GurgaonData from "../Data/GurgaonData";

function Home() {
  TabTitle("Jeet Estate Consultant | Home");
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Layout>
        <div className="hero">
          <div className="mask">
            <video className="into-img" src={VideoBg} autoPlay loop muted />
            {/* <img  src={IntroImg} alt='Hero Image' /> */}
          </div>

          {/* <div className="content">
        <p>HI, I'M A FREELANCER.</p>
        <h1>React Developer</h1>
        <div>
          <Link to="/project" className="btn">
            Projects
          </Link>
          <Link to="/contact" className="btn btn-light">
            Contact
          </Link>
        </div>
      </div> */}
        </div>
        <section className="pb90 pb30-md pt120">
          <div className="container ">
            <div
              className="main-title aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="title">Explore Apartment Types</h2>
              <p className="paragraph">
                Get some Inspirations from 1800+ skills
              </p>
              <Carousel responsive={responsive} className="mt-5">
                <div className="item">
                  <Link to="#">
                    <div className="iconbox-style1">
                      <span className="icon la la-home"></span>
                      <div className="iconbox-content">
                        <h6 className="title">Houses</h6>
                        <p className="text mb-0">22 Properties</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="#">
                    <div className="iconbox-style1">
                      <span className="icon la la-building"></span>
                      <div className="iconbox-content">
                        <h6 className="title">Apartments</h6>
                        <p className="text mb-0">22 Properties</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="#">
                    <div className="iconbox-style1">
                      <span className="icon la la-city"></span>
                      <div className="iconbox-content">
                        <h6 className="title">Office</h6>
                        <p className="text mb-0">22 Properties</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="#">
                    <div className="iconbox-style1">
                      <span className="icon la la-store-alt"></span>
                      <div className="iconbox-content">
                        <h6 className="title">Villa</h6>
                        <p className="text mb-0">22 Properties</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="#">
                    <div className="iconbox-style1">
                      <span className="icon la la-house-damage"></span>
                      <div className="iconbox-content">
                        <h6 className="title">Townhome</h6>
                        <p className="text mb-0">22 Properties</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="#">
                    <div className="iconbox-style1">
                      <span className="icon la la-synagogue"></span>
                      <div className="iconbox-content">
                        <h6 className="title">Bungalow</h6>
                        <p className="text mb-0">22 Properties</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="#">
                    <div className="iconbox-style1">
                      <span className="icon la la-city"></span>
                      <div className="iconbox-content">
                        <h6 className="title">Loft</h6>
                        <p className="text mb-0">22 Properties</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </Carousel>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md ">
          <div className="container ">
            <div
              className="main-title aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="title">Emaar Properties in Gurgaon</h2>
              <p className="paragraph">
                Get some Inspirations from 1800+ skills
              </p>
              <div className="propertys-box">
                <div className="row">
                  <>
                    <div className="col-lg-6 mb-3">
                      <button
                        role="link"
                        onClick={() =>
                          openInNewTab(
                            "https://emaardigihomes.jeetestate.com/"
                          )
                        }
                        style={{
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        <div className="gallery-boxsemmar col-r">
                          <img src={emaarbnner1} alt="" />
                          <div className="elwidgetbox">
                            <h4 className="text-white">Emaar Digi Homes</h4>
                            Sector – 62{" "}
                          </div>
                        </div>
                      </button>
                    </div>
                  </>

                  <div className="col-lg-6 ">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <Link to="/EmaarBusinessDistrict">
                          <div className="gallery-boxsemmar h-90">
                            <img src={emaarbnner2} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">EBD – 75A</h4>
                              Sector – 75, Gurgaon
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <Link to="/EmaarBusinessDistrict99">
                          <div className="gallery-boxsemmar h-90">
                            <img src={emaarbnner3} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">EBD – 99</h4>
                              Sector – 99, Gurgaon
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-6 mb-3">
                        <Link to="/EmaarBusinessDistrict89">
                          <div className="gallery-boxsemmar h-90">
                            <img src={emaarbnner4} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">EBD – 89</h4>
                              Sector – 89, Gurgaon
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <button
                          role="link"
                          onClick={() =>
                            openInNewTab(
                              "https://ebd114.jeetestate.com/"
                            )
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            width: "100%",
                          }}
                        >
                          <div className="gallery-boxsemmar h-90">
                            <img src={emaarbnner5} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">EBD – 114</h4>
                              Sector – 114, Gurgaon
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md ">
          <div className="container ">
            <div
              className="main-title aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="title">Elevate Properties in Gurgaon</h2>
              <p className="paragraph">
                Get some Inspirations from 1800+ skills
              </p>
              <div className="propertys-box">
                <div className="row">
                  <>
                    <div className="col-lg-6 mb-3">
                      <button
                        role="link"
                        onClick={() =>
                          openInNewTab(
                            "https://elevate59.jeetestate.com/"
                          )
                        }
                        style={{
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        <div className="gallery-boxsemmar col-r">
                          <img src={emaarbnnerel} alt="" />
                          <div className="elwidgetbox">
                            <h4 className="text-white">Elevate</h4>
                            Sector 59, Gurgaon
                          </div>
                        </div>
                      </button>
                    </div>
                  </>

                  <div className="col-lg-6 ">
                    <button
                      role="link"
                      onClick={() =>
                        openInNewTab(
                          "https://elevatereserve.jeetestate.com/"
                        )
                      }
                      style={{
                        background: "transparent",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      <div className="gallery-boxsemmar col-r">
                        <img src={emaarbnnerel1} alt="" />
                        <div className="elwidgetbox">
                          <h4 className="text-white">
                            Conscient Elevate Reserve
                          </h4>
                          Sector 62, Gurgaon
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md ">
          <div className="container ">
            <div
              className="main-title aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="title">Bestech Group Properties in Gurgaon</h2>
              <p className="paragraph">
                Get some Inspirations from 1800+ skills
              </p>
              <div className="propertys-box">
                <div className="row">
                  <>
                    <div className="col-lg-6 mb-3">
                      <button
                        role="link"
                        onClick={() =>
                          openInNewTab(
                            "https://bestechparkviewspa.jeetestate.com/"
                          )
                        }
                        style={{
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        <div className="gallery-boxsemmar col-r ">
                          <img src={ParkView} alt="" />
                          <div className="elwidgetbox">
                            <h4 className="text-white">
                              Bestech Park View Spa
                            </h4>
                            Sector – 47{" "}
                          </div>
                        </div>
                      </button>
                    </div>
                  </>

                  <div className="col-lg-6 ">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <button
                          role="link"
                          onClick={() =>
                            openInNewTab(
                              "https://BestechParkViewGrandSpa.jeetestate.com/"
                            )
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            width: "100%",
                          }}
                        >
                          <div className="gallery-boxsemmar h-90">
                            <img src={ParkView1} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">
                                B-Park View Grand Spa
                              </h4>
                              Sector – 75, Gurgaon
                            </div>
                          </div>
                        </button>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <button
                          role="link"
                          onClick={() =>
                            openInNewTab(
                              "https://BestechParkViewSanskruti.jeetestate.com/"
                            )
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            width: "100%",
                          }}
                        >
                          <div className="gallery-boxsemmar h-90">
                            <img src={ParkView2} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">
                                B-Park View Sanskruti
                              </h4>
                              Sector – 99, Gurgaon
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-6 mb-3">
                        <button
                          role="link"
                          onClick={() =>
                            openInNewTab(
                              "https://BestechParkViewAltura.jeetestate.com/"
                            )
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            width: "100%",
                          }}
                        >
                          <div className="gallery-boxsemmar h-90">
                            <img src={ParkView3} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">B-Park View Altura</h4>
                              Sector – 89, Gurgaon
                            </div>
                          </div>
                        </button>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <button
                          role="link"
                          onClick={() =>
                            openInNewTab(
                              "https://BestechParkViewAnanda.jeetestate.com/"
                            )
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            width: "100%",
                          }}
                        >
                          <div className="gallery-boxsemmar h-90">
                            <img src={ParkView4} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">B-Park View Ananda</h4>
                              Sector – 114, Gurgaon
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md ">
          <div className="container ">
            <div
              className="main-title aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="title">DLF Group Properties in Gurgaon</h2>
              <p className="paragraph">
                Get some Inspirations from 1800+ skills
              </p>
              <div className="propertys-box">
                <div className="row">
                  <>
                    <div className="col-lg-6 mb-3">
                      <button
                        role="link"
                        onClick={() =>
                          openInNewTab(
                            "https://dlfthearbour.jeetestate.com/"
                          )
                        }
                        style={{
                          background: "transparent",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        <div className="gallery-boxsemmar col-r">
                          <img src={Dlfp1} alt="" />
                          <div className="elwidgetbox">
                            <h4 className="text-white">ÐLF The Arbour</h4>
                            Sector – 63{" "}
                          </div>
                        </div>
                      </button>
                    </div>
                  </>

                  <div className="col-lg-6 ">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <button
                          role="link"
                          onClick={() =>
                            openInNewTab(
                              "https://dlfprivana.jeetestate.com/"
                            )
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            width: "100%",
                          }}
                        >
                          <div className="gallery-boxsemmar h-90">
                            <img src={Dlfp2} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">DLF Privana</h4>
                              Sector – 77, Gurgaon
                            </div>
                          </div>
                        </button>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <button
                          role="link"
                          onClick={() =>
                            openInNewTab(
                              "https://dlfthemagnolias.jeetestate.com/"
                            )
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            width: "100%",
                          }}
                        >
                          <div className="gallery-boxsemmar h-90">
                            <img src={Dlfp3} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">DLF The Magnolias</h4>
                              Sector – 42, Gurgaon
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-6 mb-3">
                        <button
                          role="link"
                          onClick={() =>
                            openInNewTab(
                              "https://dlftheprimus.jeetestate.com/"
                            )
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            width: "100%",
                          }}
                        >
                          <div className="gallery-boxsemmar h-90">
                            <img src={Dlfp4} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">DLF The Primus</h4>
                              Sector – 77, Gurgaon
                            </div>
                          </div>
                        </button>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <button
                          role="link"
                          onClick={() =>
                            openInNewTab(
                              "https://dlftheultima.jeetestate.com/"
                            )
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            width: "100%",
                          }}
                        >
                          <div className="gallery-boxsemmar h-90">
                            <img src={Dlfp5} alt="" />
                            <div className="elwidgetbox">
                              <h4 className="text-white">DLF The Ultima</h4>
                              Sector – 81, Gurgaon
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md ">
          <div className="container ">
            <div className="main-title text-center">
              <h2 className="title">TOP LOCATIONS OF GURGAON</h2>
              <p className="paragraph">
                Aliquam lacinia diam quis lacus euismod
              </p>
            </div>
            <div className="row mt-5">
              <div className="col-sm-4 col-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200" >
                <div className="gugaon-box">
                  <img src={gurgaonBnner} alt="" />
                  <div className="gurgaon-info">
                    <div className="gurgaonTitle">
                    GOLF COURSE ROAD
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200" >
                <div className="gugaon-box">
                  <img src={gurgaonBnner1} alt="" />
                  <div className="gurgaon-info">
                    <div className="gurgaonTitle">
                    GOLF COURSE EXTENSION ROAD 
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200" >
                <div className="gugaon-box">
                  <img src={gurgaonBnner2} alt="" />
                  <div className="gurgaon-info">
                    <div className="gurgaonTitle">
                    SOUTHERN PERIPHERAL ROAD
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200" >
                <div className="gugaon-box">
                  <img src={gurgaonBnner3} alt="" />
                  <div className="gurgaon-info">
                    <div className="gurgaonTitle">
                    DWARKA EXPRESSWAY
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200" >
                <div className="gugaon-box">
                  <img src={gurgaonBnner4} alt="" />
                  <div className="gurgaon-info">
                    <div className="gurgaonTitle">
                    NEW GURGAON
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md ">
          <div className="container ">
            <div className="main-title text-center">
              <h2 className="title">See How Realton Can Help</h2>
              <p className="paragraph">
                Aliquam lacinia diam quis lacus euismod
              </p>
            </div>
            <div className="row mt-5">
              <div
                className="col-sm-6 col-lg-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="iconbox-style2 text-center">
                  <div className="icon">
                    <img
                      alt="icon"
                      loading="lazy"
                      width="150"
                      height="150"
                      decoding="async"
                      data-nimg="1"
                      src={buyProp}
                    />
                  </div>
                  <div className="iconbox-content">
                    <h4 className="title">Buy a property</h4>
                    <p className="text">
                      Nullam sollicitudin blandit eros eu pretium. Nullam
                      maximus ultricies auctor.
                    </p>
                    <Link className="ud-btn btn-white2" to="">
                      Find a home<i className="fal fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-6 col-lg-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="iconbox-style2 text-center">
                  <div className="icon">
                    <img
                      alt="icon"
                      loading="lazy"
                      width="150"
                      height="150"
                      decoding="async"
                      data-nimg="1"
                      src={selprop}
                    />
                  </div>
                  <div className="iconbox-content">
                    <h4 className="title">Sell a property</h4>
                    <p className="text">
                      Nullam sollicitudin blandit eros eu pretium. Nullam
                      maximus ultricies auctor.
                    </p>
                    <Link className="ud-btn btn-white2" to="">
                      Place an ad<i className="fal fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-6 col-lg-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="iconbox-style2 text-center">
                  <div className="icon">
                    <img
                      alt="icon"
                      loading="lazy"
                      width="150"
                      height="150"
                      decoding="async"
                      data-nimg="1"
                      src={rentprop}
                    />
                  </div>
                  <div className="iconbox-content">
                    <h4 className="title">Rent a property</h4>
                    <p className="text">
                      Nullam sollicitudin blandit eros eu pretium. Nullam
                      maximus ultricies auctor.
                    </p>
                    <Link className="ud-btn btn-white2" to="">
                      Find a rental<i className="fal fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md ">
          <div className="container ">
            <div className="main-title text-center">
              <h2 className="title">Explore Lifestyles</h2>
              <p className="paragraph">
                Aliquam lacinia diam quis lacus euismod
              </p>
            </div>

            <div className="row mt-5">
              <div className="col-lg-6 col-sm-12">
                <div className="taxonomy-item-rectangle">
                  <div className="taxonomy-item houzez-lazy-bg">
                    <Link className="taxonomy-link hover-effect-flat" to="#">
                      <img src={Lifestyles1} alt="" />
                      <div className="taxonomy-text-wrap">
                        <div className="taxonomy-title">Apartment</div>
                        <div className="taxonomy-subtitle">17 Properties </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <div className="taxonomy-item-rectangle">
                      <div className="taxonomy-item houzez-lazy-bg">
                        <Link
                          className="taxonomy-link hover-effect-flat"
                          to="#"
                        >
                          <img src={Lifestyles2} alt="" />
                          <div className="taxonomy-text-wrap">
                            <div className="taxonomy-title">Office</div>
                            <div className="taxonomy-subtitle">
                              17 Properties{" "}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="taxonomy-item-rectangle">
                      <div className="taxonomy-item houzez-lazy-bg">
                        <Link
                          className="taxonomy-link hover-effect-flat"
                          to="#"
                        >
                          <img src={Lifestyles3} alt="" />
                          <div className="taxonomy-text-wrap">
                            <div className="taxonomy-title">Shop</div>
                            <div className="taxonomy-subtitle">
                              17 Properties{" "}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <div className="taxonomy-item-rectangle">
                      <div className="taxonomy-item houzez-lazy-bg">
                        <Link
                          className="taxonomy-link hover-effect-flat"
                          to="#"
                        >
                          <img src={Lifestyles4} alt="" />
                          <div className="taxonomy-text-wrap">
                            <div className="taxonomy-title">Single Family</div>
                            <div className="taxonomy-subtitle">
                              17 Properties{" "}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="taxonomy-item-rectangle">
                      <div className="taxonomy-item houzez-lazy-bg">
                        <Link
                          className="taxonomy-link hover-effect-flat"
                          to="#"
                        >
                          <img src={Lifestyles5} alt="" />
                          <div className="taxonomy-text-wrap">
                            <div className="taxonomy-title">Studio</div>
                            <div className="taxonomy-subtitle">
                              17 Properties{" "}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="taxonomy-item-rectangle">
                  <div className="taxonomy-item houzez-lazy-bg">
                    <Link className="taxonomy-link hover-effect-flat" to="#">
                      <img src={Lifestyles6} alt="" />
                      <div className="taxonomy-text-wrap">
                        <div className="taxonomy-title">Villa</div>
                        <div className="taxonomy-subtitle">17 Properties </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md hidetextmobile">
          <div className="container ">
            <div className="main-title text-center">
              <h2 className="title">Top Residential Areas in Gurgaon</h2>
              <p className="paragraph">
                Aliquam lacinia diam quis lacus euismod
              </p>
              <div className="row">
                <div className="col-lg-6">
                  <div className="services-pannel-box">
                    <div className="services-pennal-listBoxs">
                      <Boxes color="blueviolet" size={38} />
                      <p>Multiple Unit</p>
                    </div>
                    <div className="services-pennal-listBoxs">
                      <MapPin color="blueviolet" size={38} />

                      <p>Stategic Location</p>
                    </div>
                    <div className="services-pennal-listBoxs">
                      <AlarmSmokeIcon color="blueviolet" size={38} />
                      <p>Amenities</p>
                    </div>
                    <div className="services-pennal-listBoxs">
                      <Newspaper color="blueviolet" size={38} />
                      <p>Invastment Opportunity</p>
                    </div>
                    <div className="services-pennal-listBox">
                      <Coins color="blueviolet" size={38} />
                      <p>Rental Income</p>
                    </div>
                  </div>
                  <div
                    className="main-title text-center"
                    style={{ top: "250px", position: "relative" }}
                  >
                    <h2 className="title">Meet Our Property Expert</h2>
                    <p className="paragraph">
                      With over 15 years of Experience in both Commercial and
                      Residential properties in Gurgaon. Jeet Estate has been
                      closely involved with both clients and developers &
                      Specialization in Sales and Rentals over Gurgaon.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <img className="banneds" src={banner} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb90 pb30-md ">
          <div className="container ">
            <div className="main-title text-center">
              <h2 className="title">Amenities Feathers</h2>
              <p className="paragraph">
                Aliquam lacinia diam quis lacus euismod
              </p>
              <div className="row">
                <div className="col-lg-3 col-sm-12 mb-4">
                  <div className="Feathers-items prop-main">
                    <img src={Amenities1} width="100%" alt="" />
                    <h5>SPORTING FACILITIES</h5>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 mb-4">
                  <div className="Feathers-items prop-main">
                    <img src={Amenities2} width="100%" alt="" />
                    <h5>SPORTING FACILITIES</h5>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 mb-4">
                  <div className="Feathers-items prop-main">
                    <img src={Amenities3} width="100%" alt="" />
                    <h5>SPORTING FACILITIES</h5>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 mb-4">
                  <div className="Feathers-items prop-main">
                    <img src={Amenities4} width="100%" alt="" />
                    <h5>SPORTING FACILITIES</h5>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 mb-4">
                  <div className="Feathers-items prop-main">
                    <img src={Amenities5} width="100%" alt="" />
                    <h5>SPORTING FACILITIES</h5>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 mb-4">
                  <div className="Feathers-items prop-main">
                    <img src={Amenities6} width="100%" alt="" />
                    <h5>SPORTING FACILITIES</h5>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 mb-4">
                  <div className="Feathers-items prop-main">
                    <img src={Amenities7} width="100%" alt="" />
                    <h5>SPORTING FACILITIES</h5>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 mb-4">
                  <div className="Feathers-items prop-main">
                    <img src={Amenities8} width="100%" alt="" />
                    <h5>SPORTING FACILITIES</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md ">
          <div className="container ">
            <div className="main-title text-center">
              <h2 className="title">Our Brands Patner</h2>
              <p className="paragraph">We exclusive deal in blow project</p>
            </div>

            <div className="row mt-4">
              {galleryList &&
                galleryList.map((itemxxxxx) => {
                  return (
                    <div className="col-lg-4 col-sm-12 mb-3">
                      <div className="card">
                        <Link to={`/OURBrandsListing/${itemxxxxx.id}`}>
                          <div className="brands-container">
                            <img src={itemxxxxx.img} alt="" />
                            <h4 className="brands--title">{itemxxxxx.name}</h4>
                            <h6>{itemxxxxx.location}</h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md ">
          <div className="container ">
            <div className="main-title text-center">
              <h2 className="title">Top Projects in Gurgaon</h2>
              <p className="paragraph">
                Aliquam lacinia diam quis lacus euismod
              </p>

              <Carousel responsive={responsive} className="mt-5">
               
                {
                  GurgaonData && GurgaonData.map((datas) => {
                    return (
                      <Link to="/gurgaon">
                        <div className="areas-gurgaon-items card prop-main m-2 p-3">
                          <div className="section-areas">
                            <img src={datas.img} alt="" />
                            <div className="area-box">
                              <h6>{datas.name}</h6>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                
              </Carousel>
            </div>
          </div>
        </section>
        <section className="pb90 pb30-md ">
          <div className="container ">
            <div className="main-title text-center">
              <h2 className="title">Meet Our Property Expert</h2>
              <p className="paragraph">
                With over 15 years of Experience in both Commercial and
                Residential properties in Gurgaon. Jeet Estate has been closely
                involved with both clients and developers & Specialization in
                Sales and Rentals over Gurgaon.
              </p>
            </div>
            <div className="contact-forms mt-4">
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <div className="card mycontct-formz ">
                    <div className="mycontct-form">
                      <h4 className="contct-title">How To Find Us</h4>
                      <div className="box-formcontact">
                        <div className="iccon-form">
                          <FaPhone />
                        </div>
                        <a href="tel:9810010295" className="mycontect-text">
                          +91-9810010295
                        </a>
                      </div>
                      <div className="box-formcontact">
                        <div className="iccon-form">
                          <FaEnvelope />
                        </div>
                        <a href="tel:9810010295" className="mycontect-text">
                          sales@jeetestate.com,care@jeetestate.com
                          <br></br>jeetestateconsultent@gmail.com
                        </a>
                      </div>
                      <div className="box-formcontact">
                        <div className="iccon-form">
                          <FaHome />
                        </div>
                        <div className="mycontect-text">
                          D-142,Ground Floor, Sushant Lok III Extension, Sector
                          57, Gurugram, Haryana 122003
                        </div>
                      </div>
                      <h4 className="contct-title mt-4">Opening Hours</h4>
                      <div className="d-flex justify-content-between me-5">
                        <div className="subha-text">Monday - Saturday:</div>
                        <div className="lnight-text">10:00 - 19:00</div>
                      </div>
                      <div className="d-flex justify-content-between me-5 mt-3">
                        <div className="subha-text">Tuesday:</div>
                        <div className="lnight-text text-danger">Closed</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 ">
                  <div className="card mycontct-formz inputfromtext">
                    <h4 className="text-contct">Get in touch</h4>
                    <form>
                      <div className="form-row row mt-2">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">Full Name</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group col-md-6">
                          <label for="inputPassword4">Phone</label>
                          <input type="Phone" className="form-control" />
                        </div>
                      </div>
                      <div className="form-group mt-2">
                        <label for="inputAddress">Email Id</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-group mt-2">
                        <label for="exampleFormControlTextarea1">
                          Enter Message
                        </label>
                        <textarea className="form-control" rows="3"></textarea>
                      </div>
                      <button type="submit" className="btn btn-primary mt-3">
                        LET US CONTACT YOU!
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-5"></div>
            </div>
          </div>
        </section>
        <Iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.1587812556836!2d77.07645827540436!3d28.414465393950145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2281352aae7b%3A0x9f4faae3c38418e9!2sJeet%20Estate%20Consultant!5e0!3m2!1sen!2sin!4v1702902379520!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
      </Layout>
    </>
  );
}

export default Home;
