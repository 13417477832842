import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Elan/banner.jpg";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";
import ElanData from "../../Data/ListData/Elan";

function ElanListing() {
  TabTitle("Jeet Estate | Elan Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Elan Group is one of the most reputed commercial real estate
              development firms. This brand is involved in high-speed
              development with INR 3,000 Cr worth of planned investments. It has
              a customer base of 3,500 people who believe and have faith in Elan
              Group. This is not only a development firm but a majestic brand
              that always delivers what is promised. Today, Elan is reshaping
              the trend of commercial complexes, it is a destination that
              provides world-class retail & workspace with state of art
              features. Recently Elan group acquired 40 acres of land and
              designed a renowned commercial centre in Gurgaon.
            </p>
            <div className="row">
              {ElanData &&
                ElanData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default ElanListing;
