import img1 from "../../assets/Project/DwarkaExpresswayDatalist/1.jpg"
import img2 from "../../assets/Project/DwarkaExpresswayDatalist/2.jpg"
import img3 from "../../assets/Project/DwarkaExpresswayDatalist/3.jpg"
import img4 from "../../assets/Project/DwarkaExpresswayDatalist/4.jpg"
import img5 from "../../assets/Project/DwarkaExpresswayDatalist/5.jpg"
import img6 from "../../assets/Project/DwarkaExpresswayDatalist/6.jpg"
import img7 from "../../assets/Project/DwarkaExpresswayDatalist/7.jpg"
import img8 from "../../assets/Project/DwarkaExpresswayDatalist/8.jpg"
import img9 from "../../assets/Project/DwarkaExpresswayDatalist/9.jpg"
import img10 from "../../assets/Project/DwarkaExpresswayDatalist/10.jpg"
import img11 from "../../assets/Project/DwarkaExpresswayDatalist/11.jpg"
import img12 from "../../assets/Project/DwarkaExpresswayDatalist/12.jpg"
import img13 from "../../assets/Project/DwarkaExpresswayDatalist/13.jpg"
import img14 from "../../assets/Project/DwarkaExpresswayDatalist/14.jpg"
import img15 from "../../assets/Project/DwarkaExpresswayDatalist/15.jpg"





const DwarkaExpresswayDatalist = [
    {
        id: "1",
        name: "ROF Galleria",
        img: img1,
        location: " Sector 67, Gurgaon",
        desc: "ROF Insignia Park I is the most luxurious residential Plots available in Gurgaon, Sector 93. This property offers luxury lands a varied range of plots with utility and amenities services. These plots are ideal homes with maximum space, here you can live an exclusive lifestyle at an unparalleled price range."
    },
    {
        id: "2",
        name: "AMB Selfie Street",
        img: img2,
        location: " Sector 90, Gurgaon",
        desc: "AMB Selfie Street is a turnkey commercial solution. It is the premier type of base for your unique & creative business in Gurgaon. This is an iconic complex that offers a prestigious address in the upcoming suburb of the city."
    },
    {
        id: "3",
        name: "Elan Mercado",
        img: img3,
        location: " Sector 80, Gurgaon",
        desc: "Elan Mercado Sector 80, Gurgaon is an exclusive commercial & residential complex by the Elan Group. It is in a prime location that offers you an impeccable business & office address along with a like-minded community in the city. Elan Mercado Gurgaon is situated in Sector 80 and offers excellent surroundings and easy access to major NCR highways. It is a modern all-in-one complex with retail and office space."
    },
    {
        id: "4",
        name: "Nimai place",
        img: img4,
        location: " Sector 114, Gurgaon",
        desc: "Nimai Place is an impressive commercial development in Dwarka Expressway, Gurgaon. It spans 3.5 Acres of land and has landscape space for trade and commerce. There is a wide range of Office Spaces, Studio Apartments, Commercial Shops, & Retail Outlets to showcase your business & lifestyle."
    },
    {
        id: "5",
        name: "Spaze Tristaar",
        img: img5,
        location: " Sector 92, Gurgaon",
        desc: "Spaze Tristaar is a retail & commercial epicenter with integrated open-air bazaars with a unique triangular shape, which ensures maximum visibility. It has been designed keeping in mind the comfort of the shoppers. The project would offer a Galleria shopping experience."
    },
    {
        id: "6",
        name: "AMB Selfie Square",
        img: img6,
        location: "  Sector 37D, Gurgaon",
        desc: "AMB Selfie Square is the High-End Commercial Project in Sector-37D Gurgaon offering Stunning Offices Spaces, Retail Shops, Food Courts, Multiplex and more with the Top Notch Amenities. It is spread over an area of 4 acres with a total of 460 Units."
    },
    {
        id: "7",
        name: "Ameya Sapphire Ninety",
        img: img7,
        location: " Sector 90, Gurgaon",
        desc: "Ameya, a Gurgaon based real estate group, brings to you its new venture, Ameya Sapphire Ninety, that is quite an enviable destination. Sapphire Ninety offers 260 units of 1BHK apartments that make you feel safe, secure and happy."
    },
    {
        id: "8",
        name: "Raheja Trinity",
        img: img8,
        location: " Sector 84, Gurgaon",
        desc: "As Raheja is known for its innovative designs and its quality assurance. In tune with this, Raheja Developers Limited has now added yet another project, with a novel approach, to the long list of its successful real estate endeavors."
    },
    {
        id: "9",
        name: "JMS Crosswalk",
        img: img9,
        location: " Sector 93, Gurgaon",
        desc: "JMS CrossWalk – A Lifestyle Destination 2,20,000 Sq.ft of High Street. Retail A structure grand and lavish, incorporated with premium Spanish architectural facets, JMS CrossWalk is the next big retail destination of sector -93, Gurgaon. At a distance of a few brisk steps from premium group housing project, this landmark will be the fulcrum of neighbourhood lifestyle activities."
    },
    {
        id: "10",
        name: "SS Group Omnia",
        img: img10,
        location: " Sector 86, Gurgaon",
        desc: "SS Group Omnia (“DLF Galleria Type” Low Maintenance Market) by SS Group in sector -86 Gurgaon consisting low maintenance shops / retail space ranging 180 sq.ft onwards. the new commercial project has two side (from 200 ft. and 80 ft. wide road) entry."
    },
    {
        id: "11",
        name: "Satya The Hive",
        img: img11,
        location: " Sector 102, Gurgaon",
        desc: "Satya The Hive is an Exclusive Commercial Retail Mix Project in Sector-102 Dwarka Expressway Gurgaon. It is spread over an area of 6 Acres. Here, Satya Group presenting Stunning Office Spaces, Retail Shops, Food Courts, Anchor Stores, Restaurants, Service Apartments, Multiplexes and lot more."
    },
    {
        id: "12",
        name: "Indiabulls One 09",
        img: img12,
        location: " Sector 109, Gurgaon",
        desc: "Indiabulls One 09 is Upcoming Commercial Project by Indiabulls Group. This Project is located in Sector-109 Dwarka Expressway Gurgaon. Here, Indiabulls presents Stunning Office Spaces, Retail Shops, Restaurants, Food Courts and lot more with the range of sizes starting from 400 to 10,000 Sq. Ft."
    },
    {
        id: "13",
        name: "Ansal Townwalk",
        img: img13,
        location: " Sector 104, Gurgaon",
        desc: "Ansal Town Walk is a 2.01 Acres Exclusive Commercial Project in Sector-104 Dwarka Expressway Gurgaon. Here, they are offering High-End Retail Shops and Premium Office Spaces. It is near to so many Big Residential Projects like Puri Emerald Bay, ATS Triumph and more."
    },
    {
        id: "14",
        name: "CHD Eway Towers",
        img: img14,
        location: " Sector 109, Gurgaon",
        desc: "CHD Eway Towers is an Exclusive Commercial Project offering the Premium Office Spaces, Retail Shops and Food Courts with the size range from 400 to 3000 Sq. Ft. This Commercial Project is in Sector-109 Dwarka Expressway Gurgaon. It is spread over an area of 2.02 Acres."
    },
    {
        id: "15",
        name: "Conscient One",
        img: img15,
        location: " Sector 109, Gurgaon",
        desc: "Setting a new benchmark in luxury shopping and eclectic living spaces, Conscient brings its latest offering – Conscient One. A sprawling 3-acre development, located right on Dwarka Expressway, Sector-109, Gurgaon."
    }
]

export default DwarkaExpresswayDatalist;