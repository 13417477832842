import React from "react";
import Layout from "../Layout/Layout";
import contactBanner from "../../assets/Map/1608779192banner-site-plan.jpg";
import MapsData from "../Data/Maps.js";
import { TabTitle } from "../../functionTitle.js";
// import { useParams } from "react-router-dom";

function Map() {
  TabTitle('Jeet Estate | Gurgaon Map Sector')
  return (
    <>
      <Layout>
        <div className="main-box p-0">
          <div className="banner-box">
            <img src={contactBanner} alt="" />
            <div className="banner-text">
              <h3>Map Gurgaon</h3>
            </div>
          </div>
          <div className="container">
            <div className="main-box-padd">
              <p className="heading-text">DLF City Maps</p>
              <div className="row">
                {MapsData.map((items) => {
                  return (
                    <>
                      <div className="col-lg-4 mb-4">
                        <div
                          className="card prop-main p-4 "
                          data-bs-toggle="modal"
                          data-bs-target={`#${items.Namemodal}`}
                        >
                          <img src={items.img} alt={items.Name} />
                          <h5 className="mt-3">{items.Name}</h5>
                        </div>
                      </div>
                      <div
                        class="modal fade"
                        id={items.Namemodal}
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                        <div class="modal-dialog  modal-dialog-centered modal-lg">
                          <div class="modal-content">
                            <div class="modal-body">
                            <img src={items.img} alt={items.Name} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Map;
