import React from "react";
import Layout from "../Layout/Layout";
import MapItems from "../../assets/Logo/Map-of-Gurgaon-Manesar-Urban-Complex-plan.jpg";
import pdfdownload from "../../assets/Logo/gurugram-master-plan-2031.pdf";
import { FaDownload } from "react-icons/fa";
import { TabTitle } from "../../functionTitle";




function GurgaonMasterPlan() {
   TabTitle('Jeet Estate | Gurgaon Master Plan')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              Gurgaon{" "}
              <span style={{ color: "rgb(190, 144, 64)" }}>Master Plan</span>
            </h2>
            <p>
              The Gurgaon Master Plan 2031 is now out. Some of the
              SEZ-designated zone lands have been converted to 7 new
              residential/commercial sectors. Some of the mysterious changes in
              the 2031 plan are – an increase in the size of sector 37-D, and
              allotting the whole land piece in between GadoliKhurd& old
              sector-37 D to builder Ramprastha. However, the change of land use
              from SEZ to residential/commercial sectors will allow more
              residential space in the new sectors creating a high-density
              residential cluster in Gurgaon, which will make it the residential
              core of Gurgaon
            </p>
            <p>
              The changes from masterplan 2025 as seen on Masterplan 2031
              comprise of land on the northern side of Pataudi Road being
              converted into residential and commercial zones as well. New
              sectors — 95A, 95B, 89A, 89B, 88A, 88B, and 99A — have been
              proposed in this area covering villages like Wazirpur, Hayatpur,
              Mohammedpur, GarouliKhurd, GarouliKalan, and Harsaru.
              Interestingly, villagers from these areas claim that a lot of Real
              Estate developers have already purchased land from them.
              Similarly, Master Plan 2025 proposed a Transport Nagar spread
              across 28 hectares in Sector 33. This was to be developed by HUDA.
              A container depot had also been proposed adjoining the
              Delhi-Rewari railway line. A Mass Rapid Transit System Corridor
              (MRTSC) along the NPR was proposed. Another MRTSC was proposed
              along Mehrauli road to extend up to the proposed 90-meter link
              road with Delhi via Gwalpahari.
            </p>
            <p>
              But now the latest plan has approximately 50 hectares reserved for
              wholesale markets of building materials, grain and vegetable
              markets in Sector 99-A, on the northern side of the railway line
              along the newly proposed 75-meter-wide road. Also, a nearby area
              of 40 hectares has been reserved for idle parking where heavy
              vehicles can be parked. The changes in the master plans between
              2025 and 2031 look substantial only on paper as emphasis has been
              put on developing the infrastructure to keep pace with the
              explosive growth of the city. This is good as it would be a step
              towards meeting the demand for housing in the Delhi NCR. However,
              what needs to be seen is what would be the costs of the FSI and
              what would be the landed costs to the developers, and eventually
              the cost to the end user. What needs to be understood is that the
              demand still lies in housing for young people who can afford
              between 50 lakhs to 1 cr, based on what they can borrow and what
              they earn. The impact on prices would be required to be seen.
            </p>
            <p>
              The Gurgaon Master Plan 2031 is the most recent and comprehensive
              plan released by the Town and Country Planning Department of
              Haryana to boost the overall development of the city. The plan was
              first released on February 5, 2007, targeting a population of 3.7
              million people with a density of 80 persons per acre.{" "}
            </p>
            <p>
              However, due to increased urbanisation, the Gurgaon Master plan
              was revised for 2025, facilitating a greater population density of
              120 people per acre. The revised plan also allocated a large chunk
              of land for Special Economic Zones (SEZs), expecting a surge in
              demand at the time.
            </p>
            <p>
              Interestingly, circumstances changed, and demand for SEZs
              collapsed, resulting in a shift in the industry environment. As a
              result, the development plan for 2025 was revised further,
              culminating in the announcement of the Gurgaon Master Plan 2031.
              The current master plan is intended to revise the previously
              amended land use for SEZs.
            </p>
            <h4>
              Gurgaon Master Plan 2031:{" "}
              <span style={{ color: "rgb(190, 144, 64)" }}>
                Key Highlights{" "}
              </span>
            </h4>
            <p>
              Gurgaon has transformed into a thriving international metropolis.
              The city’s well-planned infrastructure, and excellent connectivity
              via airways, railways, and highways, have lured many global
              companies to establish their headquarters, software development
              centres, manufacturing facilities, and other enterprises in
              Gurgaon.{" "}
            </p>
            <p>
              The Gurgaon-Manesar Urban Complex boasts a prominent location
              along National Highway Number 8. It is well known for its car
              manufacturing companies, high-end shopping malls, massive cyber
              parks, and software development facilities. The complex enjoys
              good air connectivity with major international cities as it is
              only 4 km from the Indira Gandhi International Airport.
            </p>
            <h4>
              Gurgaon{" "}
              <span style={{ color: "rgb(190, 144, 64)" }}>
                Master Plan 2031 PDF{" "}
              </span>
            </h4>
            <p>
              The Gurgaon Master Plan 2031 pdf can be downloaded from the
              Department of Town and Country Planning, Haryana Government
              website. A copy of the same is as below.{" "}
            </p>
            <img src={MapItems} alt="" />
            <h4>
              Gurgaon Master Plan 2031:{" "}
              <span style={{ color: "rgb(190, 144, 64)" }}>
                Proposed Land Uses
              </span>
            </h4>
            <span>
              The following are the projected land uses in the Gurgaon Master
              Plan 2031:{" "}
            </span>
            <table class="table text-center">
              <thead>
                <tr>
                  <th scope="col">SL. No.</th>
                  <th scope="col">Land use</th>
                  <th scope="col">Area (in Hectares)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Residential</td>
                  <td>16021</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Commercial</td>
                  <td>1616</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Industrial</td>
                  <td>4613</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>Transport and Communication</td>
                  <td>4428</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>Public Utilities</td>
                  <td>608</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td>Public and semi public</td>
                  <td>2027</td>
                </tr>
                <tr>
                  <th scope="row">7</th>
                  <td>Open spaces</td>
                  <td>2928</td>
                </tr>
                <tr>
                  <th scope="row">Total</th>
                  <td>Total Land</td>
                  <td>32988</td>
                </tr>
              </tbody>
            </table>
            <a
              href={pdfdownload}
              style={{ width: "fit-content" }}
              class="btn btn-outline-primary" target="_blank" rel="noreferrer"
            >
              Gurgaon Master Plan 2031 PDF Download <FaDownload />{" "}
            </a>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default GurgaonMasterPlan;
