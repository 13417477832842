import React from "react";
import Layout from "../Layout/Layout";
import { useParams } from "react-router-dom";
import PropertiesData from "../Data/PropertiesData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Iframe from "react-iframe";
import { TabTitle } from "../../functionTitle";

function PropertiesDetails() {
  const { productId } = useParams();
  const thisProduct =
  PropertiesData && PropertiesData.find((prod) => prod.id === productId);
  TabTitle(`Jeet Estate | ${thisProduct.name}`)
  return (
    <>
      <Layout>
        <div className="main-box">
          <div className="container">
            <div className="card prop-main p-4">
              {/* <div className="title--box">
                <h3>{thisProduct.name}</h3>
                <span>{thisProduct.location}</span>
              </div> */}
              <div className="gallery--main-box">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="PhotonCard--gallery left--gallery zoomin">
                      <img
                        className="PhotonCard--img1 zoomout"
                        src={thisProduct.img}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-4  mb-4">
                    {thisProduct &&
                      thisProduct.steps.map((imgData) => (
                        <div className="zoomin right--gallery">
                          <img
                            className="PhotonCard--img2 zoomout mb-3"
                            src={imgData.img}
                            alt=""
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="main--boxprop mt-3">
                <img src={thisProduct.logoprop} alt="" />
                <div className="leftpropbox">
                  <h4>{thisProduct.name}</h4>
                  <span>{thisProduct.location}</span>
                </div>
              </div>
            </div>
            <section className="pb-3 pt-5">
              <div className="text-center">
                <h2 class="titile--main">{thisProduct.name}</h2>
                <div class="section-divider divider-traingle"></div>
              </div>
              <div className="card prop-main mt-5 p-4">
                <div className="row">
                  <div className="col-lg-6">
                    <img
                      className="prop-banner-img"
                      src={thisProduct.aboutbanner}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="leftpropbox mb-4">
                      <h2>{thisProduct.name}</h2>
                      <p>{thisProduct.location}</p> <br />{" "}
                      <b>
                        Size <span> {thisProduct.Sizes}</span>
                      </b>
                    </div>
                    <p>{thisProduct.desc}</p>

                    <button
                      type="button"
                      class="btn btn-primary mt-5"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Download Brochure
                    </button>

                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <div class="modal-dialog  modal-dialog-centered modal-lg">
                        <div class="modal-content">
                          <div class="modal-body">
                            <div class="left_col">
                              <div class="content">
                                <img
                                  src={thisProduct.Logo}
                                  alt="project logo"
                                  class="img-fluid"
                                />
                                <h4 className="text-center">
                                  {thisProduct.name}
                                </h4>
                                <h4 class="typo">{thisProduct.UnitType}</h4>
                                <h4 class="price">
                                  Starting Price: ₹ 1.99 Cr*
                                </h4>
                              </div>
                            </div>
                            <div class="right_col">
                              <div class="animated_form">
                                <div class="form-head">
                                  <h4 class="form_heading">
                                    Send us a message
                                  </h4>
                                </div>
                                <div class="form-group">
                                  <input
                                    class="form-input"
                                    name="name"
                                    type="text"
                                    placeholder="Your Name"
                                    required=""
                                    id="qSenderNamemodal"
                                  />
                                </div>
                                <div class="form-group">
                                  <input
                                    class="form-input email-address"
                                    type="email"
                                    name="email"
                                    placeholder="Your Email"
                                    required=""
                                    id="qEmailIDmodal"
                                  />
                                </div>
                                <div class="form-group">
                                  <input
                                    id="qMobileNomodal"
                                    class="form-input numberText number-only"
                                    placeholder="Mobile Number"
                                    type="number"
                                    name="Mobile No."
                                    required=""
                                  />
                                </div>

                                <div class="form-group">
                                  <input
                                    id="qMessagemodal"
                                    class="form-input"
                                    type="text"
                                    name="Comments.."
                                    placeholder="Comment"
                                    required=""
                                  />
                                </div>
                                <input
                                  type="submit"
                                  value="Request A Call"
                                  class="btn form-control"
                                  id="SubmitQuerymodal"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="pb-3 pt-5">
              <div className="text-center">
                <h2 class="titile--main">Floor Plans & Pricing</h2>
                <div class="section-divider divider-traingle"></div>
              </div>
              <div className=" mt-5 p-4">
                <div className="row">
                  {thisProduct.pricing.map((price) => (
                    <>
                      <div className="col-lg-4 mb-4">
                        <div className="card prop-main floorbox p-4">
                          <div class="list_header_bold text-bold text-success ">
                            ₹ {price.price}
                          </div>
                          <div className="prop-headertext">
                            <i class="la la-home"></i>
                            <div>
                              <div class="badges_large_secondary  FloorPlanCardV2__areaDisplay">
                                {price.Sizes}
                              </div>
                              <div class="caption_subdued_medium FloorPlanCardV2__areaType">
                                {price.unity}
                              </div>
                            </div>
                          </div>
                          <div class="_floorPlanCard FloorPlanCardV2__floorPlanImageWrapper mb-4">
                            <img
                              src={price.img}
                              style={{ height: "275px", objectFit: "contain" }}
                              alt=""
                            />
                          </div>
                          <Link
                            class="FloorPlanCardV2__fpReqCallBack pageComponent "
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <div class="buttonText_medium">
                              Request callback
                            </div>
                            <div class="trackGAClick">
                              <i class="las la-angle-double-right"></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className="col-lg-12 mb-4">
                    <div className="card prop-main Layout--floorbox p-4">
                      <img
                        src={thisProduct.Layout}
                        className="Layout--img"
                        alt=""
                        style={{
                          height: "570px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="pb-3 pt-5">
              <div className="text-center">
                <h2 class="titile--main">Amenities</h2>
                <div class="section-divider divider-traingle"></div>
                <div className="row mt-5">
                  {thisProduct.Amenities.map((Amen) => (
                    <div className="col-lg-3 mb-4">
                      <div className="amenities-boxs">
                        <img src={Amen.img} alt="" />
                        <h4>{Amen.name}</h4>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <section className="pb-3 pt-5">
              <div className="text-center">
                <h2 class="titile--main">Location Map</h2>
                <div class="section-divider divider-traingle"></div>
              </div>
              <div className="prop-main card p-4 mt-5">
                <div className="row mt-5 ">
                  <div className="col-lg-6">
                    <div className="location-box">
                      <p>{thisProduct.desc}</p>
                      <ul>
                        <li>
                          <span>1</span>M3m 65th Avenue Mall - 0.25 Km
                        </li>
                        <li>
                          <span>2</span>
                          DPS International School - 0.85 Km
                        </li>
                        <li>
                          <span>3</span>Golf Course Extension Road - 1.4 Km
                        </li>
                        <li>
                          <span>4</span>Park Hospital - 3.5 Km
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="location-box">
                      <img src={thisProduct.locmap} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="location-map">
                      <Iframe
                        src={thisProduct.map}
                        width="100%"
                        height="550"
                        style={{ borderRadius: "10px" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></Iframe>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="">
              <div className="text-center mt-5 mb-3">
                <h2 class="titile--main">Contact Us Form</h2>
                <div class="section-divider divider-traingle"></div>
              </div>
              <div class="container contact-form mt-5">
                <div class="content ">
                  <div class="left-side">
                    <div class="address details">
                      <i class="fas fa-map-marker-alt"></i>
                      <div class="topic">Address</div>
                      <div class="text-one">Surkhet, NP12</div>
                      <div class="text-two">Birendranagar 06</div>
                    </div>
                    <div class="phone details">
                      <i class="fas fa-phone-alt"></i>
                      <div class="topic">Phone</div>
                      <div class="text-one">+0098 9893 5647</div>
                      <div class="text-two">+0096 3434 5678</div>
                    </div>
                    <div class="email details">
                      <i class="fas fa-envelope"></i>
                      <div class="topic">Email</div>
                      <div class="text-one">codinglab@gmail.com</div>
                      <div class="text-two">info.codinglab@gmail.com</div>
                    </div>
                  </div>
                  <div class="right-side">
                    <div class="topic-text">Send us a message</div>
                    <p>
                      If you have any work from me or any types of quries
                      related to my tutorial, you can send me message from here.
                      It's my pleasure to help you.
                    </p>
                    <form action="#">
                      <div class="input-box">
                        <input type="text" placeholder="Enter your name" />
                      </div>
                      <div class="input-box">
                        <input type="text" placeholder="Enter your email" />
                      </div>
                      <div class="input-box">
                        <input
                          type="number"
                          maxLength={10}
                          placeholder="Enter your phone"
                        />
                      </div>
                      <div class="input-box message-box">
                        <textarea
                          className="from-control"
                          placeholder="Enter your message-box"
                        ></textarea>
                      </div>
                      <div class="button">
                        <input type="button" value="Send Now" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default PropertiesDetails;
