import React from 'react'
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Vatika/vatika-collection-website-promo-banner.jpg";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";
import VatikaData from "../../Data/ListData/Vatika";




function VatikaListing() {
  TabTitle("Jeet Estate |  Vatika Listing");
  return (
    <>
    <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className=" ">
           
            <div className="row">
              {VatikaData &&
                VatikaData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default VatikaListing