import img1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/1.jpg";
import img2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/2.jpg";
import img3 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/3.jpg";
// import img4 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/4.jpg";
import img5 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/5.jpg";
import img6 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/6.jpg";
import img7 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/7.jpg";
import img8 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/8.jpg";
import img9 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/9.jpg";
// import img10 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/10.jpg";
// import img11 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/11.jpg";
import img12 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/12.jpg";
// import img13 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/13.jpg";
// import img14 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/14.jpg";
// import img15 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/15.jpg";
// import img16 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/16.jpg";
import img17 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/17.jpg";
import img18 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/18.jpg";
import img19 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/19.jpg";
import img20 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/20.jpg";
import img21 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/21.jpg";
import img22 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/22.jpg";
// import img23 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/23.jpg";
// import img24 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/24.jpg";
// import img25 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/25.jpg";
// import img26 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/26.jpg";

// // Adani Samsara Vilasa
// import Adanilogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/26.jpg";
// import Adani1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/26.jpg";
// import Adani2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/26.jpg";

// Adani Samsara Vilasa
import Samsaralogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project1/y9xfhsey.jpg";
import Samsara11 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project1/1.jpg";
import Samsara12 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project1/2.jpg";
import Samsara1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project1/f1.png";
import Samsara2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project1/f2.png";
import Samsara3 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project1/f3.jpg";

// Ireo
import Ireologo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project19/logowefdcv.jpg"
import Ireo1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project19/1.jpg"
import Ireo2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project19/2.jpg"
import Archimg1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project19/f1.png"
import Archimg2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project19/f2.png"
import Archimg3 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project19/f3.png"

// Ireo
import Skyonlogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project20/logo_1.jpg"
import Skyon1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project20/1.jpg"
import Skyon2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project20/2.jpg"
import fimg1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project20/f1.jpg"
import fimg2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project20/f2.jpg"
import fimg3 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project20/f3.jpg"

// Ireo Victory Vallry
import Victorylogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project21/uxibr0n7.jpg"
import Victorya1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project21/1.jpg"
import Victorya2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project21/2.jpg"
import Victory1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project21/f1.png"
import Victory2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project21/f3.jpg"
import Victory3 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project21/f3.jpg"

// Birla
import Birlalogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project2/42yjv2s7.jpg"
import Birla1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project2/1.jpg"
import Birla2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project2/2.jpg"
import Birlaf1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project2/f1.jpg"
import Birlaf2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project2/f3.jpg"
import Birlaf3 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project2/f3.jpg"

// Puri The Aravallis
import Purilogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project3/6npm11br.jpg"
import Puri1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project3/1.jpg"
import Puri2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project3/2.jpg"
import Purif1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project3/f1.png"
import Purif2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project3/f2.png"


// Puri The Aravallis
import Anantlogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project4/vwzos9je.png"
import Anant1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project4/1.jpg"
import Anant2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project4/2.jpg"
import Anantf1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project4/f1.jpg"
import Anantf2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project4/f2.png"
import Anantf3 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project4/f3.png"

// Arbour 
import Arbourlogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project6/g7l9ot56.jpg"
import Arbourf1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project6/f1.png"


// Arbour 
import Orchadlogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project7/v1ybd9mw.png"
import Orchad1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project7/1.jpg"
import Orchad2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project7/2.jpg"
import Orchadf1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project7/f1.png"
import Orchadf2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project7/f2.png"

// Emaar 
import Emaarlogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project8/ryxffghf.jpg"
import Emaar1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project8/1.jpg"
import Emaar2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project8/2.jpg"
import Emaarf1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project8/f1.png"

// fimg: Vardhmanf1, 
import Vardhmanlogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project9/piumudoa.jpg"
import Vardhmanf1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project9/f1.jpg"
import Vardhmanf2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project9/f2.jpg"

// fimg: Vardhmanf1, 
import Signaturelogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project10/5eja7e98.jpg"
import Signature1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project10/1.jpg"
import Signature2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project10/2.jpg"
import Signaturef1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project10/f1.jpg"
import Signaturef2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project10/f2.jpg"

// fimg: Heightsf1,, 
import Heightslogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project17/3or9whtu.jpg"
import Heights1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project17/1.jpg"
import Heights2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project17/2.jpg"
import Heightsf1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project17/f1.png"
import Heightsf2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project17/f2.jpg"

// Merlin 
import Merlinlogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project18/8ve52rxv.jpg"
import Merlin1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project18/1.jpg"
import Merlin2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project18/2.jpg"
import Merlinf1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project18/f1.jpg"
import Merlinf2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project18/f2.jpg"

// Merlin 
import Pioneerlogo from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project22/logo.jpg"
import Pioneer1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project22/1.jpg"
import Pioneer2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project22/2.jpg"
import Pioneerf1 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project22/f1.png"
import Pioneerf2 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project22/f2.jpg"
import Pioneerf3 from "../../../assets/Project/ResidentialProjects/GolfcourseExtensionRoad/project22/f3.png"




const golfcourseextensionroadDataRes = [
  {
    id: "1",
    name: "Adani Samsara Vilasa",
    img: img1,
    location: " Sector 63, Gurgaon",
    link: "Adani-Tatva-Estates",
    zip: "122002",
    logo: Samsaralogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Samsara11,
      },
      {
        id: "2",
        img: Samsara12,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Adani Samsara Vilasa is an elegant residential complex in Gurgaon. Its unique architecture was built to provide a decent & perfect lifestyle on the outskirts of the city. This is a prestigious community, part of 75 Acre development & offers beautiful low-rise buildings with premium 3 & 4 BHK apartments with added privacy & security.",
      },
      {
        id: "2",
        pra: "Adani Samsara Vilasa is surrounded by lush vegetation and a lush green landscape. With world-class security, this is the perfect prerequisite for independent living in self-catering apartments. With all the colors of a coveted lifestyle, the Adani Brahma Samsara Vilasa is among Gurgaon’s first-class accommodations.",
      },
      {
        id: "3",
        pra: "Adani Samsara Vilasa’s location is excellent in the city, it is situated in Sector 63 on Golf Course Extension Road. In addition, it is in the vicinity of Sector 54 Rapid Metro Station & other regular transit facilities. This iconic residential complex is also close to Sohna Road and just a few kilometers from NH8. Sector 63, Adani Samsara Vilasa, and its surroundings are a housing community that hosts spaces for like-minded entrepreneurs, working professionals, and indigenous peoples of Gurgaon. Regarding heritage in the daily road network, the Adani Brahma Samsara Vilasa offers a wealth of amenities uniquely designed to give you an easy lifestyle.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "The price at Adani Samsara Vilasa is cheap compared to the surrounding area of Sector 63.",
      },
      {
        id: "2",
        name: "A spacious 3 BHK home on the second floor is available at only 2.30 Cr.",
      },
      {
        id: "3",
        name: "Buying an apartment in Adani Samsara Vilasa is a futuristic investment plan.",
      },
      {
        id: "4",
        name: "Extremely large apartment 4 BHK with all furniture only cost 3.55 Cr.",
      },
      {
        id: "5",
        name: "There is high-quality mobile furniture in Samsara Vilasa. Adani Samsara Vilasa offers a wide hall with anti-slip tiles.",
      },
      {
        id: "6",
        name: "There is an extra large basement in many buildings. Most of the buildings have large private and shared terraces.",
      },
      {
        id: "7",
        name: "Ample Parking Space Children’s play area  Security & staff quarter High-speed lift with 8 pax Supreme community hall",
      },
    ],
    table: [
      {
        id: "1",
        type: "3 BHK",
        Saleable_Area: "2080 Sq. Ft.",
        price: "On Request",
        fimg: Samsara1,
      },
      {
        id: "2",
        type: "3 BHK",
        Saleable_Area: "2850 Sq. Ft.",
        price: "On Request",
        fimg: Samsara2,
      },
      {
        id: "3",
        type: "4 BHK",
        Saleable_Area: "2750 Sq. Ft.",
        price: "On Request",
        fimg: Samsara3,
      },
    ],
    descD:
      "Adani Samsara Vilasa is an elegant residential complex in Gurgaon. Its unique architecture was built to provide a decent & perfect lifestyle on the outskirts of the city. This is a prestigious community, part of 75 Acre development & offers beautiful low-rise buildings with premium 3 & 4 BHK apartments with added privacy & security. Adani Samsara Vilasa is surrounded by lush vegetation and a lush green landscape. With world-class security, this is the perfect prerequisite for independent living in self-catering apartments. With all the colors of a coveted lifestyle, the Adani Brahma Samsara Vilasa is among Gurgaon’s first-class accommodations.",
      mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.8052455435222!2d77.0816176754552!3d28.3949496948301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d232087955273%3A0x33608e53196da48d!2sAdani%20Samsara%20Vilasa%20Floors!5e0!3m2!1sen!2sin!4v1702299297821!5m2!1sen!2sin",
    },
  {
    id: "2",
    name: "Birla Navya",
    img: img2,
    location: " Sector 63A, Gurgaon",
    link: "Adani-Tatva-Estates",
    zip: "122002",
    logo: Birlalogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Birla1,
      },
      {
        id: "2",
        img: Birla2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Birla Estate has recently unveiled its newest residential development, Birla Navya, in the city of Gurgaon. This premium and contemporary housing complex is situated in a prime location that is Sector 63A Gurgaon and offers its residents easy access to the city’s best amenities and attractions. Birla Navya Gurgaon offers a wide range of spacious and well-designed homes, including 2 BHK, 3 BHK, 3 BHK+U, and 4 BHK+U units with sizes ranging from 1261 sq. ft. to 2549 sq. ft.",
      },
      {
        id: "2",
        pra: "One of the standout features of Birla Navya is its adherence to IGBC precertification standards, which means that the project has been designed and built to meet the highest environmental and sustainability criteria. This is reflected in the wide range of facilities and amenities offered by Birla Navya sector 63A Gurgaon to its residents, which are not only designed for comfort and convenience, but also for a sustainable and eco-friendly lifestyle. These include a mini theatre, swimming pool, 24/7 security, power backup, sports facilities like table tennis, badminton, football, basketball, and squash court, an onsite cafeteria, and more.",
      },
      {
        id: "3",
        pra: "In addition to these recreational amenities, it also offers a range of facilities for residents who need to work from home. There is a business lounge, library, and work zone in this property, which provide residents with the space and resources they need to be productive and efficient while working remotely. And when it comes to leisure and relaxation, this property has you covered with its ample recreational options, including an amphitheater, meditation area, club house, landscape garden, and paved compound.",
      },
      {
        id: "4",
        pra: "Another key selling point of is its strategic location on Golf Course Extension Road, Sector 63A. The area is well-connected to both residential and commercial developments, making it easy for residents to access all the necessities and luxuries of city living. The Birla Navya project also offers flexible payment options and affordable prices, with homes starting from 3.62cr. The developer also offers a variety of flexible payout terms to make the possession process convenient for buyers.",
      },
      {
        id: "5",
        pra: "All in all, Birla Navya Sector 63A Gurgaon is a world-class residential development that offers an unparalleled lifestyle in the heart of Gurgaon. With its exceptional facilities, sustainable design, prime location, and flexible payment options, it’s no wonder that this development is quickly becoming one of the most sought-after residential projects in the city.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "3 BHK types of apartments (3+U) are available at 3.62 Cr. Apartments in this property are available at flexible prices.",
      },
      {
        id: "2",
        name: "4 BHK apartment with Utility Space & Basement is at just 4.52 Cr. Prices in Birla Navya Gurugram are not market-driven, but Value-Based.",
      },
      {
        id: "3",
        name: "Birla Navya sector 63A prices is more efficient with CLP, FLP, & TLP Payment plans. It Gurgaon has a Variety of Apartment layouts.",
      },
      {
        id: "4",
        name: "Adequate ceiling height. Apartments only have five levels in a building. provides Spacious 3 & 4 BHK apartments. Internally connected double-winder stairs.",
      },
      {
        id: "5",
        name: "Additionally, 3 & 4 BHK apartments with Utility Space are available. The apartment are designed in such a way that there is cross ventilation in Apartment. 3 BHK size ranges from 1261 to 1650 sq. ft..",
      },
      {
        id: "6",
        name: "It is hardly 2 km far from AIPL Business Club. Within proximity, there is PVR Cinema. In the vicinity, Yug Sports Management Complex.",
      },
      {
        id: "7",
        name: "Birla Navya Gurgaon is Gated Community. It has multi-tier security for your safety. Along with the landscape garden, there are fitness zone & well-being areas. There is a fine dining range along with a rooftop terrace. Birla Navya Gurugram has a ClubHouse in 3.5+ Acre.  It is an IGBC Gold-certified development in a prime area of Gurgaon.",
      },
    ],
    table: [
      {
        id: "1",
        type: "2 BHK",
        Saleable_Area: "1600 – 1654 sq. ft",
        price: "7.90 Cr* Onwards",
        fimg: Birlaf1,
      },
      {
        id: "2",
        type: "3 BHK",
        Saleable_Area: "2460 – 2557 sq. ft",
        price: "On Request",
        fimg: Birlaf2,
      },
      {
        id: "3",
        type: "4 BHK",
        Saleable_Area: "3451 – 3558 sq. ft",
        price: "On Request",
        fimg: Birlaf3,
      },
    ],
    descD: "Birla Estate has recently unveiled its newest residential development, Birla Navya, in the city of Gurgaon. This premium and contemporary housing complex is situated in a prime location that is Sector 63A Gurgaon and offers its residents easy access to the city’s best amenities and attractions. Birla Navya Gurgaon offers a wide range of spacious and well-designed homes, including 2 BHK, 3 BHK, 3 BHK+U, and 4 BHK+U units with sizes ranging from 1261 sq. ft. to 2549 sq. ft.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.862850627873!2d77.08426467545516!3d28.393210094908447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23f390e77e17%3A0xdbd91ab0710eebe!2sBirla%20Navya%20Sales%20Gallery!5e0!3m2!1sen!2sin!4v1702299506821!5m2!1sen!2sin",
  },
  {
    id: "3",
    name: "Puri The Aravallis",
    img: img3,
    location: " Sector 61, Gurgaon",
    link: "Puri-The-Aravallis",
    zip: "122002",
    logo: Purilogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Puri1,
      },
      {
        id: "2",
        img: Puri2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Puri The Aravallis is a residential project by Puri Builders on 10.5 Acre of land with Four glittering towers, each comprising Magnificent 42 floors. It is positioned in Sector 61, Gurgaon & its strategic location is on Golf Course Extension Road. Puri The Aravallis is in the proximity of Sector 54 Chowk Rapid Metro Station & only a few miles from Huda City Centre. In addition, it is an iconic luxury residence project, introduced in July 2022 & its expected possession is August 2026.",
      },
      {
        id: "2",
        pra: "However, in respect of apartments, Puri The Aravallis offers 3 & 4 BHK high-rise apartments in 2250 & 2750 sq. ft. Plus, the apartments at this property are houses with practical amenities & modern amenities along with amazing design. Puri The Aravallis is a premier housing solution that represents a true combination of Western architecture, Indian culture, and Round-the-clock security.",
      },
      {
        id: "3",
        pra: "Moreover, Puri The Aravallis is an ideal complex to experience a contemporary lifestyle, it is a gated community with the best daily necessities. For what you were longing for, you will get it at Puri The Aravallis. From uninterrupted water supply to fire suppression systems and the latest bathroom appliances to the floor-to-ceiling glass wrap-around balcony, apartments are fitted with all Class A appliances.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "Puri The Aravallis pricings are alluring. The price of 3 BHK in Puri The Aravallis starts at 3.40 Cr.",
      },
      {
        id: "2",
        name: "4 BHK Apartments in Puri The Aravallis from only 4.40 Cr. Loans are offered by banks which include; HDFC, IDBI Bank, PNB, SBI, and CitiBank .",
      },
      {
        id: "3",
        name: "Puri The Aravallis homes are ultra-premium & luxurious. Puri The Aravallis have a yoga area and a meditation area  Puri The Aravallis Gurgaon has 50,000 wide Club House.",
      },
      {
        id: "4",
        name: "Premises of Puri The Aravallis are equipped with Biometric Safe Access. Ample parking space with EV charging stations.",
      },
      {
        id: "5",
        name: "Puri The Aravallis is very close to National Highway 248A. Puri The Aravallis is only a few km from Southern Peripheral Road. It is located close to Business centres, IT Parks, & Commercial Zone. Puri The Aravallis is just 25 minutes from IGI Airport.",
      },
      {
        id: "6",
        name: "Udhyog Vihar, Cyber City, & MG Road is just 10 minutes from Puri The Aravallis.  Puri The Aravallis is in the vicinity of Sector 54 Chowk Rapid Metro Station.  Puri The Aravallis apartments ultra-luxury. Puri The Aravallis is a high-rise residential complex.",
      },
    ],
    table: [
      {
        id: "1",
        type: "3 BHK",
        Saleable_Area: "2250 sq. ft",
        price: "7.90 Cr* Onwards",
        fimg: Purif1,
      },
      {
        id: "2",
        type: "4 BHK",
        Saleable_Area: "2750 sq. ft",
        price: "On Request",
        fimg: Purif2,
      },
    ],
    descD: "Puri The Aravallis is a residential project by Puri Builders on 10.5 Acre of land with Four glittering towers, each comprising Magnificent 42 floors. It is positioned in Sector 61, Gurgaon & its strategic location is on Golf Course Extension Road. Puri The Aravallis is in the proximity of Sector 54 Chowk Rapid Metro Station & only a few miles from Huda City Centre. In addition, it is an iconic luxury residence project, introduced in July 2022 & its expected possession is August 2026.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28078.89197065801!2d77.06623998552627!3d28.393250994026864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23979939e80d%3A0xa8e9df449ac96a63!2sPURI%20The%20Aravalis!5e0!3m2!1sen!2sin!4v1702299611483!5m2!1sen!2sin",
  },
  {
    id: "5",
    name: "Anant Raj Floors Sector 63",
    img: img5,
    location: "Sector 63, Gurgaon",
    link: "Anant-Raj-Floors",
    zip: "122002",
    logo: Anantlogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Anant1,
      },
      {
        id: "2",
        img: Anant2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Anant Raj estate floors, Sector 63A, Gurgaon offers its residents a luxurious style of living to its residents with intrinsic modern architecture. This project is designed by a leading real estate developer, i.e. Anant Raj Group, which holds a strong presence in Delhi NCR, Rajasthan & Haryana. The group is committed to delivering world-class real estate projects for a comfortable & luxurious experience for its clients.",
      },
      {
        id: "2",
        pra: "The Anant Raj sector 63 offers stunning & independent estate floor plans which are designed keeping in mind the comfort and desires of its residents. The apartments of this residential property offer a high-end infrastructure and are well-equipped with world-class facilities for a luxurious living experience. Moreover, this is a well-planned township in Gurgaon, with excellent connectivity to major roads & highways like Golf Course Extension Road, Sohna Road, & NH-8. Anant Raj sector 63A is offering premium & fully furnished apartments for rent that are available in 2-floor sizes, i.e. 270 Sq. yd & 400 Sq. yd. This ready-to-move dwelling unit is equipped with modern facilities & amenities such as parking spaces at the stilt, additional storage in the basement, large-sized living room spaces, separate provision for a servant’s room, high-speed elevator, lush green open spaces, a clubbing zone, gymnasium, swimming pool, fine dining & open-air eateries option, top-notch cleaning services and more. This residential property is close to various coworking spaces like CoFynd, Scapes-Co-Work, Awfis Coworking, The Office Pass, Co-Offiz coworking space, and more within 10km of range from your destination. Metro station close to this location is sector 55-56, sector 54 Chowk & sector 53-54 all lying on the Rapid line are located within a 10km of distance. This residential place is situated at a great location since, it is close to various nearby places like Adarsh Senior Secondary School, Sapphire mall, Eros City Square, HDFC Bank & ATM, ICICI Bank & ATM, Mumbaikar’s, Skyon club restaurant, Food Therapy, Chai Point and more. Overall, this is a perfect residential place that would let you enjoy an extravagant livelihood with your friends and family in Gurgaon. Explore Gupta Promoters to know more about this amazing place and book your desired apartment for rent at exclusive price deals without any brokerage fee.",
      },
      {
        id: "3",
        pra: "Anant Raj Estate Floors sector 63 is an ultra-luxurious residential property offering independent estate floor plans that are available in 2 sizes i.e 270 sq. yd & 400 sq. yd. The entire residential property is equipped with modern & exclusive facilities such as parking space at the stilt, a basement, a high-speed elevator, large-sized living room spaces, fine dining & open-air eateries, a gaming zone, top-class housekeeping services, and a lot more beyond your imagination. Moreover, this residential property is close to various locations such as Worldmark Gurgaon, Heritage Xperiential learning school, The Shriram millennium school, Indira Gandhi eye hospital, elements one mall, Sapphire mall, Aria mall, rapid line metro station and more all lying within the proximal distance of your future to be home. The IGI Airport is around 40-45 minutes a straight drive from your residence. This seems to be a perfect destination to begin a new living far away from the hustle & bustle of the city. Without further ado, book your floor plan with us at Gupta Promoters at a 0% brokerage fee and exclusive price deals. For more information about this residential property, dial us at +91 9910010295 or drop an email at info@jeetestate.com.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "Anant Raj Sector 63A comprises luxurious apartments for rent available in 2-floor plan sizes, i.e. 270 sq. yd & 400 sq. yd. The 270 sq. yd floor plan is available at a price range starting from ₹2.50 Cr.",
      },
      {
        id: "2",
        name: "Contact us at Gupta Promoters to enquire more about the pricing strategies for booking an apartment for rent at Anant Raj Estate floors sector 63A.",
      },
      {
        id: "3",
        name: "Anant Raj Sector 63A offers stunning floor plans to its residents which are available in 2-floor sizes i.e 270 Sq.yd & 400 sq. yd. The 270 sq. yd floor plan consists of a ground, first & second floor along with a basement plan.",
      },
      {
        id: "4",
        name: "The 400 sq. yd floor plan consists of a ground, first & second floor, basement plan, provision for a servant’s room & Stilt for parking.",
      },
      {
        id: "5",
        name: "Anant Raj estate floors sector 63 is well-connected with major road networks i.e Kadarpur road, Golf Course Extension Road & Sohna Road.",
      },
      {
        id: "6",
        name: "This premium residential property is close to various well-known schools such as Adarsh Senior Secondary school, The Shriram millennium school, DPS Public school, CGS International school, and more are within 10-15 km of distance from the location.",
      },
    ],
    table: [
      {
        id: "1",
        type: "3 BHK",
        Saleable_Area: "1855.5-2732 sq.ft.",
        price: "On Request",
        fimg: Anantf1,
      },
      {
        id: "2",
        type: "4 BHK",
        Saleable_Area: "3600-5072 sq.ft.",
        price: "On Request",
        fimg: Anantf2,
      },
      {
        id: "3",
        type: "5 BHK",
        Saleable_Area: "2700-7582 sq. ft",
        price: "On Request",
        fimg: Anantf3,
      },
    ],
    descD: "Anant Raj estate floors, Sector 63A, Gurgaon offers its residents a luxurious style of living to its residents with intrinsic modern architecture. This project is designed by a leading real estate developer, i.e. Anant Raj Group, which holds a strong presence in Delhi NCR, Rajasthan & Haryana. The group is committed to delivering world-class real estate projects for a comfortable & luxurious experience for its clients.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.7227053690503!2d77.09430098265369!3d28.397442132321814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d222697fee7b3%3A0xb32caac841eaf5e3!2sAnantraj%20Estate%20Floors!5e0!3m2!1sen!2sin!4v1702299699190!5m2!1sen!2sin",
  },
  {
    id: "6",
    name: "DLF The Arbour",
    img: img6,
    location: " Sector 63, Gurgaon",
    link: "DLF-The-Arbour",
    zip: "122002",
    logo: Arbourlogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        // img: Adani1,
      },
      {
        id: "2",
        // img: Adani2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "DLF The Arbour Sector 63 Gurgaon is a newly launched residential project on 25 acres of land. This is a top-notch property in the upcoming suburb of the city, near Golf Course Road & Sector 55-56 rapid metro station. It is a well-planned society and has lavish 4 BHK apartments in a 3950 sq. ft. super built-up area for your prestigious lifestyle. This is a gated community that consists of 5 stunning high-rise towers, a landscape park, a children’s playing area, and a lot more for an easy and unparalleled lifestyle in Gurgaon.",
      },
      {
        id: "2",
        pra: "DLF The Arbour will also host one of the biggest clubhouses in the city, in more than 1.25 lac sq. ft. area. In addition, the apartments in this property will be two sides open and fully ventilated with all the latest facilities. It is one of the luxury residences with premium furnishings. This property is under construction and its expected possession is February 2026. Book your apartments in DLF The Arbour Gurgaon before they get over-subscribed. Call us now at +9810010295 to get an apartment in this property at an exclusive price!",
      },
      {
        id: "3",
        pra: "DLF The Arbour is a premier residential property in Gurgaon. It is an iconic development by the renowned brand DLF in the upcoming residential hub of the city. This property is not just to grab a home in Gurgaon, but an asset and investment opportunity to grow your money at a high pace. It is strategically located in the mostly preferred locality and surrounded by lush greenery. This property attracts people from all around and offers lavish 4 BHK two-side open apartments along with the latest furnishings and a range of modern amenities. DLF The Arbour is a newly launched project, it is under construction, but you can connect with Gupta Promoters to know more about its floor plan, pricing, location advantage, specification, etc. Call us at +91 9999 06 3322 or write a message at info@atulgupta.in to get in touch with our real estate experts.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "This residential project is spread over 25 Acres of land with 5 high-rise towers and 85% open area. Size of 4 BHK apartments in DLF The Arbour Sector 63 Gurgaon is a 3950 sq. ft. super built-up area and it has an extra large master bedroom with workspace setup, twin extended decks, spacious living room, and a lot more.",
      },
      {
        id: "2",
        name: "This Property is located in Sector 63 at Maidawas Marg, near Golf Course Road and Samrat Mihir Bhoj Chowk. This property is only a 5 to 10 minutes drive from Sector 55-56 rapid metro station. It is surrounded by various residential developments like; Baani Apartments, Adani Samsara, Birla Navya, etc. It is situated in the proximity of top business districts like; Sohna Road, Golf Course Extension Road, & Golf Course Road.",
      },
      {
        id: "3",
        name: "DLF The Arbour Gurgaon is NCR’s 1st Zone 5 Building. This property offers 85% open area along with five stunning high-rise towers.",
      },
      {
        id: "4",
        name: "It will host one of the largest clubhouses in Gurgaon, sprawling over a 1.25 lac sq. ft. area. Apartments in the project are two sides open and fully ventilated.",
      },
      {
        id: "5",
        name: "This property is just 10 minute’s drive from NH 248 & Golf Course Road and 15 minutes from Southern Peripheral Road.",
      },
      {
        id: "6",
        name: "Starting price for a 4 BHK apartment in this property is only INR XYZ",
      },
    ],
    table: [
      {
        id: "1",
        type: "4 BHK",
        Saleable_Area: "3950  sq. ft",
        price: "₹ 7.50 Cr*",
        fimg: Arbourf1,
      },
    ],
    descD: "DLF The Arbour Sector 63 Gurgaon is a newly launched residential project on 25 acres of land. This is a top-notch property in the upcoming suburb of the city, near Golf Course Road & Sector 55-56 rapid metro station. It is a well-planned society and has lavish 4 BHK apartments in a 3950 sq. ft. super built-up area for your prestigious lifestyle. This is a gated community that consists of 5 stunning high-rise towers, a landscape park, a children’s playing area, and a lot more for an easy and unparalleled lifestyle in Gurgaon. ",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.761979997647!2d77.08228267545526!3d28.396256194771134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d238482b18ab1%3A0x5f656228ecf90a8c!2sThe%20Arbour!5e0!3m2!1sen!2sin!4v1702299774860!5m2!1sen!2sin",
  },
  {
    id: "7",
    name: "Smart World Orchad",
    img: img7,
    location: " Sector 61, Gurgaon",
    link: "Smart-World-Orchad",
    zip: "122002",
    logo: Orchadlogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Orchad1,
      },
      {
        id: "2",
        img: Orchad2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Recently stepped into the world of offering architectural marvels, Smart World has launched a residential project Smart World Orchard Sector 61 Gurgaon. This project is a perfect opportunity to live in an ambience that mixes both convenience and comfort at the same time.",
      },
      {
        id: "2",
        pra: "Spread over 25 acres, Smart World Orchard Sector 61 is a well equipped township with all amenities, from basic to premium for all residents. The beauty of the area enhances with the development of the lake in between making it everyone’s favorite hangout spot. Situated close to natural surroundings, there’s ample sunlight, fresh breath of air and gorgeous views for a peaceful living.",
      },
      {
        id: "3",
        pra: "Smart World Orchard Sector 61 Gurgaon carefully designed and planned keeping in mind the trends and technology of today. Moreover, you would find the top-notch schools, medical infrastructure, grocery stores and entertainment spots for high quality of living.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "An exclusive clubhouse to host various parties with your friends and family. Beautiful Swimming pool with kids pool and a sun deck to relax and chill. Safe Play Area for the kids.",
      },
      {
        id: "2",
        name: "Reading Area to read any book of your choice. A Multipurpose hall to arrange various events. Spend a good time drinking coffee at the cafeteria.",
      },
      {
        id: "3",
        name: "Book your property at just ₹2 lac* in Smart World 61 Orchard Gurgaon. Zero Payment Till Possession.",
      },
      {
        id: "4",
        name: "Zero Maintenance on Existing Property. Free Personal Office and Private Terrace.  100% money back if not delivered within 2 years.",
      },
      {
        id: "5",
        name: "There will also be a lake in Smart World Orchard Sector 61 that makes this a delightful place to hang out. Stylish facade- 1st time in India.",
      },
    ],
    table: [
      {
        id: "1",
        type: "2.5 BHK + Private Office + Terrace",
        Saleable_Area: "1120 & 1150 Sq.Ft.",
        price: "On Request",
        fimg: Orchadf1,
      },
      {
        id: "2",
        type: "3 BHK + Private Office + Terrace",
        Saleable_Area: "1368 & 1549 & 1630 Sq.Ft.",
        price: "On Request",
        fimg: Orchadf2,
      },
    ],
    descD: "Recently stepped into the world of offering architectural marvels, Smart World has launched a residential project Smart World Orchard Sector 61 Gurgaon. This project is a perfect opportunity to live in an ambience that mixes both convenience and comfort at the same time.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.71856359712!2d77.04348757545534!3d28.397567194712035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23cbee90929d%3A0x7e350ee043946918!2ssmart%20world%20orchard%20sector%2066!5e0!3m2!1sen!2sin!4v1702299847702!5m2!1sen!2sin",
  },
  {
    id: "8",
    name: "Emaar Digi Homes",
    img: img8,
    location: " Sector 62, Gurgaon",
    link: "Emaar-Digi-Homes",
    zip: "122002",
    logo: Emaarlogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Emaar1,
      },
      {
        id: "2",
        img: Emaar2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Emaar Digi Homes is a luxury Residential project in Sector 62 Gurgaon by Emaar India Group. As the Name specifies Digital Homes, these homes are designed to work on your Touch and Voice Commands. Here, they are offering 2BHK and 3BHK Apartments ranging from 1508 sq.ft to 2589 sqft with Abundant of space by using the optimum area without disrupting one Luxury Feeling.",
      },
      {
        id: "2",
        pra: "Emaar Digi Homes are in the Heart of the Prime Golf Course Extension Road. Here, They Promise for Best towards Quality and Luxury Living. This Project consists of Three High Towers and In Each Tower, there are four Apartments. All the Facilities are provided here to be you more relaxed, de-stress and comfortable.",
      },
      {
        id: "3",
        pra: "In Emaar Digi Homes Sector 62, All the Apartments are created to put a smile on every one Face. Every apartment here is well designed and open Indoor so that Every Angle of the House looks Elegant. This Project takes care of your Necessary requirements and makes a Beautiful place for every Inhabitant in the Community. These Homes have plenty of Greenery which makes an Enjoyable Ambience. Here, You will get all the Modern Luxuries like Swimming Pool, Gym, Clubhouse, Kids Play Area and so on. These Luxury Homes with Greenery Surrounding is the Best Option for you and your Family Members.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "Onsite Salon, Restaurant, & ATM. Emaar Digi Homes have Yoga Zone & Meditation Area. Emaar Digi Homes Gurgaon has automation technology. Three levels for Clubhouse with indoor & party games.",
      },
      {
        id: "2",
        name: "Emaar Digi Homes has 32 floors in each tower. Emaar Digi Homes are crafted with moderate ventilation level. Emaar Digi Homes are fitted with modern ergonomic furnishings. Emaar Digi Homes floor plan offers three sides open apartments. Emaar Digi Homes are designed with wooden flooring & vitrified tiles.",
      },
      {
        id: "3",
        name: "This property is situated in Sector 62, near Golf Course Road. It is in the vicinity of Sohna Road, Southern Peripheral Road, & Go;f Course Road.",
      },
      {
        id: "4",
        name: "Emaar Digi Homes are only 4 km from Sector 55-56 Rapid Metro Station. IGI Airport is only 30 minutes drive from Emaar Digi Homes. This property is surrounded by top business centres & schools.",
      },
      {
        id: "5",
        name: "Emaar Digi Homes has 3 side open apartments. There is an exquisitely designed clubhouse in 30,000+ sq. ft.",
      },
      {
        id: "6",
        name: "This property is seamlessly connected with Golf Course Road & Sohna Road. Apartments are equipped with digital features like Voice & Touch command controller.",
      },
    ],
    table: [
      {
        id: "1",
        type: "2 BHK",
        Saleable_Area: "1508  sq. ft",
        price: "On Request",
        fimg: Emaarf1,
      },
      {
        id: "2",
        type: "3BHK + Utility (T-1)",
        Saleable_Area: "2567  sq. ft",
        price: "On Request",
        
      },
      {
        id: "3",
        type: "3BHK + Utility (T-2)",
        Saleable_Area: "2589  sq. ft",
        price: "On Request",
        
      },
    ],
    descD: "Emaar Digi Homes is a luxury Residential project in Sector 62 Gurgaon by Emaar India Group. As the Name specifies Digital Homes, these homes are designed to work on your Touch and Voice Commands. Here, they are offering 2BHK and 3BHK Apartments ranging from 1508 sq.ft to 2589 sqft with Abundant of space by using the optimum area without disrupting one Luxury Feeling.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.536343705625!2d77.08275167545551!3d28.403068894463946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d221b3ab9c24f%3A0xd8f49965c365fd6!2sEmaar%20Digi%20Homes!5e0!3m2!1sen!2sin!4v1702299924582!5m2!1sen!2sin",
  },
  {
    id: "9",
    name: "Shree Vardhman Victoria",
    img: img9,
    location: " Sector 70, Gurgaon",
    link: "Shree-Vardhman-Victoria",
    zip: "122002",
    logo: Vardhmanlogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        // img: Adani1,
      },
      {
        id: "2",
        // img: Adani2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Shree Vardhman Victoria is a residential complex that is always in your dreams. It is in the prime suburb of Gurgaon, at Southern Peripheral Road in Sector 70. This is an attractive and luxurious housing solution, complemented by large, lush greenery that is filled with flowers & mature trees. It is an urban property that fulfills all the needs of a home buyer. On these premises, you will get a quality lifestyle with exceptional services. This iconic building has 590+ home units that bring a community of Doctors, Engineers, Business Persons, Consultants, NRIs, & reputed Working professionals together. In addition, Shree Vardhman Victoria is built with a modern design and 44 state-of-art amenities including; gaming courts, a party area, equipped gym, & meditation area.",
      },
      {
        id: "2",
        pra: "This complex is spanned on 10.97 acres of land with 10 shiny towers. It is a RERA registered project with a wide variety of lavish 3 BHK homes in 1,300+ sq. ft. All the apartments of this complex have an extensive balcony, and designer furniture, & linked with high-speed uninterrupted lifts. Explore this complex and book your dreamy apartment for just INR 78 Lacs.!",
      },
    ],
    hig: [
      {
        id: "1",
        name: "Starting price of a 3 BHK apartment is only INR 78 Lacs.  Sector 70 witnessed a price jump of 10% from Feb-22 to Sep-22 Price surge of 4% in a year in Shree Vardhman Victoria , Various payment modes including Down Payment",
      },
      {
        id: "2",
        name: "There is a wide variety of 3 BHK apartments  Each tower of the complex has 18 floors Floorings are made up of Italian marble  Bedrooms have wooden floorings The bathroom/kitchen are made up of Anti-Skid, Ceramic & Vitrified tiles",
      },
      {
        id: "3",
        name: "It is a gated community 80% open area in the complex 10 Shiny high-rise towers It has 590+ home units Only 10 minutes from NH8 ",
      },
    ],
    table: [
      {
        id: "1",
        type: "2 BHK",
        Saleable_Area: "1300 sq. ft",
        price: "On Request",
        fimg: Vardhmanf1,
      },
      {
        id: "2",
        type: "2 BHK",
        Saleable_Area: "1350 sq. ft",
        price: "On Request",
      },
      {
        id: "3",
        type: "3 BHK + Dress + Powd",
        Saleable_Area: "1950 sq. ft",
        price: "On Request",
        fimg: Vardhmanf2,
      },
    ],
    descD: "Shree Vardhman Victoria is a residential complex that is always in your dreams. It is in the prime suburb of Gurgaon, at Southern Peripheral Road in Sector 70. This is an attractive and luxurious housing solution, complemented by large, lush greenery that is filled with flowers & mature trees. It is an urban property that fulfills all the needs of a home buyer. On these premises, you will get a quality lifestyle with exceptional services. This iconic building has 590+ home units that bring a community of Doctors, Engineers, Business Persons, Consultants, NRIs, & reputed Working professionals together. In addition, Shree Vardhman Victoria is built with a modern design and 44 state-of-art amenities including; gaming courts, a party area, equipped gym, & meditation area.",
   mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.8537213185923!2d77.01602827545523!3d28.393485794895984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d22c92f2f9fb7%3A0x587ab3b97228e563!2sShree%20Vardhman%20Victoria!5e0!3m2!1sen!2sin!4v1702299998301!5m2!1sen!2sin",
  },
  {
    id: "12",
    name: "Signature Global City 63A",
    img: img12,
    location: " Sector 63A, Gurgaon",
    link: "Signature-Global",
    zip: "122002",
    logo: Signaturelogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Signature1,
      },
      {
        id: "2",
        img: Signature2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "These apartments are ideal picks as a perfect home for middle-class families. Signature Global Sector 63A Gurgaon homes are affordable, as well as luxury, modern, & supreme in all aspects.",
      },
      {
        id: "2",
        pra: "In addition, Signature Global in Sector 63A is nestled in the most prominent spot, only a step away from the landmark & bus stops. The neighborhood of Signature Global Sec 63A is filled with ample greenery & lush vegetation to offer a Clean & Hygienic living environment.",
      },
      {
        id: "3",
        pra: "Sector 63A is among the best residential addresses in Gurgaon. It is located on the outskirts of the city with easy & direct access to prime spots. This address is ideal to provide you with all luxury living with a great social infrastructure.",
      },
      {
        id: "4",
        pra: "Sector 63A is close to business parks, as well as in the vicinity of thriving shopping arcades, educational institutes, hospitals, & wellness centers. With A grade transit facility & ample natural greenery, the location of Signature Global 63A is perfect. Living in this region means maintaining or improving a lifestyle from the core.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "Power backup cost for 3KVA is only 60 Thousand. Prices in Signature Global 63A are supreme right now. Signature Global 63A offers an included parking area worth 4 Lac.",
      },
      {
        id: "2",
        name: "4 BHK apartment with Utility Space & Basement is at just 4.70 Cr. 2 BHK apartments in Signature Global 63A starts from only 1.27 Cr. Signature Global City Gurgaon offers 3 BHK apartments in just 1.57 Cr. Signature Global Sector 63 witnessed a surge of 10% in price since last year. ",
      },
      {
        id: "3",
        name: "Premium moveable furnishings. Broad passage with anti-skid tiles. U-shaped stairs along with a high-speed lift. Extensive basement with lobby & distinct partitions.",
      },
      {
        id: "4",
        name: "All buildings have broad private & communal terraces. Three types of Spacious apartments available in Signature Global 63A.",
      },
      {
        id: "5",
        name: "Signature Global City 63A Wide twin balcony with natural green plants. Signature Global 63A has Low-rise 4-floor towers with terrace & basement. ",
      },
      {
        id: "6",
        name: "Signature Global 63A is designed by Hafeez Contractor.  Seamless sports facilities including; courts & parks. The complex has modern Rain Water Harvesting Technology. Signature Global 63A has a dedicated support team. Signature Global Park 2&3 has ample greenery. Signature Global Sector 63A is accessible from the entire NCR.  Homes are at an attractive price in Signature Global Park 2&3. Signature Global Park 2&3 offers 100% power backup assistance.",
      },
    ],
    table: [
      {
        id: "1",
        type: "2BHK +2 Toilet",
        Saleable_Area: "1050 sq. ft",
        price: "On Request",
        fimg: Signaturef1,
      },
      {
        id: "2",
        type: "3BHK +3 Toilet",
        Saleable_Area: "1305 sq. ft",
        price: "On Request",
        fimg: Signaturef2,
      },
      {
        id: "3",
        type: "3BHK +3 Toilet + Pooja Room",
        Saleable_Area: "1470 sq. ft",
        price: "On Request",
      },
    ],
    descD: "Signature Global City 63A is a modern residential complex. It is the first & best project under Deen Dayal Jan Awas Yojana in the southern region of Gurgaon, a policy for affordable housing solutions by the Haryana Govt. Signature Global city 63A is integrated with a distinct range of apartments, ranging from 2 BHK + 1 Toilet to 3 BHK + 3 Toilets.",
   mapLocation:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.631270983913!2d77.07605297545737!3d28.46052969187081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18e80f3a5797%3A0xd96082db5fb0e30!2ssignature%20global%2063a%20gurgaon!5e0!3m2!1sen!2sin!4v1702300064873!5m2!1sen!2sin",
  },
  {
    id: "17",
    name: "M3M City Heights",
    img: img17,
    location: " Sector 65, Gurgaon",
    link: "M3M-City-Heights",
    zip: "122002",
    logo: Heightslogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Heights1,
      },
      {
        id: "2",
        img: Heights2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "M3M City Heights is a luxury residential project by M3M in Sec-65, Gurgaon on Golf Course Extension Road. M3M City Heights offers 2BHK & 2BHK + Study apartment of 1261 sqft. & 1433 sqft. M3M City heights have many luxury amenities like Lift, Rooftop Cafe, an Intelligent home access control system, etc.",
      },
      {
        id: "2",
        pra: "M3M city heights price is starting from ₹ 14950/- sqft. M3M city height is well equipped with all modren amenities. M3M City Heights is very close to M3M 65th avenue and is very close to all basic needs and requirements.",
      },
      {
        id: "3",
        pra: "M3M Heights is providing 2 BHK and 3 BHK luxurious Residential apartments with all kinds of ultra-modern specifications and facilities. M3M Heights superfluity Apartments is well positioned on Golf Course Extension Road, Gurgaon which is enormously close to the proposed metro station.        ",
      },
      {
        id: "4",
        pra: "In the surrounding area, there are schools like Delhi Public School, Gems Academy, St. Xavier School, Lotus International, and many more which are already ready. There are Hospitals like Artemis, Park Hospital, etc. In terms of existing Golf Course Extension Road is sought after location in Gurugram.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "An Under Construction Residential Project by M3M.  Spread over an area of 14.41 Acres.",
      },
      {
        id: "2",
        name: "2 and 3 BHK Luxury Apartments. 6 Towers.",
      },
      {
        id: "3",
        name: "Flexible Payment Plan. Possession Period- July 2024.",
      },
      {
        id: "4",
        name: "Total of 800 Apartments. 2 BHK Apartments from 1150 to 1450 Sq. Ft. 3 BHK Apartments from 1800 to 2100 Sq. Ft. ",
      },
    ],
    table: [
      {
        id: "1",
        type: "2 BHK",
        Saleable_Area: "1261  sq. ft",
        price: "On Request",
        fimg: Heightsf1,
      },
      {
        id: "2",
        type: "3 BHK",
        Saleable_Area: "2000 sq. ft",
        price: "On Request",
        fimg: Heightsf2,
      },
      
    ],
    descD: "M3M City Heights is a luxury residential project by M3M in Sec-65, Gurgaon on Golf Course Extension Road. M3M City Heights offers 2BHK & 2BHK + Study apartment of 1261 sqft. & 1433 sqft. M3M City heights have many luxury amenities like Lift, Rooftop Cafe, an Intelligent home access control system, etc.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.590164820495!2d77.06273307545544!3d28.40144399453715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d225da692ab6b%3A0x81c103cc9b3f672b!2sM3M%2C%20Golf%20Estate%20Rd%2C%20Sector%2065%2C%20Gurugram%2C%20Haryana%20122102!5e0!3m2!1sen!2sin!4v1702300162644!5m2!1sen!2sin",
  },
  {
    id: "18",
    name: "M3M Merlin",
    img: img18,
    location: " Sector 67, Gurgaon",
    link: "M3M-Merlin",
    zip: "122002",
    logo: Merlinlogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Merlin1,
      },
      {
        id: "2",
        img: Merlin2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "M3M Merlin is one of the High-End Residential Projects designed to fulfill the ever-growing Needs of the Professional Living Community. M3M Merlin brings the Singapore Style 3 and 4 BHK Apartments in Sector-67 Gurgaon with sizes from 2019 to 5790 Sq. Ft. It is spread over a Built-up Area of 13.34 Acres with a total of 510 Apartments.",
      },
      {
        id: "2",
        pra: "It is surrounded by the Abundant of Greenery in the Parks. This Lavish Project is designed to meet Every Modern Residential need for your Luxury Lifestyle. M3M Merlin Gurgaon is equipped with all the Top-Notch Amenities like Exclusive Clubhouse, Swimming Pool, Kids’ Play Area, High-Speed Elevators, Gym, Kids Play Area, Meditation Area, Cafeteria, Restaurants, Laundry Facilities, and a lot more.",
      },
      {
        id: "3",
        pra: "This Place is Close proximity to a No. of Schools, Hospitals, Malls, and a lot more. It is near the Two Remarkable M3M Commercial Projects- M3M Urbana and M3M Cosmopolitan. The Designing of the M3M Merlin is done by DP Architects- A Singapore Based Company. Landscaping Work is done by the Belt Collins from the USA. So, Now You can understand How Amazing this Residential Project is.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "A Ready to Move Residential Project by M3M Group. Completed in March 2017. ",
      },
      {
        id: "2",
        name: "3,4 BHK Luxury Apartments. Spread over an area of 13.34 Acres. 510 Apartments.",
      },
      {
        id: "3",
        name: "Total of 10 Towers. Construction Credit is given to Larsen and Toubro- An Indian MNC. 3 BHK Units with a range from 2019 to 2727 Sq. Ft. 4 BHK Units from 3103 to 5790 Sq. Ft. ",
      },
    ],
    table: [
      {
        id: "1",
        type: "3 BHK",
        Saleable_Area: "1844 sq. ft",
        price: "On Request",
        fimg: Merlinf1,
      },
      {
        id: "2",
        type: "4 BHK",
        Saleable_Area: "3286 sq. ft",
        price: "On Request",
        fimg: Merlinf2,
      },
    ],
    descD: "M3M Merlin is one of the High-End Residential Projects designed to fulfill the ever-growing Needs of the Professional Living Community. M3M Merlin brings the Singapore Style 3 and 4 BHK Apartments in Sector-67 Gurgaon with sizes from 2019 to 5790 Sq. Ft. It is spread over a Built-up Area of 13.34 Acres with a total of 510 Apartments.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1754.9927088041281!2d77.05869638870024!3d28.389508403586238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2257701491cd%3A0x6f279e311416bea5!2sM3M%20MERLIN%2C%20Sector%2067%2C%20Gurugram%2C%20Haryana%20122102!5e0!3m2!1sen!2sin!4v1702300221463!5m2!1sen!2sin",
  },
  {
    id: "19",
    name: "Ireo Grand Arch",
    img: img19,
    location: " Sector 58, Gurgaon",
    link: "Ireo-Grand-Arch",
    zip: "122102",
    logo: Ireologo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Ireo1,
      },
      {
        id: "2",
        img: Ireo2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Ireo Grand Arch is one of the High-End Residential Projects located near the Aravalli Hills Gurgaon. It is a part of the Grand 700-acre Ireo City Township. This Lavish Project is on the Sector-58 Golf Course Extension Road Gurgaon. Here, Ireo The Grand Arch is offering 2,3, and 4 BHK Apartments including duplexes with a size range from 1375 to 2864 Sq. Ft.",
      },
      {
        id: "2",
        pra: "This Amazing Project is spread over a Built-up Area of 20 Acres with 80% Open Area. Ireo Grand Arch Gurgaon comprises 10 Towers with over 1061 Units. It redefines the Next Level of living through its Unique Combination of Comfortability, Convenience, and Security. Spread over an area of 700 Acres, it has easy access to Various Schools, Hospitals, Offices, and Service Apartments in Ireo City.",
      },
      {
        id: "3",
        pra: "The Ireo Grand Arch is equipped with Unique World-Class Amenities which has not been experienced by Indians yet which include the Internationally Design Architecture with 4 Side Open Apartments for all-around Natural Sunlight, 2 BHK Duplex Apartments With Dual Length Roof Over The Lounge And Dining Area and lot more. This Grand Project is supported by the Robert Foreign Direct Investment.",
      },
      {
        id: "4",
        pra: "So, The Time has Come for your Dream Home Project. Get a Luxury and Comfortable Lifestyle with all the Conveniences at one Place in Ireo Grand Arch Sector-58 Golf Course Extension Road Gurgaon.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "The architecture is aesthetically designed to soothe urban community living.",
      },
      {
        id: "2",
        name: "Environment conducive design permits around the year access to natural light from all four sides.",
      },
      {
        id: "3",
        name: "Exclusive two-bedroom duplex apartments with a double height ceiling over the dining & lounge area.",
      },
      {
        id: "4",
        name: "Elevated walkways ensure the safety of all pedestrians be they children or an infirm aged couple.",
      },
      {
        id: "5",
        name: "Each building will have state-of-the-art 5-star concierge 24×7 services provided.",
      },
      {
        id: "6",
        name: "A Ready to Move Residential Project by Ireo Group. Spread on a sprawling area of 700 Acres of Ireo City.",
      },
      {
        id: "7",
        name: "Completed in March 2016. 2,3 and 4 BHK Apartments including Duplex and Penthouses.1061 Apartments.",
      },
      {
        id: "8",
        name: "Built-up Area of 20 Acres with Total of 10 Towers. 2 BHK Apartments from 1375 to 1632 Sq. Ft.",
      },
      {
        id: "9",
        name: "3 BHK size from 2156 to 2896 Sq. Ft. 4 BHK size from 2857 to 5085 Sq. Ft.",
      },
    ],
    table: [
      {
        id: "1",
        type: "2 BHK",
        Saleable_Area: "1625 sq. ft",
        price: "2.11 - 4.30 Cr* Onwards",
        fimg: Archimg1,
      },
      {
        id: "2",
        type: "3 BHK",
        Saleable_Area: "2896 sq. ft",
        price: "On Request",
        fimg: Archimg2,
      },
      {
        id: "3",
        type: "4 BHK",
        Saleable_Area: "2864 sq. ft",
        price: "On Request",
        fimg: Archimg3,
      },
    ],
    descD: "Ireo Grand Arch is one of the High-End Residential Projects located near the Aravalli Hills Gurgaon. It is a part of the Grand 700-acre Ireo City Township. This Lavish Project is on the Sector-58 Golf Course Extension Road Gurgaon. Here, Ireo The Grand Arch is offering 2,3, and 4 BHK Apartments including duplexes with a size range from 1375 to 2864 Sq. Ft.",
    mapLocation:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.1932120633473!2d77.10961687545587!3d28.413426293996952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d220ff3e8a5d5%3A0x91d67d4b53e8a96b!2sThe%20Grand%20Arch!5e0!3m2!1sen!2sin!4v1702300293790!5m2!1sen!2sin",
  },
  {
    id: "20",
    name: "Ireo Skyon",
    img: img20,
    location: " Sector 60, Gurgaon",
    link: "Ireo-Skyon",
    zip: "122002",
    logo: Skyonlogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Skyon1,
      },
      {
        id: "2",
        img: Skyon2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Ireo Skyon is one of the Remarkable Residential Projects by Ireo Group in Sector-60 Gurgaon. This Lavish Project is on the most Prominent Location of Golf Course Extension Road. Its Premium Amenities, Recreational Facilities, and Lush Greens make this Project very unique from others.",
      },
      {
        id: "2",
        pra: "Here, Ireo Skyon is offering Luxury 2,3, and 4 BHK Apartments with a size range from 1374 to 2791 Sq. Ft. with Servant Room. The Grand Ireo Skyon Project is spread over an area of 18 Acres. It consists of 6 Towers out of which there are 5 Mid Rise Towers and One High Rise Tower (Tower B) with a total of 750 Units.",
      },
      {
        id: "3",
        pra: "Ireo Skyon Gurgaon is easy to access from so many places like Nearby Schools, Hospitals, Shopping Malls, and also the Metro Stations. Get the World Class Amenities and Top-Notch Specifications in the Ireo Skyon Sector-60 Gurgaon like Exclusive Clubhouse, Swimming Pool, Gyms, Kids Play Area, Tennis and Badminton Court, High-End Facility Kitchen, and a lot more.",
      },
      {
        id: "4",
        pra: "Ireo provides you with the No. of Options to let you decide on the Best Purchase. So, What are you waiting for?? Call us at 99990-63322 and Our Residential Property Experts will help you in getting your Dream Home in the Ireo Skyon Sector-60 Gurgaon.​",
      },
    ],
    hig: [
      {
        id: "1",
        name: "Ready to Move Residential Project by Ireo Group.",
      },
      {
        id: "2",
        name: "Completed in Jun 2015.",
      },
      {
        id: "3",
        name: "2,3 and 4 BHK Apartments.",
      },
      {
        id: "4",
        name: "Spread over an area of 16 Acres..",
      },
      {
        id: "5",
        name: "Total No. of 6 Towers with 39 floors..",
      },
      {
        id: "6",
        name: "750 Apartments. 2 BHK size from 1365 to 1524 Sq. Ft.",
      },
      {
        id: "7",
        name: "3 BHK units size is 2045 Sq. Ft. 4 BHK Apartments range from 2768 to 2800 Sq. Ft.",
      },
      {
        id: "8",
        name: "Ireo Skyon is located within the 600 acre Ireo City Space Age Homes | Fully Automated Smart Home Systems.",
      },
    ],
    table: [
      {
        id: "1",
        type: "2 BHK",
        Saleable_Area: "1524 sq.ft.",
        price: "On Request",
        fimg: fimg1,
      },
      {
        id: "2",
        type: "3 BHK",
        Saleable_Area: "2403 sq.ft.",
        price: "On Request",
        fimg: fimg2,
      },
      {
        id: "3",
        type: "4 BHK",
        Saleable_Area: "3155 sq.ft.",
        price: "On Request",
        fimg: fimg3,
      },
    ],
    descD: "Ireo Skyon is one of the Remarkable Residential Projects by Ireo Group in Sector-60 Gurgaon. This Lavish Project is on the most Prominent Location of Golf Course Extension Road. Its Premium Amenities, Recreational Facilities, and Lush Greens make this Project very unique from others.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1754.8251825250975!2d77.09528228870029!3d28.39962640335964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2223ffa1c88f%3A0xd29d537f419ef268!2sIREO%20SKYON%2C%20Sector%2060%2C%20Gurugram%2C%20Haryana%20122102!5e0!3m2!1sen!2sin!4v1702300352527!5m2!1sen!2sin",
  },
  {
    id: "21",
    name: "Ireo Victory Valley",
    img: img21,
    location: " Sector 67, Gurgaon",
    link: "Ireo-Victory-Valley",
    zip: "122002",
    logo: Victorylogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Victorya1,
      },
      {
        id: "2",
        img: Victorya2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Ireo Victory Valley is the High-End Residential Project offering 2,3,4 and 5 BHK Apartments with the size range from 1435 Sq. Ft. to 5970 Sq. Ft. This Lavish Project is on the Sector-67 Golf Course Extension Road Gurgaon. Ireo Victory Valley is spread on a Built-up Area of 25 Acres with more than 750 Apartments in both Simplex and Duplex form.",
      },
      {
        id: "2",
        pra: "This Residential Project has numerous Exclusive Amenities like Gyms, Swimming Pool, Exclusive Clubhouse, Kids Play Area and the count goes on. Ireo Victory Valley Gurgaon is easy to access to various locations in Gurgaon from Metro Stations, various Schools, Hospitals, Shopping Malls and lot more.",
      },
      {
        id: "3",
        pra: "It comprises of 30 Towers from which there are 3 Big Towers of the stunning Height of 51 Floors. In Short, Ireo Victory Valley is an Outstanding Dream Residential Project. You will get a Villa Like Experience with its Luxury Amenities and Comforts around. So, It’s time to make your dream true.",
      },
      {
        id: "4",
        pra: "So, What are you waiting for?? Get your Dream Home in the Stunning Ireo Victory Valley Apartments in Sector-67 Gurgaon. For More Details, Call us at 9810010295.",
      },
    ],
    hig: [
      {
        id: "1",
        name: "Located in Sector 67 Gurgaon. Near to Sector 55-56 Rapid Metro Station.",
      },
      {
        id: "2",
        name: "Nearby Schools are Vibgyor High School, Indus World School and more. 10 Minute Distance from Sohna Gurgaon Road.",
      },
      {
        id: "3",
        name: "Nearby Malls are Omaxe Celebration Mall, Raheja Mall. Near to Gurugram Hospital, Shri Balaji Hospital and more. Near to the Localities of Sector-68, Sector-64 and Sector-65.",
      },
      {
        id: "4",
        name: "Ready to Move Residential Project by IREO. 2,3,4 and 5 BHK Spacious Apartments.",
      },
      {
        id: "5",
        name: "Spread over an area of 25 Acres. No. of Towers is 30 with 3 Big Towers of 51 Floors.",
      },
      {
        id: "6",
        name: "762 Apartments. 2 BHK Apartments range from 1435 Sq. Ft. to 1470 Sq. Ft. 3 BHK Apartments from 2382 Sq. Ft. to 2698 Sq. Ft. 4 BHK Apartments from 2847 Sq. Ft. to 5977 Sq. Ft.",
      },
    ],
    table: [
      {
        id: "1",
        type: "2 BHK",
        Saleable_Area: "1470 sq. ft",
        price: "7.90 Cr* Onwards",
        fimg: Victory1,
      },
      {
        id: "2",
        type: "3 BHK",
        Saleable_Area: "3548 sq. ft",
        price: "On Request",
        fimg: Victory2,
      },
      {
        id: "3",
        type: "4 BHK",
        Saleable_Area: "5977 sq. ft",
        price: "On Request",
        fimg: Victory3,
      },
    ],
    descD: "Ireo Victory Valley is the High-End Residential Project offering 2,3,4 and 5 BHK Apartments with the size range from 1435 Sq. Ft. to 5970 Sq. Ft. This Lavish Project is on the Sector-67 Golf Course Extension Road Gurgaon. Ireo Victory Valley is spread on a Built-up Area of 25 Acres with more than 750 Apartments in both Simplex and Duplex form.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.874284501254!2d77.06259687545517!3d28.39286479492398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d225a6bffffff%3A0x4fbe29cc3a81071e!2sIreo%20Victory%20Valley!5e0!3m2!1sen!2sin!4v1702300411096!5m2!1sen!2sin",
  },
  {
    id: "22",
    name: "Pioneer Araya",
    img: img22,
    location: " Sector 62, Gurgaon",
    link: "Pioneer-Araya",
    zip: "122002",
    logo: Pioneerlogo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: Pioneer1,
      },
      {
        id: "2",
        img: Pioneer2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Built with a sense of impeccable expertise that only comes after years of perseverance, Araya rises to achieve what has always eluded many – perfection. Araya, located in Gurgaon, Delhi NCR, gives you the ease of reach and a complete peace of mind.",
      },
      {
        id: "2",
        pra: "Araya offers you distinctive conveniences that meet your individual needs. Our myriad in-house offerings provide you with every comfort you may require.",
      },
      {
        id: "3",
        pra: "From the lavish swimming and splash pools to the state-of-the-art gymnasium, world-class indoor squash courts to luxurious rejuvenating spa, an exclusive lounge to a chic restaurant, the beyond comparison residences bring all the plush facilities to pamper the best in you. The indulgence in a sumptuous lifestyle can just never stop here.",
      },
      {
        id: "4",
        pra: "Strategically located on Golf Course Extension Road, Gurgaon Araya will be a life in all your desired comforts with a blend of finest of ambiance. Built with a sense of impeccable expertise that only comes after years of perseverance, Araya rises to achieve perfection.        ",
      },
      {
        id: "5",
        pra: "The project offers luxury Penthouse units with a family room, private pool and a sun deck. An extension of your highly refined world and designed to surpass expectations, Araya residences emulator-contemporary feel, subtly manifested in the architectural style and choice of finishes. Designed to surpass expectations, Araya residences emulate uber contemporary feel with its architectural style and choice of furnishing",
      },
    ],
    hig: [
      {
        id: "1",
        name: "Italian marble flooring Complete home automation system provided by Cisco VRV Airconditioning Imported wardrobs in every Apartment , Imported europian modular kitchen",
      },
      {
        id: "2",
        name: "State of the Art Imported Sanitary Ware World class recreational facilities Courtyard gardens & roof top greens",
      },
      {
        id: "3",
        name: "Imported textured paint Steam/Jacuzzi in master bathroom",
      },
      {
        id: "4",
        name: "A Luxury Residential Project by Pioneer Urban. Lavish 3,4 BHK Apartments. Spread over an area of 14 Acres.",
      },
      {
        id: "5",
        name: "Total No. of 4 Towers with 34 floors.  Total Apartments- 254. 3 BHK Apartments from 3498 to 3858 Sq. Ft. ",
      },
      {
        id: "6",
        name: "4 BHK Apartments with size from 4279 to 7673 Sq. Ft.  Open Area of 70%. Pioneer Araya would be fully loaded and include Poggenpohl kitchen. The Pioneer Araya apartments will be equipped with a complete Home Automation system provided by Cisco. ",
      },
    ],
    table: [
      {
        id: "1",
        type: "3 BHK",
        Saleable_Area: "3858 sq. ft",
        price: "On Request",
        fimg: Pioneerf1,
      },
      {
        id: "2",
        type: "4 BHK",
        Saleable_Area: "4983 sq. ft",
        price: "On Request",
        fimg: Pioneerf2,
      },
      {
        id: "3",
        type: "5 BHK",
        Saleable_Area: "9625 sq. ft",
        price: "On Request",
        fimg: Pioneerf3,
      },
    ],
    descD: "Built with a sense of impeccable expertise that only comes after years of perseverance, Araya rises to achieve what has always eluded many – perfection. Araya, located in Gurgaon, Delhi NCR, gives you the ease of reach and a complete peace of mind.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.260761483742!2d77.08589447545577!3d28.411387594088765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23cbac591929%3A0xbf4e59a38045fa5f!2sPioneer%20Araya!5e0!3m2!1sen!2sin!4v1702300468020!5m2!1sen!2sin",
  },
  // {
  //   id: "23",
  //   name: "Tata Primanti",
  //   img: img23,
  //   location: " Sector 72, Gurgaon",
  //   link: "Adani-Tatva-Estates",
  //   zip: "122002",
  //   logo: Adanilogo,
  //   price: "Starting Price ₹ 7.18 Cr*",
  //   steps: [
  //     {
  //       id: "1",
  //       img: Adani1,
  //     },
  //     {
  //       id: "2",
  //       img: Adani2,
  //     },
  //   ],
  //   fec: [
  //     {
  //       id: "1",
  //       fcname: "24*7 Power Backup",
  //     },
  //     {
  //       id: "2",
  //       fcname: "Chair Accessible",
  //     },
  //     {
  //       id: "3",
  //       fcname: "Electricity",
  //     },
  //     {
  //       id: "4",
  //       fcname: "Parking",
  //     },
  //     {
  //       id: "5",
  //       fcname: "SecurityWiFi",
  //     },
  //     {
  //       id: "6",
  //       fcname: "WiFi",
  //     },
  //   ],
  //   desc: [
  //     {
  //       id: "1",
  //       pra: "Adani Realty, one of the leading real estate developers in India launched a thriving residential project in Gurgaon, named Adani Tatva Estates Sector 99A Gurgaon. This project offers a diversified range of residential plots in 131 sq. yd. to 179 sq. yd. area. The property caters to a wide range of homebuyers and provides them with an exceptional living experience. It offers top-notch amenities, such as; a badminton court, cricket pitch, kids’ play area, open-air theatre, yoga garden, multipurpose garden, gaming courts, skating rink, outdoor fitness zone, etc. With these amenities, residents will be able to enjoy a luxurious and comfortable lifestyle.",
  //     },
  //     {
  //       id: "2",
  //       pra: "This is a perfect property that will suit the dreams of any family who wants to build their dream home. In addition, the biggest advantage of this project is its strategic location. It is situated in Sector 99A, Gurgaon, just off Dwarka Expressway. This property is also in proximity of Pataudi Road, Southern Peripheral, & Gurgaon Road. It offers excellent connectivity for major business districts, like; Golf Course Extension Road, Sohna Road, Dwarka Sector 21, Udyog Vihar, & IMT Manesar. It is also seamlessly linked with IGI Airport, Old Gurgaon Railway Station, & Dwarka Sector 21 Metro Station (Blue & Airport Express Line). Overall, Adani Tatva Estates is an excellent residential project and investment opportunity to grab now. Explore it!",
  //     },
  //     {
  //       id: "3",
  //       pra: "This residential project is situated just off Dwarka Highway and only a quick drive from Pataudi Road & Southern Peripheral.",
  //     },
  //   ],
  //   hig: [
  //     {
  //       id: "1",
  //       name: "Adani Tatva Estates Dwarka Expressway is a secured gated community with a wide range of residential plots at affordable price.",
  //     },
  //     {
  //       id: "2",
  //       name: "It is equipped with 24/7 CCTV surveillance, RFD-enabled boom barriers, and more to ensure the safety of residents.",
  //     },
  //     {
  //       id: "3",
  //       name: "This property is only a 15-minute drive from Old Gurgaon Railway Station and hardly a 30-minute drive from IGI Airport.",
  //     },
  //     {
  //       id: "4",
  //       name: "This residential project is easily accessible from the suburbs of Gurgaon & Delhi.",
  //     },
  //     {
  //       id: "5",
  //       name: "It offers various attractive payment plans with inclusive gift benefits.",
  //     },
  //     {
  //       id: "6",
  //       name: "This project has top-notch lifestyle amenities, such as; a cricket net, open-air theatre, yoga garden, skating rink, & outdoor fitness zone.",
  //     },
  //   ],
  //   table: [
  //     {
  //       id: "1",
  //       type: "3 BHK",
  //       Saleable_Area: "2874-2892 sq. ft",
  //       price: "7.90 Cr* Onwards",
  //     },
  //     {
  //       id: "2",
  //       type: "4 BHK",
  //       Saleable_Area: "3684 sq. ft",
  //       price: "On Request",
  //     },
  //     {
  //       id: "3",
  //       type: "5 BHK",
  //       Saleable_Area: "4503 sq. ft",
  //       price: "On Request",
  //     },
  //   ],
  //   descD: "Primanti is envisioned in a way where nature’s boundless beauty meets every luxury you’d expect. Be it walking to the spa through the manicured lawns or entertaining yourself in an outdoor pool amid lush greens, it’s everything you have always dreamt of.",
  // },
  // {
  //   id: "24",
  //   name: "Pioneer Presidia",
  //   img: img24,
  //   location: " Sector 62, Gurgaon",
  //   link: "Adani-Tatva-Estates",
  //   zip: "122002",
  //   logo: Adanilogo,
  //   price: "Starting Price ₹ 7.18 Cr*",
  //   steps: [
  //     {
  //       id: "1",
  //       img: Adani1,
  //     },
  //     {
  //       id: "2",
  //       img: Adani2,
  //     },
  //   ],
  //   fec: [
  //     {
  //       id: "1",
  //       fcname: "24*7 Power Backup",
  //     },
  //     {
  //       id: "2",
  //       fcname: "Chair Accessible",
  //     },
  //     {
  //       id: "3",
  //       fcname: "Electricity",
  //     },
  //     {
  //       id: "4",
  //       fcname: "Parking",
  //     },
  //     {
  //       id: "5",
  //       fcname: "SecurityWiFi",
  //     },
  //     {
  //       id: "6",
  //       fcname: "WiFi",
  //     },
  //   ],
  //   desc: [
  //     {
  //       id: "1",
  //       pra: "Adani Realty, one of the leading real estate developers in India launched a thriving residential project in Gurgaon, named Adani Tatva Estates Sector 99A Gurgaon. This project offers a diversified range of residential plots in 131 sq. yd. to 179 sq. yd. area. The property caters to a wide range of homebuyers and provides them with an exceptional living experience. It offers top-notch amenities, such as; a badminton court, cricket pitch, kids’ play area, open-air theatre, yoga garden, multipurpose garden, gaming courts, skating rink, outdoor fitness zone, etc. With these amenities, residents will be able to enjoy a luxurious and comfortable lifestyle.",
  //     },
  //     {
  //       id: "2",
  //       pra: "This is a perfect property that will suit the dreams of any family who wants to build their dream home. In addition, the biggest advantage of this project is its strategic location. It is situated in Sector 99A, Gurgaon, just off Dwarka Expressway. This property is also in proximity of Pataudi Road, Southern Peripheral, & Gurgaon Road. It offers excellent connectivity for major business districts, like; Golf Course Extension Road, Sohna Road, Dwarka Sector 21, Udyog Vihar, & IMT Manesar. It is also seamlessly linked with IGI Airport, Old Gurgaon Railway Station, & Dwarka Sector 21 Metro Station (Blue & Airport Express Line). Overall, Adani Tatva Estates is an excellent residential project and investment opportunity to grab now. Explore it!",
  //     },
  //     {
  //       id: "3",
  //       pra: "This residential project is situated just off Dwarka Highway and only a quick drive from Pataudi Road & Southern Peripheral.",
  //     },
  //   ],
  //   hig: [
  //     {
  //       id: "1",
  //       name: "Adani Tatva Estates Dwarka Expressway is a secured gated community with a wide range of residential plots at affordable price.",
  //     },
  //     {
  //       id: "2",
  //       name: "It is equipped with 24/7 CCTV surveillance, RFD-enabled boom barriers, and more to ensure the safety of residents.",
  //     },
  //     {
  //       id: "3",
  //       name: "This property is only a 15-minute drive from Old Gurgaon Railway Station and hardly a 30-minute drive from IGI Airport.",
  //     },
  //     {
  //       id: "4",
  //       name: "This residential project is easily accessible from the suburbs of Gurgaon & Delhi.",
  //     },
  //     {
  //       id: "5",
  //       name: "It offers various attractive payment plans with inclusive gift benefits.",
  //     },
  //     {
  //       id: "6",
  //       name: "This project has top-notch lifestyle amenities, such as; a cricket net, open-air theatre, yoga garden, skating rink, & outdoor fitness zone.",
  //     },
  //   ],
  //   table: [
  //     {
  //       id: "1",
  //       type: "3 BHK",
  //       Saleable_Area: "2874-2892 sq. ft",
  //       price: "7.90 Cr* Onwards",
  //     },
  //     {
  //       id: "2",
  //       type: "4 BHK",
  //       Saleable_Area: "3684 sq. ft",
  //       price: "On Request",
  //     },
  //     {
  //       id: "3",
  //       type: "5 BHK",
  //       Saleable_Area: "4503 sq. ft",
  //       price: "On Request",
  //     },
  //   ],
  //   descD: "Pioneer Presidia is a High-End Residential Project offering the Luxurious 3,4 and 5 BHK Apartments with the size range from 2595 Sq. Ft. to 5111 Sq. Ft in Sector-62 Gurgaon. This Lavish Project is constructed on a Built-up Area of 11 Acres with over 5 Towers and 27 Floors.",
  // },
  // {
  //   id: "25",
  //   name: "Trump Tower",
  //   img: img25,
  //   location: " Sector 65, Gurgaon",
  //   link: "Adani-Tatva-Estates",
  //   zip: "122002",
  //   logo: Adanilogo,
  //   price: "Starting Price ₹ 7.18 Cr*",
  //   steps: [
  //     {
  //       id: "1",
  //       img: Adani1,
  //     },
  //     {
  //       id: "2",
  //       img: Adani2,
  //     },
  //   ],
  //   fec: [
  //     {
  //       id: "1",
  //       fcname: "24*7 Power Backup",
  //     },
  //     {
  //       id: "2",
  //       fcname: "Chair Accessible",
  //     },
  //     {
  //       id: "3",
  //       fcname: "Electricity",
  //     },
  //     {
  //       id: "4",
  //       fcname: "Parking",
  //     },
  //     {
  //       id: "5",
  //       fcname: "SecurityWiFi",
  //     },
  //     {
  //       id: "6",
  //       fcname: "WiFi",
  //     },
  //   ],
  //   desc: [
  //     {
  //       id: "1",
  //       pra: "Adani Realty, one of the leading real estate developers in India launched a thriving residential project in Gurgaon, named Adani Tatva Estates Sector 99A Gurgaon. This project offers a diversified range of residential plots in 131 sq. yd. to 179 sq. yd. area. The property caters to a wide range of homebuyers and provides them with an exceptional living experience. It offers top-notch amenities, such as; a badminton court, cricket pitch, kids’ play area, open-air theatre, yoga garden, multipurpose garden, gaming courts, skating rink, outdoor fitness zone, etc. With these amenities, residents will be able to enjoy a luxurious and comfortable lifestyle.",
  //     },
  //     {
  //       id: "2",
  //       pra: "This is a perfect property that will suit the dreams of any family who wants to build their dream home. In addition, the biggest advantage of this project is its strategic location. It is situated in Sector 99A, Gurgaon, just off Dwarka Expressway. This property is also in proximity of Pataudi Road, Southern Peripheral, & Gurgaon Road. It offers excellent connectivity for major business districts, like; Golf Course Extension Road, Sohna Road, Dwarka Sector 21, Udyog Vihar, & IMT Manesar. It is also seamlessly linked with IGI Airport, Old Gurgaon Railway Station, & Dwarka Sector 21 Metro Station (Blue & Airport Express Line). Overall, Adani Tatva Estates is an excellent residential project and investment opportunity to grab now. Explore it!",
  //     },
  //     {
  //       id: "3",
  //       pra: "This residential project is situated just off Dwarka Highway and only a quick drive from Pataudi Road & Southern Peripheral.",
  //     },
  //   ],
  //   hig: [
  //     {
  //       id: "1",
  //       name: "Adani Tatva Estates Dwarka Expressway is a secured gated community with a wide range of residential plots at affordable price.",
  //     },
  //     {
  //       id: "2",
  //       name: "It is equipped with 24/7 CCTV surveillance, RFD-enabled boom barriers, and more to ensure the safety of residents.",
  //     },
  //     {
  //       id: "3",
  //       name: "This property is only a 15-minute drive from Old Gurgaon Railway Station and hardly a 30-minute drive from IGI Airport.",
  //     },
  //     {
  //       id: "4",
  //       name: "This residential project is easily accessible from the suburbs of Gurgaon & Delhi.",
  //     },
  //     {
  //       id: "5",
  //       name: "It offers various attractive payment plans with inclusive gift benefits.",
  //     },
  //     {
  //       id: "6",
  //       name: "This project has top-notch lifestyle amenities, such as; a cricket net, open-air theatre, yoga garden, skating rink, & outdoor fitness zone.",
  //     },
  //   ],
  //   table: [
  //     {
  //       id: "1",
  //       type: "3 BHK",
  //       Saleable_Area: "2874-2892 sq. ft",
  //       price: "7.90 Cr* Onwards",
  //     },
  //     {
  //       id: "2",
  //       type: "4 BHK",
  //       Saleable_Area: "3684 sq. ft",
  //       price: "On Request",
  //     },
  //     {
  //       id: "3",
  //       type: "5 BHK",
  //       Saleable_Area: "4503 sq. ft",
  //       price: "On Request",
  //     },
  //   ],
  //   descD: "Trump Tower Gurgaon is a high-end luxury residential project by Trump & Tribeca located at Sector 65, Golf Course Road Extension, Gurgaon. Trump Tower Gurgaon consist of two exclusive towers rising 650 ft from the ground made of complete glass comprising of spacious 3 BHK and 4 BHK Duplex Apartments.",
  // },
  // {
  //   id: "26",
  //   name: "M3M Golf Estate",
  //   img: img26,
  //   location: " Sector 66, Gurgaon",
  //   link: "Adani-Tatva-Estates",
  //   zip: "122002",
  //   logo: Adanilogo,
  //   price: "Starting Price ₹ 7.18 Cr*",
  //   steps: [
  //     {
  //       id: "1",
  //       img: Adani1,
  //     },
  //     {
  //       id: "2",
  //       img: Adani2,
  //     },
  //   ],
  //   fec: [
  //     {
  //       id: "1",
  //       fcname: "24*7 Power Backup",
  //     },
  //     {
  //       id: "2",
  //       fcname: "Chair Accessible",
  //     },
  //     {
  //       id: "3",
  //       fcname: "Electricity",
  //     },
  //     {
  //       id: "4",
  //       fcname: "Parking",
  //     },
  //     {
  //       id: "5",
  //       fcname: "SecurityWiFi",
  //     },
  //     {
  //       id: "6",
  //       fcname: "WiFi",
  //     },
  //   ],
  //   desc: [
  //     {
  //       id: "1",
  //       pra: "Adani Realty, one of the leading real estate developers in India launched a thriving residential project in Gurgaon, named Adani Tatva Estates Sector 99A Gurgaon. This project offers a diversified range of residential plots in 131 sq. yd. to 179 sq. yd. area. The property caters to a wide range of homebuyers and provides them with an exceptional living experience. It offers top-notch amenities, such as; a badminton court, cricket pitch, kids’ play area, open-air theatre, yoga garden, multipurpose garden, gaming courts, skating rink, outdoor fitness zone, etc. With these amenities, residents will be able to enjoy a luxurious and comfortable lifestyle.",
  //     },
  //     {
  //       id: "2",
  //       pra: "This is a perfect property that will suit the dreams of any family who wants to build their dream home. In addition, the biggest advantage of this project is its strategic location. It is situated in Sector 99A, Gurgaon, just off Dwarka Expressway. This property is also in proximity of Pataudi Road, Southern Peripheral, & Gurgaon Road. It offers excellent connectivity for major business districts, like; Golf Course Extension Road, Sohna Road, Dwarka Sector 21, Udyog Vihar, & IMT Manesar. It is also seamlessly linked with IGI Airport, Old Gurgaon Railway Station, & Dwarka Sector 21 Metro Station (Blue & Airport Express Line). Overall, Adani Tatva Estates is an excellent residential project and investment opportunity to grab now. Explore it!",
  //     },
  //     {
  //       id: "3",
  //       pra: "This residential project is situated just off Dwarka Highway and only a quick drive from Pataudi Road & Southern Peripheral.",
  //     },
  //   ],
  //   hig: [
  //     {
  //       id: "1",
  //       name: "Adani Tatva Estates Dwarka Expressway is a secured gated community with a wide range of residential plots at affordable price.",
  //     },
  //     {
  //       id: "2",
  //       name: "It is equipped with 24/7 CCTV surveillance, RFD-enabled boom barriers, and more to ensure the safety of residents.",
  //     },
  //     {
  //       id: "3",
  //       name: "This property is only a 15-minute drive from Old Gurgaon Railway Station and hardly a 30-minute drive from IGI Airport.",
  //     },
  //     {
  //       id: "4",
  //       name: "This residential project is easily accessible from the suburbs of Gurgaon & Delhi.",
  //     },
  //     {
  //       id: "5",
  //       name: "It offers various attractive payment plans with inclusive gift benefits.",
  //     },
  //     {
  //       id: "6",
  //       name: "This project has top-notch lifestyle amenities, such as; a cricket net, open-air theatre, yoga garden, skating rink, & outdoor fitness zone.",
  //     },
  //   ],
  //   table: [
  //     {
  //       id: "1",
  //       type: "3 BHK",
  //       Saleable_Area: "2874-2892 sq. ft",
  //       price: "7.90 Cr* Onwards",
  //     },
  //     {
  //       id: "2",
  //       type: "4 BHK",
  //       Saleable_Area: "3684 sq. ft",
  //       price: "On Request",
  //     },
  //     {
  //       id: "3",
  //       type: "5 BHK",
  //       Saleable_Area: "4503 sq. ft",
  //       price: "On Request",
  //     },
  //   ],
  //   descD: "M3M Golf Estate is the High-End Residential Project by M3M Group. This Lavish Residential Project is on the Sector-65 Golf Course Extension Road Gurgaon. It is a combination of Low-Rise, Mid-Rise, and High-Rise Towers. Here, M3M presents the Spacious 3BHK, 4BHK and 5BHK Apartments, Duplex, and Penthouses with a range of sizes from 3005 Sq. Ft. to 5760 Sq. Ft.",
  // },
];

export default golfcourseextensionroadDataRes;
