import React from 'react'
import Layout from '../Layout/Layout'
import "./gurgaon.css"
import GurgaonData from '../Data/GurgaonData'


function Gurgaon() {
    return (
        <>
            <Layout>
                <div className="container  mt-4 mb-4">
                    <div className="row ">
                        
                            {
                                GurgaonData && GurgaonData.map((items) => {
                                    return (
                                        <div className="col-lg-4">
                                        <div class="rounded-img project-section">
                                            <div class="item" data-cues="slideInUp" data-delay="400">
                                                <div class="box_cat_rooms">
                                                    <div class="brand-card d-flex justify-content-center align-content-center align-items-center">
                                                        <img src={items.logo} width="140" height="34" alt="" class="img-fluid" />
                                                    </div>
                                                    <figure class="background-image" style={{ backgroundImage: `url(${items.img})`}} >
                                                        <div class="info">
                                                            <h3>{items.name}</h3>
                                                            <small>{items.location}</small>
                                                            <h4 class="project-typology">{items.size}</h4>
                                                            <h4 class="project-price">Starting Price : {items.price}</h4>
                                                            <div class="query-btn-section">
                                                                <button class="query-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now </button>
                                                                <a class="projects-details" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                    <button class="query-btn">Know More </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    )
                                })
                            }

                       
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Gurgaon