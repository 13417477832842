import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/Puri/puri-q28l97nab7wgby9w57onq40gqp0cncka4i9nu71z20.jpg";
import PuriData from "../../Data/ListData/Puri";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function PuriListing() {
  TabTitle("Jeet Estate | Puri Listing");
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="projectbanner">
            <img src={dlfBanne} alt="" />
          </div>
          <div className="card prop-main p-4">
            <p>
              Puri Constructions Pvt ltd is among the finest real estate
              developers in India. This brand inaugurated several remarkable
              projects that are thriving in the NCR region. Puri Constructions
              is known for its luxury homes, they have already delivered 5000+
              accommodations on time with a customer-centric approach. It has a
              record to provide quality and distinctive design that is ideal to
              attract home buyers. With excellence and a legacy of over 50
              years, Puri Constructions introduced an iconic residential complex
              Puri The Aravallis in Gurgaon Sector 61. This is a high-rise
              complex that is built to provide a modern and extremely satisfying
              lifestyle in the heart of the city.
            </p>
            <div className="row">
              {PuriData &&
                PuriData.map((items) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default PuriListing;
