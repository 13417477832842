import React from "react";
import Layout from "../Layout/Layout";
import BannerPrivana from "../../assets/DLFPriivana/Banner.jpg";
import bannerdfl from "../../assets/DLFPriivana/dlf_a1.jpg";
import bannerdf2 from "../../assets/DLFPriivana/dlf_a2.jpg";

function Dlfpriivana() {
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="priivana-project">
            <img src={BannerPrivana} alt="" />
            <div className="project--itemDetails">
              <h2 className="main-txt">DLF NEW LAUNCH – PRIVANA</h2>
              <div className="head_line">
                <h4 className="typo">LUXURY 3/4 BHK APARTMENTS</h4>
              </div>
              <div class="st_price">
                <span class="rupee_symbol">₹</span>
                <br />
                <span class="price_line">Under*</span>
                <br />
                <span class="amount"> Sector 77, Gurgaon </span>
              </div>
            </div>
          </div>
          <div className="iconbox-style1 mt-5">
            <div className="row ">
              <div className="col-lg-6">
                <div className="dlf--banner">
                  <img src={bannerdfl} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <h3 className="mb-4">
                  Welcome to DLF Privana – Where Luxury Meets Serenity!
                </h3>
                <p>We are as thrilled as you.</p>
                <p>
                  Yes, we know you can’t hold your excitement to get your dream
                  home ready but you’ll be glad to know that this dream of yours
                  is going to fulfill soon.
                </p>
                <p>
                  At DLF Privana, you are going to be introduced to an exclusive
                  residential haven that combines luxury, comfort, and
                  tranquility giving you complete peace of mind. Located in the
                  prime location of Gurgaon, DLF Privana is living evidence of
                  DLF’s legacy of crafting exceptional living spaces based on
                  the unique requirements of buyers. With careful attention to
                  detail and a commitment to creating a world-class living
                  experience, this property is all set to give a new definition
                  to luxurious urban living.
                </p>
              </div>
            </div>
          </div>
          <div className="iconbox-style1 mt-5">
            <div className="row ">
              <div className="col-lg-6">
                <h3 className="mb-4">Save the Date – It’s Launching Soon!</h3>
                <p>We are as thrilled as you.</p>
                <p>
                  Though the DLF Privana launch date is still a mystery,
                  spilling a few beans, we can say that it is going to launch
                  very soon and your wait shall come to an end. So, we suggest
                  you mark your calendars and be prepared for the launch well in
                  advance. So, if you do not wish to miss out on a chance to be
                  a part of this exquisite community where luxury meets comfort
                  and your privacy is given a preference, then make sure to
                  check the DLF Privana launch date on a regular basis. For more
                  convenience, you can get in touch with us and we’ll be more
                  than happy to notify you about the same.
                </p>
                <p>
                  So, get ready to embark on a journey that transcends the
                  ordinary and gives your lifestyle that much-needed upgrade you
                  have been waiting for since long.
                </p>
              </div>
              <div className="col-lg-6">
                <div className="dlf--banner">
                  <img src={bannerdf2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Dlfpriivana;
