import img1 from "../../../assets/Project/Center/1.jpg";
import img2 from "../../../assets/Project/Center/2.jpg";
import img3 from "../../../assets/Project/Center/3.jpg";



const CentralParkData = [
  {
    id: "1",
    name: "Central Park Flower Valley",
    location: "Sector 49, Gurgaon",
    img: img1,
    desc: "Central Park Flower valley offers a bouquet of fine choices with a huge variety of residential options in this Quintessential global township. They provide an extraordinary life experience in their exclusively designed towers for their residences such as Flamingo floors, Cerise floors, Clovers floors, and Aqua front towers.    ",
  },
  {
    id: "2",
    name: "Central Park 2 Bellevue",
    location: "Sector 48, Gurgaon",
    img: img2,
    desc: "Central Park II B, located at Sector 48, Sohna Road, Gurgaon, is an ideal home for you and your family. This appealing project is built and designed by one of the biggest architectural firms Hellmuth, Obata and Kassabaum International; on over forty-eight acres of land Central Park II B can easily be reached by using these roads: Hero Honda Chowk and Sohna road.    ",
  },
  {
    id: "3",
    name: "Central Park 2 The Room",
    location: "Sector 48, Gurgaon",
    img: img3,
    desc: "Unparalleled in its excellent amenities and its design and very much a part of the city lifestyle, the property at Central Park aces the location card like no other property in its genre. Affording its residents a high quality lifestyle is bound to The Room’s trump card. Drop by The Room if you too, want to be a winner!    ",
  }
];

export default CentralParkData;
