import img1 from "../../../assets/Project/M3M/1.jpg"
import img2 from "../../../assets/Project/M3M/2.jpg"
import img3 from "../../../assets/Project/M3M/3.jpg"
import img4 from "../../../assets/Project/M3M/4.jpg"
import img5 from "../../../assets/Project/M3M/5.jpg"
import img6 from "../../../assets/Project/M3M/6.jpg"
import img7 from "../../../assets/Project/M3M/7.jpg"
import img8 from "../../../assets/Project/M3M/8.jpg"

const M3MData = [
    {
        id: "1",
        name: "M3M Golf Hills",
        location: "Sector 79, Gurgaon",
        img: img1,
        desc: "M3M Golf Hills Sector 79 Gurgaon is one of the ultra-luxurious residential projects in the city. It is an ideal property by renowned developers M3M to offer a lavish lifestyle with top-notch amenities. It is sprawling in approx 55+ acres of land and boasts an executive golf course with nine holes, stunning high-rise towers, landscaped gardens, "
    },
    {
        id: "2",
        name: "M3M Crown",
        location: "Sector 111, Gurgaon",
        img: img2,
        desc: "MM3M Crown Sector 111, Gurgaon is an ultra-luxurious residential property located near Dwarka Expressway. This property is exclusively designed by M3M India, and it is known for setting a high-scale benchmark in the real estate sector."
    },
    {
        id: "3",
        name: "M3M Antalya Hills",
        location: "Sector 79, Gurgaon",
        img: img3,
        desc: "M3M Antalya Hills is a luxury residential project in the prime region of the city, Sector 79 Gurgaon. This is an under-development property that covers almost 10 acres of land in phase 1. It is located at Sector 79, IMT Manesar, near Naurangpur Road. "
    },
    {
        id: "4",
        name: "M3M Capital",
        location: "Sector 113,Bajghera, Gurgaon",
        img: img4,
        desc: "M3M Capital M3M Capital, another residential high-rise development, offers your dream home or apartment with modern facilities and luxury amenities. M3M Capital Gurgaon offers lavish, well-designed, 2.5, 3.5, and 4.5 BHK apartments."
    },
    {
        id: "5",
        name: "M3M SCO 114 Market",
        location: "Sector 114, Gurgaon",
        img: img5,
        desc: "M3M SCO 114 Market is a standout shop cum office space by M3M Group. It is a perfect mixture of dynamic shops & stunning office spaces. One of the modern commercial complexes, M3M SCO 114 Market hosts fine dining options, fun zones, studio condos, & recreational facilities. From a remarkable entrance to an adequate car parking facility, M3M 114 Market has all high-end features to attract customers."
    },
    {
        id: "6",
        name: "M3M City Heights",
        location: "Sector 65, Gurgaon",
        img: img6,
        desc: "M3M City Heights is a luxury residential project by M3M in Sec-65, Gurgaon on Golf Course Extension Road. M3M City Heights offers 2BHK & 2BHK + Study apartment of 1261 sqft. & 1433 sqft. M3M City heights have many luxury amenities like Lift, Rooftop Cafe, an Intelligent home access control system, etc."
    },
    {
        id: "7",
        name: "M3M Merlin",
        location: "Sector 67, Gurgaon",
        img: img7,
        desc: "M3M Merlin is one of the High-End Residential Projects designed to fulfill the ever-growing Needs of the Professional Living Community. M3M Merlin brings the Singapore Style 3 and 4 BHK Apartments in Sector-67 Gurgaon with sizes from 2019 to 5790 Sq. Ft. It is spread over a Built-up Area of 13.34 Acres with a total of 510 Apartments."
    },
    {
        id: "8",
        name: "M3M Golf Estate",
        location: "Sector 66, Gurgaon",
        img: img8,
        desc: "M3M Golf Estate is the High-End Residential Project by M3M Group. This Lavish Residential Project is on the Sector-65 Golf Course Extension Road Gurgaon. It is a combination of Low-Rise, Mid-Rise, and High-Rise Towers. Here, M3M presents the Spacious 3BHK, 4BHK and 5BHK Apartments, Duplex, and Penthouses with a range of sizes from 3005 Sq. Ft. to 5760 Sq. Ft."
    },
    
]

export default M3MData;