import React,{ useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { FaAngleDown, FaPhone } from "react-icons/fa";
import Logo from "../../assets/img/Logo.png";
import "../Layout/NavbarStyles.css";
import Gallery from "../Data/gallery";

function Header() {
  const [scroll, setScroll] = useState(false);


useEffect(() => {
  window.addEventListener("scroll", () => {
    setScroll(window.scrollY > 10);
  });
});
  return (
    <>
      {/* <div className={color ? "header header-bg " : "header "} style={headers}>
        <div className="container navbar--flex">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="" class="desktop-item">
                Dropdown Menu
              </Link>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" class="mobile-item">
                Dropdown Menu
              </label>
              <ul class="drop-menu">
                <li>
                  <Link to="">Drop menu 1</Link>
                </li>
                <li>
                  <Link to="">Drop menu 2</Link>
                </li>
                <li>
                  <Link to="">Drop menu 3</Link>
                </li>
                <li>
                  <Link to="">Drop menu 4</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="" class="desktop-item">
                Mega Menu
              </Link>
              <input type="checkbox" id="showMega" />
              <label for="showMega" class="mobile-item">
                Mega Menu
              </label>
              <div class="mega-box">
                <div class="content">
                  <div class="row">
                    <img
                      src="https://fadzrinmadu.github.io/hosted-assets/responsive-mega-menu-and-dropdown-menu-using-only-html-and-css/img.jpg"
                      alt=""
                    />
                  </div>
                  <div class="row">
                    <header>Design Services</header>
                    <ul class="mega-links">
                      <li>
                        <Link to="">Graphics</Link>
                      </li>
                      <li>
                        <Link to="">Vectors</Link>
                      </li>
                      <li>
                        <Link to="">Business cards</Link>
                      </li>
                      <li>
                        <Link to="">Custom logo</Link>
                      </li>
                    </ul>
                  </div>
                  <div class="row">
                    <header>Email Services</header>
                    <ul class="mega-links">
                      <li>
                        <Link to="">Personal Email</Link>
                      </li>
                      <li>
                        <Link to="">Business Email</Link>
                      </li>
                      <li>
                        <Link to="">Mobile Email</Link>
                      </li>
                      <li>
                        <Link to="">Web Marketing</Link>
                      </li>
                    </ul>
                  </div>
                  <div class="row">
                    <header>Security services</header>
                    <ul class="mega-links">
                      <li>
                        <Link to="">Site Seal</Link>
                      </li>
                      <li>
                        <Link to="">VPS Hosting</Link>
                      </li>
                      <li>
                        <Link to="">Privacy Seal</Link>
                      </li>
                      <li>
                        <Link to="">Website design</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to="/Properties">Properties</Link>
            </li>

            <li>
              <Link to="/About">About</Link>
            </li>

            <li>
              <Link to="/Contact">Contact</Link>
            </li>
            <li>
              <Link to="/Map">Map</Link>
            </li>
          </ul>

          <div className="hamburger" onClick={handleClick}>
            {click ? (
              <FaTimes size={20} style={{ color: "#fff" }} />
            ) : (
              <FaBars size={20} style={{ color: "#000" }} />
            )}
          </div>
        </div>
      </div> */}
      <nav className={`headerMain ${scroll ? "sticky" : ""}`}>
        <div class="wrapper">
          <div class="logo">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul class="nav-links">
            <label for="close-btn" class="btn close-btn">
              <i class="fas fa-times"></i>
            </label>
            <li>
              <Link to="/">Home</Link>
            </li>

            <li>
              <Link to="" class="desktop-item">
                Residential
                <FaAngleDown />
              </Link>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" class="mobile-item">
                Residential
                <FaAngleDown />
              </label>
              <ul class="drop-menu">
                <li>
                  <Link to="/ResidentialProjects/GolfCourseRoad">Golf Course Road</Link>
                </li>
                <li>
                  <Link to="/ResidentialProjects/DwarkaExpressway">Dwarka Expressway</Link>
                </li>
                <li>
                  <Link to="/ResidentialProjects/GolfExtensionRoad">Golf Course Extension Road</Link>
                </li>
                <li>
                  <Link to="/ResidentialProjects/SohnaRoad">Sohna Road</Link>
                </li>
                <li>
                  <Link to="/gurgaon">Gurgaon</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="" class="desktop-item">
                Commercial
                <FaAngleDown />
              </Link>
              <input type="checkbox" id="showDrop1" />
              <label for="showDrop1" class="mobile-item">
                Commercial
                <FaAngleDown />
              </label>
              <ul class="drop-menu">
                <li>
                  <Link to="/CommercialProjects/MGRoad">MG Road</Link>
                </li>
                <li>
                  <Link to="/CommercialProjects/GolfCourseExtensionRoad">Golf Course Extension Road</Link>
                </li>
                <li>
                  <Link to="/CommercialProjects/GolfCourseRoad">Golf Course Road</Link>
                </li>
                <li>
                  <Link to="/CommercialProjects/DwarkaExpressway">Dwarka Expressway</Link>
                </li>
                <li>
                  <Link to="/CommercialProjects/SohnaRoad">Sohna Road</Link>
                </li>
                <li>
                  <Link to="/GurgaonCommercial">Gurgaon</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="" class="desktop-item">
                Gurgaon Map
                <FaAngleDown />
              </Link>
              <input type="checkbox" id="showDrop2" />
              <label for="showDrop2" class="mobile-item">
                Gurgaon Map
                <FaAngleDown />
              </label>
              <ul class="drop-menu">
                <li>
                  <Link to="/GurgaonMasterPlan">Gurgaon Master Plan</Link>
                </li>
                <li>
                  <Link to="/Map">Gurgaon Sector Maps</Link>
                </li>
                <li>
                  <Link to="/DwarkaExpresswayMap">Dwarka Expressway Map</Link>
                </li>
                <li>
                  <Link to="/SohnaMasterPlan">Sohna Road Master Plan</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="" class="desktop-item">
                Builders
                <FaAngleDown />
              </Link>
              <input type="checkbox" id="showMega" />
              <label for="showMega" class="mobile-item">
                Builders
                <FaAngleDown />
              </label>
              <div class="mega-box">
                <h3 className="title-heading-service">
                  Residential Properties
                </h3>
                <div class="content">
                  {Gallery.map((item) => {
                    return (
                      <Link to={`/${item.link}`} class="row">
                        <img src={item.img} alt="" />
                      </Link>
                    );
                  })}
                </div>
              </div>
            </li>
            <li>
              <Link to="/About">About Us</Link>
            </li>
            <li>
              <a href="tel:9810010295" style={{ color: "#BE9040" }}>
                <FaPhone
                  style={{ transform: "rotate(85deg)", color: "#BE9040" }}
                />
                +91-9810010295
              </a>
            </li>
          </ul>
          <label for="menu-btn" class="btn menu-btn">
            <i class="fas fa-bars"></i>
          </label>
        </div>
      </nav>
    </>
  );
}

export default Header;
