import img1 from "../../../assets/Project/ResidentialProjects/SohnaRoad/1.jpg"
import img2 from "../../../assets/Project/ResidentialProjects/SohnaRoad/2.jpg"
import img3 from "../../../assets/Project/ResidentialProjects/SohnaRoad/3.jpg"
import img4 from "../../../assets/Project/ResidentialProjects/SohnaRoad/4.jpg"
import img5 from "../../../assets/Project/ResidentialProjects/SohnaRoad/5.jpg"
import img6 from "../../../assets/Project/ResidentialProjects/SohnaRoad/6.jpg"
import img7 from "../../../assets/Project/ResidentialProjects/SohnaRoad/7.jpg"
import img8 from "../../../assets/Project/ResidentialProjects/SohnaRoad/8.jpg"
import img9 from "../../../assets/Project/ResidentialProjects/SohnaRoad/9.jpg"




const dataMgroadRes = [
    {
        id: "1",
        name: "Godrej Nature Plus Gurgaon",
        img: img1,
        location: " Sohna, Gurgaon",
        desc: "Godrej Nature Plus: A development with modern craftsmanship and the beauty of nature for the life you always wanted! Gurgaon is a fabulous city and part of the National Capital Region that is constantly evolving every day. There is so much in this city beyond what meets the eye! Today, Gurgaon is one of the most famous cities in the country."
    },
    {
        id: "2",
        name: "Ashiana Anmol",
        img: img2,
        location: "  Sector 33, Gurgaon",
        desc: "Ashiana Anmol is a high-class residential project that offers, designed and luxurious living homes, apartments, and flats to each family with numerous ranges and sizes starting from 780 to 1262 sq ft. This premium residency is another luxury project which features high-rise apartments & flats that offers you 2 and 3 BHK apartments with an open wide area with lush greens gardens and plants."
    },
    {
        id: "3",
        name: "Central Park Flower Valley",
        img: img3,
        location: " Sector 49, Gurgaon",
        desc: "Central Park Flower valley offers a bouquet of fine choices with a huge variety of residential options in this Quintessential global township. They provide an extraordinary life experience in their exclusively designed towers for their residences such as Flamingo floors, Cerise floors, Clovers floors, and Aqua front towers."
    },
    {
        id: "4",
        name: "Aradhya Homes",
        img: img4,
        location: " Sector 67, Gurgaon",
        desc: "Aradhya Homes is a new residential project located in Sector 67A of Gurgaon. The project offers 4 BHK + 3T and 4 BHK + 4T spacious Low Rise Builder Floors which are equipped with modern amenities to ensure a life of convenience and comfort.The floor plan of Aradhya Homes Gurgaon includes Low Rise Builder Floor, "
    },
    {
        id: "5",
        name: "Eldeco Acclaim",
        img: img5,
        location: " sector 2 sohna road Gurgaon",
        desc: "Eldeco acclaim Gurgaon is an upper-class residential project renowned by Sobha group located in sector 2 sohna road, Gurgaon. The area of this spacious property comes with numerous ranges and sizes starting from 1381 to 2343 sq ft."
    },
    {
        id: "6",
        name: "Central Park 2 Bellevue",
        img: img6,
        location: " Sector 48, Gurgaon",
        desc: "Central Park II B, located at Sector 48, Sohna Road, Gurgaon, is an ideal home for you and your family. This appealing project is built and designed by one of the biggest architectural firms Hellmuth, Obata and Kassabaum International; on over forty-eight acres of land Central Park II B can easily be reached by using these roads: Hero Honda Chowk and Sohna road."
    },
    {
        id: "7",
        name: "Central Park 2 The Room",
        img: img7,
        location: " Sector 48, Gurgaon",
        desc: "When expectations are a lot higher than the ordinary, it’s your outlook that sets you apart. Presenting ‘The Room’. A concept that will change the way you look at the concept of studio apartments."
    },
    {
        id: "8",
        name: "Orchid Petals",
        img: img8,
        location: " Sector 49, Gurgaon",
        desc: "Orchid Petals is an excellent Project developed by one of the Gurgaon well-known Developers Orchid Infrastructure Developers. It is spread across 37 acres."
    },
    {
        id: "9",
        name: "Signature Global Park 4&5",
        img: img9,
        location: " Sector 36, Gurgaon",
        desc: "The Signature Group has brought breathtaking residential projects in the millennium city of Gurgaon with their Global Parks 4&5 being the most talked about in town."
    },
]

export default dataMgroadRes;