import img1 from "../../../assets/Project/AMB/1.jpg";
import img2 from "../../../assets/Project/AMB/2.jpg";


const AMBData = [
  {
    id: "1",
    name: "AMB Selfie Street",
    location: "Sector 90, Gurgaon",
    img: img1,
    desc: "AMB Selfie Street is a turnkey commercial solution. It is the premier type of base for your unique & creative business in Gurgaon. This is an iconic complex that offers a prestigious address in the upcoming suburb of the city.    ",
  },
  {
    id: "2",
    name: "AMB Selfie Square",
    location: "Sector 37-D, Gurgaon",
    img: img2,
    desc: "AMB Selfie Square is the High-End Commercial Project in Sector-37D Gurgaon offering Stunning Offices Spaces, Retail Shops, Food Courts, Multiplex and more with the Top Notch Amenities. It is spread over an area of 4 acres with a total of 460 Units.    ",
  }
];

export default AMBData;
