import img1 from "../../../assets/Project/Sobha/1.jpg";


const SobhaData = [
  {
    id: "1",
    name: "Sobha City Gurgaon",
    loc: "Sector 108, Gurgaon",
    img: img1,
    desc: "Sobha City is nothing less than the most luxurious residential complex in Gurgaon. This multi-story community features lavish 2 & 3 BHK apartments with utility space and servant quarters. The size of a 2 BHK home starts from 1381 sq. ft. whereas a 3 BHK premium apartment spans 1711 sq. ft. These are the ideal homes with maximum space, here you can live a premier lifestyle while keeping your head parallel to the sky.    ",
  },
  
];

export default SobhaData;
