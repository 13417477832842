import React from "react";
import Layout from "../../Layout/Layout";
import dlfBanne from "../../../assets/Project/DLF/banner.jpg";
import DlfData from "../../Data/ListData/Dlf";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TabTitle } from "../../../functionTitle";

function DLFListing() {
  TabTitle('Jeet Estate | DLF Projects')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
            <div className="projectbanner">
                <img src={dlfBanne} alt=""/>
            </div>
          <div className="card prop-main p-4">
            <p>
              Delhi Land and Finance is known as DLF. This firm has gained
              immense success in the real estate industry since its inception.
              DLF Limited has 75 years of experience in developing, investing, &
              managing world-class structures. It was founded in 1946 by
              Chaudhary Raghvendra Singh and created 22 suburbs in Delhi. DLF
              started its operations in Gurgaon in 1985 and has since developed
              commercial and residential projects for the community of Millenium
              city. This is an ideal brand that offers you exceptional living
              and work space with state of art features. Today, DLF is in 15
              states of India with unbelievable and highly advanced complexes.
            </p>
            <div className="row">
                {
                    DlfData && DlfData.map((items) =>{
                        return(
                            <div className="col-lg-4 mb-3">
                      <Link to="#" className="box--project">
                        <div className="card prop-main p-4">
                          <span>
                            <FaLocationArrow />
                            {items.loc}
                          </span>
                          <div className="img--box">
                            <img src={items.img} alt={items.name} />
                          </div>
                          <h3>{items.name}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </Link>
                    </div>
                        )
                    })
                }
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default DLFListing;
