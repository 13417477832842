import img1 from "../../assets/Project/golfcourseData/1.jpg"
import img2 from "../../assets/Project/golfcourseData/2.jpg"
import img3 from "../../assets/Project/golfcourseData/3.jpg"
import img4 from "../../assets/Project/golfcourseData/4.jpg"
import img5 from "../../assets/Project/golfcourseData/5.jpg"
import img6 from "../../assets/Project/golfcourseData/6.jpg"
import img7 from "../../assets/Project/golfcourseData/7.jpg"
import img8 from "../../assets/Project/golfcourseData/8.jpg"
import img9 from "../../assets/Project/golfcourseData/9.jpg"







const golfcourseData = [
    {
        id: "1",
        name: "Centrum Plaza",
        img: img1,
        location: " Sector 53, Gurgaon",
        desc: "Centrum Plaza is a Commercial Project on Sector-53 Golf Course Road Gurgaon which is next to the Famous Audi Building. This Amazing Commercial Project is offering to the Ever Growing Needs of Fully Furnished Coworking Spaces, Office Spaces and Retail Shops."
    },
    {
        id: "2",
        name: "Paras Twin Tower",
        img: img2,
        location: " Sector 54, Gurgaon",
        desc: "Paras Twin Tower is a Commercial Office Space Building on the Sector-54 Golf Course Road Gurgaon by the Paras Buildtech. This Building is a combination of Business and Leisure Amenities. Paras Twin Towers comprise of G+11 and G+3 Floors in combination with basements of 3 different levels."
    },
    {
        id: "3",
        name: "Vatika Atrium",
        img: img3,
        location: " Sector 53, Gurgaon",
        desc: "Vatika Atrium is a High-End Commercial Project by the Vatika Developers which is offering 2,20,000 Sq. Ft. of Fully Furnished Premium Commercial Office Space and the Coworking Spaces on the Two Towers and over six floors."
    },
    {
        id: "4",
        name: "Vipul Plaza",
        img: img4,
        location: " Sector 54, Gurgaon",
        desc: "Vipul Plaza is a Commercial Project on Sector-54 Golf Course Road in the Suncity Township.Vipul Plaza Suncity is equipped with Superior Construction Quality, Bigger Floor Plates with the Latest Amenities and Features."
    },
    {
        id: "5",
        name: "Global Foyer",
        img: img5,
        location: " Sector 43, Gurgaon",
        desc: "Global Foyer Mall Gurgaon is a High-End Ready to Move Commercial Project in Sector-43 Golf Course Road Road Gurgaon. Here, It presents the Commercial-Mix including Commercial Office Space, Retail Shops, Restaurants and lot more. This Project is on the 6.7 Lac Sq. Ft. Area with 2.2 Lac Sq Ft for the Retail Spaces."
    },
    {
        id: "6",
        name: "Suncity Business Tower",
        img: img6,
        location: " Sector 54, Gurgaon",
        desc: "Suncity Business Tower is a High-End Commercial Project on Sector-54 Gurgaon with the Exclusive Fully Furnished Commercial Office Spaces with the range of sizes starts from 1,000 Sq. Ft. with Abundant of Greenery for Better and Comfortable Work Environment. It is built over an area of 2.15 lac Sq. Ft."
    },
    {
        id: "7",
        name: "Emaar MGF Palm Spring Plaza",
        img: img7,
        location: " Sector 54, Gurgaon",
        desc: "Emaar Palm Springs Plaza is an Exclusive Commercial Project on Sector-54 Golf Course Road Gurgaon which is The Most Desirable Location of Gurgaon. This Project is near to the Emaar Palm Spring which gives a remark of Lavish and the Extravagance Amenities in their Villas and Apartments Towers. Emaar MGF The Palm Springs Plaza is a Mix of the Premium Office Spaces and the Luxury Retail Spaces."
    },
    {
        id: "8",
        name: "DLF – One Horizon Center",
        img: img8,
        location: " Sector 43, Gurgaon",
        desc: "DLF Horizon Center is one of the Amazing Commercial Project by the Renowned DLF Developers. The Commercial Project- DLF Horizon Cente is on one of the Lavish Location of Gurgaon- Sec-43 Golf Course Road. This Project is done by the Collaboration of DLF with the Hines."
    },
    {
        id: "9",
        name: "Baani The Address",
        img: img9,
        location: " Sector 56, Gurgaon",
        desc: "Baani The Address One is a Commercial Building on the Sector-56 Golf Course Road Gurgaon. Here, They presents the Fully Furnished Premium Office Spaces and the Retail Shops. This Project is on the Prime Location of Golf Course Road."
    },
]

export default golfcourseData;