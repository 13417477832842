import React from "react";
import Layout from "../../Layout/Layout";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaLocationArrow } from "react-icons/fa";
import golfcourseData from "../../Data/golfcourseroad";
import { TabTitle } from "../../../functionTitle";
function GolfCourseRoad() {
  TabTitle('Jeet Estate | Golf Course Road')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              Golf Course
              <span style={{ color: "rgb(190, 144, 64)" }}> Road</span>
            </h2>
            <p>
              Gurgaon is the financial hub of India and Golf Course Road is one
              of its arms. It is the most sought-after location that is a
              landmark in the city as well as in the Nation. Golf Course Road is
              a prime motorway, starting from Sikanderpur to Golf Course
              Extension Road (Sector 54). Some of the new sectors which are in
              the race for development are Sector 61, 62, 65, 66, & Sector 67.
              These areas are becoming the hotspot and boast stunning business
              of Gurgaon. Commercial developments which are offering Shops or
              Office Space on rent or sale are Centrum Plaza, Vatika Atrium,
              Paras Twin Tower, Vipul Plaza, Global Foyer, Suncity Business
              Tower, Emaar MGF Plaza, DLF- One Horizon Center, & Baani The
              Address.
            </p>
            <div className="row">
              {golfcourseData.map((items) => {
                return (
                  <div className="col-lg-4 mb-3">
                    <Link to="#" className="box--project">
                      <div className="card prop-main p-4">
                      <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                        <div className="img--box">
                          <img src={items.img} alt={items.name} />
                          
                        </div>
                        <h3>{items.name}</h3>
                        <p>{items.desc}</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
            <h4>
              About
              <span style={{ color: "rgb(190, 144, 64)" }}>
                Golf Course Road Gurgaon
              </span>
            </h4>

            <p>
              Golf Course Road is a 9 km long stretch in the heart of Millenium
              City Gurgaon. It is a prime motorway that connects DLF Phase 1,
              DLF Phase 2, DLF Phase 5, Sector 42, Sector 43, Sector 53, Sector
              54, & Golf Course Extension Road. This region is among the posh
              areas with thriving and stunning infrastructure development. There
              is a wide variety of residential and commercial properties on the
              golf course road. You can explore and book them exclusively at
              Gupta promoters at an unbeatable price.
            </p>
            <p>
              Golf Course Road is one of the expansive localities of the city.
              It connects Bristol Chowk of Sector 26 to Sector 56 and Golf
              Course Extension Road. In addition, this is a premier locality
              with a variety of schools, shopping malls, recreational
              facilities, office spaces, IT hubs, & a lot more. We offer you
              various commercial projects on Golf Course Road within a 4 km
              radius of the residential hub. Having an office or retail space
              near them is nothing less than a boon for your business. Try this
              locality and buy a property on Golf Course Road now with Gupta
              Promoters.
            </p>
            <h4>
              Buy Commercial Property on
              <span style={{ color: "rgb(190, 144, 64)" }}>
                Golf Course Road Gurgaon
              </span>
            </h4>

            <p>
              Golf Course Road is one of the finest localities in the city. It
              is an iconic hub in Gurgaon, NCR, India, & even in Asia. This road
              is surrounded by the best localities, making it a hotspot for
              business. Various national & international builders are attracted
              to this region and regularly introduce world-class commercial
              projects on Golf Course Road. In addition, the price of the
              property is also rapidly increasing in this area. It witnesses an
              immense growth of 20% even after the pandemic. Commercial
              properties on Golf Course Road are not just space for your
              business but an opportunity to grab. Explore & compare our
              hand-picked property on Golf Course Road now!
            </p>
            <h4>
              Top Properties on
              <span style={{ color: "rgb(190, 144, 64)" }}>
                Golf Course Road Gurgaon
              </span>
            </h4>
            <ul typeof="number">
              <li>AIPL Autograph</li>
              <li>Iris Tech Park</li>
              <li>Spaze Platinum</li>
              <li>JMD IT Megapolis</li>
              <li>Vipul Trade Centre</li>
            </ul>
            <h5>
              1.
              <span style={{ color: "rgb(190, 144, 64)" }}>
                DLF- One Horizon Center
              </span>
            </h5>
            <p>
              DLF- One Horizon Center is one of the iconic offerings by the DLF
              group. It is a lavish business complex with premium office spaces.
              This wonderful commercial property on Golf Course Road Gurgaon is
              in the vicinity of thriving developments of the locality like; DLF
              Pinnacle, Magnolias, Aralias, and Park Place. In this complex you
              will find destinations like; Retail Shops, Restaurants, Cafes, &
              fully furnished amenities loaded with Office Space.
            </p>
            <h5>
              2.
              <span style={{ color: "rgb(190, 144, 64)" }}>
                Emaar MGF Palm Spring Plaza
              </span>
            </h5>
            <p>
              Emaar MGF Palm Spring Plaza is an exclusive commercial property
              for rent on Golf Course Road, Gurgaon. It has a world-class office
              & retail space in various sizes. In addition, Emaar MGF Palm
              Spring Plaza is one of the best destinations in Millenium city for
              shopping, eating, recreation, & business. It is a place near
              luxury residential complexes, hospitals, and schools, & in the
              iconic business district of Gurgaon, Sector 54.
            </p>
            <h5>
              3.
              <span style={{ color: "rgb(190, 144, 64)" }}>
                Paras Twin Tower
              </span>
            </h5>
            <p>
              Paras Twin Tower is one of the best commercial properties on Golf
              Course Road. It is an elegant premise in Sector 54 near Sector 54
              Chowk Rapid Metro Station. This complex is an ideal office space
              along with international office standards. Here you can reserve
              your office from 920 sq. ft. area to 15,000 sq. ft. Explore Paras
              Twin Tower now and book your space exclusive with Gupta Promoters.
            </p>

            <h5>
              4.
              <span style={{ color: "rgb(190, 144, 64)" }}>Vatika Atrium</span>
            </h5>
            <p>
              Vatika Atrium is among the high-end commercial property on Golf
              Course Road. It is a premier type space in Sector 53 near Central
              Plaza Mall. This complex has a variety of stunning office suites
              along with a coworking space. It offers you all the business
              amenities and facilities that are ideal for the rapid growth of
              your modern business.
            </p>
            <h5>
              5.
              <span style={{ color: "rgb(190, 144, 64)" }}>Vipul Plaza</span>
            </h5>
            <p>
              Vipul Plaza is nothing less than an excellent commercial property
              on Golf Course Road. It’s a very popular spot spread over 3 acres
              of land. This complex has a constructed area of ​​210,000 sq. ft.
              with 6 elevated floors, excluding ground parking levels. With us,
              you can book different types of office spaces with A-class
              services at Vipul Plaza at an exceptional price.
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default GolfCourseRoad;
