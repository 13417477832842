import React from "react";
import Layout from "../../Layout/Layout";
import dataMgroad from "../../Data/Mgroad";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaLocationArrow } from "react-icons/fa";
import { TabTitle } from "../../../functionTitle";

function MGRoad() {
  TabTitle('Jeet Estate | MG Road')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              MG
              <span style={{ color: "rgb(190, 144, 64)" }}> Road</span>
            </h2>
            <p>
              MG Road is one of the historical places in Gurgaon. It is one of
              the prime suburbs that has all walks of life. MG Road is one of
              the biggest commercial spaces that host thriving shopping malls,
              business parks, retail outlets, & a lot more places that attract
              the community of Millenium City. In addition, MG Road is an iconic
              development in the heart of Gurgaon. It is linked with a seamless
              road network and has a metro station that connects it from various
              regions of NCR. This region has an A-grade social and retail
              infrastructure that attracts investors, shop buyers, and consumers
              from all around. Some of the widely popular developments in this
              area are Vipul Agora, MGF Metropolis, & ABW Tower. You can buy or
              rent a shop or office space to showcase your brand or to scale up
              your business to the next level.
            </p>
            <div className="row">
              {dataMgroad && dataMgroad.map((items) => {
                return (
                  <div className="col-lg-4">
                    <Link to={`/CommercialProjects/MGRoadDeatils/${items.id}/${items.link}`} className="box--project">
                      <div className="card prop-main p-4">
                      <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                        <div className="img--box">
                          <img src={items.img} alt={items.name} />
                          
                        </div>
                        <h3>{items.name}</h3>
                        <p>{items.desc}</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>

            <hr />
            <h4>
              About
              <span style={{ color: "rgb(190, 144, 64)" }}>
                MG Road Gurgaon
              </span>
            </h4>
            <p>
              Are you in search of a professionalised yet quirky commercial
              property on MG, Road Gurgaon to run your business? Then you have
              arrived at the correct place as we at Jeet Estate Consultant have
              a wide range of Commercial properties for sale in MG Road,
              Gurgaon. This location is a major commercial hub of Gurgaon and a
              home to well-known software companies, too. It has an extensive
              range of reputable businesses, and first-rate infrastructure along
              with accessibility to adjacent shopping complexes, food courts,
              malls, restaurants, medical facilities, hospitals, and more.
            </p>
            <p>
              Is your firm in search of well-built commercial space on MG Road,
              Gurgaon? This entire location is flooded with a huge variety of
              commercial spaces used for coworking setups, malls, hotels,
              restaurants, and more. This location has excellent access to all
              the parts of the city via 148A Road. Moreover, it is close to all
              public modes of transit and Sikandarpur metro station(Yellow Line)
              i.e. only 1km away from amazing commercial projects on MG Road,
              Gurugram. This road is well-connected to the Railway station and
              Indira Gandhi National Airport too. Explore this highly
              professionalized locality and find a suitable commercial property
              for rent in MG Road, Gurgaon, to expand your business or for a
              startup business. For more relevant information about commercial
              property on MG Road, Gurgaon gain insights from Jeet Estate
              Consultant.{" "}
            </p>
            <h4>
              Buy Commercial
              <span style={{ color: "rgb(190, 144, 64)" }}>
                Property on MG Road, Gurgaon
              </span>
            </h4>
            <p>
              Wondering to expand your business or start a new one? All you need
              is a professional and affordable workspace to begin or grow your
              journey of business. A well-constructed commercial property for
              sale in MG Road, Gurgaon by Gupta Promoter, is waiting for you to
              begin your success journey. Commercial space on MG Road, Gurgaon
              comes in all shapes & sizes, which can accommodate numerous
              groups. Moreover, purchasing a commercial property for rent in
              Mehrauli Gurgaon Road, Gurugram is not that difficult or
              time-consuming now and all you need are your firm documents for
              it.{" "}
            </p>
            <p>
              Buying a commercial property is not only a dream but an investment
              for your life. No need to get carried away or tensed about buying
              a property space, as Jeet Estate Consultant always stand beside
              you with the best property advice. All the commercial properties
              for sale in MG Road, Gurgaon are equipped with basic to modern
              amenities for your convenience. All you need to do is to explore a
              variety of commercial properties offered by Jeet Estate Consultant
              and choose the one which fits your business the best. Moreover,
              all the commercial spaces on MG Road, Gurgaon, are within the
              periphery of the best malls, food courts, restaurants, cineplexes
              and with greenery & fresh air all around. Investing in commercial
              property on MG Road, Gurgaon for your business will help you to
              achieve huge profits with a better reach.
            </p>
            <h4>
              Top Properties on
              <span style={{ color: "rgb(190, 144, 64)" }}>
                MG Road Gurgaon
              </span>
            </h4>

            <p>
              Jeet Estate Consultant is ready to offer an exclusive range of
              commercial properties for sale in MG Road, Gurugram. All the
              commercial spaces are well-equipped with basic to modern
              amenities. If you are planning to start your own business in a
              physical location, then MG Road, Gurgaon has plenty of commercial
              properties which are highly business oriented. The location has
              various categories of commercial properties for many purposes such
              as industrial, healthcare, hotels, warehouses, retail, office
              buildings, coworking spaces, and more.{" "}
            </p>
            <p>
              Moreover, all the top commercial projects on MG Road, Gurugram,
              are highly professional with a vibrant environment. This location
              is home to plenty of malls, such as MGF Metropolitan mall, Essel
              Towers, and DLF Beverly Park, Essel Towers along with many food
              joints, cafes, institutions, residential complexes, etc. If you
              are expanding your business, then your success depends upon making
              a correct decision, i.e. choosing the perfect commercial space on
              MG Road, Gurgaon. Before making a final purchase of a property on
              MG Road, Gurugram you should check the access route,
              customizability of space, on-site facilities, and local amenities
              provided. Some of the top listed commercial properties for sale in
              MG Road, Gurugram by Jeet Estate Consultant, are ABW Tower, Vipul
              Agora, MGF Metropolis, and many more. This seems to be a perfect
              location for a successful business as it is a commercial hub with
              a variety of businesses and software agencies too. If you want to
              touch the sky with an amazing profit ratio then book your desired
              commercial space with Jeet Estate Consultant now.
            </p>
            <h4>
              Popular Builders on
              <span style={{ color: "rgb(190, 144, 64)" }}>
                MG Road Gurgaon
              </span>
            </h4>
            <p>
              MG Road, Gurgaon, is one of the best suburbs with the biggest
              commercial space sector to attract people from all walks of life.
              The entire commercial sector is home to numerous shopping malls,
              business parks, retail outlets, food courts, cafes, hotels, and
              more. This location is also called a “Millennial city” due to its
              gorgeous nightlife and exciting work culture at the workspaces.
              Moreover, Jeet Estate Consultant is at its peak to offer its
              clients with best commercial property for sale in MG Road, Gurgaon
              to boost their business and profit rate.
            </p>
            <p>
              Commercial space doesn’t mean it needs to have boring interiors
              with traditional infrastructure. All the commercial projects on MG
              Road, Gurgaon, have vibrant interiors with modernized
              infrastructure to grab their clients’ attention. Be a part of this
              lively location and take your business to the next level with one
              and only Jeet Estate Consultant. There is an extensive range of
              real estate properties designed by popular builders such as Arun
              Gaur and CO, Silverglades Group, Allure constructions, Park Town
              Developers, Vivanta Developers, Millennial estates, Sai Real
              Estate, Alliance Realtors, and many more. Select the best
              commercial space which is best for your business and will help you
              to reach higher.
            </p>
            <h4>
              Location Advantage
              <span style={{ color: "rgb(190, 144, 64)" }}>
                MG Road Gurgaon
              </span>
            </h4>
            <p>
              The real-estate sector in this MG Road Gurgaon has expanded
              significantly becoming one of the most well-known localities in
              the entire Gurgaon. Moreover, this is a well-known real estate
              hotspot with plenty of commercial properties for sale on MG Road,
              Gurgaon. The entire city is home to some of the most sought-after
              real estate developers in the entire city. Moreover, MG Road,
              Gurgaon boasts a wide range of commercial properties that are
              exquisitely constructed and well-furnished with all the amenities.
              This location has excellent connectivity with the metro station,
              nearby railway station, IGI Airport, and other modes of transit
              which make the place convenient to reach. All the commercial
              projects on MG Road, Gurgaon, have easy access to Gurgaon and
              other parts of the city, too. All the businessmen and retailers
              out there should consider buying a commercial property in this
              location as it has many advantages added to it. Moreover, this
              entire region has greenery all around with a good air-quality
              index, too. Your business will be a big hit in Gurgaon because of
              a massive millennial & working professional population. Explore
              this lovely location and book your commercial space at Gupta
              Promoters now!{" "}
            </p>
            <h4>
              Why Choose
              <span style={{ color: "rgb(190, 144, 64)" }}>
                Jeet Estate Consultant
              </span>
            </h4>
            <p>
              Jeet Estate Consultant not only provide commercial property for
              sale in MG Road, Gurgaon but they also give amazing business
              advice to startups and those who want to expand their business.
              All the property spaces offered by them are fully furnished and
              equipped with modern amenities. Jeet Estate Consultant welcome all
              kinds of clients who are in desperate need of a perfect commercial
              property for their business.
            </p>
            <p>
              MG Road Gurgaon comprises iconic commercial properties which are
              in the heart of Gurgaon. Moreover, this location has a seamless
              road network and is well-connected with other modes of transit
              too. This real estate company partners with plenty of top builders
              and commercial properties to gain maximum attention from
              customers. One thing is for sure, you can trust us with closed
              eyes as we provide the best commercial spaces to everyone. Our
              entire team will help you to make this huge decision and. make
              sure that your investment with them is worth relying upon. Buying
              a commercial property space requires a lot of courage, dedication,
              and an open heart to fulfill your dreams. Once you are a part of
              Jeet Estate Consultant, there is no going back and your business
              will reach higher.
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default MGRoad;
