import img1 from "../../../assets/Project/Vatika/1.jpg"
import img2 from "../../../assets/Project/Vatika/2.jpg"



const VatikaData = [
    {
        id: "1",
        name: "Vatika Atrium",
        location: "Sector 53, Gurgaon",
        img: img1,
        desc: "Vatika Atrium is a High-End Commercial Project by the Vatika Developers which is offering 2,20,000 Sq. Ft. of Fully Furnished Premium Commercial Office Space and the Coworking Spaces on the Two Towers and over six floors.        "
    },
    {
        id: "2",
        name: "Vatika Professional Point",
        location: "Sector 66, Gurgaon",
        img: img2,
        desc: "Vatika Professional Point is one of the High-End Commercial Projects by Vatika Group in Sector-66 Gurgaon. Here, Vatika presents Stunning Commercial Office Spaces fully enriched with all the Top-Notch Amenities to take your Business to the Next Level. This Amazing Commercial Project is constructed on the Built-up Area of 2.11 Acres.        "
    },
    
    
]


export default VatikaData;