import React from "react";
import Layout from "../../Layout/Layout";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaLocationArrow } from "react-icons/fa";
import DwarkaExpresswayDatalist from "../../Data/DwarkaExpresswayData";
import { TabTitle } from "../../../functionTitle";

function DwarkaExpressway() {

  
  TabTitle('Jeet Estate | Dwarka Expressway ')
  return (
    <>
      <Layout>
        <div className="container  mt-4 mb-4">
          <div className="card prop-main p-4">
            <h2>
              Gurgaon
              <span style={{ color: "rgb(190, 144, 64)" }}>
                {" "}
                Dwarka Expressway
              </span>
            </h2>
            <p>
              Dwarka Expressway is among the top destinations for commercial
              purposes. It is a newly launched project that has ready-to-use
              Office Space & Shops for your modern businesses. This is an
              incredible stretch that connects the community of Delhi & Gurgaon.
              Dwarka Expressway is in the vicinity of IGI Airport, Dwarka, Udyog
              Vihar, & IMT Manesar. In addition, Dwarka Expressway is an
              emerging residential hub. It already hosts a group of 20,000+
              families in widely popular residential developments. A retail Shop
              or an Office Space on Dwarka Expressway is nothing less than a
              boon for your business. Book your unit today with us in Elan
              Mercado, Nimai Place, Conscient One, AMB Selfie Square, Indiabulls
              One 09, Ansal Townwalk, CHB Eway Towers, & Satya The Hive.
            </p>
            <div className="row">
              {DwarkaExpresswayDatalist.map((items) => {
                return (
                  <div className="col-lg-4 mb-3">
                    <Link to="#" className="box--project">
                      <div className="card prop-main p-4">
                      <span>
                            <FaLocationArrow />
                            {items.location}
                          </span>
                        <div className="img--box">
                          <img src={items.img} alt={items.name} />
                          
                        </div>
                        <h3>{items.name}</h3>
                        <p>{items.desc}</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default DwarkaExpressway;
