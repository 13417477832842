import img1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/1.jpg";
import img2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/2.jpg";
import img3 from "../../../assets/Project/ResidentialProjects/golfcourseroad/3.jpg";
import img4 from "../../../assets/Project/ResidentialProjects/golfcourseroad/4.jpg";
import img5 from "../../../assets/Project/ResidentialProjects/golfcourseroad/5.jpg";
import img6 from "../../../assets/Project/ResidentialProjects/golfcourseroad/6.jpg";
import img7 from "../../../assets/Project/ResidentialProjects/golfcourseroad/7.jpg";
import img8 from "../../../assets/Project/ResidentialProjects/golfcourseroad/8.jpg";
import img9 from "../../../assets/Project/ResidentialProjects/golfcourseroad/9.jpg";
import img10 from "../../../assets/Project/ResidentialProjects/golfcourseroad/10.jpg";
import img11 from "../../../assets/Project/ResidentialProjects/golfcourseroad/11.jpg";

// Tulip Monsella
import tulip1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project1/1.jpg";
import tulip2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project1/3.jpg";
import logo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project1/logo.png";

// Vipul Belmonte
import Vipul1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project2/1.jpg";
import Vipul2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project2/2.jpg";
import Vipullogo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project2/logo.jpg";

// Salcon Verandas
import Salcon1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project3/1.jpg";
import Salcon2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project3/2.jpg";
import Salconlogo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project3/logo.jpg";

// Abw La Lagune
import Abw1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project4/1.jpg";
import Abw2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project4/2.jpg";
import Abwlogo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project4/logo.png";

// DLF Aralias
import Aralias1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project5/1.jpg";
import Aralias2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project5/2.jpg";
import Araliaslogo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project5/logo.jpg";

// DLF Belaire
import Belaire1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project6/1.jpg";
import Belaire2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project6/2.jpg";
import Belairelogo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project6/logo.jpg";

// DLF Magnolias
import Magnolias1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project7/1.webp";
import Magnolias2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project7/2.webp";
import Magnoliaslogo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project7/logo.jpg";

// DLF Park
import Park1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project8/1.jpg";
import Park2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project8/2.jpg";
import Parklogo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project8/logo.jpg";

// DLF PINNACLE
import PINNACLE1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project9/1.jpg";
import PINNACLE2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project9/2.jpg";
import PINNACLElogo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project9/logo.jpg";

// DLF Crest
import Crest1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project10/1.jpg";
import Crest2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project10/2.jpg";
import Crestlogo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project10/logo.jpg";

// DLF Exotica
import Exotica1 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project11/1.jpg";
import Exotica2 from "../../../assets/Project/ResidentialProjects/golfcourseroad/project11/2.jpg";
import Exoticalogo from "../../../assets/Project/ResidentialProjects/golfcourseroad/project11/logo.jpg";

const golfcourseDataRes = [
  {
    id: "1",
    name: "Tulip Monsella",
    img: img1,
    location: " Sector 53, Gurgaon",
    link: "Tulip-Monsella",
    zip: "122002",
    logo: logo,
    price: "Starting Price ₹ 7.18 Cr*",
    steps: [
      {
        id: "1",
        img: tulip1,
      },
      {
        id: "2",
        img: tulip2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "24*7 Power Backup",
      },
      {
        id: "2",
        fcname: "Chair Accessible",
      },
      {
        id: "3",
        fcname: "Electricity",
      },
      {
        id: "4",
        fcname: "Parking",
      },
      {
        id: "5",
        fcname: "SecurityWiFi",
      },
      {
        id: "6",
        fcname: "WiFi",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
      },
      {
        id: "2",
        pra: "It comes up with mesmerizing and well equipped 3, 4 and 5 BHK luxurious apartments sprinkled with lavish green gardens, enormous parks, kids play area, swimming pool, gaming lounge and more.",
      },
      {
        id: "3",
        pra: "Our homes and apartments give extra love, care and satisfaction for the lifetime source to each of the individuals in a budget-friendly manner with keeping in mind the latest fashion or requirements of millennials.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "It offers a massive space encircled by lavish green gardens and plants to appreciate natural air, daylight and a tranquil climate.",
        },
        {
            id: "2",
            name: "The venture comprises an eco-accommodating area, badminton court, yoga deck, jogging, swimming pool, cycling track and gym.",
        },
        {
            id: "3",
            name: "It provides world-class facilities like a lounge, cafeteria, convenience shopping, spa, restaurants etc.",
        },
        {
            id: "4",
            name: "Tulip Monsella residency also includes the sky hub, sports pavilion, clubhouse, tower lobbies, and more.",
        },
        {
            id: "5",
            name: "Above that, it offers VRF/ VRV air-conditioned bedrooms, a private terrace, a modern kitchen, a spacious balcony, a personal work area and an exotic hall.",
        },
        {
            id: "6",
            name: "It is compromised with a wide-open area with beautiful greenery and plants.",
        },
        {
            id: "7",
            name: "Ultra Luxury Apartments & Penthouses In 3, 4 and 5 bhk configurations.",
        },
        {
            id: "8",
            name: "A quaint neighbourhood surrounded with prestigious Schools, Hospitals, Shopping Centers and Hotels.",
        },
        {
            id: "9",
            name: "Energy efficient VRF / VRV air conditioning systems in bedrooms, living and kitchen of standard make",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2874-2892 sq. ft",
          price: "7.90 Cr* Onwards"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3684 sq. ft",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4503 sq. ft",
          price: "On Request"
      },
  ],
    descD: "Tulip Monsella is a high-rise luxurious residences project located in the heart of Gurgaon, sector 53, Golf course road. It provides you luxury style premium apartments or flats with incredible facilities that help to make your stay great",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.4484195599853!2d77.09813167545654!3d28.435895892983055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19874fc4379b%3A0x3eb7ba636611668e!2sTulip%20Monsella%20Sector%2053%2C%20Gurgaon!5e0!3m2!1sen!2sin!4v1701496988309!5m2!1sen!2sin"
  },
  {
    id: "2",
    name: "Vipul Belmonte",
    img: img2,
    location: " Sector 53, Gurgaon",
    link: "Vipul-Belmonte",
    zip: "122003",
    logo: Vipullogo,
    price: "Starting Price ₹ 3.11 Cr*",
    steps: [
      {
        id: "1",
        img: Vipul1,
      },
      {
        id: "2",
        img: Vipul2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "Back yard",
      },
      {
        id: "2",
        fcname: "balcony",
      },
      {
        id: "3",
        fcname: "Basketball court",
      },
      {
        id: "4",
        fcname: "Central Air",
      },
      {
        id: "5",
        fcname: "Chair Accessible",
      },
      {
        id: "6",
        fcname: "Electricity",
      },
      {
        id: "7",
        fcname: "Equipped Kitchen",
      },
      {
        id: "8",
        fcname: "Front yard",
      },
      {
        id: "9",
        fcname: "Garden",
      },
      {
        id: "10",
        fcname: "Gym",
      },
      {
        id: "11",
        fcname: "Heating",
      },
      {
        id: "12",
        fcname: "Laundry",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Vipul Belmonte is a Ready to Move Luxurious Residential Project in Sector-53 Gurgaon offering 3,4,5 BHK Apartments with a range of sizes from 2450 Sq. Ft. to 4000 Sq. Ft .",
      },
      {
        id: "2",
        pra: "It is one of the Best Residential Projects by Vipul Groups. Vipul Belmonte offers Top-Notch Amenities like Clubhouse, Gyms, Swimming Pool, 100% Power Backup, Kids Play Area, Fully Air-Conditioned and lot more.",
      },
      {
        id: "3",
        pra: "It has a built-up area of 11.4 Acres with a total of 11 Towers with 18 Floors on Each Tower. Vipul Belmonte is the Home of many Big Corporates.",
      },
      {
        id: "4",
        pra: "It is near to so Many Premium Residential Projects in Sector-42, Sector-52 Gurgaon. Having all the Lavish Amenities and Convenience at one place is not the deal to be missed. For More Details, Call us at 99990-63322..",
      },
    ],
    hig: [
        {
            id: "1",
            name: "Ready to Move Residential Project by Vipul Group.",
        },
        {
            id: "2",
            name: "Apartment Sizes Range from 2450 Sq. Ft to 4,000 Sq. Ft .",
        },
        {
            id: "3",
            name: "3,4 and 5 BHK Luxury Apartments completed in August 2008.",
        },
        {
            id: "4",
            name: "Total Built-Up Area of 11.40 Acres.",
        },
        {
            id: "5",
            name: "Located in Sector-53 Gurgaon.",
        },
        {
            id: "6",
            name: "Near to Huda City Metro Station.",
        },
        {
            id: "7",
            name: "25 Minute Distance to IGI Airport.",
        },
        {
            id: "8",
            name: "Near to Lotus Valley School, Scottish High International School.",
        },
        {
            id: "9",
            name: "15 Minute Distance to Paras Hospital.",
        },
        {
            id: "10",
            name: "11 Towers with 18 Floors on Each Tower.",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"2450 sq.ft.",
          price: "On Request"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3110 sq.ft.",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"3925 sq. ft",
          price: "On Request"
      },
  ],
    descD: "Vipul Belmonte is a Ready to Move Luxurious Residential Project in Sector-53 Gurgaon offering 3,4,5 BHK Apartments with a range of sizes from 2450 Sq. Ft. to 4000 Sq. Ft . It is one of the Best Residential Projects by Vipul Groups. Vipul Belmonte offers Top-Notch Amenities like Clubhouse, Gyms, Swimming Pool, 100% Power Backup, Kids Play Area, Fully Air-Conditioned and lot more.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.283063822937!2d77.09809817545676!3d28.440882292758037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18b298055c0f%3A0x5e32684ed6ee02c!2sVipul%20Belmonte%2C%20BELMONTE%20APARTMENT%2C%20Golf%20Course%20Rd%2C%20Parsvnath%20Exotica%2C%20DLF%20Phase%205%2C%20Sector%2053%2C%20Gurugram%2C%20Haryana%20122003!5e0!3m2!1sen!2sin!4v1701497406234!5m2!1sen!2sin"
  },
  {
    id: "3",
    name: "Salcon Verandas",
    img: img3,
    location: " Sector 54, Gurgaon",
    link: "Salcon-Verandas",
    zip: "122016",
    logo: Salconlogo,
    price: "Starting Price ₹ 6.50 Cr*",
    steps: [
      {
        id: "1",
        img: Salcon1,
      },
      {
        id: "2",
        img: Salcon2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "Back yard",
      },
      {
        id: "2",
        fcname: "balcony",
      },
      {
        id: "3",
        fcname: "Basketball court",
      },
      {
        id: "4",
        fcname: "Central Air",
      },
      {
        id: "5",
        fcname: "Chair Accessible",
      },
      {
        id: "6",
        fcname: "Electricity",
      },
      {
        id: "7",
        fcname: "Equipped Kitchen",
      },
      {
        id: "8",
        fcname: "Front yard",
      },
      {
        id: "9",
        fcname: "Garden",
      },
      {
        id: "10",
        fcname: "Media Room",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "Welcome to a home that lets you live and celebrate life to the fullest. Salcon The Verandas offering incredibly spacious apartments come in 4, 5 and 6 bedroom variations on Sector-54 Gurgaon. There are three towers of 4BHK – CORAL, IRIS & MAPLE, two towers of 5 BHK – BASIL , OLIVE and only one exclusive tower of 6 BHK which is called AZIZA.",
      },
      {
        id: "2",
        pra: "Salcon The Verandas Gurgaon is considered the 3rd most luxurious Ready to Move in property on the premium Golf Course Road Gurgaon after the likes of DLF MAGNOLIAS and DLF Aralias which both are priced much above 10 CR. There are 6 towers with a total of 192 homes spread out on just over 9 acres.",
      },
      {
        id: "3",
        pra: "Each home has balconies that span the length of either side of the home, providing greenery and open spaces and all of them include at least one terrace space per floor. Apartments come with two staff rooms and a large utility balcony.",
      },
      {
        id: "4",
        pra: "All the rooms lead off of a long hallway that spans the length of the apartment, down the middle. Most rooms have a dressing area the last room has been provided with the option of turning it into a family room. The Amenities in these Luxury Apartments are World-Class like Swimming Pool, Gyms, Badminton Court, Salon, Spa Facility and more.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "Located in Sector-54 Gurgaon.",
        },
        {
            id: "2",
            name: "Near to Sector-54 Rapid Metro Station.",
        },
        {
            id: "3",
            name: "30 Minute Distance to IGI Airport.",
        },
        {
            id: "4",
            name: "Close to Suncity School and Kinder Plume Pre School and Day Care.",
        },
        {
            id: "5",
            name: "Easy Access to the Other Locations in Gurgaon.",
        },
        {
            id: "6",
            name: "15 Minute Distance to South Point Mall.",
        },
    ],
    table: [
      {
          id: "1",
          type: "4 BHK",
          Saleable_Area:"4495 - 4645 sq.ft.",
          price: "₹ 13.21 - 13.65 Cr"
      },
      {
          id: "2",
          type: "5 BHK",
          Saleable_Area:"5330 - 5500 sq.ft.",
          price: "₹ 15.66 - 16.16 Cr"
      },
      {
          id: "3",
          type: "6 BHK",
          Saleable_Area:"6068 - 6425 sq.ft.",
          price: "₹ 17.83 - 18.88 Cr"
      },
  ],
    descD: "Welcome to a home that lets you live and celebrate life to the fullest. Salcon The Verandas offering incredibly spacious apartments come in 4, 5 and 6 bedroom variations on Sector-54 Gurgaon. There are three towers of 4BHK – CORAL, IRIS & MAPLE, two towers of 5 BHK – BASIL , OLIVE and only one exclusive tower of 6 BHK which is called AZIZA.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.4431174731058!2d77.10204887545652!3d28.436055792976006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f52d950aec5%3A0xb00dab405db6c6d!2sSalcon%20The%20Verandas!5e0!3m2!1sen!2sin!4v1701497489635!5m2!1sen!2sin"
  },
  {
    id: "4",
    name: "Abw La Lagune",
    img: img4,
    location: " Sector 54, Gurgaon",
    link: "Abw-La-Lagune",
    zip: "122011",
    logo: Abwlogo,
    price: "Starting Price ₹ 4.36 - 7.70 Cr*",
    steps: [
      {
        id: "1",
        img: Abw1,
      },
      {
        id: "2",
        img: Abw2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "Back yard",
      },
      {
        id: "2",
        fcname: "balcony",
      },
      {
        id: "3",
        fcname: "Basketball court",
      },
      {
        id: "4",
        fcname: "Central Air",
      },
      {
        id: "5",
        fcname: "Chair Accessible",
      },
      {
        id: "6",
        fcname: "Electricity",
      },
      {
        id: "7",
        fcname: "Equipped Kitchen",
      },
      {
        id: "8",
        fcname: "Front yard",
      },
      {
        id: "9",
        fcname: "Garden",
      },
      {
        id: "10",
        fcname: "Media Room",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "“La-Lagune”, in the heart Gurgaon, spread across 11.3 acres of land. Located on the Golf Course road, La-Lagune, the most sought after premium residential address in Gurgaon, has been conceptualized to offer a luxurious living experience at par with international design and comfort.",
      },
      {
        id: "2",
        pra: "It’s the perfect symphony of Luxury & Convenience. Visit our show flat to see a glimps of the dream to unfold.",
      },
      {
        id: "3",
        pra: "La-Lagune occupies a very desirable location on the main sector road. Built around verdant wide-open spaces, and sprawling gardens, its a masterpiece of design and planning finesse. The lavish condominium floor plan ranges from 4-5-bedroom luxury living space providing a personal paradise in the city. ",
      },
      {
        id: "4",
        pra: "All apartments in this beautiful complex will be air-conditioned. High-end features will include Italian marble flooring; complete designer woodwork, smart security systems and high-speed elevators. Adequate care has been taken to bring nature closer.",
      },
      {
        id: "5",
        pra: "To add further luxury, each apartment has been provided with two service rooms and separate elevator for services. La Lagune is a masterpiece of design and planning finesse. The condominium floor plan ranges from 4-5-bedroom luxury living space. The company has roped in leading architects, SAA Architects (Singapore) and Pradeep Sharma Architects to design the elevations.",
      },
      {
        id: "6",
        pra: "The luxury condominium complex brings in a host of amenities and services to facilitate a luxurious lifestyle including exclusive clubs, cultural centers, swimming pools, wi-fi for each apartment and the excellent landscaped environment. The highlight will be the water bodies and landscaping designed by Paul Friedberg and Partners (USA).",
      },
    ],
    hig: [
        {
            id: "1",
            name: "A Ready to Move Residential Project by Suncity.",
        },
        {
            id: "2",
            name: "3,4,5 BHK Luxurious Apartments.",
        },
        {
            id: "3",
            name: "Built-up Area of 11.34 Acres.",
        },
        {
            id: "4",
            name: "314 Total Apartments.",
        },
        {
            id: "5",
            name: "12 Towers with 18 Floors on Each Tower.",
        },
        {
            id: "6",
            name: "This complex spanned 22+ Acre.Completed in September 2013.",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"3160 sq.ft.",
          price: "On Request"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3160 - 5330 sq.ft.",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4180 sq.ft.",
          price: "On Request"
      },
  ],
    descD: "“La-Lagune”, in the heart Gurgaon, spread across 11.3 acres of land. Located on the Golf Course road, La-Lagune, the most sought after premium residential address in Gurgaon, has been conceptualized to offer a luxurious living experience at par with international design and comfort.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.374791388532!2d77.10546147545662!3d28.4381162928829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f53ab3e31e7%3A0xac3a688cf76678f5!2sLa%20Lagune%20Rd%2C%20Suncity%2C%20Sector%2054%2C%20Gurugram%2C%20Haryana%20122003!5e0!3m2!1sen!2sin!4v1701497555464!5m2!1sen!2sin"
  },
  {
    id: "5",
    name: "DLF Aralias",
    img: img5,
    location: " Sector 42, Gurgaon",
    link: "DLF-Aralias",
    zip: "122002",
    logo: Araliaslogo,
    price: "Starting Price ₹ 18 Cr*",
    steps: [
      {
        id: "1",
        img: Aralias1,
      },
      {
        id: "2",
        img: Aralias2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "Back yard",
      },
      {
        id: "2",
        fcname: "balcony",
      },
      {
        id: "3",
        fcname: "Basketball court",
      },
      {
        id: "4",
        fcname: "Central Air",
      },
      {
        id: "5",
        fcname: "Chair Accessible",
      },
      {
        id: "6",
        fcname: "Electricity",
      },
      {
        id: "7",
        fcname: "Equipped Kitchen",
      },
      {
        id: "8",
        fcname: "Front yard",
      },
      {
        id: "9",
        fcname: "Garden",
      },
      {
        id: "10",
        fcname: "Media Room",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "DLF The Aralias is the Ready to Move Residential Projects in Sector-42 Gurgaon with the Luxurious Offering of 4BHK Apartments with the size of 5575 Sq. Ft. to 6,000 Sq. Ft.",
      },
      {
        id: "2",
        pra: "This Project is located in the Best Location on Golf Course Road. DLF Aralias is a High Scale Development Project consisting of 11 towers with 17 Floors on Each Tower which makes a total of 254 Apartments. All the Luxury Amenities and Features like Fully Air-Conditioned, 100% Power Backup, Mini Theatre, Swimming Pool, and Gyms all are available in DLF The Aralias.",
      },
      {
        id: "3",
        pra: "The Exclusive Clubhouse in these Apartments is not just for the Residents but the Other People can also join there. The Best Part of this Location is that It is very easy to access the other Locations in Gurgaon like NH8, Golf Course Extension Road, MG Road, Cyber City, and a lot more.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "Located on Sector-42 Gurgaon.",
        },
        {
            id: "2",
            name: "Near to Sector-42,43 Rapid Metro Station.",
        },
        {
            id: "3",
            name: "30 Minute Distance to the IGI Airport.",
        },
        {
            id: "4",
            name: "8 km Distance to Sohna Gurgaon Road.",
        },
        {
            id: "5",
            name: "20 Minute Distance to Fortis Hospital.",
        },
        {
            id: "6",
            name: "Near to the Popular Schools and Shopping Malls.",
        },
        {
            id: "7",
            name: "20 Minute Distance to Huda City Centre Metro Station.",
        },
        {
            id: "8",
            name: "Ready to Move Residential Project in Sector-42 Gurgaon.",
        },
        {
            id: "9",
            name: "Developed by the Most Renowned DLF Group.",
        },
        {
            id: "10",
            name: "Luxurious 4BHK Apartments with sizes from 5575 Sq. Ft. to 6,000 Sq. Ft. .",
        },
        {
            id: "11",
            name: "Ready to Move Residential Project in Sector-42 Gurgaon.",
        },
    ],
    table: [
      {
          id: "1",
          type: "3 BHK",
          Saleable_Area:"3160 sq.ft.",
          price: "On Request"
      },
      {
          id: "2",
          type: "4 BHK",
          Saleable_Area:"3160 - 5330 sq.ft.",
          price: "On Request"
      },
      {
          id: "3",
          type: "5 BHK",
          Saleable_Area:"4180 sq.ft.",
          price: "On Request"
      },
  ],
    descD: "DLF The Aralias is the Ready to Move Residential Projects in Sector-42 Gurgaon with the Luxurious Offering of 4BHK Apartments with the size of 5575 Sq. Ft. to 6,000 Sq. Ft.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.7055815614776!2d77.09852968266785!3d28.458290329489436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f36c02d55d7%3A0x7ba9ae318f9fa1a3!2sDLF%20Aralias!5e0!3m2!1sen!2sin!4v1701497613920!5m2!1sen!2sin"
  },
  {
    id: "6",
    name: "DLF Belaire",
    img: img6,
    location: " Sector 54, Gurgaon",
    link: "DLF-Aralias",
    zip: "122002",
    logo: Belairelogo,
    price: "Starting Price ₹ 5.20 Cr*",
    steps: [
      {
        id: "1",
        img: Belaire1,
      },
      {
        id: "2",
        img: Belaire2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "Back yard",
      },
      {
        id: "2",
        fcname: "balcony",
      },
      {
        id: "3",
        fcname: "Basketball court",
      },
      {
        id: "4",
        fcname: "Central Air",
      },
      {
        id: "5",
        fcname: "Chair Accessible",
      },
      {
        id: "6",
        fcname: "Electricity",
      },
      {
        id: "7",
        fcname: "Equipped Kitchen",
      },
      {
        id: "8",
        fcname: "Front yard",
      },
      {
        id: "9",
        fcname: "Garden",
      },
      {
        id: "10",
        fcname: "Media Room",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "DLF Belaire is a High-End Residential Project in Sector-54 Golf Course Road Gurgaon. Here. DLF presents the Luxurious 3,4 BHK Apartments plus Servant Room with the size range from 2667 Sq. Ft. to 4098 Sq. Ft.",
      },
      {
        id: "2",
        pra: "DLF Belaire offers tons of Lavish Amenities like Swimming Pool, Gyms, Intercom Facility, Clubs, Kids Play Area, Abundant Parking Space for your Luxury Lifestyle and many more from Basic to Advanced.",
      },
      {
        id: "3",
        pra: "This location is also very easy to access to mostly all the Locations in Gurgaon. This Project is Ready to Move Residential Project which means You don’t have to wait for up to the Possession Period.",
      },
      {
        id: "4",
        pra: "This Amazing Project is the home of so many Big Corporates. So, Why you are thinking? Don’t wait so much. Get the Luxury Apartments in DLF Belaire Sector-54 Gurgaon. For More Details, Call us at 99990-63322 and Get the Best Deal.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "Built-up Area of 7 Acres.",
        },
        {
            id: "2",
            name: "Luxurious 3,4 BHK Apartments.",
        },
        {
            id: "3",
            name: "Total- 350 Apartments.",
        },
        {
            id: "4",
            name: "Total 5 Towers with 30 Floors on Each Tower.",
        },
        {
            id: "5",
            name: "Ready to Move Project Completed in December 2012.",
        },
        {
            id: "6",
            name: "One of the Best DLF’s Residential Projects.",
        },
        
    ],
    table: [
      {
          id: "1",
          type: "4 BHK + SQ",
          Saleable_Area:"2858 sq.ft.",
          price: "₹ 5.2 - 8.00 Cr*"
      },
      {
          id: "2",
          type: "4 BHK + SQ",
          Saleable_Area:"3000 sq.ft.",
          price: "₹ 5.2 - 8.00 Cr*"
      },
      {
          id: "3",
          type: "4 BHK + SQ",
          Saleable_Area:"3430 sq.ft.",
          price: "₹ 5.2 - 8.00 Cr*"
      },
      {
          id: "4",
          type: "4 BHK + SQ",
          Saleable_Area:"4072 sq.ft.",
          price: "₹ 5.2 - 8.00 Cr*"
      },
      {
          id: "5",
          type: "4 BHK + SQ",
          Saleable_Area:"4098 sq.ft.",
          price: "₹ 5.2 - 8.00 Cr*"
      },
  ],
    descD: "DLF Belaire is a High-End Residential Project in Sector-54 Golf Course Road Gurgaon. Here. DLF presents the Luxurious 3,4 BHK Apartments plus Servant Room with the size range from 2667 Sq. Ft. to 4098 Sq. Ft.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.9985535736923!2d77.09844457545698!3d28.449459992370752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1925efea7cbd%3A0x4971b2944936e3e0!2sThe%20Belaire!5e0!3m2!1sen!2sin!4v1701497674287!5m2!1sen!2sin"
  },
  {
    id: "7",
    name: "DLF Magnolias",
    img: img7,
    location: " Sector 42, Gurgaon",
    link: "DLF-Magnolias",
    zip: "122022",
    logo: Magnoliaslogo,
    price: "Starting Price ₹ 24 Cr*",
    steps: [
      {
        id: "1",
        img: Magnolias1,
      },
      {
        id: "2",
        img: Magnolias2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "Back yard",
      },
      {
        id: "2",
        fcname: "balcony",
      },
      {
        id: "3",
        fcname: "Basketball court",
      },
      {
        id: "4",
        fcname: "Central Air",
      },
      {
        id: "5",
        fcname: "Chair Accessible",
      },
      {
        id: "6",
        fcname: "Electricity",
      },
      {
        id: "7",
        fcname: "Equipped Kitchen",
      },
      {
        id: "8",
        fcname: "Front yard",
      },
      {
        id: "9",
        fcname: "Garden",
      },
      {
        id: "10",
        fcname: "Media Room",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "DLF Magnolias brings an Amazing View of DLF Golf Course Road and Aravalias by offering the 4 BHK Apartments and 5BHK Duplex and Penthouses with Servant Room covering 5825 Sq. Ft. to 9800 Sq. Ft. This Project is located in Sector-42 Gurgaon with the Top-Notch Amenities and Specifications for a Lavish Lifestyle.",
      },
      {
        id: "2",
        pra: "Here, you will get all the Amenities at one place. Here, you don’t have the need to go outside for Dining, Fitness and Entertainment Requirements. DLF Magnolias also comes with Exclusive Clubs, Mini Theatre, Swimming Pool and more. DLG The Magnolias has a built-up area of 22 Acres. It comprises of 5 Towers with 25 Floors in Each Tower with a total of 589 Apartments .",
      },
      {
        id: "3",
        pra: "It is the Home for Big Names in the Corporates. For More Info, Call us at 99990-63322 and Get your Dream Home in DLF Magnolias Sector-42 Gurgaon.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "Ready to Move Residential Project completed in September 2011.",
        },
        {
            id: "2",
            name: "4 and 5 BHK Luxurious Apartments.",
        },
        {
            id: "3",
            name: "Built-up Area of 22 Acres.",
        },
        {
            id: "4",
            name: "5 Towers with 25 Floors.",
        },
        {
            id: "5",
            name: "589 Total Apartments.",
        },
        {
            id: "6",
            name: "Excellent Design Floor Plan.",
        },
        
    ],
    table: [
      {
          id: "1",
          type: "4 BHK + SQ",
          Saleable_Area:"5875 sq.ft.",
          price: "₹ 24 - 32 Cr*"
      },
      {
          id: "2",
          type: "5 BHK + SQ",
          Saleable_Area:"9800 sq.ft.",
          price: "₹ 24 - 32 Cr*"
      },
  ],
    descD: "DLF Magnolias brings an Amazing View of DLF Golf Course Road and Aravalias by offering the 4 BHK Apartments and 5BHK Duplex and Penthouses with Servant Room covering 5825 Sq. Ft. to 9800 Sq. Ft. This Project is located in Sector-42 Gurgaon with the Top-Notch Amenities and Specifications for a Lavish Lifestyle.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.787417834844!2d77.09643237545724!3d28.45582399208333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18b4559372c5%3A0x659557a21efb019a!2sDlf%20Magnolias!5e0!3m2!1sen!2sin!4v1701497759101!5m2!1sen!2sin"
  },
  {
    id: "8",
    name: "DLF Park Place",
    img: img8,
    location: " Sector 54, Gurgaon",
    link: "DLF-Park-Place",
    zip: "122009",
    logo: Parklogo,
    price: "Starting Price ₹ 3.50 Cr*",
    steps: [
      {
        id: "1",
        img: Park1,
      },
      {
        id: "2",
        img: Park2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "Back yard",
      },
      {
        id: "2",
        fcname: "balcony",
      },
      {
        id: "3",
        fcname: "Basketball court",
      },
      {
        id: "4",
        fcname: "Central Air",
      },
      {
        id: "5",
        fcname: "Chair Accessible",
      },
      {
        id: "6",
        fcname: "Electricity",
      },
      {
        id: "7",
        fcname: "Equipped Kitchen",
      },
      {
        id: "8",
        fcname: "Front yard",
      },
      {
        id: "9",
        fcname: "Garden",
      },
      {
        id: "10",
        fcname: "Media Room",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "DLF Park Place is one of the Best Residential Projects by DLF Group. Here, they are offering 3,4 BHK Lavish Apartments with one Servant Room covering 1677 Sq. Ft. to 2627 Sq. Ft.",
      },
      {
        id: "2",
        pra: "This Project is on the Sector-54 Golf Course Road. DLF The Park Place is a Large Scale Development Project with High-End Amenities from Basic to Luxuries like Fully Air-Conditioned, Access to Gyms, Exclusive Clubhouse, Swimming Pool, and lot more.",
      },
      {
        id: "3",
        pra: "This is a Ready Move Residential Project completed in April 2012 with resale available. This project has fully designed 13 Blocks comprised of a total of 1560 Apartments. DLF Park Place is a part of the Best Projects in DLF Phase 5 Gurgaon. Many Big Corporate Executive have chosen this as their Dream Home.",
      },
      {
        id: "4",
        pra: "So, you can understand the demand for this Residential Project. So, Don’t wait for more. Get your Dream Home in the DLF Park Place Sector-54 Gurgaon.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "Ready to Move Large Residential Project in Sector-54 Gurgaon.",
        },
        {
            id: "2",
            name: "Completed in October 2016.",
        },
        {
            id: "3",
            name: "Built-up Area of 15 Acres.",
        },
        {
            id: "4",
            name: "13 Towers with a total of 1560 Apartments.",
        },
        {
            id: "5",
            name: "30 Floors in Each Tower.",
        },
        {
            id: "6",
            name: "3,4 BHK Lavish Apartments.",
        },
        
    ],
    table: [
      {
          id: "1",
          type: "4 BHK + SQ",
          Saleable_Area:"5875 sq.ft.",
          price: "₹ 24 - 32 Cr*"
      },
      {
          id: "2",
          type: "5 BHK + SQ",
          Saleable_Area:"9800 sq.ft.",
          price: "₹ 24 - 32 Cr*"
      },
  ],
    descD: "DLF Park Place is one of the Best Residential Projects by DLF Group. Here, they are offering 3,4 BHK Lavish Apartments with one Servant Room covering 1677 Sq. Ft. to 2627 Sq. Ft.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1754.099584199659!2d77.10681703870068!3d28.443411902378006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f4e7e38b97b%3A0xda74048aa66d09c1!2sDLF%20PARK%20PLACE%2C%20DLF%20Phase%205%2C%20Sector%2054%2C%20Gurugram%2C%20Haryana%20122002!5e0!3m2!1sen!2sin!4v1701497831965!5m2!1sen!2sin"
  },
  {
    id: "9",
    name: "DLF PINNACLE",
    img: img9,
    location: " Sector 43, Gurgaon",
    link: "DLF-PINNACLE",
    zip: "122009",
    logo: PINNACLElogo,
    price: "Starting Price ₹ 6 Cr*",
    steps: [
      {
        id: "1",
        img: PINNACLE1,
      },
      {
        id: "2",
        img: PINNACLE2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "Back yard",
      },
      {
        id: "2",
        fcname: "balcony",
      },
      {
        id: "3",
        fcname: "Basketball court",
      },
      {
        id: "4",
        fcname: "Central Air",
      },
      {
        id: "5",
        fcname: "Chair Accessible",
      },
      {
        id: "6",
        fcname: "Electricity",
      },
      {
        id: "7",
        fcname: "Equipped Kitchen",
      },
      {
        id: "8",
        fcname: "Front yard",
      },
      {
        id: "9",
        fcname: "Garden",
      },
      {
        id: "10",
        fcname: "Media Room",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "DLF Pinnacle is a High-End Residential Project in Sector-43 Gurgaon offering the Lavish 4BHK Apartments with two Servant Rooms per Apartment with an area of 3868 sq. ft. These Luxury Apartments are available for both Rent and Sale.",
      },
      {
        id: "2",
        pra: "DLF The Pinnacle Gurgaon comprises of 288 Lavish Apartments with the Luxury Amenities like Club Facility, Swimming Pool, Gyms, Multipurpose Halls, Tennis Court, Big Parking Space for both Residents and Visitors. This Project has good Connectivity with the localities like Sector-50, Sector-52, Sector-62 and more.",
      },
      {
        id: "3",
        pra: "It is also near to the Sector 53-54 Rapid Metro Station. These all Apartments are designed in a Feng Shui and Vaastu Friendly manner which gives Fresh Air and Plenty of Natural Light. Having all the Extravagance Amenities and Features at one place is not the deal to be missed. So, call us at 99990-63322 and Get your Dream Home in DLF Pinnacle Sector-43 Gurgaon.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "Ready to Move Residential Project in Sector-43 Gurgaon.",
        },
        {
            id: "2",
            name: "Built-up Area of 4.25 Acres.",
        },
        {
            id: "3",
            name: "4 Towers with 19 Floors in each Tower.",
        },
        {
            id: "4",
            name: "4BHK covering 3868 sq.ft. Lavish Apartments.",
        },
        {
            id: "5",
            name: "Total of 288 Apartments.",
        },
        {
            id: "6",
            name: "High-End Amenities like Exclusive Clubs, Gyms, Tennis Court and More.",
        },
        {
            id: "7",
            name: "Completed in Dec 2008.",
        },
        
    ],
    table: [
      {
          id: "1",
          type: "4 BHK + SQ",
          Saleable_Area:"3868 sq.ft.",
          price: "₹ 6.00 Cr*"
      },
      
  ],
    descD: "DLF Pinnacle is a High-End Residential Project in Sector-43 Gurgaon offering the Lavish 4BHK Apartments with two Servant Rooms per Apartment with an area of 3868 sq. ft. These Luxury Apartments are available for both Rent and Sale.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.8849258563887!2d77.09316277545706!3d28.452885092216103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18e7c0b4d831%3A0x417b360ccc41e86c!2sDLF%20Pinnacle!5e0!3m2!1sen!2sin!4v1701497900973!5m2!1sen!2sin"
  },
  {
    id: "10",
    name: "DLF Crest",
    img: img10,
    location: " Sector 54, Gurgaon",
    link: "DLF-Crest",
    zip: "122002",
    logo: Crestlogo,
    price: "Starting Price ₹ 7 Cr*",
    steps: [
      {
        id: "1",
        img: Crest1,
      },
      {
        id: "2",
        img: Crest2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "Back yard",
      },
      {
        id: "2",
        fcname: "balcony",
      },
      {
        id: "3",
        fcname: "Basketball court",
      },
      {
        id: "4",
        fcname: "Central Air",
      },
      {
        id: "5",
        fcname: "Chair Accessible",
      },
      {
        id: "6",
        fcname: "Electricity",
      },
      {
        id: "7",
        fcname: "Equipped Kitchen",
      },
      {
        id: "8",
        fcname: "Front yard",
      },
      {
        id: "9",
        fcname: "Garden",
      },
      {
        id: "10",
        fcname: "Media Room",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "DLF Crest is nothing but the most luxurious residential complex in Gurgaon. This skyscraper development hosts apartments & penthouses as your ideal homes in Sector 54. DLF The Crest is extended into an 8.82-acre area with 6 dazzling highly elevated towers. These towers are designed by Hafeez Contractor, which individually has 36 floors and a total of 500+ homes in the most privileged location of the City.",
      },
      {
        id: "2",
        pra: "DLF The Crest Gurgaon offers ready-to-move-in facilities, ranging from 3 BHK apartments to 5 BHK Penthouse with Servant Quarter & extra wide sky platform. These ultra-modern homes are built with the richness of technology & luxury. From smart living & lightning to digital locks, DLF The Crest Gurgaon is a live example of Elegant homes.",
      },
      {
        id: "3",
        pra: "In addition, DLF The Crest apartments are spacious, the size of 3 BHK apartments starts from 2662 sq. ft. Plus, 5 BHK Penthouses are unique accommodations in a 6000+ sq. ft. carpet area. Well, along with roomy & freshen homes, DLF The Crest has infinite features.",
      },
    ],
    hig: [
        {
            id: "1",
            name: "Spacious 3 BHK apartment starts from only 7 Cr.",
        },
        {
            id: "2",
            name: "DLF The Crest is very close to Sector 53-54 Rapid Metro Station.",
        },
        {
            id: "3",
            name: "DLF The Crest Gurgaon is just off to the Golf Course Road.",
        },
        {
            id: "4",
            name: "A quick ride away from Huda Market Sector 56.",
        },
        {
            id: "5",
            name: "Hardly 10 minutes away from NH48.",
        },
        {
            id: "6",
            name: "Covered with highly developed infrastructure.",
        },
        {
            id: "7",
            name: "Hospitals & Educational institutes are just around DLF The Crest Gurgaon.",
        },
        {
            id: "8",
            name: "Fully fitted ready-to-move-in homes.",
        },
        {
            id: "9",
            name: "IGI Airport is just 15kms from DLF The Crest Gurgaon.",
        },
        {
            id: "10",
            name: "Centralised homes, entrance, lift, & lobbies.",
        },
        {
            id: "11",
            name: "6 polished towers with 500+ residential units.",
        },
        
    ],
    table: [
      {
          id: "1",
          type: "3 BHK + SQ",
          Saleable_Area:"2662 sq.ft.",
          price: "₹ 7.00 - 13.00 Cr*"
      },
      {
          id: "2",
          type: "4 BHK + SQ",
          Saleable_Area:"3081 sq.ft.",
          price: "₹ 7.00 - 13.00 Cr*"
      },
      {
          id: "3",
          type: "4 BHK + SQ",
          Saleable_Area:"3497 sq.ft.",
          price: "₹ 7.00 - 13.00 Cr*"
      },
      {
          id: "4",
          type: "4 BHK + SQ",
          Saleable_Area:"3898 sq.ft.",
          price: "₹ 7.00 - 13.00 Cr*"
      },
      
  ],
    descD: "DLF Crest is nothing but the most luxurious residential complex in Gurgaon. This skyscraper development hosts apartments & penthouses as your ideal homes in Sector 54. DLF The Crest is extended into an 8.82-acre area with 6 dazzling highly elevated towers. These towers are designed by Hafeez Contractor, which individually has 36 floors and a total of 500+ homes in the most privileged location of the City.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.4840346835517!2d77.10636627545652!3d28.434821793031485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f56adfc001f%3A0xf7420f434b54a69f!2sDLF%20The%20Crest%202%20Gurugram!5e0!3m2!1sen!2sin!4v1701497963395!5m2!1sen!2sin"
  },
  {
    id: "11",
    name: "Parsvnath Exotica",
    img: img11,
    location: "Sector 53, Gurugram",
    link: "Parsvnath-Exotica",
    zip: "122002",
    logo: Exoticalogo,
    price: "Starting Price ₹ 7 Cr*",
    steps: [
      {
        id: "1",
        img: Exotica1,
      },
      {
        id: "2",
        img: Exotica2,
      },
    ],
    fec: [
      {
        id: "1",
        fcname: "Back yard",
      },
      {
        id: "2",
        fcname: "balcony",
      },
      {
        id: "3",
        fcname: "Basketball court",
      },
      {
        id: "4",
        fcname: "Central Air",
      },
      {
        id: "5",
        fcname: "Chair Accessible",
      },
      {
        id: "6",
        fcname: "Electricity",
      },
      {
        id: "7",
        fcname: "Equipped Kitchen",
      },
      {
        id: "8",
        fcname: "Front yard",
      },
      {
        id: "9",
        fcname: "Garden",
      },
      {
        id: "10",
        fcname: "Media Room",
      },
    ],
    desc: [
      {
        id: "1",
        pra: "When you’ve moved into the luxurious ambience of Parsvnath Exotica. Where refinement cast its aura of style. Where life takes on a new elegance. Where you want to live every moment to the fullest.  Where you wish to hold the time. Where every corner captivates your imagination.",
      },
      {
        id: "2",
        pra: "Located on Main Sector Road, Gurgaon, Parsvnath Exotica offers a magnificent view of the golf course and prides itself with vicinity of premium residential projects. Tucked away in refined splendor, it nestles a grand life for you. Imagine your life at Parsvnath Exotica. Wake up to a new, gracious way to live. ",
      },
      {
        id: "3",
        pra: "You experience violet tinged clouds as you race pass the cool breeze of the morning. And you pause from jogging . . . breathe deep the early morning air amidst water bodies and bamboo groves. You witness a complex on 27 acres, of which over 84% is landscaped to emerald perfection. At the entrance, the dancing waters of fountains sparkle in a kaleidoscope of lights. ",
      },
    ],
    hig: [
        {
            id: "1",
            name: "Spacious 3 BHK apartment starts from only 3.50 Cr.",
        },
        {
            id: "2",
            name: "Parsvnath Exotica is very close to Sector 53 Rapid Metro Station.",
        },
        {
            id: "3",
            name: "Parsvnath Exotica Gurgaon is just off to the Golf Course Road.",
        },
        {
            id: "4",
            name: "A quick ride away from Huda Market Sector 56.",
        },
        {
            id: "5",
            name: "Hardly 10 minutes away from NH48.",
        },
        {
            id: "6",
            name: "Covered with highly developed infrastructure.",
        },
        {
            id: "7",
            name: "Hospitals & Educational institutes are just around Parsvnath Exotica Gurgaon.",
        },
        {
            id: "8",
            name: "Fully fitted ready-to-move-in homes.",
        },
        {
            id: "9",
            name: "IGI Airport is just 15kms from Parsvnath Exotica Gurgaon.",
        },
        {
            id: "10",
            name: "Centralised homes, entrance, lift, & lobbies.",
        },
        {
            id: "11",
            name: "6 polished towers with 500+ residential units.",
        },
        
    ],
    table: [
      {
          id: "1",
          type: "3 BHK + SQ",
          Saleable_Area:"2645-2209 sq.ft.",
          price: "₹ 3.50 Cr*"
      },
      {
          id: "2",
          type: "4 BHK + SQ",
          Saleable_Area:"3645-3400 sq.ft.",
          price: "₹ 4.99 Cr*"
      },
      {
          id: "3",
          type: "5 BHK + SQ",
          Saleable_Area:"6805-6500 sq.ft.",
          price: "₹ 6.45 Cr*"
      },
  ],
    descD: "When you’ve moved into the luxurious ambience of Parsvnath Exotica. Where refinement cast its aura of style. Where life takes on a new elegance. Where you want to live every moment to the fullest.  Where you wish to hold the time. Where every corner captivates your imagination.",
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.1128188772814!2d77.09714707545692!3d28.44601529252621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18b507b3b8a7%3A0xfa8dcc3878f0fc25!2sParsvnath%20Exotica!5e0!3m2!1sen!2sin!4v1701498014706!5m2!1sen!2sin"
  },
];

export default golfcourseDataRes;
