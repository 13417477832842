import img1 from "../../../assets/Project/Tata/1.jpg"
import img2 from "../../../assets/Project/Tata/2.jpg"
import img3 from "../../../assets/Project/Tata/3.jpg"



const TataData = [
    {
        id: "1",
        name: "Tata Gurgaon Gateway",
        location: "Sector 112, Sohna Gurgaon",
        img: img1,
        desc: "Tata Gurgaon Gateway is a luxury Residential Project by Tata Housing Group in Sector-113 Gurgaon. Here, Tata Housing Gurgaon Gateway presents 2,3 BHK Luxury Apartments with the size range starting from 1580 to 2925 Sq. Ft.        "
    },
    {
        id: "2",
        name: "Tata Primanti",
        location: "Sector 72, Gurgaon",
        img: img2,
        desc: "Primanti is envisioned in a way where nature’s boundless beauty meets every luxury you’d expect. Be it walking to the spa through the manicured lawns or entertaining yourself in an outdoor pool amid lush greens, it’s everything you have always dreamt of.        "
    },
    {
        id: "3",
        name: "Tata La Vida",
        location: "Sector 113, Gurgaon",
        img: img3,
        desc: "Tata La Vida is the New Residential Project by Tata Housing on Sector-113 Gurgaon. Here, Tata Housing gives you a choice of 2 and 3 BHK Apartments with the Floor plan size from 1276 to 1579 Sq. Ft.        "
    }
]


export default TataData;