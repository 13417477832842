import React from 'react'
import "../Layout/footer.css"
import {Link} from "react-router-dom";
import imglogo from "../../assets/img/Logo.png";


function Footer() {
  return (
    <>
        <footer>
        <div class="footer-top">
            <div class="container">
                <div class="footer-day-time">
                    <div class="row">
                        <div class="col-md-8">
                            <ul>
                                <li>Opening Hours : Monday - Saturday: 10 : 00AM - 19 : 00PM</li>
                                <li>Tuesday : <span class="lnight-text text-danger"> Closed</span></li>
                            </ul>
                        </div>
                        <div class="col-lg-4">
                            <div class="phone-no">
                                <a href="tel:+91-9810010295"><i class="fa fa-mobile" aria-hidden="true"></i>Call +91-9810010295</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        
                        <h4>About us</h4>
                        <p>Jeet Estate Consultant is a distinguished real estate firm, established independently in 2010 after years of dedicated effort. Situated in Gurugram, Haryana, our accomplished team specializes in the sale, purchase, lease, and management of numerous properties. Our profound knowledge of the region, coupled with extensive local market experience, enables us to provide the utmost level of service to our esteemed clientele, comprising Property Owners, Buyers, and Investors.</p>

                    </div>

                    <div class="col-md-4">
                        <h4>Information</h4>
                        <ul class="address1">
                            
                            <li><i class="fa fa-envelope"></i><a href="mailto:#">sales@jeetestate.com</a></li>
                            <li><i class="fa fa-mobile" aria-hidden="true"></i> <a href="tel:9810010295">9810010295</a></li>
                            <li><i class="fa fa-map-marker"></i>D-142,Ground Floor, Sushant Lok III Extension, Sector 57, Gurugram, Haryana 122003</li>
                        </ul>
                    </div>

                    <div class="col-md-2">
                        <h4>Follow us</h4>
                        <ul class="social-icon">
                            <li><a href="#"><i class="fab fa-facebook" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
                        </ul>
                        <img className='mt-5' src={imglogo} alt=''/>
                    </div>

                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Our services</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-6">
                        <p class="copyright text-uppercase">Copyright © - Jeet estate Consultant All Rights Reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    
    
    
    </>
  )
}

export default Footer;