import img1 from "../../assets/Map/Sector/DLF_1_Gurgaon-fotor-2023120417923.jpg"
import img2 from "../../assets/Map/DLF_II_Gurgaon.jpg"
import img3 from "../../assets/Map/DLF_III_Gurgaon.jpg"
import img4 from "../../assets/Map/Sector/dlf-phase-4-map-scaled.jpg"
import img5 from "../../assets/Map/Sector/DLF_V_Gurgaon.jpg"
import img6 from "../../assets/Map/Sector/1_Gurgaon Master Plan 2021-fotor-20231204163421.png"
import img7 from "../../assets/Map/Sector/greenwood-city-map-scaled.jpg"
import img8 from "../../assets/Map/Sector/malibu-town-map-scaled.jpg"
import img9 from "../../assets/Map/Sector/mayfield-gardens-map-scaled.jpg"
import img10 from "../../assets/Map/Sector/nirvana-country-scaled.jpg"
import img11 from "../../assets/Map/Sector/pace-city-1-map-scaled.jpg"
import img12 from "../../assets/Map/Sector/rosewood-city-map-scaled.jpg"
import img13 from "../../assets/Map/Sector/saraswati-vihar-map-scaled.jpg"
import img14 from "../../assets/Map/Sector/sector-4-and-7-map-scaled.jpg"
import img15 from "../../assets/Map/Sector/sector-5-map-scaled.jpg"
import img16 from "../../assets/Map/Sector/sector-7-map-scaled.jpg"
import img17 from "../../assets/Map/Sector/sector-9-a-map-scaled.jpg"
import img18 from "../../assets/Map/Sector/sector-9-map-scaled.jpg"
import img19 from "../../assets/Map/Sector/sector-10-a-map-scaled.jpg"
import img20 from "../../assets/Map/Sector/sector-10-map-scaled.jpg"
import img21 from "../../assets/Map/Sector/sector-12-a-map-scaled.jpg"
import img22 from "../../assets/Map/Sector/sector-14-map-scaled.jpg"
import img23 from "../../assets/Map/Sector/sector-15-1-map-scaled.jpg"
import img24 from "../../assets/Map/Sector/sector-15-2-map-scaled.jpg"
import img25 from "../../assets/Map/Sector/sector-23-a-map-scaled.jpg"
import img26 from "../../assets/Map/Sector/sector-23-map-scaled.jpg"
import img27 from "../../assets/Map/Sector/sector-27-and-28-map-scaled.jpg"
import img28 from "../../assets/Map/Sector/sector-29-map-scaled.jpg"
import img29 from "../../assets/Map/Sector/sector-31-and-32-map-scaled.jpg"
import img30 from "../../assets/Map/Sector/sector-31-new-colony-housing-board-map-scaled.jpg"
import img31 from "../../assets/Map/Sector/sector-34-map-scaled.jpg"
import img32 from "../../assets/Map/Sector/sector-38-map-scaled.jpg"
import img33 from "../../assets/Map/Sector/sector-39-map-scaled.jpg"
import img34 from "../../assets/Map/Sector/sector-40-map-scaled.jpg"
import img35 from "../../assets/Map/Sector/sector-42-map-scaled.jpg"
import img36 from "../../assets/Map/Sector/sector-46-map-scaled.jpg"
import img37 from "../../assets/Map/Sector/sector-47-map-scaled.jpg"
import img38 from "../../assets/Map/Sector/sector-51-map-scaled.jpg"
import img39 from "../../assets/Map/Sector/sector-52-map-scaled.jpg"
import img40 from "../../assets/Map/Sector/southcity-1-map-scaled.jpg"
import img41 from "../../assets/Map/Sector/sushant-lok-1-map-scaled.jpg"
import img42 from "../../assets/Map/Sector/Sushant Lok_II_Sector_55_56_57.jpg"
import img43 from "../../assets/Map/Sector/Sushant-Lok-Phase-3-Map.jpg"
import img44 from "../../assets/Map/Sector/udyog-vihar-map-scaled.jpg"
import img45 from "../../assets/Map/Sector/uppal-southend-map-scaled.jpg"
import img46 from "../../assets/Map/Sector/vipul-world-sector-48-map.jpg"

const mapData =[
    {
    id: "1",
    Name: "DLF 1 Gurgaon",
    img: img1,
    Namemodal: "map1"
},
{
    id: "2",
    Name: "DLF II Gurgaon",
    img: img2,
    Namemodal: "map2"
},
{
    id: "3",
    Name: "DLF III Gurgaon",
    img: img3,
    Namemodal: "map3"
},
{
    id: "4",
    Name: "DLF IV Gurgaon",
    img: img4,
    Namemodal: "map4"
},
{
    id: "5",
    Name: "DLF V Gurgaon",
    img: img5,
    Namemodal: "map5"
},
{
    id: "6",
    Name: "Gurgaon Master Plan",
    img: img6,
    Namemodal: "map6"
},
{
    id: "7",
    Name: "Greenwood City Gurgaon ",
    img: img7,
    Namemodal: "map7"
},
{
    id: "8",
    Name: "Malibu Town Gurgaon ",
    img: img8,
    Namemodal: "map8"
},
{
    id: "9",
    Name: "Mayfield Gardens Gurgaon ",
    img: img9,
    Namemodal: "map9"
},
{
    id: "10",
    Name: "Nirvana Country Scaled Gurgaon ",
    img: img10,
    Namemodal: "map10"
},
{
    id: "11",
    Name: "Pace City I Gurgaon ",
    img: img11,
    Namemodal: "map11"
},
{
    id: "12",
    Name: "Rosewood  City Gurgaon ",
    img: img12,
    Namemodal: "map12"
},
{
    id: "13",
    Name: "Saraswati Vihar Gurgaon ",
    img: img13,
    Namemodal: "map13"
},
{
    id: "14",
    Name: "Sector 4 and 7 Gurgaon ",
    img: img14,
    Namemodal: "map14"
},
{
    id: "15",
    Name: "Sector 5 Gurgaon ",
    img: img15,
    Namemodal: "map15"
},
{
    id: "16",
    Name: "Sector 7 Gurgaon ",
    img: img16,
    Namemodal: "map16"
},
{
    id: "17",
    Name: "Sector 9-A Gurgaon ",
    img: img17,
    Namemodal: "map17"
},
{
    id: "18",
    Name: "Sector 9 Gurgaon ",
    img: img18,
    Namemodal: "map18"
},
{
    id: "19",
    Name: "Sector 10-A Gurgaon ",
    img: img19,
    Namemodal: "map19"
},
{
    id: "20",
    Name: "Sector 10 Gurgaon ",
    img: img20,
    Namemodal: "map20"
},
{
    id: "21",
    Name: "Sector 12-A Gurgaon ",
    img: img21,
    Namemodal: "map21"
},
{
    id: "22",
    Name: "Sector 14 Gurgaon ",
    img: img22,
    Namemodal: "map22"
},
{
    id: "23",
    Name: "Sector 15-I Gurgaon ",
    img: img23,
    Namemodal: "map23"
},
{
    id: "24",
    Name: "Sector 15-II Gurgaon ",
    img: img24,
    Namemodal: "map24"
},
{
    id: "25",
    Name: "Sector 23-A Gurgaon ",
    img: img25,
    Namemodal: "map25"
},
{
    id: "26",
    Name: "Sector 23 Gurgaon ",
    img: img26,
    Namemodal: "map26"
},
{
    id: "27",
    Name: "Sector 27,28 Gurgaon ",
    img: img27,
    Namemodal: "map27"
},
{
    id: "28",
    Name: "Sector 29 Gurgaon ",
    img: img28,
    Namemodal: "map28"
},
{
    id: "29",
    Name: "Sector 31,32-A Gurgaon ",
    img: img29,
    Namemodal: "map29"
},
{
    id: "30",
    Name: "Sector 31 Gurgaon ",
    img: img30,
    Namemodal: "map30"
},
{
    id: "31",
    Name: "Sector 34 Gurgaon ",
    img: img31,
    Namemodal: "map31"
},
{
    id: "32",
    Name: "Sector 38 Gurgaon ",
    img: img32,
    Namemodal: "map32"
},
{
    id: "33",
    Name: "Sector 39 Gurgaon ",
    img: img33,
    Namemodal: "map33"
},
{
    id: "34",
    Name: "Sector 40 Gurgaon ",
    img: img34,
    Namemodal: "map34"
},
{
    id: "35",
    Name: "Sector 42 Gurgaon ",
    img: img35,
    Namemodal: "map35"
},
{
    id: "36",
    Name: "Sector 46 Gurgaon ",
    img: img36,
    Namemodal: "map36"
},
{
    id: "37",
    Name: "Sector 47 Gurgaon ",
    img: img37,
    Namemodal: "map37"
},
{
    id: "38",
    Name: "Sector 51 Gurgaon ",
    img: img38,
    Namemodal: "map38"
},
{
    id: "39",
    Name: "Sector 52 Gurgaon ",
    img: img39,
    Namemodal: "map39"
},
{
    id: "40",
    Name: "South City I Gurgaon ",
    img: img40,
    Namemodal: "map40"
},
{
    id: "41",
    Name: "Sushant Lok I  Gurgaon ",
    img: img41,
    Namemodal: "map41"
},
{
    id: "42",
    Name: "Sushant Lok II  Gurgaon ",
    img: img42,
    Namemodal: "map42"
},
{
    id: "43",
    Name: "Sushant Lok III  Gurgaon ",
    img: img43,
    Namemodal: "map43"
},
{
    id: "44",
    Name: "Udyog Vihar All Gurgaon ",
    img: img44,
    Namemodal: "map44"
},
{
    id: "45",
    Name: "uppal`s Southend Gurgaon ",
    img: img45,
    Namemodal: "map45"
},
{
    id: "46",
    Name: "Vipul World Gurgaon ",
    img: img46,
    Namemodal: "map46"
},
]
export default mapData;